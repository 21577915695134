import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import formError from "../utils/formError";
import Spinner from "../utils/Spinner";
import ClientDetailForm from "./ClientDetailForm";
import ExpiredModal from "./ExpiredModal";

const ClientDetails = () => {
  const [client, setClient] = useState();

  const history = useHistory();
  const { uuid } = useParams();
  const [error, setError] = useState(false);

  const { takeAction, loading } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `client-details-form/${uuid}`, values)
      .then(() => {
        history.push("/success");
      })
      .catch(formError);
  };

  useEffect(() => {
    takeAction("show", `client-details-form/${uuid}`)
      .then(({ data }) => setClient(data.data))
      .catch((err) => {
        Sentry.captureException(err);
        setError(true);
      });
  }, []);

  return (
    <>
      <Spinner loading={loading} />
      <ExpiredModal modal={error} />
      <div className="signin-wrapper">
        <div className="signin-box signup" style={{ width: "800px" }}>
          <h2 className="slim-logo mb-0">
            <a
              href="https://landing.thebossapp.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              theBOSSapp
            </a>
            {client && (
              <h3 className="signin-title-primary">
                Please fill out the following details for {client?.organisation}
              </h3>
            )}
          </h2>
          <ClientDetailForm
            onSubmit={onSubmit}
            client={client}
            initialValues={{
              request_account: client?.account_request,
              account_request: client?.account_request,
              name: client?.name,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ClientDetails;
