import queryString from "query-string";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as PiggyBank } from "../../svgs/piggy_bank.svg";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import { remove } from "../utils/arrayUtils";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";

import FilterButtons from "../clients/FilterButtons";
import errorSwal from "../utils/errorSwal";
import SpreadPrice from "../utils/SpreadPrice";
import SVGContainer from "../utils/SVGContainer";
import InvoiceBadge from "./InvoiceBadge";
import useUserGroup from "../hooks/useUserGroup";

const ProjectTable = ({ project }) => {
  const isAccounting = useUserGroup("Accounting");
  const { state } = useLocation();
  const [filter, setFilter] = useState(state?.filter);

  const {
    loading,
    data: invoices,
    setData: setInvoices,
    setUrl,
  } = useApi(determineUrl(filter, project));

  const { takeAction: deleteInvoice } = useApi();

  useEffect(() => {
    setUrl(determineUrl(filter, project));
  }, [filter]);

  const deleteInv = (invoice) => {
    deleteSwal(invoice.name)
      .then(() => deleteInvoice("destroy", `invoices/${invoice.uuid}`))
      .then(() => {
        setInvoices(remove("uuid", invoices, invoice));
        toast.success(`${invoice.name} deleted.`);
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info) => {
        const invoice = info.row.original;
        return <Link to={invoice.link}>{invoice.name}</Link>;
      },
    },
    {
      accessorKey: "details",
      header: "Details",
      cell: (info) => {
        const invoice = info.row.original;
        return invoice?.paid_at ? (
          <InvoiceBadge invoice={{ ...invoice, paid_at: null }} />
        ) : (
          <InvoiceBadge invoice={invoice} />
        );
      },
    },
    {
      accessorKey: "paid_at",
      header: "Paid Date",
      cell: (info) => {
        const invoice = info.row.original;
        return invoice?.paid_at ? <InvoiceBadge invoice={invoice} /> : "-";
      },
    },
    {
      accessorKey: "total_due",
      header: "Invoice Value",
      cell: (info) => <SpreadPrice price={info.row.original?.total_due} />,
    },
  ];

  if (isAccounting) {
    columns.push({
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => {
        const invoice = info.row.original;
        if (invoice.first_completed_at) return "-";
        return <DeleteButton deleteFunction={() => deleteInv(invoice)} />;
      },
    });
  }

  return (
    <>
      <div className="d-flex mb-2">
        <p className="tx-inverse mb-0 me-auto">All prices exclude GST.</p>
        <FilterButtons
          filter={filter}
          setFilter={setFilter}
          loading={loading}
        />
      </div>
      {invoices?.length === 0 && !loading ? (
        <SVGContainer SVG={PiggyBank}>
          <p className="my-3">
            No invoices found, click above to start adding!
          </p>
        </SVGContainer>
      ) : (
        <ReactTable data={invoices} columns={columns} loading={loading} />
      )}
    </>
  );
};

const determineUrl = (filter, project) => {
  return `projects/${project.uuid}/invoices?${queryString.stringify(
    filter
      ? {
          filter: filter,
        }
      : null,
  )}`;
};

export default ProjectTable;
