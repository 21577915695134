import axios from "../api/api";
import { FETCH_NUCLEAR_DENSOMETERS } from "../actions/types";

export const fetchSampleNuclearDensometers = (nucUuid) => (dispatch) => {
  return axios
    .get(`/sample-nuclear-densometers/${nucUuid}`)
    .then((response) => response.data.data)
    .then((nuclearDensometers) =>
      dispatch({
        type: FETCH_NUCLEAR_DENSOMETERS,
        payload: nuclearDensometers,
      }),
    );
};
