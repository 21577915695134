import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import { useAuth } from "../../context/auth-context";
import errorSwal from "../utils/errorSwal";
import SubmitButton from "../utils/SubmitButton";
import InitialAvatar from "../utils/InitialAvatar";

interface DocChatModalProps {
  toggle: () => void;
  modal: boolean;
}

const DocChatModal = (props: DocChatModalProps) => {
  const { toggle, modal } = props;
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const { user } = useAuth();
  const [chat, setChat] = useState<
    {
      query: string;
      answer?: string;
    }[]
  >([]);

  useEffect(() => {
    return () => setChat([]);
  }, []);

  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios
        // @ts-ignore
        .post(import.meta.env.VITE_CHAT_URL, {
          query: query,
        });
      if (res.data?.answer) {
        setChat([
          ...chat,
          {
            query: query,
            answer: res.data?.answer,
          },
        ]);
      }
    } catch (err) {
      errorSwal(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        className="wd-md-1000 mx-wd-800 w-95"
      >
        <ModalHeader toggle={toggle}>Ask Question</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-12">
                <div className="px-0 border rounded-lg">
                  {chat.map((message, index) => (
                    <React.Fragment key={index}>
                      {index > 0 ? <hr /> : null}
                      <div className="d-flex p-2 py-3 space-x-5">
                        <div>
                          <InitialAvatar
                            className=""
                            userName={user?.name ?? "-"}
                            size={30}
                            fontSize={14}
                          />
                        </div>
                        <div>
                          <div>
                            <p className="mb-0">{message.query}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex p-2 py-3 space-x-5 bg-gray-100">
                        <div>
                          <img
                            src="/img/boss-192px.png"
                            alt="The BOSS App Logo"
                            style={{ height: "30px" }}
                          />
                        </div>
                        <div>
                          <div>
                            <p className="mb-0">{message?.answer ?? ""}</p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="col-12 form-group">
                <textarea
                  name="query"
                  className="form-control"
                  style={{ height: "65px" }}
                  onChange={handleQueryChange}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            onClick={handleSubmit}
            save="Ask"
            saving="Processing..."
            className="ms-3"
            submitting={loading}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DocChatModal;
