interface IcsEvent {
  dtStart: Date;
  dtEnd: Date;
  summary: string;
  description?: string;
  location?: string;
  url?: string;
}

export const generateWebcalUrl = (event: IcsEvent): string => {
  let ics = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\n`;
  ics += `DTSTART:${event.dtStart.toISOString().replace(/[-:.]/g, "")}\n`;
  ics += `DTEND:${event.dtEnd.toISOString().replace(/[-:.]/g, "")}\n`;
  ics += `SUMMARY:${event.summary.replace(/\n/g, "\\n")}\n`;
  ics += `DESCRIPTION:${event.description?.replace(/\n/g, "\\n")}\n`;
  ics += `LOCATION:${event.location ?? ""}\n`;
  ics += `URL:${event.url ?? ""}\n`;
  ics += `END:VEVENT\nEND:VCALENDAR\n`;

  return `data:text/calendar;charset=utf8,${encodeURIComponent(ics)}`;
};

const generateIcs = (filename: string, event: IcsEvent) => {
  let ics = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\n`;
  ics += `DTSTART:${event.dtStart.toISOString()}\n`;
  ics += `DTEND:${event.dtEnd.toISOString()}\n`;
  ics += `SUMMARY:${event.summary.replace(/\n/g, "\\n")}\n`;
  ics += `DESCRIPTION:${event.description?.replace(/\n/g, "\\n")}\n`;
  ics += `LOCATION:${event.location ?? ""}\n`;
  ics += `URL:${event.url ?? ""}\n`;
  ics += `END:VEVENT\nEND:VCALENDA\n`;

  const blob = new Blob([ics], { type: "text/calendar;charset=utf-8;" });

  var link = document.createElement("a");

  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      filename.replace(/[<>:"\/\\|?*\x00-\x1F]/g, "_"),
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default generateIcs;
