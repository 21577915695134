import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import CPIRReducer from "./CPIRReducer";
import authReducer from "./authReducer";
import calibrationTrackingReducer from "./calibrationTrackingReducer";
import calibrationUnitReducer from "./calibrationUnitReducer";
import calibrationsReducer from "./calibrationsReducer";
import compactionReducer from "./compactionReducer";
import deliverableReducer from "./deliverableReducer";
import docketReducer from "./docketReducer";
import inviteReducer from "./inviteReducer";
import localStorageReducer from "./localStorageReducer";
import moistureContainerReducer from "./moistureContainerReducer";
import noticeboardReducer from "./noticeboardReducer";
import notificationsReducer from "./notificationsReducer";
import nuclearDensometerReducer from "./nuclearDensometerReducer";
import organisationReducer from "./organisationReducer";
import organisationSettingsReducer from "./organisationSettingsReducer";
import oversizeFieldMoistureReducer from "./oversizeFieldMoistureReducer";
import payReducer from "./payReducer";
import payRunTimesheetReducer from "./payRunTimesheetReducer";
import payTimesheetReducer from "./payTimesheetReducer";
import preSignedUrlReducer from "./preSignedUrlReducer";
import productivityReducer from "./productivityReducer";
import projectContactReducer from "./projectContactReducer";
import projectOverviewDateRangeReducer from "./projectOverviewDateRangeReducer";
import projectReducer from "./projectReducer";
import sampleReducer from "./sampleReducer";
import sampleTestReducer from "./sampleTestReducer";
import staffScheduleReducer from "./staffScheduleReducer";
import standardJobTypeReducer from "./standardJobTypeReducer";
import testRequestReducer from "./testRequestReducer";
import testsReducer from "./testsReducer";
import timesheetReducer from "./timesheetReducer";
import toDoReducer from "./toDoReducer";
import userReducer from "./userReducer";

export default combineReducers({
  auth: authReducer,
  calibrations: calibrationsReducer,
  calibrationTracking: calibrationTrackingReducer,
  calibrationUnits: calibrationUnitReducer,
  compactions: compactionReducer,
  CPIRs: CPIRReducer,
  deliverables: deliverableReducer,
  dockets: docketReducer,
  form: formReducer,
  invitations: inviteReducer,
  localStorage: localStorageReducer,
  moistureContainers: moistureContainerReducer,
  noticeboards: noticeboardReducer,
  notifications: notificationsReducer,
  nuclearDensometers: nuclearDensometerReducer,
  organisations: organisationReducer,
  organisationSettings: organisationSettingsReducer,
  oversizeFieldMoistures: oversizeFieldMoistureReducer,
  pays: payReducer,
  payRunTimesheets: payRunTimesheetReducer,
  payTimesheets: payTimesheetReducer,
  preSignedUrl: preSignedUrlReducer,
  productivity: productivityReducer,
  projectContacts: projectContactReducer,
  projectOverviewDateRange: projectOverviewDateRangeReducer,
  projects: projectReducer,
  samples: sampleReducer,
  sampleTests: sampleTestReducer,
  staffSchedules: staffScheduleReducer,
  standardJobTypes: standardJobTypeReducer,
  tests: testsReducer,
  testRequests: testRequestReducer,
  timesheets: timesheetReducer,
  toDos: toDoReducer,
  users: userReducer,
});
