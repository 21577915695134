import { useEffect, useState } from "react";
import { Popover } from "reactstrap";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";

const UserPopover = ({
  user,
  target,
}: {
  user: {
    uuid: string;
    name: string;
  };
  target: string;
}) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const { data, loading } = useApi(`/basic-users/${user.uuid}`, null, true);

  let timeout: any = null;

  return (
    <div
      onMouseEnter={() => {
        if (timeout) {
          clearTimeout(timeout);
        }
        setOpen(true);
      }}
      onMouseLeave={() => {
        timeout = setTimeout(() => setOpen(false), 500);
      }}
    >
      <button
        type="button"
        className="text-secondary text-start bg-transparent border-0 p-0"
        id={target}
      >
        {user.name}
      </button>
      {show && (
        <Popover
          className="rounded-lg"
          style={{ width: "250px" }}
          target={target}
          isOpen={open}
        >
          {loading ? (
            <CustomScaleLoader>Fetching Data...</CustomScaleLoader>
          ) : (
            <div>
              <div className="p-3">
                <p className="mb-0 text-dark tx-16">{user.name}</p>
                <p className="mb-0">{data.primary_position?.name}</p>
                <p className="mb-0 tx-12">{data.primary_position?.branch}</p>
              </div>
              <div className="p-3 bg-gray-100 d-flex space-x-3 border-top">
                <a
                  href={`mailto:${data?.email}`}
                  className="btn text-white btn-info btn-sm"
                >
                  Email
                </a>
                <a href={`tel:${data?.phone}`} className="btn btn-sm border">
                  Call
                </a>
              </div>
            </div>
          )}
        </Popover>
      )}
    </div>
  );
};

export default UserPopover;
