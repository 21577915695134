import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { DraftEditorCommand, EditorState, RichUtils } from "draft-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../data/colors";
import useApi from "../api/useApi";
import "draft-js/dist/Draft.css";

const MentionField = (props: any) => {
  const {
    input,
    required,
    label,
    meta: { touched, error, warning },
    fieldClass,
  } = props;

  const ref = useRef<Editor>(null);

  const { data, takeAction } = useApi("", []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    props.input.onChange(editorState);
  }, [editorState]);

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>(data);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return (
          <Link
            style={{
              backgroundColor: colors.info.light,
              borderColor: colors.info.border,
              color: colors.info.main,
            }}
            className="link-hover p-1 rounded-lg"
            to={mentionProps.mention.link ?? ""}
          >
            {mentionProps.mention.name}
          </Link>
        );
      },
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback((values: { value: string }) => {
    setSuggestions([]);
    if (!values.value) {
      return;
    }

    takeAction("index", `mention-search?search=${values.value}`).then(
      ({ data }: { data: { data: MentionData[] } }) => {
        setSuggestions(data.data);
      },
    );
  }, []);

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState,
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  return (
    <>
      <label className="form-control-label tx-inverse tx-semibold">
        {label}
        {required ? <span className="tx-danger"> *</span> : ""}
      </label>
      <div
        className={
          fieldClass ?? "bg-white p-2 form-control-border shadow-sm rounded-lg"
        }
        onClick={() => {
          ref.current?.focus();
        }}
      >
        <Editor
          {...props}
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          ref={ref}
          editorKey={"editor"}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />
      </div>
    </>
  );
};

export default MentionField;
