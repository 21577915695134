import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import errorSwal from "../utils/errorSwal";
import axios from "../api/api";
import DocsModal from "../utils/DocsModal";

export const PendingApprovals = ({ user }) => {
  const [approvals, setApprovals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/user-pending-approval-actions/${user.uuid}`)
      .then(({ data }) => {
        setApprovals(data.data);
      })
      .catch(errorSwal)
      .then(() => setLoading(false));
  }, []);

  const columns = [
    {
      accessorKey: "title",
      header: "Name",
      cell: info => <Link to={info.row.original.link}>{info.getValue()}</Link>,
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/approvals/" />
      <ReactTable
        disableSearch
        columns={columns}
        data={approvals}
        loading={loading}
      />
    </>
  );
};
