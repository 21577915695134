import React from "react";
import Edit from "./Edit";
import NotFound from "../404";
import ProfileSingleFile from "../utils/ProfileSingleFile";

const ShowPage = (props) => {
  const { initialValues, match, payment, setPayment } = props;
  const { page, uuid } = match.params;

  const whenSubmitted = ({ data }) => {
    setPayment({
      ...payment,
      invoice: {
        ...payment.invoice,
        file: data.data,
      },
    });
  };

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={initialValues} />;
    case "invoice":
      return (
        <ProfileSingleFile
          whenSubmitted={whenSubmitted}
          {...props}
          link={payment.invoice.file.link}
          mime_type={payment.invoice.file.mime_type}
          documentable_id={payment.invoice.uuid}
          documentable_type="App\Models\PurchaseInvoice"
          bucket={`purchase-invoices/${payment.uuid}`}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
