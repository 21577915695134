import React from "react";
import { Badge } from "reactstrap";
import getStatus from "./getStatus";

const StatusBadge = ({ purchase }) => {
  const status = getStatus(purchase);
  return <Badge color={status.color}>{status.text}</Badge>;
};

export default StatusBadge;
