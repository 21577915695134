export type UserSettingKeys = "load_work_day" | "auto_fill_purchase_branch";

export enum AdministrationLevels {
  Senior,
  Junior,
  Basic,
}

export interface UserIndex {
  id: number;
  uuid: string;
  full_name: string;
  email: string;
  phone: string;
  link: string;
  is_admin: boolean;
  jobs_count?: number;
  primary_position?: {
    name: string;
  };
}

export interface EmployedUser {
  name: string;
  id: number;
  uuid: string;
  email: string;
}

export interface AuthUser {
  name: string;
  uuid: string;
  id: number;
  email: string;
  has_mfa: boolean;
  two_factor_secret: boolean;
  two_factor_required_at?: {
    display_name: string;
    two_factor_enabled_at: string;
  };
  settings: {
    [k in UserSettingKeys]: boolean;
  };
  is_position_admin: boolean;
  is_admin: boolean;
  position_administration_level: AdministrationLevels;
  default_branch_id: number;
  active_organisation: {
    name: string;
    display_name: string;
    uuid: string;
    id: number;
    trading_name: string;
    beta: boolean;
    timezone: string;
    syncable_organisation_id?: number;
  };
  acl_items: {
    model: string;
    method: string;
  }[];
  navigation: {
    name: string;
    links: {
      value: string;
      link: string;
    }[];
  }[];

  multiple_organisations: boolean;
  hasAccessTo: (model: string, method: string) => boolean;
  groups: string[];
}

export interface IncompleteUserTask {
  title: string;
  sub_title: string;
  items: {
    name: string;
    link: string;
  }[];
  count: number;
}

export interface Tab {
  link: String;
  page: String;
  icon: String;
  label: String;
}

export enum GroupType {
  Accounting = "Accounting",
  Safety = "Safety",
  HR = "HR",
  Administration = "Administration",
  Procedures = "Procedures",
  Compliance = "Compliance",
  Equipment = "Equipment",
  BusinessDevelopment = "Business Development",
  IT = "I.T.",
  Marketing = "Marketing",
}
