import { Route, Switch } from "react-router-dom";
import SignUpUser from "./components/auth/SignUpUser";
import ExternalClientFeedbackForm from "./components/clientFeedback/ExternalClientFeedbackForm";
import ClientDetails from "./components/clients/ClientDetails";
import ExternalJobForm from "./components/customJobs/jobs/ExternalJobForm";
import ForgotPassword from "./components/login/ForgotPassword";
import Login from "./components/login/Login";
import Register from "./components/login/Register";
import ResetPassword from "./components/login/ResetPassword";
import SharedFiles from "./components/standardDocuments/SharedFiles";
import Success from "./components/Success";
import ExternalInsurances from "./components/suppliers/ExternalInsurances";
import ExternalPage from "./components/suppliers/ExternalPage";
import Terms from "./components/termsOfService/Terms";
import UnauthenticatedFallback from "./UnauthenticatedFallback";
import { CreateZipProvider } from "./context/createZip-context";
import TwoFactorChallenge from "./components/login/TwoFactorChallenge";
import TwoFactorRequired from "./components/login/TwoFactorRequired";

const UnauthenticatedApp = () => {
  return (
    <CreateZipProvider>
      <Switch>
        <Route exact path="/client-details/:uuid" component={ClientDetails} />
        <Route exact path="/supplier-details/:uuid" component={ExternalPage} />
        <Route
          exact
          path="/required-insurances/:uuid"
          component={ExternalInsurances}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/terms-of-service" component={Terms} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/signup/user/:uuid" component={SignUpUser} />
        <Route exact path="/reset/:uuid?" component={ResetPassword} />
        <Route exact path="/register/:type/:uuid?" component={Register} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/external-jobs/:uuid" component={ExternalJobForm} />
        <Route
          exact
          path="/two-factor-challenge"
          component={TwoFactorChallenge}
        />
        <Route
          exact
          path="/two-factor-required"
          component={TwoFactorRequired}
        />
        <Route
          exact
          path="/shared-files/:uuid?/:folderUuid?"
          component={SharedFiles}
        />
        <Route
          exact
          path="/client-feedback/:uuid"
          component={ExternalClientFeedbackForm}
        />
        <Route component={UnauthenticatedFallback} />
      </Switch>
    </CreateZipProvider>
  );
};

export default UnauthenticatedApp;
