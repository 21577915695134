import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Badge } from "reactstrap";
import { formValueSelector } from "redux-form";
import { setLocalStorage } from "../../actions/localStorageActions";
import { cancelEmployment } from "../../actions/organisationUserActions";
import { modifyDate } from "../../actions/timesheetActions";
import { fetchUsers } from "../../actions/userActions";
import { useAuth } from "../../context/auth-context";
import { TimesheetProvider } from "../../context/timesheet-context";
import axios from "../api/api";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import CurrentTimesheetProductivityOverview from "../timesheets/CurrentTimesheetProductivityOverview";
import TimesheetDayPicker from "../timesheets/TimesheetDayPicker";
import * as header from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import EmploymentButtons from "./EmploymentButtons";
import PositionsWidget from "./PositionsWidget";
import RequestLinks from "./RequestLinks";
import ShowPage from "./ShowPage";
import UserProfileTabs from "./UserProfileTabs";

const Profile = (props) => {
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);
  const { userId, page, folderUuid } = useParams();
  const { user: authUser } = useAuth();

  useEffect(() => fetchUsers(), []);

  useEffect(() => fetchUsers(), [userId]);

  const fetchUsers = () => {
    setLoading(true);

    axios
      .get(`users/${userId}`)
      .then(({ data }) => {
        setUser(data.data.user);
        setLoading(false);
      })
      .catch(errorSwal);
  };

  const handleDayClick = (day, { selected }) => {
    return selected ? undefined : props.modifyDate(user, day);
  };

  if (loading) return <Spinner loading />;

  const links = [
    {
      link: "/",
      name: `${user.first_name} ${user.last_name}`,
      active: true,
    },
  ];

  if (authUser.is_admin) {
    links.unshift({
      link: "/users",
      name: "Users",
    });
    links.unshift({
      link: "/organisations/settings/links",
      name: "Organisation",
    });
  }

  return (
    <TimesheetProvider>
      <HeaderPage titlePage="Profile" crumbs={links} />
      <div className="row row-sm">
        <div className="col-lg-8">
          <header.DashboardHeader>
            <header.DashboardH3>
              {`${user.first_name} ${
                user.display_name ? `(${user.display_name})` : ""
              } ${user.last_name}`}
            </header.DashboardH3>
            <header.DashboardSubTitle>{user.phone}</header.DashboardSubTitle>
            <header.DashboardSubTitle>{user.email}</header.DashboardSubTitle>
            <header.DashboardSubTitle>
              Joined on: {dayjs(user.created_at).format("DD/MM/YYYY")}
            </header.DashboardSubTitle>
            {user.is_admin ? (
              <header.DashboardSubTitle>
                <Badge color="info">Administrator</Badge>
              </header.DashboardSubTitle>
            ) : null}
          </header.DashboardHeader>
          <RequestLinks user={user} className="d-lg-none" />
          <ShowPage {...props} user={user} setUser={setUser} />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <UserProfileTabs {...props} user={user} />
          <PositionsWidget positions={user.positions} {...props} />
          {authUser.is_admin && (
            <>
              <EmploymentButtons
                uuid={user.organisation_user_uuid}
                user={user}
                setUser={setUser}
              />
              <UpdateAdministratorStatus
                admin={user.is_admin}
                uuid={user.organisation_user_uuid}
                setUser={setUser}
              />
            </>
          )}
          {page === "timesheets" && (
            <>
              <RequestLinks user={user} className="d-none d-lg-flex" />
              <div className="card mt-3 d-none d-lg-block">
                <TimesheetDayPicker
                  user={user}
                  handleDayClick={handleDayClick}
                />
              </div>
              <div className="row mt-3">
                <CurrentTimesheetProductivityOverview
                  width="12"
                  addTimesheet={folderUuid === "add"}
                  userUuid={user.uuid}
                  form="AddTimesheet"
                />
              </div>
            </>
          )}
          <div className="mt-3">
            <SideList
              commentableType="App\Models\User"
              commentableId={user.id}
            />
          </div>
        </div>
      </div>
    </TimesheetProvider>
  );
};

const mapStateToProps = (state) => {
  const selector = formValueSelector("UserDetail");
  const physicalAddress = selector(
    state,
    "line_1",
    "line_2",
    "phone",
    "suburb",
    "postcode",
    "state",
  );

  return {
    user: state.users.items && state.users.items.user,
    physicalAddress,
    addTimesheet: state.timesheets.addTimesheet,
    selectedDay: state.timesheets.selectedDay,
    bricks: state.localStorage.bricks,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  modifyDate,
  cancelEmployment,
  setLocalStorage,
})(Profile);

const UpdateAdministratorStatus = ({ admin, uuid, setUser }) => {
  const [submitting, setSubmitting] = useState(false);

  const buttonText = admin
    ? isSubmitting(submitting, "Remove as Administrator", "Removing...")
    : isSubmitting(submitting, "Add as Administrator", "Adding...");

  const url = `organisation-administrators${admin ? `/${uuid}` : ""}`;
  const updateStatus = () => {
    setSubmitting(true);
    return addOrDelete(url, admin, uuid)
      .then(({ data }) => {
        toast.success("User updated");
        setUser(data.data.user);
      })
      .catch(errorSwal)
      .then(() => setSubmitting(false));
  };

  return (
    <button
      className="btn btn-outline-secondary mt-3 w-100"
      onClick={updateStatus}
      disabled={submitting}
    >
      {buttonText}
    </button>
  );
};

const addOrDelete = (url, admin, uuid) => {
  const newUrl = `/${url}`;
  if (admin) {
    return axios.delete(newUrl);
  }

  return axios.post(newUrl, { uuid });
};
