import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedList from "../pagination/PaginatedList";
import usePurchases from "./hooks/usePurchases";
import PurchaseTable from "./PurchaseTable";
import {
  Purchase,
  PurchaseArchiveStatus,
  PurchaseGSTType,
  PurchaseStatus,
  PurchaseType,
} from "./purchaseTypes";
import { Project } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

type PurchaseFilters = IFilter<
  "status" | "includes_gst" | "type" | "archive_status",
  PurchaseStatus | PurchaseGSTType | PurchaseType | PurchaseArchiveStatus
>;

const List = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/purchasing/" />
      <HeaderPage
        titlePage="Purchases"
        pageDescription={
          <>
            The purchasing feature streamlines procurement and expense tracking
            by centralizing standard purchases, purchase orders, and contract
            agreements under projects, while utilizing account codes and
            branches for detailed expense categorization, enabling informed
            financial decisions and resource allocation.
          </>
        }
        relatedLinks={[{ name: "Projects", link: "/projects", model: Project }]}
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          { link: "", name: "Purchases", active: true },
        ]}
      />
      <PaginatedList
        indexHook={usePurchases}
        originalFilters={purchaseFilters}
        list={({ data }: { data?: Purchase[] }) => (
          <div className="col-12">
            <PurchaseTable data={data} />
          </div>
        )}
      />
    </>
  );
};

export const purchaseFilters: PurchaseFilters[] = [
  {
    label: "Type",
    name: "type",
    options: [
      {
        label: "Payment",
        value: PurchaseType.CreditCard,
      },
      {
        label: "Purchase Order",
        value: PurchaseType.PurchaseOrder,
      },
      {
        label: "Contract Agreement",
        value: PurchaseType.RecurringPurchase,
      },
    ],
  },
  {
    label: "Status",
    name: "status",
    options: [
      {
        label: "Billed",
        value: PurchaseStatus.Billed,
      },
      {
        label: "Ready To Be Billed",
        value: PurchaseStatus.ReadyToBeBilled,
      },
      {
        label: "Not Ready To Be Billed",
        value: PurchaseStatus.NotReadyToBeBilled,
      },
    ],
  },
  {
    label: "Archive Status",
    name: "archive_status",
    options: [
      {
        label: "Archived",
        value: PurchaseArchiveStatus.Archived,
      },
      {
        label: "Not Archived",
        value: PurchaseArchiveStatus.NotArchived,
      },
    ],
  },
  {
    label: "Tax Type",
    name: "includes_gst",
    options: [
      {
        label: "GST Included",
        value: PurchaseGSTType.GSTIncluded,
      },
      {
        label: "GST Excluded",
        value: PurchaseGSTType.GSTExcluded,
      },
      {
        label: "N/A",
        value: PurchaseGSTType.NA,
      },
    ],
  },
];

export default List;
