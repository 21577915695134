import { Field, formValueSelector, InjectedFormProps } from "redux-form";

import { connect } from "react-redux";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { useEffect } from "react";
import PayRunOverview from "./PayRunOverview";
import SelectInput from "../form/SelectInput";
import renderToggleInput from "../utils/renderToggleInput";

export interface TimesheetUser {
  user: {
    name: string;
    email: string;
    phone: string;
  };
  timesheets: {
    date: string;
    uuid: string;
    link: string;
    pay: number;
    type_description: string;
    total_hours: number;
  }[];
}

const PayRunForm = (
  props: InjectedFormProps & {
    startDate?: string;
    finishDate?: string;
    branches?: any[];
    onlyDatesWithinPeriod?: boolean;
  },
) => {
  const { startDate, finishDate, branches, onlyDatesWithinPeriod } = props;

  const { data, setUrl, loading } = useApi("", []);

  const timesheetUsers = data as TimesheetUser[];

  useEffect(() => {
    if (startDate && finishDate) {
      let url = `pay-run-overview?start_date=${startDate}&finish_date=${finishDate}`;

      if (branches) {
        url += branches.map((b) => `&branches[]=${b.value}`).join("");
      }

      if (onlyDatesWithinPeriod) {
        url += `&only_dates_within_period=true`;
      }

      setUrl(url);

      return;
    }
  }, [startDate, finishDate, branches, onlyDatesWithinPeriod]);

  return (
    <>
      <div className="row">
        <div className="col-lg-3 form-group">
          <Field
            name="start_date"
            label="Start Date"
            type="date"
            component={renderField}
            required
            validate={required}
          />
        </div>
        <div className="col-lg-3 form-group">
          <Field
            name="finish_date"
            type="date"
            label="Finish Date"
            component={renderField}
            required
            validate={required}
          />
        </div>

        <div className="form-group col-lg-4">
          <Field
            component={SelectInput}
            isMulti
            name="branches"
            label="Branches"
            url="organisation-branches"
            formatData={(data: any[]) =>
              data.map((d) => {
                return {
                  label: d.name,
                  value: d.id,
                };
              })
            }
          />
          <small>Leave blank to include timesheets across all branches.</small>
        </div>
        <div className="col-lg-2">
          <Field
            component={renderToggleInput}
            name="only_dates_within_period"
            label="Only Dates Within Period"
          />
        </div>
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      </div>
      <PayRunOverview
        startDate={startDate}
        finishDate={finishDate}
        timesheetUsers={timesheetUsers}
        loading={loading}
      />
    </>
  );
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);

  return {
    startDate: selector(state, "start_date"),
    finishDate: selector(state, "finish_date"),
    branches: selector(state, "branches"),
    onlyDatesWithinPeriod: selector(state, "only_dates_within_period"),
  };
};

export default connect(mapStateToProps, {})(PayRunForm);
