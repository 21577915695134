import { Field, FormSubmitHandler } from "redux-form";
import useApi from "../../api/useApi";
import FormModal from "../../utils/FormModal";
import { IUseApi } from "../../api/apiTypes";
import { toast } from "react-toastify";
import formError from "../../utils/formError";
import AsyncSelectInput from "../../form/AsyncSelectInput";
import api from "../../api/api";
import required from "../../utils/required";

const SaveEmailToProjectModal = ({
  toggle,
  modal,
  conversations,
}: {
  toggle: () => void;
  modal: boolean;
  conversations: string[];
}) => {
  const { takeAction }: IUseApi<{}, { data: any[] }> = useApi();

  const onSubmit: FormSubmitHandler<{ project_id: number }> = (values) => {
    return takeAction("store", "mail-conversations", {
      conversable_type: "App\\Models\\Project",
      conversable_id: values.project_id,
      //Do this to get unique values
      conversations: [...new Set(conversations)],
    })
      .then(() => {
        toast.success("Emails saved to project");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      onSubmit={onSubmit}
      title="Add Email to Project"
      form="AddEmailToProject"
    >
      <div className="form-group col-12">
        <Field
          component={AsyncSelectInput}
          name="project_id"
          label="Project"
          asyncFunction={(inputValue: string) =>
            api
              .get(`/schedule-projects?filter[search]=${inputValue}`)
              .then(({ data }) => data.data)
          }
          validate={required}
          required
        />
      </div>
    </FormModal>
  );
};

export default SaveEmailToProjectModal;
