import { useParams } from "react-router";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import BranchCpirs from "./BranchCpirs";
import BranchDeliverables from "./BranchDeliverables";
import BranchEquipment from "./BranchEquipment";
import BranchJobs from "./BranchJobs";
import BranchLeaveCalendar from "./BranchLeaveCalendar";
import { BranchProfilePages } from "./BranchProfile";
import BranchProjectsList from "./BranchProjectsList";
import BranchStaff from "./BranchStaff";
import EditBranch from "./Edit";
import BranchTenders from "./BranchTenders";

const ShowBranchPage = (props: { branch: any; setBranch: Function }) => {
  const { user } = useAuth();
  const { page, uuid } = useParams<{
    page: BranchProfilePages;
    uuid: string;
  }>();

  switch (page) {
    case "details":
      return <EditBranch {...props} initialValues={props.branch} />;
    case "staff":
      return <BranchStaff />;
    case "leave":
      return <BranchLeaveCalendar branch={props.branch} />;
    case "equipment":
      return <BranchEquipment branch={props.branch} />;
    case "projects":
      return <BranchProjectsList />;
    case "cpirs":
      return <BranchCpirs branch={props.branch} />;
    case "jobs":
      return <BranchJobs />;
    case "deliverables":
      return <BranchDeliverables />;
    case "documents":
      return (
        <NewProfileDocuments
          {...props}
          model="App\Models\OrganisationBranch"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation?.uuid}/documents/branches/${uuid}`}
          homeUrl={`projects/${uuid}/documents`}
        />
      );
    case "tenders":
      return user?.is_position_admin ? (
        <BranchTenders branch={props.branch} />
      ) : (
        <NotFound />
      );
    default:
      return <NotFound />;
  }
};

export default ShowBranchPage;
