import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import DownloadLogModal from "../documents/DownloadLogModal";
import useModal from "../hooks/useModal";
import PermissionsModal from "./PermissionsModal";
import RenameModal from "./RenameModal";
import ManageFileSharingModal from "./ManageFileSharingModal";

const DropDown = (props) => {
  const { doc, deleteDocument, documents, setDocuments } = props;
  const { toggle, modal } = useModal();
  const { user } = useAuth();
  const { modal: downloadLogModal, toggle: toggleDownloadLogVersion } =
    useModal();
  const { takeAction } = useApi();
  const { modal: permissionModal, toggle: togglePermission } = useModal();
  const { modal: shareModal, toggle: toggleShare } = useModal();

  return (
    <>
      <div className="col-6 col-sm-1 tx-right mg-t-5 mg-sm-t-0">
        <UncontrolledDropdown
          onClick={(e) => {
            e.preventDefault();
            return e.stopPropagation();
          }}
        >
          <DropdownToggle color="link" className="text-secondary">
            <i className="icon ion-more" />
            <i className="icon wb-more-vertical" aria-hidden="true" />
          </DropdownToggle>
          <DropdownMenu>
            {!doc.is_folder && (
              <DropdownItem
                onClick={() => {
                  takeAction("store", "document-downloads", {
                    document_type: "App\\Models\\StandardDocument",
                    document_id: doc.id,
                  }).then(() => window.location.replace(doc.link));
                }}
              >
                Download
              </DropdownItem>
            )}
            <DropdownItem onClick={toggle}>Rename</DropdownItem>
            <DropdownItem onClick={deleteDocument}>Delete</DropdownItem>
            {!doc.is_folder && user.is_admin && (
              <DropdownItem onClick={toggleDownloadLogVersion}>
                View Download Log
              </DropdownItem>
            )}
            {user.is_admin && (
              <>
                <DropdownItem onClick={togglePermission}>
                  Edit Permissions
                </DropdownItem>
              </>
            )}
            <DropdownItem onClick={toggleShare}>
              {user.is_admin ? "Manage" : "View"} File Share
            </DropdownItem>
            {doc?.external_links?.map((link) => {
              return (
                <DropdownItem
                  key={link.url}
                  onClick={(e) => {
                    window.open(link.url, "_blank");
                  }}
                >
                  <img
                    src={link.icon}
                    width="20"
                    alt={`Open in ${link.name}`}
                  />{" "}
                  Open in {link.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <RenameModal
        toggle={toggle}
        modal={modal}
        document={doc}
        initialValues={{ name: doc.name }}
        form={`RenameDocument${doc.uuid}`}
        {...props}
      />
      <DownloadLogModal
        modal={downloadLogModal}
        toggle={toggleDownloadLogVersion}
        document={doc}
        document_type={"App\\Models\\StandardDocument"}
      />
      <PermissionsModal
        toggle={togglePermission}
        modal={permissionModal}
        document={doc}
        setDocuments={props.setDocuments}
        documents={props.documents}
        handleDoubleClick={(e) => e.stopPropagation()}
      />
      {shareModal && (
        <ManageFileSharingModal
          doc={doc}
          toggle={toggleShare}
          modal={shareModal}
          query={{
            documentableType: "App\\Models\\StandardDocument",
            documentableId: doc.uuid,
          }}
          title={`Manage ${doc.name} sharing`}
          documents={documents}
          setDocuments={setDocuments}
        />
      )}
    </>
  );
};

export default DropDown;
