import { useParams } from "react-router-dom";
import EditEmploymentDetails from "./EditEmploymentDetails";
import { EmploymentDetailIndex } from "./employmentDetailTypes";
import EmploymentDetailHolds from "./EmploymentDetailHolds";

interface ShowPageProps {
  employmentDetails: EmploymentDetailIndex;
  setEmploymentDetails: (details: EmploymentDetailIndex) => void;
}

const ShowPage = (props: ShowPageProps) => {
  const { page } = useParams<{ page: string }>();
  switch (page) {
    case "details":
      return <EditEmploymentDetails {...props} />;
    case "holds":
      return <EmploymentDetailHolds {...props} />;
    default:
      return null;
  }
};

export default ShowPage;
