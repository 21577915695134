import { Field, FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import HazardReportAnswer from "./HazardReportAnswer";
import { HazardReport } from "./types";

const HazardReportForm = (props: InjectedFormProps<HazardReport>) => {
  const { handleSubmit, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="prompt"
            label="What prompted you to undertake this take 5?"
          />
        </div>
        <FormHeader>Questions</FormHeader>
        <FieldArray {...props} component={HazardReportAnswer} name="answers" />
        <div className="form-group col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<HazardReport>({});

export default form(HazardReportForm);
