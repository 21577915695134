import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useAuth } from "../../../context/auth-context";
import {
  CustomJob,
  Docket,
  OrganisationBranch,
  Project,
} from "../../../enums/Model";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import useBranchFilters from "../../branches/hooks/useBranchFilters";
import HeaderPage from "../../header/HeaderPage";
import PaginatedList from "../../pagination/PaginatedList";
import {
  StatusBubble,
  statusDetails,
} from "../../projectManagers/ProjectManagerJob";
import { jobFilters } from "../../projectManagers/ProjectManagerJobs";
import { AuthUser } from "../../user/userTypes";
import BottomLogo from "../../utils/BottomLogo";
import errorSwal from "../../utils/errorSwal";
import formatMoney from "../../utils/formatMoney";
import useJobs from "../hooks/useJobs";
import { IndexJob } from "../jobTypes";
import DocsModal from "../../utils/DocsModal";
import FieldInformationPopOver from "../../utils/FieldInformationPopOver";

const JobRegister = () => {
  const branchFilters = useBranchFilters();
  const { user } = useAuth();

  const authUser = user as AuthUser;

  const [baseFilters, setBaseFilters] = useState(jobFilters);

  useEffect(() => {
    setBaseFilters([
      {
        label: "Your Jobs",
        name: "user_is_part_of_job",
        options: [
          {
            label: "Your Jobs",
            value: authUser.id,
            selected: true,
          },
        ],
      },
      ...jobFilters,
      {
        label: "Branch",
        name: "organisation_branches.id",
        options: branchFilters.map((branch: { name: string; id: number }) => {
          return {
            label: branch.name,
            value: branch.id,
          };
        }),
      },
    ]);
  }, [branchFilters]);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/jobs/" />
      <HeaderPage
        titlePage="Jobs"
        pageDescription={
          <>
            Assign work to employees and monitor the progress of each job.
            Additionally, you can also add deliverables to jobs and then invoice
            those deliverables.
          </>
        }
        relatedLinks={[
          { name: "Dockets", link: "/dockets", model: Docket },
          { name: "Custom Jobs", link: "/custom-jobs", model: CustomJob },
          { name: "Branches", link: "/branches", model: OrganisationBranch },
          { name: "Projects", link: "/projects", model: Project },
        ]}
        crumbs={[{ link: "/jobs", name: "Jobs", active: true }]}
      />
      <PaginatedList
        indexHook={useJobs}
        originalFilters={baseFilters}
        itemCard={({ item }: { item: IndexJob }) => (
          <JobCard key={item.uuid} item={item} />
        )}
        extraButtons={({ searchQuery }) => (
          <>
            <FieldInformationPopOver information="Filters now default to 'Your Jobs'" />
            <DownloadJobs searchQuery={searchQuery} />
          </>
        )}
      />
    </>
  );
};

const JobCard = ({ item }: { item: IndexJob }) => {
  const statusInfo = statusDetails(item);
  const { user } = useAuth();

  const info: { name: string; value?: string; link?: string }[] = [
    {
      link: item.project.link,
      name: "Project",
      value: item.project.name,
    },
    {
      name: "Branch",
      value: item.branch.name,
    },
    {
      name: "Job Type",
      value: item.type.name,
    },
    {
      name: "Manager",
      value: item.manager.name,
    },
    {
      name: "Created At",
      value: dayjs(item.created_at).format("DD/MM/YYYY"),
    },
    {
      name: item.actual_start_date ? "Start Date" : "Scheduled Start Date",
      value: item.actual_start_date
        ? dayjs(item.actual_start_date).format("DD/MM/YYYY")
        : dayjs(item.scheduled_start_date).format("DD/MM/YYYY"),
    },
    {
      name: item.actual_finish_date ? "Finish Date" : "Scheduled Finish Date",
      value: item.actual_finish_date
        ? dayjs(item.actual_finish_date).format("DD/MM/YYYY")
        : dayjs(item.scheduled_finish_date).format("DD/MM/YYYY"),
    },
  ];

  if (user?.is_position_admin) {
    info.push(
      {
        name: "Estimated Value",
        value: `$${formatMoney(item.estimated_value)}`,
      },
      {
        name: "Completed Value",
        value: `$${formatMoney(item.completed_value)}`,
      },
    );
  }

  return (
    <div className="col-lg-4 col-12 py-2">
      <div className="bg-white p-3 border shadow-sm rounded-lg position-relative paginated-card tn-1000">
        <div style={{ zIndex: 100 }} className="position-relative">
          <div className="d-flex pb-4">
            <div className="width-25">
              <div
                id={`job_status_${item.uuid}`}
                className={`me-2 d-flex justify-content-center align-items-center rounded-circle shadow bg-grad-${statusInfo.color}`}
                style={{ height: "40px", width: "40px" }}
              >
                <StatusBubble job={item} />
                {item.uuid && (
                  <UncontrolledTooltip target={`job_status_${item.uuid}`}>
                    {statusInfo.text}
                  </UncontrolledTooltip>
                )}
              </div>
            </div>
            <div className="text-justify no-wrap">
              <Link
                id={`job_${item.uuid}`}
                to={item.link}
                className="mb-2 tx-inverse fw-bold"
              >
                {item.name}
              </Link>
              {item.uuid && (
                <UncontrolledTooltip
                  placement="top-start"
                  target={`job_${item.uuid}`}
                >
                  {item.name}
                </UncontrolledTooltip>
              )}
              <p className="mb-0 tx-12">{item.number}</p>
            </div>
          </div>
          <div className="mt-4">
            <div className="space-y-3">
              {info.map((information, index) => {
                return (
                  <div
                    className="d-flex pb-3"
                    style={{
                      borderBottom:
                        index !== info.length - 1 ? "1px dotted #dee2e6 " : "",
                    }}
                    key={information.name}
                  >
                    <p className="mb-0">{information.name} </p>
                    <p className="mb-0 ms-auto">
                      {information.link ? (
                        <Link className=" text-secondary" to={information.link}>
                          {information.value}
                        </Link>
                      ) : (
                        information.value ?? "-"
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <BottomLogo />
      </div>
    </div>
  );
};

const DownloadJobs = ({ searchQuery }: { searchQuery?: string }) => {
  const { user } = useAuth();

  const { takeAction, loading }: IUseApi<{}, { data: string }> = useApi();

  const downloadFile = () => {
    takeAction("show", `jobs/csv?filter[search]=${searchQuery}`)
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  if (!user?.is_admin) {
    return null;
  }

  return (
    <Button
      color="link"
      className="text-decoration-none"
      onClick={downloadFile}
      disabled={loading}
    >
      {loading ? (
        <i className="fa fa-spinner fa-spin" />
      ) : (
        <FiDownload className="tx-16" />
      )}
    </Button>
  );
};

export default JobRegister;
