import React from "react";
import { useParams } from "react-router-dom";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import LeaveForm from "./LeaveForm";
import { IRequestedLeave, RequestedLeaveProps } from "./leaveTypes";
import { toast } from "react-toastify";

const EditRequestedLeave = (props: RequestedLeaveProps) => {
  const { requestedLeave, setRequestedLeave } = props;

  const { uuid } = useParams<{ uuid: string }>();

  const { takeAction }: IUseApi<{}, { data: IRequestedLeave }> = useApi();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("update", `requested-leave/${uuid}`, values)
      .then(({ data }) => {
        setRequestedLeave(data.data);
        toast.success("Leave Request Updated");
      })
      .catch(formError);
  };

  return (
    <>
      <LeaveForm
        form="EditRequestedLeave"
        initialValues={{
          ...requestedLeave,
          multi_day: !requestedLeave.date,
        }}
        onSubmit={onSubmit}
        /** @ts-ignore */
        userId={requestedLeave.user_id}
      />
    </>
  );
};

export default EditRequestedLeave;
