import dayjs from "dayjs";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import useApi from "../api/useApi";

import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";
import ProjectForm from "./ProjectForm";

const AddProject = (props) => {
  const { history, handleSubmit } = props;

  const { user } = useAuth();
  const { takeAction } = useApi();

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/projects/required-information`,
  );

  const onSubmit = (values) => {
    return upload(values)
      .then((data) => takeAction("store", "projects", data))
      .then(({ data }) => {
        toast.success("Project created successfully!");
        history.push(`/projects/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };
  const { takeAction: generatePrompt, loading: generatingPrompt } = useApi();

  const onPrompt = (fieldUuid, prompt, name, change) => {
    return generatePrompt("store", "general-prompts", {
      prompts: [
        {
          role: "user",
          content: prompt,
        },
      ],
    }).then(({ data }) => {
      change(name, data.data);
    });
  };

  return (
    <>
      <HeaderPage
        titlePage="New project"
        crumbs={[
          { link: "projects", name: "Projects" },
          { link: "projects/add", name: "New Project", active: true },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProjectForm
          onPrompt={onPrompt}
          generatingPrompt={generatingPrompt}
          adding={true}
          {...props}
        />
      </form>
    </>
  );
};

const form = reduxForm({
  form: "Project",
  initialValues: {
    status: "0",
    scheduled_start_date: dayjs().format("YYYY-MM-DD"),
  },
});

export default form(AddProject);
