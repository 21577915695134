import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DocumentModal = props => {
  const { modal, toggle, link, title } = props;

  const url = embedded => {
    return `https://docs.google.com/viewer?embedded=${embedded}&url=${encodeURIComponent(
      link
    )}`;
  };

  const iframeStyle = {
    width: "100%",
    height: "90%",
    position: "fixed",
    left: "0px",
    top: "60px",
    border: "0",
    zIndex: "999999"
  };

  const Header = () => {
    return (
      <h5 className="d-flex w-100 modal-title">
        <span className="tx-white">{title} </span>
        <a
          href={url(false)}
          target="_blank"
          className="ms-auto me-1"
          rel="noopener noreferrer"
        >
          <span aria-hidden="true">
            <i className="fa fa-print tx-20 tx-white" />
          </span>
        </a>
      </h5>
    );
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="effect-just-me document-preview"
      style={{ height: "100%", width: "100%", maxWidth: "90%" }}
    >
      {title && <ModalHeader tag={Header} toggle={toggle} />}
      <ModalBody>
        <iframe style={iframeStyle} src={url(true)} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentModal;
