import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSampleNuclearDensometers } from "../../actions/sampleNuclearDensometerActions";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import Form from "./Form";
import GenerateReport from "./GenerateReport";

const NuclearDensometer = props => {
  const [loading, setLoading] = useState(true);
  const { match, fetchSampleNuclearDensometers, initialValues } = props;

  useEffect(() => {
    fetchSampleNuclearDensometers(match.params.test)
      .catch(err => errorSwal(err))
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner loading />;

  const firstNuc = initialValues.nucs[0];

  const pageTitle = `Nuclear Densometers for ${firstNuc.test_request}`;

  return (
    <>
      <HeaderPage
        titlePage={pageTitle}
        crumbs={[
          {
            link: firstNuc.test_request_link,
            name: firstNuc.test_request,
          },
          { link: "", name: pageTitle, active: true },
        ]}
      />
      <Form {...props} />
      <GenerateReport {...props} />
    </>
  );
};

const mapStateToProps = state => ({
  initialValues: {
    nucs: state.nuclearDensometers.items,
  },
});

export default connect(mapStateToProps, { fetchSampleNuclearDensometers })(
  NuclearDensometer,
);
