import React from "react";

const CardDash = ({ items, outerClass, selectedView }) => {
  const colSize = 12 / items.length;
  return (
    <div className={`card card-dash-one ${outerClass}`}>
      <div className="row no-gutters">
        {items.map((item, index) => {
          const isSelected = selectedView === item.view;
          return (
            <div
              onClick={item.onClick}
              role={item.onClick ? "button" : ""}
              className={`col-lg-${colSize} ${
                item.onClick ? "link-hover pointer-hover" : ""
              } ${isSelected ? "bg-secondary" : ""}`}
              key={index}
            >
              <i className="icon ion-ios-analytics-outline" />
              <div className="dash-content w-100">
                <label
                  className={`tx-${
                    isSelected ? "gray-300" : item.color
                  } w-100 d-flex`}
                >
                  <span className="ms-auto">{item.label}</span>
                </label>
                <h2>{item.value}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardDash;
