import React from "react";
import WIPTable from "./WIPTable";
import InvoicedTable from "./InvoicedTable";
import MadePurchasesTable from "./MadePurchasesTable";
import WagesTable from "./WagesTable";
import BilledPurchasesTable from "./BilledPurchasesTable";
import EstimatedAmountBilled from "./EstimatedAmountBilledTable";

const DashboardViews = props => {
  return (
    <div className="row">
      <div className="col-12 mt-3">
        <Router {...props} />
      </div>
    </div>
  );
};

const Router = props => {
  const { view, ...dashboard } = props;

  switch (view) {
    case "wip":
      return <WIPTable {...dashboard} />;
    case "invoiced":
      return <InvoicedTable {...dashboard} />;
    case "purchases_made":
      return <MadePurchasesTable {...dashboard} />;
    case "wages":
      return <WagesTable {...dashboard} />;
    case "purchases_billed":
      return <BilledPurchasesTable {...dashboard} />;
    case "estimated_amount_billed":
      return <EstimatedAmountBilled {...dashboard} />;
    default:
      return null;
  }
};

export default DashboardViews;
