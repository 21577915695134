import { useParams } from "react-router";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import { BranchIndex } from "./branchTypes";
import ShowBranchPage from "./ShowBranchPage";
import DocsModal from "../utils/DocsModal";
import { useAuth } from "../../context/auth-context";
import { ProfileTab } from "../tabs/tabTypes";

export type BranchProfilePages =
  | "details"
  | "staff"
  | "equipment"
  | "projects"
  | "cpirs"
  | "jobs"
  | "deliverables"
  | "documents"
  | "leave"
  | "tenders";

const BranchProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, setData, loading }: IUseApi<any> = useApi(
    `organisation-branches/${uuid}`,
    {},
    true,
  );

  const { user } = useAuth();

  const branch = data as BranchIndex;

  const tabs: ProfileTab<BranchProfilePages>[] = [
    {
      label: "Details",
      link: `/branches/${uuid}/details`,
      page: "details",
    },
    {
      label: "Documents",
      link: `/branches/${uuid}/documents`,
      page: "documents",
    },
    {
      label: "Staff",
      link: `/branches/${uuid}/staff`,
      page: "staff",
    },
    {
      label: "Leave",
      link: `/branches/${uuid}/leave`,
      page: "leave",
    },
    {
      label: "Equipment",
      link: `/branches/${uuid}/equipment`,
      page: "equipment",
    },
    {
      label: "Projects",
      link: `/branches/${uuid}/projects`,
      page: "projects",
    },
    {
      label: "CPIRs",
      link: `/branches/${uuid}/cpirs`,
      page: "cpirs",
    },
    {
      label: "Jobs",
      link: `/branches/${uuid}/jobs`,
      page: "jobs",
    },
    {
      label: "Deliverables",
      link: `/branches/${uuid}/deliverables`,
      page: "deliverables",
    },
  ];

  if (user?.is_position_admin) {
    tabs.push({
      label: "Tenders",
      link: `/branches/${uuid}/tenders`,
      page: "tenders",
    });
  }

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/branches-divisions-and-tracking-sites/" />
      <HeaderPageAdminFilter
        crumbs={[
          { link: "branches", name: "Branches" },
          { link: "", name: "Branch Profile", active: true },
        ]}
        titlePage={branch.display_name}
      />
      <Profile<BranchProfilePages>
        content={<ShowBranchPage branch={branch} setBranch={setData} />}
        header={
          <DashboardHeader>
            <DashboardH3>{branch.display_name}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
      />
    </>
  );
};

export default BranchProfile;
