import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";
import { connect } from "react-redux";

export interface DetailForm {
  extra_info: {
    user_email_template: string;
    user_email_template_domain: string;
  };
}

interface DetailsProps {
  template?: string;
  domain?: string;
}

const Details = (
  props: InjectedFormProps<DetailForm, DetailsProps> & DetailsProps,
) => {
  const { handleSubmit } = props;

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<DetailForm> = (values) => {
    return takeAction("update", "integrations/employment-hero/details", values)
      .then(() => {
        toast.success("Details updated.");
      })
      .catch(formError);
  };

  return (
    <></>
  );
};

const form = reduxForm<DetailForm, DetailsProps>({
  form: "EmploymentHeroDetails",
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("EmploymentHeroDetails");

  return {
    template: selector(state, "extra_info.user_email_template"),
    domain: selector(state, "extra_info.user_email_template_domain"),
  };
};

export default connect(mapStateToProps)(form(Details));
