import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useFilter from "../hooks/useFilter";
import { InvoiceStatus } from "../invoices/invoiceTypes";
import { JobStatus } from "../jobs/jobTypes";
import {
  jobFilters,
  JobFilterType,
} from "../projectManagers/ProjectManagerJobs";
import { checkMobileAndTablet } from "../utils/deviceDetection";
import errorSwal from "../utils/errorSwal";
import FilterDropdown from "../utils/FilterDropdown";
import LoadingOverlay from "../utils/LoadingOverlay";
import DraggableJob from "./DraggableJob";

const JobOrder = ({ dashboardData }: { dashboardData: any }) => {
  const { takeAction, loading }: IUseApi = useApi();
  const [jobs, setJobs] = useState<any[]>([]);

  const { filters, toggleFilter, filterCount, activeFilters } = useFilter<
    JobFilterType,
    JobStatus | boolean | InvoiceStatus
  >(jobFilters);

  useEffect(() => {
    setJobs(dashboardData);
  }, [dashboardData]);

  const [search, setSearch] = useState("");

  const filteredJobs =
    !search && filterCount === 0
      ? jobs
      : jobs.filter((job) => {
          const searched = search
            ? searchValues.some((value) => {
                let columnValue = _.get(job, value.column);
                if (value.format) {
                  columnValue = value.format(job);
                }

                return columnValue.toLowerCase().includes(search.toLowerCase());
              })
            : true;

          const filtered =
            activeFilters.length === 0
              ? true
              : activeFilters.some((value) => {
                  const column =
                    value.header_name === "overdue"
                      ? "is_overdue"
                      : value.header_name;

                  return job[column ?? ""] == value.value;
                });

          return searched && filtered;
        });

  const nonPrioritisedJobs = filteredJobs.filter(
    (job) => job.priority === null,
  );

  const prioritisedJobs = _.orderBy(
    filteredJobs.filter((job) => job.priority !== null),
    "priority",
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (
      result.destination.droppableId === "non-priority" &&
      result.source.droppableId === "non-priority"
    ) {
      return;
    }

    const droppedJob = jobs.find(({ uuid }) => result.draggableId === uuid);
    const oldJobs = jobs;

    if (result.destination.droppableId === "priority") {
      if (droppedJob.priority === result.destination?.index) {
        return;
      }
      const newPrioritised = prioritisedJobs.filter(
        ({ uuid }) => uuid !== result.draggableId,
      );

      newPrioritised.splice(result.destination.index, 0, {
        ...droppedJob,
        priority: result.destination.index,
      });

      const prioritised = newPrioritised.map((job, index) => {
        return {
          ...job,
          priority: index,
        };
      });

      const newJobs = _.uniqBy([...prioritised, ...nonPrioritisedJobs], "uuid");

      setJobs(newJobs);

      takeAction("update", "job-priorities", {
        jobs: prioritised,
      })
        .then(({ data }: { data: { data: any[] } }) => {
          setJobs(
            newJobs.map((job) => {
              const freshJob = data.data.find(
                (fresh) => fresh.uuid === job.uuid,
              );
              return freshJob ?? job;
            }),
          );
        })
        .catch((err) => {
          errorSwal(err);
          setJobs(oldJobs);
        });

      return;
    }

    const newNonPriority = jobs.map((job) => {
      if (job.uuid === result.draggableId) {
        return {
          ...job,
          priority: null,
        };
      }
      return job;
    });

    setJobs(newNonPriority);

    takeAction("destroy", `job-priorities/${result.draggableId}`)
      .then(({ data }) => {
        setJobs(
          newNonPriority.map((j) =>
            j.uuid === data.data.uuid ? data.data : j,
          ),
        );
      })
      .catch((err) => {
        errorSwal(err);
        setJobs(oldJobs);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="d-flex align-items-center mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i className="fa fa-search" />
          </button>
        </div>
        <FilterDropdown
          filters={filters}
          toggleFilter={toggleFilter}
          filterCount={filterCount}
        />
      </div>
      {search && <p>Unable to prioritise whilst searching.</p>}
      <div className="row">
        {checkMobileAndTablet() ? (
          <DroppableJobs
            jobs={filteredJobs}
            allJobs={jobs}
            title="Your Jobs"
            droppableId="your-jobs"
            loading={loading}
            setJobs={setJobs}
            search={search}
          />
        ) : (
          <>
            <DroppableJobs
              jobs={prioritisedJobs}
              allJobs={jobs}
              title="Priority"
              droppableId="priority"
              loading={loading}
              setJobs={setJobs}
              search={search}
            />
            <DroppableJobs
              jobs={nonPrioritisedJobs}
              allJobs={jobs}
              title="Non-Priority"
              droppableId="non-priority"
              loading={loading}
              setJobs={setJobs}
              search={search}
            />
          </>
        )}
      </div>
    </DragDropContext>
  );
};

interface DroppableJobsProps {
  droppableId: "priority" | "non-priority" | "your-jobs";
  title: "Priority" | "Non-Priority" | "Your Jobs";
  jobs: any[];
  loading: boolean;
  setJobs: Function;
  allJobs: any[];
  search?: string;
}

const DroppableJobs = ({
  droppableId,
  jobs,
  title,
  loading,
  setJobs,
  allJobs,
  search,
}: DroppableJobsProps) => {
  const amountOfHoursLeft = _.sumBy(jobs, "estimated_hours_left");

  return (
    <div className="col-lg-6 h-100 mb-3 mb-lg-0">
      <Droppable
        isDropDisabled={!!search || checkMobileAndTablet()}
        droppableId={droppableId}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          return (
            <div className="position-relative h-100">
              <LoadingOverlay loading={loading} />
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="bg-white p-3 border"
              >
                <p className="slim-card-title mb-1">{title}</p>
                <p className="mb-0">
                  <span className="tx-inverse">{amountOfHoursLeft}</span> hours
                  of work remaining for{" "}
                  <span className="tx-inverse">{jobs.length}</span> jobs
                </p>
                <div className="mt-4 space-y-3">
                  {jobs.map((job: any, index: number) => {
                    return (
                      <div key={job.uuid}>
                        <Draggable
                          isDragDisabled={!!search || checkMobileAndTablet()}
                          draggableId={job.uuid}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot,
                          ) => {
                            return (
                              <DraggableJob
                                provided={provided}
                                snapshot={snapshot}
                                job={job}
                                setJobs={setJobs}
                                jobs={allJobs}
                              />
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                </div>
                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};

const searchValues: { column: string; format?: (job: any) => string }[] = [
  {
    column: "name",
  },
  {
    column: "number",
  },
  {
    column: "project.full_name",
  },
  {
    column: "scheduled_finish_date",
    format: (job) => dayjs(job.scheduled_finish_date).format("DD/MM/YYYY"),
  },
];

export default JobOrder;
