import swal from "sweetalert";
import * as Sentry from "@sentry/react";
import history from "./History";

const errorSwal = (message) => {
  let title = "Error";
  let icon = "error";
  let displayMessage = "";
  let button = null;

  if (isType(message, 422)) {
    displayMessage = formatMessage(message.response);
  } else if (isType(message, 403)) {
    title = "Unauthorised";
    icon = "warning";
    displayMessage =
      formatMessage(message.response) ||
      "You are not authorized to perform that action.";
  } else if (isType(message, 404)) {
    title = "404, Unable to find resource";
    displayMessage =
      message.response.data.message ??
      "This issue mainly occurs for the following reasons: \n\n1. You are currently logged into a different company to the resource you are trying to access. \n\n2. You don't have access to this resource. \n\n3. This resource has been deleted and you are trying to access a 'dead' link.";
    icon = "warning";

    if (message.response.data.organisation) {
      button = `Switch to ${message.response.data.organisation.name}`;
    }
  } else if (isType(message, 500)) {
    title = "Server Error";
    icon = "error";
    displayMessage = message.response.data.message;
  } else if (isType(message, 503)) {
    title = "Request Timeout";
    icon = "warning";
    displayMessage =
      "Your request has timed out, please try again or reprt a bug.";
    Sentry.captureException(message);
    Sentry.captureMessage("503 Service Unavailable");
  } else if (isType(401)) {
    title = "Unauthenticated";
    icon = "warning";
    displayMessage =
      "Your session has timed out, please refresh page to continue.";
  } else if (isType(message, 400)) {
    title = "Server Error";
    icon = "error";
    displayMessage = message.response.data.message;
  }

  if (import.meta.env.DEV) {
    console.log(message);
  }

  return swal({
    icon,
    title,
    text:
      displayMessage ||
      "Sorry there was an error, please try again or report a bug",
    button,
  }).then((value) => {
    if (value) {
      changeOrganisation(
        message.response.data.organisation.id,
        message.response.data.link,
        message.response.data.organisation.name,
      );
    }
  });
};

const isType = (message, type) =>
  message &&
  typeof message === "object" &&
  message.response &&
  message.response.status === type;

const formatMessage = ({ data }) => {
  let errorString = "";

  if (data.message) {
    errorString += `${data.message}\n`;
  }

  if (!data.errors) {
    return errorString;
  }

  Object.keys(data.errors).forEach((val) => {
    data.errors[val].forEach((error) => {
      errorString += ` ${error}\n\n`;
    });
  });
  return errorString;
};

const changeOrganisation = (id, link) => {
  history.push(`/organisations/${id}/switch?link=${link}`);
  history.go();
};

export default errorSwal;
