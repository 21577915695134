import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { formatNumber } from "../dashboard/OverviewCard";
import ReactTable from "../table/ReactTable";
import formatDate from "../utils/formatDate";
import InvoiceStatusBadge from "./InvoiceStatusBadge";
import getStatus from "./status";

const JobTable = ({
  jobs,
  selectedJob,
  setSelectedJob,
  showProject = false,
  showEstimate = true
}) => {
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const columns = [
    {
      accessorKey: "full_name",
      header: "Job",
      cell: (info) => {
        const job = info.row.original;
        return (
          <>
            <Link className="text-secondary" to={job.link}>
              {info.getValue()}
            </Link>
            {(job.is_overdue || job.critical_path) && (
              <div>
                {job.is_overdue ? (
                  <Badge className="me-1" color="danger">
                    Overdue
                  </Badge>
                ) : null}
                {job.critical_path ? (
                  <Badge color="danger">Critical Path</Badge>
                ) : null}
              </div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "manager.name",
      header: "Job Manager",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "users",
      header: "Team Members",
      cell: (info) => {
        return info
          .getValue()
          ?.map((user) => user.label)
          .join(", ");
      },
    },
    {
      accessorKey: "date",
      header: "Finish Date",
      cell: (info) => finishDate(info.row.original),
    },
    {
      accessorKey: "estimated_value",
      header: "Estimated/Invoiced",
      cell: (info) =>
        `$${formatNumber(info.getValue())} / $${formatNumber(
          info.row.original.completed_value,
        )}`,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => {
        const job = info.row.original;

        return getStatus(job.status);
      },
    },
    {
      accessorKey: "invoice_status",
      header: "Invoice Status",
      cell: (info) => <InvoiceStatusBadge status={info.getValue()} />,
    },
  ];

  if (showProject) {
    columns.splice(0, 0, {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => {
        const job = info.row.original;
        return <Link to={`/${job.project.link}`}>{info.getValue()}</Link>;
      },
    });
  }

  let filteredColumns = isPositionAdmin
    ? columns
    : columns.filter(({ accessorKey }) => accessorKey !== "estimated_value");

  if (!showEstimate) {
      filteredColumns = filteredColumns.filter(
          ({ accessorKey }) => accessorKey !== "estimated_value" && accessorKey !== "invoice_status"
      );
  }

  const rowClasses = (row) => {
    if (row.id === selectedJob?.id) {
      return "table-secondary";
    }
  };

  return (
    <ReactTable
      disableSearch
      columns={filteredColumns}
      data={jobs}
      onRowClick={(job) => {
        setSelectedJob(job.id !== selectedJob?.id ? job : undefined);
      }}
      rowClasses={rowClasses}
    />
  );
};

const finishDate = (job) => {
  if (job.actual_finish_date) {
    return `Actual: ${formatDate(job.actual_finish_date)}`;
  }
  return `Scheduled: ${formatDate(job.scheduled_finish_date)}`;
};

export default JobTable;
