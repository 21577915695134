import React from "react";
import PaginatedList from "../pagination/PaginatedList";
import useNotices from "./hooks/useNotices";
import Notice from "./Notice";

const Notices = () => {
  return (
    <PaginatedList
      indexHook={useNotices}
      originalFilters={[]}
      list={({ data }) => {
        return (
          <div className="space-y-5 col-12">
            {data?.map((notice) => {
              return <Notice notice={notice} key={notice.uuid} />;
            })}
          </div>
        );
      }}
    />
  );
};

export default Notices;
