import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../../context/auth-context";
import JobStatus from "../../../enums/JobStatus";
import useApi from "../../api/useApi";
import ApprovalButton from "../../approvals/ApprovalButton";
import ApprovalComments from "../../approvals/ApprovalComments";
import SideList from "../../comments/SideList";
import HeaderPage from "../../header/HeaderPage";
import useUploadCustomFields from "../../hooks/useUploadCustomFields";
import getStatus from "../../jobs/status";
import ProfilePage from "../../profile";
import ProfileViewsList from "../../profileViews/ProfileViewsList";
import ApprovalStatus from "../../user/ApprovalStatus";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../../utils/DashboardHeader";
import errorSwal from "../../utils/errorSwal";
import formError from "../../utils/formError";
import Spinner from "../../utils/Spinner";
import DownloadDropdown from "../../customJobs/jobs/DownloadDropdown";
import ShowPage from "../../customJobs/jobs/ShowPage";

const Profile = () => {
  const { uuid } = useParams();

  const { user } = useAuth();

  const { takeAction: generatePrompt, loading: generatingPrompt } = useApi();

  const {
    data: job,
    setData: setJob,
    loading,
    response,
    refreshData,
  } = useApi(`project-forms/jobs/${uuid}`, {}, true, "/jobs");
  
  const tabs = [
    {
      link: `/project-forms/jobs/${uuid}/details`,
      icon: "fa-pencil",
      page: "details",
      label: "Details",
    },
    {
      link: `/project-forms/jobs/${uuid}/documents`,
      icon: "fa-folder-open-o",
      page: "documents",
      label: "Documents",
    }
  ];

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/project-forms/${uuid}`,
  );

  const { takeAction } = useApi();

  const onPrompt = (fieldUuid, prompt, name, change) => {
    return generatePrompt("store", `custom-fields/${fieldUuid}/prompts`, {
      prompts: [
        {
          role: "user",
          content: prompt,
        },
      ],
      modelType: "App\\Models\\ProjectJob",
      modelId: job?.id,
    })
      .then(({ data }) => {
        toast.success("Prompt added");
        change(name, data.data);
      })
      .catch(errorSwal);
  };

  const onSubmit = (values) => {
    return upload(values)
      .then((values) => {
        return takeAction("update", `project-forms/jobs/${uuid}`, values);
      })
      .then(({ data }) => {
        setJob(data.data);
        toast.success("Job updated");
      })
      .catch(formError);
  };

  if (loading || !response) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={job?.name}
        crumbs={[
          { name: "Projects", link: "/projects" },
          { name: job?.project?.name, link: `/${job?.project?.link}` },
          { name: job?.name, link: job?.link },
          { name: job?.type?.name, link: "", active: true },
        ]}
      />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={job}
            viewable_type={"App\\Models\\ProjectJob"}
          />
        </div>
      </div>
      <ProfilePage
        header={
          <DashboardHeader>
            <DashboardH3>
              <Link to={job?.link}>{job?.name}</Link>
            </DashboardH3>

            <DashboardSubTitle>
              Client:{" "}
              <Link to={`${job?.project?.client?.link}`}>
                {job?.project?.client?.name}
              </Link>
            </DashboardSubTitle>
            <DashboardSubTitle>
              Project:{" "}
              <Link to={`/${job?.project?.link}`}>{job?.project?.name}</Link>
            </DashboardSubTitle>
            <div className="d-flex">
              <p className="mg-b-0 mg-t-10">
                {getStatus(job?.status)}
                {(job?.critical_path === 1 || job?.critical_path === true) && (
                  <Badge color="danger">Critical Path</Badge>
                )}
                {job?.is_overdue && (
                  <Badge className="mg-l-5" color="danger">
                    Overdue
                  </Badge>
                )}
              </p>
              {job.sign_in_required && (
                <div className="align-self-center">
                  {" "}
                  <Badge className="rounded-pill shadow" color="info">
                    Sign in Required
                  </Badge>
                </div>
              )}
            </div>
          </DashboardHeader>
        }
        content={
          <>
            <DownloadDropdown
              model={{ model: "App\\Models\\ProjectJob", id: job?.id }}
              documents={job?.template?.documents}
              emails={job?.emails}
              {...job}
              job={job}
            />

            <ShowPage
              onSubmit={onSubmit}
              form="EditCustomJobJob"
              job={job}
              initialValues={job}
              setJob={setJob}
              onPrompt={onPrompt}
              generatingPrompt={generatingPrompt}
            />
          </>
        }
        tabs={tabs}
        sideBar={
          <>
            <ApprovalButton
              onSubmitted={refreshData}
              approvalAction={job.approval_action}
              approvalOptions={[
                { value: 1, label: "Approve" },
                { value: 2, label: "Redo" },
              ]}
            />

            <ApprovalStatus
              className="w-100 my-3"
              status={job.approval_status}
            />
            <div className="mt-3">
              <ApprovalComments
                modelType="App\Models\ProjectJob"
                modelId={job?.id}
              />
            </div>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\ProjectJob"
                commentableId={job?.id}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default Profile;
