import React from "react";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import EditHeader from "./EditHeader";
import SubjectItems from "./SubjectItems";
import { ISubject } from "./subjectTypes";

const Subject = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const {
    data: subject,
    loading,
    setData: setSubject,
  }: IUseApi<ISubject> = useApi(`subjects/${uuid}`);

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <EditHeader subject={subject} setSubject={setSubject} />
      <SubjectItems />
    </>
  );
};

export interface SubjectChildrenProps {
  subject?: ISubject;
  setSubject: (subject: ISubject) => void;
}

export default Subject;
