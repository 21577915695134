import { AiOutlineClose } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { UserToSync } from "./SyncUsers";

interface EmploymentHeroUserCardProps {
  user: UserToSync;
  users: UserToSync[];
  setUsers: (users: UserToSync[]) => void;
}

const EmploymentHeroUserCard = ({
  user,
  users,
  setUsers,
}: EmploymentHeroUserCardProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const filterUsers = () => {
    return users.filter((u) => u.uuid !== user.uuid);
  };

  const createUser = () => {
    return takeAction("store", `integrations/employment-hero/users/sync/${user.uuid}`)
      .then(() => {
        toast.success(`${user.name} synced to Employment Hero`);
        setUsers(filterUsers());
      })
      .catch(errorSwal);
  };

  const syncUser = () => {
    return takeAction("update", `integrations/employment-hero/users/sync/${user.uuid}`, {
      employment_hero_id: user.employment_hero_user?.id,
    })
      .then(() => {
        toast.success(`${user.name} synced to Employment Hero`);
        setUsers(filterUsers());
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-6 mb-3">
      <div className="bg-white border rounded-lg">
        <div className="p-3">
          <div className="d-flex mb-1">
            <p className="mb-0 tx-inverse fw-bolder">{user.name}</p>
            <p className="mb-0 ms-auto">
              {user?.employment_hero_user ? (
                <FiCheck className="tx-success" />
              ) : (
                <AiOutlineClose className="tx-danger" />
              )}
            </p>
          </div>
          <p className="mb-0">{user.email}</p>
          {user?.employment_hero_user ? (
            <div className="mt-3 tx-12 mb-0">
              Employment Hero email:{" "}
              <span className="tx-inverse">{user.employment_hero_user.email}</span>
            </div>
          ) : null}
        </div>
        <button
          type="button"
          disabled={loading}
          onClick={() => {
            if (user?.employment_hero_user) {
              syncUser();
              return;
            }
            createUser();
          }}
          className={`p-3 border-top border-0 tx-inverse bg-light rounded-bottom w-100 text-start`}
        >
          {isSubmitting(
            loading,
            user?.employment_hero_user
              ? `Sync to ${user?.employment_hero_user.email}`
              : "Create new Employment Hero user",
            "Syncing...",
          )}
        </button>
      </div>
    </div>
  );
};

export default EmploymentHeroUserCard;
