import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, reduxForm, submit } from "redux-form";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import { useHistory } from "react-router-dom";
import renderToggleInput from "../utils/renderToggleInput";

const ReasonModal = (props) => {
  const { handleSubmit, form, dispatch, initialValues, modal } = props;
  const history = useHistory();
  const toggle = () => history.push("/leave-reasons");

  return (
    <Modal className="wd-md-850" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {initialValues ? `Edit ${initialValues.reason}` : "Add Reason"}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 form-group">
              <Field component={RenderField} name="reason" label="Reason" />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="ordinary_hours"
                label="Treat as Ordinary Hours"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="without_pay"
                label="Leave Without Pay"
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton {...props} onClick={() => dispatch(submit(form))} />
      </ModalFooter>
    </Modal>
  );
};

export default reduxForm({ enableReinitialize: true })(ReasonModal);
