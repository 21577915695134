import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";
import Form from "./Form";

const Add = () => {
  const { takeAction } = useApi();
  const history = useHistory();
  const { user } = useAuth();

  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const onSubmit = (values) => {
    return upload(
      values.file,
      `organisations/${user.active_organisation.uuid}/deductions`,
      (file) => {
        return takeAction("store", "deductions", { ...values, file })
          .then(() => {
            toast.success("Deduction Added");
            history.push("/organisations/settings/links");
          })
          .catch((err) => {
            errorSwal(err);
            setUploadPercent(0);
          });
      },
    );
  };

  return (
    <>
      <HeaderPage titlePage="Add New Deduction" crumbs={[]} />

      <Form
        onSubmit={onSubmit}
        form="AddDeduction"
        uploadPercent={uploadPercent}
      />
    </>
  );
};

export default Add;
