import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import LeaveForm from "./LeaveForm";
import { useEffect, useState } from "react";

const Request = (props) => {
  const { takeAction } = useApi();
  const { user } = useAuth();
  const { id } = useParams();

  const [userLeaveBalances, setUserLeaveBalances] = useState();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user.active_organisation.uuid}/leave/${user.uuid}`,
  );

  const onSubmit = (values) => {
    return upload(values.file ? [values.file] : [])
      .then((files) => {
        const data = {
          ...values,
          document: files[0] ?? null,
        };

        return takeAction("store", "requested-leave", {
          ...data,
          user_id: id,
        });
      })
      .then(({ data }) => {
        toast.success("Leave Requested");

        props.history.push(`/requested-leave/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    takeAction(
      "index",
      `integrations/employment-hero/users/leave-balances/${user.uuid}`,
    ).then(({ data: { data } }) => {
      setUserLeaveBalances(data);
    });
  }, []);

  return (
    <>
      <HeaderPage
        titlePage="Request Leave"
        crumbs={[
          {
            name: "Profile",
            link: `/users/${user.uuid}/timesheets`,
          },
          {
            name: "Request Leave",
            link: `/`,
            active: true,
          },
        ]}
      />

      <LeaveForm
        initialValues={{ full_day: true }}
        form="RequestLeave"
        onSubmit={onSubmit}
        user={user}
        userLeaveBalances={userLeaveBalances}
      />
    </>
  );
};

export default Request;
