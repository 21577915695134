import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import useApi from "../api/useApi";
import PermissionGroupSubjects from "./PermissionGroupSubjects";
import {
  PermissionGroupProps,
  PermissionGroupSubject,
} from "./permissionGroupTypes";

const UserPermissionGroups = (props: PermissionGroupProps) => {
  const { data } = useApi("/custom-fields/users", [], true);

  const options = data as {
    id: number;
    name: string;
    disabled: boolean;
  }[];

  return (
    <>
      <p className="slim-card-title">Users</p>
      <PermissionGroupSubjects
        subjectTitle="User"
        subjectType="App\Models\User"
        {...props}
        options={options
          .filter((u) => !u.disabled)
          .map((option) => ({
            value: option.id,
            label: option.name,
          }))}
      />
    </>
  );
};

export default UserPermissionGroups;
