import PayRunDetails from "./PayRunDetails";
import PayRunTimesheets from "./PayRunTimesheets";
import NoMatch from "../404";
import PayRunBranches from "./PayRunBranches";

const ShowPage = (props) => {
  const {
    filteredPays,
    filterOptions,
    selectedOptions,
    match,
    change,
    payRun,
    setPayRun,
  } = props;

  switch (match.params.page) {
    case "pays":
      return (
        <PayRunDetails
          {...props}
          filteredPays={filteredPays}
          filterOptions={filterOptions}
          selectedOptions={selectedOptions}
          change={change}
        />
      );
    case "branches":
      return (
        <PayRunBranches
          payRun={payRun}
          setPayRun={setPayRun}
          initialValues={{
            branches: payRun.branches.map((branch) => {
              return {
                label: branch.name,
                value: branch.id,
              };
            }),
          }}
        />
      );
    case "timesheets":
      return <PayRunTimesheets uuid={match.params.uuid} />;
    default:
      return <NoMatch />;
  }
};
export default ShowPage;
