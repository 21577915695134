import { toast } from "react-toastify";
import { reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import DeliverableTable from "./DeliverableTable";
import DeliverablesModal from "./Modal";

const ProfileDeliverables = (props) => {
  const { toggle, modal } = useModal();
  const { jobId, model, id } = props;
  const { user } = useAuth();

  const {
    loading,
    data: deliverables,
    setData: setDeliverables,
  } = useApi(`/jobs/${id}/deliverables`, []);

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    const bucket = `organisations/${user.active_organisation.uuid}/deliverables`;
    return uploadToS3(values.file, bucket)
      .then((documents) => {
        return takeAction("store", "deliverables", {
          ...values,
          documents,
          deliverable_type: model,
          job_id: jobId,
          contacts: values.deliverable_contacts,
          recipients: values.deliverable_recipients,
        });
      })
      .then(({ data }) => {
        toast.success(`${data.data.name} added successfully`);
        setDeliverables([...deliverables, data.data]);
        toggle();
        dispatch(reset("deliverableDetails"));
      })
      .catch(errorSwal);
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Deliverables...</CustomScaleLoader>;
  }

  return (
    <>
      <ul className="nav nav-activity-profile mg-t-20">
        <li className="nav-item">
          <button
            className="nav-link w-100 pointer-hover onFocus"
            type="button"
            onClick={toggle}
          >
            <i className="icon ion-compose tx-primary" />
            Add New Deliverable
          </button>
        </li>
      </ul>
      <DeliverablesModal
        jobId={jobId}
        {...props}
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
        initialValues={{
          pricing: [{}],
        }}
      />
      <div className="mt-3">
        <DeliverableTable deliverables={deliverables} />
      </div>
    </>
  );
};

export default ProfileDeliverables;
