import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import FormBranch from "./Form";

const EditBranch = (props) => {
  const { uuid } = useParams();
  const { takeAction } = useApi();
  const { branch, setBranch } = props;

  const onSubmit = (values) => {
    return takeAction("update", `organisation-branches/${uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setBranch(data.data);
      })
      .catch(errorSwal);
  };

  const deleteBranch = () => {
    deleteSwal(branch.name).then(() => {
      return takeAction("destroy", `organisation-branches/${uuid}`)
        .then(() => {
          history.push(`/branches`);
          toast.success(`${branch.name} deleted successfully`);
        })
        .catch(errorSwal);
    });
  };

  return (
    <FormBranch
      initialValues={branch}
      onSubmit={onSubmit}
      form="EditBranch"
      onDelete={deleteBranch}
    />
  );
};

export default EditBranch;
