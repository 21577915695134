import { IUseApiWithData } from "../api/apiTypes";
import { Sample, Test } from "./testRequestTypes";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { useState } from "react";
import TestActions from "./TestActions";
import NewTestTable from "./NewTestTable";
import { Table } from "@tanstack/react-table";

interface TestRequestTestsProps {
  projectID: number;
  testRequestID: number;
}

const TestRequestTests = (props: TestRequestTestsProps) => {
  const { projectID, testRequestID } = props;

  const { uuid } = useParams<{ uuid: string }>();
  const [selectedTests, setSelectedTests] = useState<Test[]>([]);
  const [tableInstance, setTableInstance] = useState<Table<any>>();

  const {
    data: tests,
    loading,
    setData,
    refreshData: fetchTests,
  }: IUseApiWithData<Test[]> = useApi(`test-requests/${uuid}/tests`, [], true);

  const { data: samples, setData: setSamples }: IUseApiWithData<Sample[]> =
    useApi(`test-requests/${uuid}/samples`, []);

  return (
    <>
      <TestActions
        tests={tests}
        selectedTests={selectedTests}
        setSelectedTests={setSelectedTests}
        samples={samples}
        setSamples={setSamples}
        tableInstance={tableInstance}
        fetchTests={fetchTests}
      />
      <NewTestTable
        setSelectedTests={setSelectedTests}
        tests={tests}
        loading={loading}
        setTableInstance={setTableInstance}
        setTests={setData}
        selectedTests={selectedTests}
        testRequestID={testRequestID}
        projectID={projectID}
        fetchTests={fetchTests}
      />
    </>
  );
};

export default TestRequestTests;
