import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import CreateFolder from "./CreateFolder";
import List from "./List";
import DocsModal from "../utils/DocsModal";

const Documents = (props) => {
  const { uuid } = useParams();
  const url = uuid ? `folders/${uuid}` : "folders";
  const { user } = useAuth();

  const {
    loading,
    data: folder,
    setData: setFolder,
    setUrl,
    response,
    refreshData,
  } = useApi(url, "");

  useEffect(() => {
    setUrl(url);
  }, [uuid]);

  if (loading || !response) {
    return <Spinner loading />;
  }

  const crumbs =
    folder?.path?.map((route, index) => {
      return index + 1 === folder.path.length
        ? {
            ...route,
            active: true,
          }
        : route;
    }) || [];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/controlled-documents/" />
      <HeaderPage titlePage="Documents" crumbs={crumbs} />
      <div className="row">
        {user.is_admin && (
          <div className="col-md-3 d-block mg-b-20">
            <CreateFolder {...props} setFolder={setFolder} folder={folder} />
            <small>To upload drag file onto file list.</small>
          </div>
        )}
        {!folder ? (
          <div>No folders found.</div>
        ) : (
          <div className={user.is_admin ? "col-md-9" : "col-12"}>
            <List
              folder={folder}
              setFolder={setFolder}
              onSuccess={refreshData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Documents;
