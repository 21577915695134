import { Link, useHistory, useParams } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { DataResponse, IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ISubject } from "../subjects/subjectTypes";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import Empty from "../utils/Empty";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import TextButton from "../utils/TextButton";
import { EditTopicProps } from "./EditTopic";

const TopicSubjects = (props: EditTopicProps) => {
  const { topic, setTopic } = props;
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const { user } = useAuth();

  const { takeAction, loading: adding }: IUseApi = useApi();

  const deleteSubject = (subject: ISubject) => {
    return deleteSwal(subject.name)
      .then(() => takeAction("destroy", `subjects/${subject.uuid}`))
      .then(({ data }) => {
        setTopic(data.data);
      })
      .catch(errorSwal);
  };

  const addSubject = () => {
    return takeAction("store", `topics/${uuid}/subjects`)
      .then(({ data }: DataResponse<ISubject>) => {
        history.push(`/subjects/${data.data.uuid}`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <div>
        <div className="d-flex mt-3 align-items-center">
          <label className="section-title my-0">Subjects</label>
          {user?.hasAccessTo("App\\Models\\Subject", "create") && (
            <Button
              size="sm"
              disabled={adding}
              color="primary"
              className="ms-auto"
              onClick={addSubject}
            >
              {isSubmitting(adding, "Add New Subject", "Adding...")}
            </Button>
          )}
        </div>
        <hr className="w-100" />
      </div>
      {topic?.subjects?.length === 0 ? (
        <div className="mt-5">
          <Empty top={false}>
            <div className="mt-4 text-center">
              <p className="fw-bolder text-dark mb-2">No Subjects Found.</p>
              <p className="mb-1">
                Subjects allow you to write detailed content about your topic.
                All roles associated to this topic will be required to read
                through all subjects and confirm they understand them as part of
                their employment.
              </p>
              <p>
                To get started{" "}
                <TextButton onClick={addSubject} disabled={adding}>
                  {isSubmitting(adding, "click here", "adding...")}
                </TextButton>{" "}
                or click 'Add New Subject' above.
              </p>
            </div>
          </Empty>
        </div>
      ) : (
        <div className="space-y-3 mt-4">
          {topic?.subjects?.map((subject) => {
            const requiresReview = subject.items.find(
              ({ requires_reading }) => requires_reading,
            );
            return (
              <div key={subject.uuid} className="bg-white p-3 border d-flex">
                <Link to={`/subjects/${subject.uuid}`}>
                  {subject.name ?? "Untitled"}
                </Link>
                <div className="ms-auto">
                  {user?.hasAccessTo("App\\Models\\Subject", "delete") && (
                    <DeleteButton
                      deleteFunction={() => deleteSubject(subject)}
                    />
                  )}
                  {requiresReview && (
                    <Badge className="rounded-pill shadow-lg" color="info">
                      Review Required
                    </Badge>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TopicSubjects;
