import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalStatus from "../user/ApprovalStatus";
import ApprovalButton from "../approvals/ApprovalButton";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import formatDate from "../utils/formatDate";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";
import DeleteExpenseClaim from "./DeleteExpenseClaim";

const ExpenseClaimProfile = () => {
  const { uuid } = useParams();

  const {
    loading,
    data: expenseClaim,
    setData,
  } = useApi(`expense-claims/${uuid}`, null, true, `/home`);

  const { takeAction } = useApi();

  const title = `${expenseClaim?.user?.name}'s Expense Claim for ${formatDate(
    expenseClaim?.date,
  )}`;

  const onSubmit = (values) => {
    return takeAction("update", `expense-claims/${uuid}`, values)
      .then(({ data }) => {
        setData(data.data);
        toast.success("Expense Claim Updated");
      })
      .catch(errorSwal);
  };

  const tabs = [
    {
      link: `/expense-claims/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/expense-claims/${uuid}/documents`,
      page: "documents",
      label: "Documents",
    },
  ];

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage titlePage={title} crumbs={[]} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{title}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        content={<ShowPage expenseClaim={expenseClaim} onSubmit={onSubmit} />}
        sideBar={
          <>
            <ApprovalButton approvalAction={expenseClaim.approval_action} />
            <ApprovalStatus
              className="w-100 mt-3"
              status={expenseClaim.approval_status}
            />
            <div className="mt-4">
              <DeleteExpenseClaim expenseClaim={expenseClaim} />
            </div>
          </>
        }
      />
    </>
  );
};

export default ExpenseClaimProfile;
