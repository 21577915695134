import React from "react";

import HeaderPage from "../header/HeaderPage";
import FormPosition from "./Form";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import useApi from "../api/useApi";
import { useHistory } from "react-router";

const AddPosition = (props) => {
  const { takeAction } = useApi();
  const history = useHistory();

  const onSubmit = (values) => {
    return takeAction("store", "organisation-positions", {
      ...values,
      checks_productivity: values.checks_productivity ?? false,
    })
      .then(({ data }) => {
        toast.success("Position created successfully!");
        history.push(`/positions/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Position"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "positions", name: "Positions" },
          { link: "positions/add", name: "New Position", active: true },
        ]}
      />
      <FormPosition {...props} onSubmit={onSubmit} showCompetencies />
    </>
  );
};

export default AddPosition;
