import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
const TrackingSiteButton = (props) => {
  return (
    <div className="col-lg-4 form-group">
      <div className="d-flex justify-content-center border">
        <button
          color="primary"
          size="sm"
          onClick={props.onClick}
          disabled={props.disabled}
          type="button"
          className={`${
            props.active ? "bg-royal text-white shadow" : "bg-white"
          } border-0 w-100  p-3 tn-300 position-relative `}
        >
          {props.active && (
            <AiOutlineCheck className="tx-22 text-success position-absolute r-0 t-0 mt-1 me-1" />
          )}

          <p className="tx-semibold">{props.label}</p>

          <p className={`mb-0 ${props.active ? "" : "text-secondary"}`}>
            {props.formatted_address}
          </p>
        </button>
      </div>
    </div>
  );
};

export default TrackingSiteButton;
