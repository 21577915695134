import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NoMatch from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import CheckIns from "./CheckIns";
import EditTimesheet from "./EditTimesheet";
import ProductivityForm from "./ProductivityForm";
import { EDIT_TIMESHEET_FORM } from "./TimesheetProfile";
import { Timesheet } from "./timesheetTypes";

const ShowTimesheetPage = (props: { timesheet: Timesheet }) => {
  const { timesheet } = props;
  const { user } = useAuth();

  const { page, uuid } = useParams<{ page: string; uuid: string }>();

  switch (page) {
    case "details":
      return <EditTimesheet {...props} />;
    case "productivity":
      return (
        <ProductivityForm
          {...props}
          initialValues={timesheet}
          userId={timesheet.user_uuid}
          form={EDIT_TIMESHEET_FORM}
          selectedDay={timesheet.date}
        />
      );
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\Timesheet"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/leave/${user?.uuid}`}
          homeUrl={`timesheets/${uuid}/documents`}
        />
      );
    case "check-ins":
      return <CheckIns timesheet={timesheet} />;
    default:
      return <NoMatch />;
  }
};

export default ShowTimesheetPage;
