import { useEffect, useState } from "react";
import { ClimbingBoxLoader } from "react-spinners";

export interface IFilter<StringLiteral, ValueType> {
  name: StringLiteral;
  label: string;
  options: FilterOption<ValueType, StringLiteral>[];
  multiple?: boolean;
  hide?: boolean;
}

export interface FilterOption<ValueType, StringLiteral> {
  label: string | JSX.Element;
  value: ValueType;
  selected?: boolean;
  header_label?: string;
  header_name?: StringLiteral;
}
interface UseFilterReturn<StringLiteral, ValueType> {
  stringified: string;
  setFilters: Function;
  filters: IFilter<StringLiteral, ValueType>[];
  toggleFilter: (filterName: StringLiteral, optionValue: any) => void;
  toggleMulti: (filterName: StringLiteral, optionValue: readonly any[]) => void;
  filterCount: number;
  activeFilters: FilterOption<ValueType, StringLiteral>[];
}

const useFilter = <StringLiteral, ValueType>(
  initialFilters: IFilter<StringLiteral, ValueType>[],
): UseFilterReturn<StringLiteral, ValueType> => {
  const [filters, setFilters] =
    useState<IFilter<StringLiteral, ValueType>[]>(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [JSON.stringify(initialFilters)]);

  let stringified = "";
  filters.forEach((filter) => {
    const selected = filter.options?.filter((option) => {
      return option.selected;
    });

    if (selected.length === 0) {
      return;
    }

    stringified += `&filter[${filter.name}]=${selected.map(
      (select) => select.value,
    )}`;
  });

  const toggleMulti = (filterName: StringLiteral, values: readonly any[]) => {
    const filter = filters.find((filter) => filter.name === filterName);

    if (!filter) {
      return;
    }

    if (!filter.multiple) {
      throw new Error(
        `Unable to use toggleMulti for filters that aren't marked multiple. Please add the multiple attribute to filter to ${filter.label}`,
      );
    }

    const valueArray = values.map(({ value }) => value);

    const newFilter = {
      ...filter,
      options: filter.options.map((option) => {
        return valueArray.includes(option.value)
          ? { ...option, selected: true }
          : { ...option, selected: false };
      }),
    };

    const newFilters = filters.map((filter) =>
      filter.name === filterName ? newFilter : filter,
    );
    setFilters(newFilters);
  };

  const toggleFilter = (filterName: StringLiteral, optionValue: any) => {
    const filter = filters.find((filter) => filter.name === filterName);

    if (!filter) {
      return;
    }

    const option = filter.options?.find(
      (option) => option.value === optionValue,
    );

    const newFilter: IFilter<StringLiteral, ValueType> = {
      ...filter,
      options: option
        ? filter.options.map((opt) =>
            opt.value === option.value
              ? { ...option, selected: option.selected ? false : true }
              : { ...opt, selected: filter.multiple ? opt.selected : false },
          )
        : [...filter.options],
    };

    const newFilters = filters.map((filter) =>
      filter.name === filterName ? newFilter : filter,
    );

    setFilters(newFilters);
  };

  const activeFilters = filters
    .map((filter) =>
      filter.options.map((option) => {
        return {
          ...option,
          header_label: filter.label,
          header_name: filter.name,
        };
      }),
    )
    .flat()
    .filter((option) => option.selected);

  const filterCount = activeFilters.length;

  return {
    stringified,
    setFilters,
    filters,
    toggleFilter,
    filterCount,
    activeFilters,
    toggleMulti,
  };
};
export default useFilter;
