import { ChangeEventHandler, useState } from "react";
import useObserver from "../../hooks/useObserver";
import { ISorter } from "../../hooks/useSort";
import Empty from "../../utils/Empty";
import CustomScaleLoader from "../../utils/scaleLoader";
import SortDropdown from "../../utils/SortDropdown";
import { SampleListSortKeys } from "./SampleList";
import SampleItem from "./SampleItem";
import { SampleIndex } from "./sampleTypes";
import SampleTable from "./SampleTable";
import { Button } from "reactstrap";

interface SamplesListProps {
  onSearch: ChangeEventHandler<HTMLInputElement>;
  isFetching: boolean;
  flattenedData?: SampleIndex[];
  fetchNextPage: Function;
  activeFilters: any[];
  isFetchingNextPage: boolean;
  sorters: ISorter<SampleListSortKeys>[];
  toggleSorter: (sorterName: SampleListSortKeys) => void;
}

const SamplesList = (props: SamplesListProps) => {
  const {
    isFetching,
    onSearch,
    flattenedData,
    fetchNextPage,
    isFetchingNextPage,
    activeFilters,
    sorters,
    toggleSorter,
  } = props;

  const intersection = useObserver(fetchNextPage);

  const [showTable, setShowTable] = useState<boolean>(false);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={onSearch}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i
              className={`fa fa-${isFetching ? "spinner fa-spin" : "search"}`}
            />
          </button>
        </div>
        <SortDropdown sorters={sorters} toggleSorter={toggleSorter} />
        <Button
          size="sm"
          color="primary"
          className="ms-1"
          outline
          onClick={() => setShowTable(!showTable)}
        >
          {showTable ? "Show List" : "Show Table"}
        </Button>
      </div>
      {!isFetching && flattenedData?.length === 0 && (
        <div className="mt-5">
          <Empty width="25%" height="50%">
            {activeFilters.length > 0 ? (
              <div className="d-block text-center">
                <p className="tx-inverse tx-16 fw-bolder mb-2">
                  No Results found for the following filters:
                </p>
                <div className="mb-3">
                  {activeFilters.map((filter, index) => {
                    return (
                      <p key={index} className="mb-0">
                        <span className="tx-inverse">
                          {filter.header_label}
                        </span>{" "}
                        - {filter.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="tx-inverse fw-bolder">No Results found</p>
            )}
          </Empty>
        </div>
      )}
      {showTable ? (
        <SampleTable data={flattenedData} />
      ) : (
        <div className="row row-sm">
          {flattenedData?.map((sample) => {
            return <SampleItem sample={sample} key={sample.uuid} />;
          })}
        </div>
      )}

      <div className="mt-5" />

      <div id="intersection" ref={intersection} />
      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More Data...</CustomScaleLoader>
      )}
    </>
  );
};

export default SamplesList;
