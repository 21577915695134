import { MouseEventHandler } from "react";

const DeleteButton = ({
  deleteFunction,
  deleting = false,
}: {
  deleteFunction: MouseEventHandler;
  deleting?: boolean;
}) => {
  return (
    <button
      onClick={deleteFunction}
      disabled={deleting}
      type="button"
      className="btn btn-outline-danger py-0"
    >
      Delete
    </button>
  );
};

export default DeleteButton;
