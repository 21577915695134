import React, { useState, useEffect } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import history from "../utils/History";

import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import required from "../utils/required";
import useApi from "../api/useApi";

const JobTypeModal = (props) => {
  const [jobTypes, setJobTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const { takeAction, loading } = useApi();
  const { project, handleSubmit, modal, toggle, selectProject } = props;

  useEffect(() => {
    if (modal) {
      takeAction("index", "jobtypes/types")
        .then(({ data }) => {
          setJobTypes(data);
          if (selectProject) {
            return takeAction("index", "/projects/jobs/list");
          }
          return;
        })
        .then((res) => {
          if (res) {
            setProjects(res.data);
          }
        });
    }
  }, [modal]);

  const onSubmit = ({ job_type_id, project_uuid }) => {
    const { dispatch } = props;

    const jobType = jobTypes.find((type) => type.value === job_type_id);
    const projectUuid = project_uuid || props.projectUuid;
    toggle();
    dispatch(reset("addJob"));
    if (jobType.link) {
      history.push(`/jobs/${projectUuid}/${jobType.link}`);
      return;
    }

    history.push(
      `/jobs/${projectUuid}/standard/${jobType.id}/${encodeURIComponent(
        jobType.type,
      )}`,
    );
    return;
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog modal-lg wd-300 wd-sm-600"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Select Job Type</ModalHeader>
        <ModalBody>
          <div className="row">
            {selectProject && (
              <div className="col-lg-6 form-group">
                <Field
                  name="project_uuid"
                  component={SelectInputAsync}
                  isLoading={loading}
                  options={projects}
                  label="Project"
                  validate={required}
                  required
                />
              </div>
            )}
            <div className={`col-lg-${selectProject ? "6" : "12"} form-group`}>
              <Field
                name="job_type_id"
                component={SelectInputAsync}
                options={jobTypes}
                isLoading={loading}
                required
                validate={required}
                label="Job Type"
              />
            </div>
            {project && project.status == 1 && (
              <div className="col-lg-12">
                <h5 className="tx-danger text-center">
                  This project has been closed. <br />
                  Creating a new job will reopen the project.
                </h5>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <SubmitButton {...props} save="Next" />
        </ModalFooter>
      </form>
    </Modal>
  );
};

const form = reduxForm({ form: "addJob" });
export default form(JobTypeModal);
