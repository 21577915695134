import _ from "lodash";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import ReactTable from "../table/ReactTable";

const EmployeeList = ({ initialValues, selectedTrackingSites }) => {
  const {
    data: staffUsers,
    setData: setStaffUsers,
    setUrl,
    loading,
  } = useApi(
    `trackingsites/staff?filter[id]=${initialValues.tracking_sites}&date=${initialValues?.date}`,
    [],
    true,
  );

  const { takeAction: storeTimesheet } = useApi();

  useEffect(() => {
    if (selectedTrackingSites?.length > 0) {
      setUrl(
        `trackingsites/staff?filter[id]=${selectedTrackingSites}&date=${initialValues?.date}`,
      );

      return;
    }

    setUrl("");
    setStaffUsers([]);
  }, [selectedTrackingSites]);

  const handleCreateTimesheet = (uuid) => {
    storeTimesheet(
      "store",
      `public-holidays/${initialValues.uuid}/timesheets/${uuid}`,
    )
      .then(() => toast.success("Timesheet successfully created."))
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "user.name",
      header: "Name",
      cell: (info) => {
        return <div className="m-auto">{info.getValue()}</div>;
      },
    },
    {
      accessorKey: "employment_basis",
      header: "Employment Basis",
      cell: (info) => {
        const employment_basis = info.getValue();
        const user = info.row.original;
        return (
          <div className="m-auto">
            {employment_basis}{" "}
            {!["Full Time", "Fixed Term - Full Time"].includes(
              employment_basis,
            ) ? (
              <>
                <i id={`user-${data.user_id}`} className="fa fa-info-circle" />
                <UncontrolledTooltip
                  placement="right"
                  target={`user-${data.user_id}`}
                >
                  <span>
                    Auto public holiday timesheet creation is disabled.
                  </span>
                </UncontrolledTooltip>
              </>
            ) : null}
          </div>
        );
      },
    },
    {
      accessorKey: "branch",
      header: "Branch",
      cell: (info) => {
        return <div className="m-auto">{info.getValue()}</div>;
      },
    },
  ];

  if (initialValues?.timesheets_created_at) {
    columns.push({
      accessorKey: "uuid",
      header: "Create Timesheet",
      cell: (info) => {
        return (
          <Button
            outline
            size="sm"
            color="secondary"
            onClick={() => {
              handleCreateTimesheet(info.getValue());
            }}
          >
            Create Timesheet
          </Button>
        );
      },
    });
  }

  const data = _.orderBy(
    staffUsers.map((trackingSite) => trackingSite.staffUsers).flat(),
    "name",
    "asc",
  );

  return (
    <>
      <p className="slim-card-title mt-4">Users from tracking sites</p>
      {staffUsers && (
        <ReactTable columns={columns} data={data} loading={loading} />
      )}
    </>
  );
};

export default EmployeeList;
