import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";

import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import NumberField from "../utils/NumberField";
import SubmitButton from "../utils/SubmitButton";
import RequiredCompetencies from "./RequiredCompetencies";
import deleteSwal from "../utils/deleteSwal";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import errorSwal from "../utils/errorSwal";
import { percentage } from "../form/formatters";
import renderToggleInput from "../utils/renderToggleInput";
import SelectInput from "../form/SelectInput";

const FormPosition = (props) => {
  const { initialValues } = props;
  const { takeAction } = useApi();

  const history = useHistory();

  const deletePosition = () => {
    deleteSwal(initialValues.name).then(() => {
      takeAction("destroy", `organisation-positions/${initialValues.uuid}`)
        .then(() => {
          history.push("/positions");
          toast.success(`${initialValues.name} deleted`);
        })
        .catch(errorSwal);
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-lg-4 form-group">
          <Field
            name="name"
            type="text"
            component={renderField}
            label="Position"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            name="division_id"
            url="/organisationdivisions/divisions"
            label="Division"
            component={SelectInputAsync}
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            name="administration_level"
            label="Access Level"
            url="/enums?enum=AdministrationLevels"
            component={SelectInputAsync}
            required
            validate={required}
            formatData={formatData}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            name="minimum_productivity"
            label="Minimum Productivity"
            information="The minimum productivity percentage of billable hours you would like this position to have."
            {...percentage}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderField}
            information="The maximum productivity percentage of billable hours you would like this position to have."
            name="maximum_productivity"
            label="Maximum Productivity"
            {...percentage}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={renderToggleInput}
            name="checks_productivity"
            label="Productivity Required?"
            information="Have this checked if you want people adding timesheets to this role to have at least as much productivity as hours on their timesheet."
          />
        </div>
        <NumberField
          label="Number"
          toggleClassName="col-lg-6"
          fieldClassName="col-lg-6"
          {...props}
        />
        {props.showCompetencies && (
          <FieldArray
            component={RequiredCompetencies}
            name="required_competencies"
            {...props}
          />
        )}

        {initialValues?.uuid && (
          <div className="form-group col-lg-6">
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={deletePosition}
            >
              Delete
            </button>
          </div>
        )}
        <div
          className={`form-group ${
            initialValues?.uuid ? "col-lg-6" : "col-lg-12"
          }`}
        >
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const formatData = (data) => {
  return data.data
    .filter((d) => d.label !== "Initial")
    .map((d) => {
      return {
        ...d,
      };
    });
};

const form = reduxForm({ form: "Position" });

export default form(FormPosition);
