import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import { Alert, Badge, Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import {
  AggregateOutputType,
  CustomJobAggregate,
} from "../customJobs/customJobTypes";
import CustomScaleLoader from "../utils/scaleLoader";
import CalculateProjectAggregate from "./CalculateProjectAggregate";

interface ProjectAggregatesProps {
  project: { id: number };
  from: string;
  to: string;
}

const ProjectAggregates = ({ project, from, to }: ProjectAggregatesProps) => {
  const { data: aggregates }: IUseApi<CustomJobAggregate[]> = useApi(
    `custom-job-aggregates`,
    [],
  );

  const [selected, setSelected] = useState<
    SingleValue<{
      label: string;
      value?: string;
      code: string;
      in_progress: boolean;
      output_type?: AggregateOutputType;
    }>
  >();

  const { data: values, setUrl, loading } = useApi();

  const changeValue = (
    value:
      | SingleValue<{
          label: string;
          value?: string;
          code: string;
          in_progress: boolean;
        }>
      | undefined,
  ) => {
    setSelected(value);
    setUrl(
      `custom-job-aggregates/${value?.value}?project_id=${
        project.id
      }&start_date=${dayjs(from).format("YYYY-MM-DD")}&end_date=${dayjs(
        to,
      ).format("YYYY-MM-DD")}`,
    );
  };

  useEffect(() => {
    if (from && to && selected) {
      setUrl(
        `custom-job-aggregates/${selected?.value}?project_id=${
          project.id
        }&start_date=${dayjs(from).format("YYYY-MM-DD")}&end_date=${dayjs(
          to,
        ).format("YYYY-MM-DD")}`,
      );
    }
  }, [from, to]);

  return (
    <>
      {from && to && (
        <p>
          Current Date Range {dayjs(from).format("DD/MM/YYYY")} -{" "}
          {dayjs(to).format("DD/MM/YYYY")}
        </p>
      )}
      <ReactSelect
        onChange={changeValue}
        options={
          aggregates?.map((a) => {
            return {
              label: `${a.name} ${a.in_progress ? "(In Progress)" : ""}`,
              value: a.uuid,
              code: a.code,
              in_progress: a.in_progress,
              output_type: a?.output_type,
            };
          }) ?? []
        }
        value={selected}
      />
      {loading ? (
        <CustomScaleLoader>Fetching Data...</CustomScaleLoader>
      ) : (
        <>
          {selected?.in_progress ? (
            <Alert
              color="info"
              className="mt-3 rounded-lg shadow-sm border border-info"
            >
              <p className="mb-0">
                This aggregate is currently marked as{" "}
                <span className="fw-bolder">in progress</span>.{" "}
              </p>
              <p className="mb-0">
                This means that your accounts manager or an admin is still
                working on it so it may not be correct.
              </p>
            </Alert>
          ) : null}
          <CalculateProjectAggregate
            values={values ?? []}
            code={selected?.code}
            outputType={selected?.output_type}
          />
        </>
      )}
    </>
  );
};

export default ProjectAggregates;
