const CircularProgress = ({
  value,
  max,
  size = 120,
  stroke = "white",
}: {
  value: number;
  max: number;
  size?: number;
  stroke?: "white" | "#3b7eca" | "#1c9806";
}) => {
  const radius = (size - size / 10) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (value / max) * circumference;

  return (
    <svg height={size} width={size}>
      <circle
        stroke={stroke}
        fill="transparent"
        strokeWidth={size / 10}
        strokeDasharray={circumference}
        style={{
          strokeDashoffset,
          transition: "stroke-dashoffset 0.3s ease-out",
        }}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <text
        x={size / 2}
        y={size / 2}
        textAnchor="middle"
        dy=".3em"
        fontSize={size / 5}
        fill={stroke}
      >{`${Math.round((value / max) * 100)}%`}</text>
    </svg>
  );
};

export default CircularProgress;
