import { useState } from "react";
import { Button } from "reactstrap";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import InitialAvatar from "../utils/InitialAvatar";
import { useAuth } from "../../context/auth-context";

interface ChatModalProps {
  initialChat: string;
  modelId: number;
  fieldUuid: string;
  modelType: string;
  name: string;
  change: Function;
}

const ChatModal = (props: ChatModalProps) => {
  const { modelType, modelId, fieldUuid, change } = props;

  const { modal, toggle } = useModal();

  const { user } = useAuth();

  const {
    takeAction,
  }: IUseApi<
    {},
    {
      data: string;
    }
  > = useApi();

  const [chat, setChat] = useState<
    {
      content: string;
      role: "user" | "assistant";
    }[]
  >([
    {
      content: props.initialChat,
      role: "assistant",
    },
  ]);

  const onSubmit: FormSubmitHandler<
    { prompt: string },
    ChatModalProps
  > = async (values, dispatch, props) => {
    setChat([
      ...chat,
      {
        content: values.prompt,
        role: "user",
      },
    ]);
    props.reset?.();

    return takeAction("store", `custom-fields/${fieldUuid}/prompts`, {
      modelType: modelType,
      modelId: modelId,
      prompts: [
        ...chat,
        {
          content: values.prompt,
          role: "user",
        },
      ],
    })
      .then(({ data }) => {
        setChat([
          ...chat,
          {
            content: values.prompt,
            role: "user",
          },
          {
            content: data.data,
            role: "assistant",
          },
        ]);
      })
      .catch((err) => {
        setChat(chat.slice(0, chat.length));

        props.change?.("prompt", values.prompt);
        return formError(err);
      });
  };

  return (
    <>
      <Button size="sm" outline onClick={toggle}>
        Chat
      </Button>
      <FormModal
        title="Chat"
        form="CustomFieldChat"
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
        save="Chat"
        saving="Generating Response..."
        extraFooterButtons={
          <Button
            outline
            onClick={() => {
              setChat([
                {
                  content: props.initialChat,
                  role: "assistant",
                },
              ]);
            }}
          >
            Reset Chat
          </Button>
        }
      >
        <div className="form-group col-12">
          <div className="px-0 border rounded-lg">
            {chat.map((message, index) => (
              <div
                className={`d-flex p-2 py-3 space-x-5 ${
                  message.role === "assistant" ? "bg-gray-100" : ""
                }`}
              >
                <div>
                  {message.role === "assistant" ? (
                    <img
                      src="/img/boss-192px.png"
                      alt="The BOSS App Logo"
                      style={{ height: "30px" }}
                    />
                  ) : (
                    <InitialAvatar
                      className=""
                      userName={user?.name ?? "-"}
                      size={30}
                      fontSize={14}
                    />
                  )}
                </div>
                <div>
                  <div>
                    <p className="mb-0 white-space">{message.content}</p>
                  </div>
                  {message.role === "assistant" ? (
                    <Button
                      outline
                      size="sm"
                      className="mt-3"
                      onClick={() => {
                        change(props.name, message.content);
                      }}
                    >
                      Set as field value
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 form-group">
          <Field name="prompt" textarea component={RenderField} type="text" />
        </div>
      </FormModal>
    </>
  );
};

export default ChatModal;
