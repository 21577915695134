import React from "react";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import formatDate from "../utils/formatDate";
import { Badge } from "reactstrap";
import dayjs from "dayjs";

const ProfileHeader = ({ payRun, payRunDates }) => {
  const completedPays = payRun.pays.filter((pay) => !!pay.completed_at).length;
  const percentComplete = (completedPays / payRun.pays.length) * 100;

  return (
    <DashboardHeader progressBar invertColor progress={percentComplete}>
      <DashboardH3>Pay Run: {payRunDates}</DashboardH3>
      <p className="mb-0">
        {completedPays} / {payRun.pays.length} Completed
      </p>
      <div className="mt-2">
        <Badge color={payRun.completed_at ? "success" : "warning"}>
          {payRun.completed_at
            ? `Completed at ${formatDate(
                payRun.completed_at,
                "DD/MM/YYYY hh:mm",
              )}`
            : "Incomplete"}
        </Badge>
      </div>
      <Badge color={payRun.paid_on ? "success" : "warning"}>
        {payRun.paid_on
          ? `Paid on ${dayjs(payRun.paid_on).format("DD/MM/YYYY")}`
          : "Unpaid"}
      </Badge>
    </DashboardHeader>
  );
};

export default ProfileHeader;
