import { useInfiniteQuery } from "react-query";
import api from "../../../api/api";

const getSamples = ({ pageParam = "initial" }, search?: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `sampleregisters?cursor=${
        pageParam === "initial" ? "" : pageParam
      }&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useSamples(search?: string) {
  return useInfiniteQuery(
    ["samples", { search }],
    (pageParam) => getSamples(pageParam, search),
    {
      getNextPageParam: ({ meta }) => {
        if (!meta?.next_cursor) {
          return;
        }
        return meta?.next_cursor;
      },
    },
  );
}
