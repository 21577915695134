import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { reduxForm, submit } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import SubmitButton from "./SubmitButton";

const FormModal = (props) => {
  const {
    toggle,
    modal,
    title,
    headerTag,
    handleDoubleClick = () => {},
    extraComponent = null,
    className = "wd-md-1000 mx-wd-800 w-95",
    showHeader = true,
    style = {},
  } = props;

  return (
    <Modal
      className={className}
      style={style}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      onDoubleClick={handleDoubleClick}
    >
      {showHeader ? (
        <ModalHeader toggle={toggle} tag={headerTag}>
          {title}
        </ModalHeader>
      ) : null}
      <FormBodyForm {...props} />
      {extraComponent}
    </Modal>
  );
};

const FormBody = (props) => {
  const { handleSubmit, children, form, dispatch } = props;

  return (
    <>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <FormErrorAlert error={props.error} />
            {typeof children === "function" ? children(props) : children}
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        {props.extraFooterButtons}
        <SubmitButton onClick={() => dispatch(submit(form))} {...props} />
      </ModalFooter>
    </>
  );
};

const form = reduxForm({ enableReinitialize: true });

const FormBodyForm = form(FormBody);

export default FormModal;
