import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";

const SendFormButton = ({ setClient, client }) => {
  const { uuid } = useParams();
  const { modal, toggle } = useModal();

  const clientSignUpThemselves = useOrganisationSetting("client_sign_up");

  const { takeAction, loading } = useApi();

  const sendDetailsForm = (values) => {
    values = {
      ...values,
      email: values.email.replace(/ /g, "").split(","),
    };

    return takeAction("store", `client-details-form/${uuid}`, values)
      .then(({ data }) => {
        setClient(data.data);
        toast.success(`${data.data.name} notified to fill out details`);
      })
      .catch(errorSwal);
  };

  if (!clientSignUpThemselves?.meta_value) {
    return null;
  }

  return (
    <div className="mt-3">
      <button
        className="btn btn-outline-secondary w-100"
        onClick={toggle}
        disabled={loading}
      >
        Send Details Form
      </button>
      <SendFormModal
        title="Send Details Form"
        toggle={toggle}
        modal={modal}
        onSubmit={sendDetailsForm}
        form="SendDetailsForm"
        initialValues={{ email: client.contact_email?.join(",") }}
      />
    </div>
  );
};

const SendFormModal = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-12">
        <Field component={RenderField} name="email" label="Email" />
        <small>To send to multiple, separate with a comma (,).</small>
      </div>
    </FormModal>
  );
};

export default SendFormButton;
