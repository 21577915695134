import { SubmissionError } from "redux-form";

const validate = (index, values) => {
  const nuc = values.nucs[index];

  const nucs = [];
  nucs[index] = {};

  const requiredFields = [
    "equipment_id",
    "layer_depth",
    "test_depth",
    "density_spec",
    "moisture_spec",
    "standard_density_count",
    "standard_moisture_count",
    "density_count",
    "moisture_count",
    "wet_density",
    "moisture_percent",
  ];

  requiredFields.forEach((field) => {
    if (nuc[field] === null || nuc[field] === "") {
      nucs[index][field] = "Required";
    }
  });

  if (Object.keys(nucs[index]).length !== 0) {
    throw new SubmissionError({
      nucs,
      _error: "Submision Failed",
    });
  }
};

export default validate;
