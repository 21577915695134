import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import { useAuth } from "../../context/auth-context";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { BillableItem } from "./billableItemTypes";
import useBillableItems from "./hooks/useBillableItems";
import { Pricing, BillableItemUnit } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const BillableItemsList = () => {
  const { user } = useAuth();

  const userCanAdd = user?.hasAccessTo("App\\Models\\BillableItem", "create");

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/billable-item-units-billable-items/" />
      <HeaderPage
        titlePage="Billable Items"
        pageDescription={
          <>
            The Billable Items and Billable Item Units feature enables tracking
            and billing for project items and services, with units of
            measurement specified for each item, and can be linked to
            deliverables and prices for accurate client billing, and optionally
            marked as requiring insurance.
          </>
        }
        relatedLinks={[
          {
            name: "Standard Price List",
            link: "/standard-pricing",
            model: Pricing,
          },
          {
            name: "Billable Item Units",
            link: "/billable-item-units",
            model: BillableItemUnit,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "billable-items", name: "Billable Items", active: true },
        ]}
      />

      <PaginatedList
        indexHook={useBillableItems}
        originalFilters={[]}
        addLink={userCanAdd ? "/billable-items/add" : ""}
        itemCard={({ item }: { item: BillableItem }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<BillableItemHeader item={item} />}
          />
        )}
      />
    </>
  );
};

const BillableItemHeader = ({ item }: { item: BillableItem }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={item.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={item.link}>{item.name}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const info = (item: BillableItem): PaginatedInformation[] => {
  return [
    {
      name: "Requires Insurance",
      value: item.insurable ? "Yes" : "No",
    },
  ];
};

export default BillableItemsList;
