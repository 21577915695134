import PropTypes from "prop-types";
import React from "react";
import CardDash from "../dashboard/CardDash";
import SpreadPrice from "../utils/SpreadPrice";

const DashboardCards = ({
  dashboard,
  setSelectedView,
  selectedView,
  render,
}) => {
  const totalOutgoings =
    dashboard.purchases_billed +
    dashboard.equipment_rates +
    dashboard.timesheets;

  const totalIncome = dashboard.total_invoiced;

  const overallTotal = totalIncome - totalOutgoings;

  const income = [
    {
      label: "WIP",
      value: <SpreadPrice price={dashboard.wip} />,
      color: "success",
      onClick: () => setSelectedView("wip"),
      view: "wip",
    },
    {
      label: "Invoiced",
      value: <SpreadPrice price={dashboard.total_invoiced} />,
      color: "success",
      onClick: () => setSelectedView("invoiced"),
      view: "invoiced",
    },
    {
      label: "Estimated Amount Billed",
      value: <SpreadPrice price={dashboard.estimated_amount_billed} />,
      color: "success",
      onClick: () => setSelectedView("estimated_amount_billed"),
      view: "estimated_amount_billed",
    },
  ];

  const purchases = [
    {
      label: "Purchases Made",
      value: <SpreadPrice price={dashboard.upcoming_purchases} />,
      color: "danger",
      onClick: () => setSelectedView("purchases_made"),
      view: "purchases_made",
    },
    {
      label: "Purchases Billed",
      value: <SpreadPrice price={dashboard.purchases_billed} />,
      color: "danger",
      onClick: () => setSelectedView("purchases_billed"),
      view: "purchases_billed",
    },
  ];

  const outgoings = [
    {
      label: "Wages",
      value: <SpreadPrice price={dashboard.timesheets} />,
      color: "danger",
      onClick: () => setSelectedView("wages"),
      view: "wages",
    },
    {
      label: "Equipment Hire",
      value: <SpreadPrice price={dashboard.equipment_rates} />,
      color: "danger",
      onClick: () => setSelectedView("equipment_hire"),
      view: "equipment_hire",
    },
  ];

  const totals = [
    {
      label: "Total Income",
      value: <SpreadPrice price={totalIncome} />,
      color: "success",
    },
    {
      label: "Total Expenses",
      value: <SpreadPrice price={totalOutgoings} />,
      color: "danger",
    },
  ];

  const profitPercentage =
    overallTotal !== 0 ? ((overallTotal / totalIncome) * 100).toFixed(1) : "-";

  const profitDiv = (
    <div className="d-flex">
      <span>%</span>
      <span className="ms-auto">{profitPercentage}</span>
    </div>
  );

  const net = [
    {
      label: "Net Income ($)",
      value: <SpreadPrice price={overallTotal} />,
      color: overallTotal > 0 ? "success" : "danger",
    },
    {
      label: "Net Income (%)",
      value: profitDiv,
      color: overallTotal > 0 ? "success" : "danger",
    },
  ];

  const toShow = [
    { name: "income", items: income },
    { name: "purchases", items: purchases },
    { name: "outgoings", items: outgoings },
    { name: "totals", items: totals },
    { name: "net", items: net },
  ];

  return (
    <>
      {render ? (
        toShow.map((show) => {
          return render({ ...show, selectedView });
        })
      ) : (
        <>
          <CardDash items={income} selectedView={selectedView} />
          <CardDash
            outerClass="mt-3"
            items={outgoings}
            selectedView={selectedView}
          />
          <CardDash
            outerClass="mt-3"
            items={totals}
            selectedView={selectedView}
          />
          <CardDash outerClass="mt-3" items={net} selectedView={selectedView} />
        </>
      )}
    </>
  );
};

DashboardCards.propTypes = {
  setSelectedView: PropTypes.func.isRequired,
  selectedView: PropTypes.string.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default DashboardCards;
