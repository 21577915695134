import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import DeliverableTable from "../deliverables/DeliverableTable";
import useProjectDeliverables from "./hooks/useProjectDeliverables";
import DeliverableProfileButtons from "../deliverables/DeliverableProfileButtons";
import DocsModal from "../utils/DocsModal";

const Deliverables = () => {
  const { number } = useParams();

  const [selectedDeliverables, setSelectedDeliverables] = useState([]);

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/deliverables/" />
      <DeliverableProfileButtons selectedDeliverables={selectedDeliverables} />
      <PaginatedList
        indexHook={useProjectDeliverables}
        indexHookArguments={[number]}
        originalFilters={[]}
        list={({ data }) => (
          <div className="col-12">
            {data?.length > 0 && (
              <DeliverableTable
                useSelected={true}
                deliverables={data}
                selectedDeliverables={selectedDeliverables}
                setSelectedDeliverables={setSelectedDeliverables}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

export default Deliverables;
