import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import DateTime, { formatting } from "../utils/dateTime";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const Form = (props) => {
  const { takeAction } = useApi();
  const { initialValues, uploadPercent } = props;
  const { user } = useAuth();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (initialValues?.user_id) {
      updateRoles(initialValues.user_id);
    }
  }, []);

  const updateRoles = (id) => {
    takeAction("index", getStaffRoleUrl(id)).then(({ data }) => {
      setRoles(data.data);
    });
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <div className="row">
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInputAsync}
            url="/users/staff"
            name="user_id"
            label="User"
            onChangeValue={({ value }) => updateRoles(value)}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={DateTime}
            name="date"
            label="Date"
            {...formatting}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            options={roles}
            name="role_id"
            label="Role"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field component={RenderField} name="amount" label="Amount" />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="description"
            label="Description"
          />
        </div>
        {!initialValues?.uuid && (
          <div className="col-12 form-group">
            <Field
              component={FileInput}
              name="file"
              label="File"
              percent={uploadPercent}
            />
          </div>
        )}
        {user.is_admin && (
          <div className="col-12 form-group">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

export const getStaffRoleUrl = (userId) => {
  return `react-select-data?model=${encodeURIComponent(
    "App\\Models\\StaffRole",
  )}&select=id,branch_id,position_id,user_id,employment_basis,remarks,start_date,finish_date,organisation_id&label=roleBranch&value=id&where[user_id]=${userId}&additional[branch_id]=branch_id&additional[employment_basis]=employment_basis&additional[disabled]=is_disabled&has=branch`;
};

export default reduxForm()(Form);
