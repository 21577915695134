export interface Integration<ExtraInfo = {}> {
  uuid: string;
  id: number;
  type_description: "Accounting" | "OfficeSuite" | "Payroll";
  name: "Xero" | "Microsoft" | "Xero Payroll" | "Employment Hero";
  provider_platform: "Xero" | "Microsoft" | "Employment Hero";
  extra_info: ExtraInfo;
}

export enum IntegrationType {
  Accounting = "Accounting",
  OfficeSuite = "OfficeSuite",
  Payroll = "Payroll",
}

export enum IntegrationPlatform {
  Xero = "Xero",
  Microsoft = "Microsoft",
  EmploymentHero = "Employment Hero",
}
