import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import LoadingOverlay from "../utils/LoadingOverlay";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { PenaltyRate } from "./penaltyRateTypes";

const PenaltyRateHours = ({
  fields,
  initialValues,
  setPenaltyRate,
  reset,
}: WrappedFieldArrayProps<any> & {
  initialValues?: PenaltyRate;
  setPenaltyRate?: (rate: PenaltyRate) => void;
  reset(): void;
}) => {
  const { takeAction, loading }: IUseApi<any, { data: PenaltyRate }> = useApi();

  return (
    <>
      <LoadingOverlay loading={loading} />
      <FormHeader>Hours</FormHeader>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field}>
            <div className="form-group col-lg-3">
              <Field
                component={RenderField}
                name={`${field}.from`}
                label="From"
              />
            </div>
            <div className="form-group col-lg-3">
              <Field component={RenderField} name={`${field}.to`} label="To" />
            </div>
            <div className="form-group col-lg-3">
              <Field
                component={RenderField}
                name={`${field}.rate`}
                label="Rate"
                required
                validate={required}
              />
            </div>
            <div className="form-group col-lg-3 d-flex">
              <Button
                onClick={() => {
                  const hour = initialValues?.hours?.[index];

                  if (hour && setPenaltyRate) {
                    return deleteSwal()
                      .then(() =>
                        takeAction(
                          "destroy",
                          `/penalty-rate-hours/${hour?.uuid}`,
                        ),
                      )
                      .then(({ data }) => {
                        setPenaltyRate(data.data);
                        reset();
                      })
                      .catch(errorSwal);
                  }

                  fields.remove(index);
                }}
                color="danger"
                className="align-self-end justify-self-end"
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 form-group">
        <Button color="primary" outline onClick={() => fields.push({})}>
          Add Hours
        </Button>
      </div>
    </>
  );
};

export default PenaltyRateHours;
