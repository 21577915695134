import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import EmploymentHeroUserCard from "./EmploymentHeroUserCard";

export interface UserToSync {
  name: string;
  uuid: string;
  email: string;
  employment_hero_user?: {
    email: string;
    display_name: string;
    id: string;
  };
}

const SyncUsers = () => {
  const {
    data,
    loading,
    setData: setUsers,
  }: IUseApi<UserToSync[]> = useApi("integrations/employment-hero/users/sync", [], true);

  const users = data as UserToSync[];

  if (loading) {
    return <CustomScaleLoader>Fetching users to sync...</CustomScaleLoader>;
  }

  return (
    <div className="row">
      {users.map((user) => {
        return (
          <EmploymentHeroUserCard
            users={users}
            setUsers={setUsers}
            user={user}
            key={user.uuid}
          />
        );
      })}
    </div>
  );
};

export default SyncUsers;
