import dayjs from "dayjs";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import TimesheetForm from "./TimesheetForm";
import { EDIT_TIMESHEET_FORM } from "./TimesheetProfile";

const formatProductivity = (productivities, timesheet_id) => {
  return productivities.map((productivity) => {
    const start = productivity.start || productivity.start_time;
    const finish = productivity.end || productivity.finish_time;

    return {
      comments: productivity.comments,
      finish_time: dayjs(finish).format("YYYY-MM-DD HH:mm:ss"),
      start_time: dayjs(start).format("YYYY-MM-DD HH:mm:ss"),
      job_id: productivity?.job_id,
      uuid: productivity.id || productivity.uuid,
      job_link: productivity?.job_link,
      timesheet_id: timesheet_id,
      break_type: productivity?.break_type,
    };
  });
};

const EditTimesheet = (props) => {
  const { timesheet, updateTimesheet, productivity } = props;

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    values.productivity = formatProductivity(productivity, timesheet.id);

    return takeAction("update", `/timesheets/${timesheet.uuid}`, values)
      .then(({ data }) => {
        toast.success("Timesheet Updated");
        updateTimesheet(data.data);
      })
      .catch(formError);
  };

  return (
    <TimesheetForm
      userId={timesheet.user_id}
      onSubmit={onSubmit}
      requiresApproval={timesheet.approval_status?.status === "Approved"}
      initialValues={timesheet}
      timesheet={timesheet}
      form={EDIT_TIMESHEET_FORM}
    />
  );
};

export default EditTimesheet;
