import _ from "lodash";
import FormHeader from "../utils/FormHeader";
import ReactTable from "../table/ReactTable";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { ApprovalActionType } from "../approvals/approvalTypes";
import { toast } from "react-toastify";
import FormModal from "../utils/FormModal";
import { Field, FormSubmitHandler } from "redux-form";
import RenderField from "../utils/renderField";
import useModal from "../hooks/useModal";
import formError from "../utils/formError";
import { Table } from "@tanstack/react-table";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import { OrganisationSetting } from "../organisation/types";

export interface Approval {
  id: number;
  uuid: string;
  title: string;
  link: string;
  approvable_type: string;
  user: {
    id: number;
    name: string;
  };
  approvable_type_description: string;
  extra_information?: {
    [key: string]: {
      label: string;
      value: number;
      footer?: "sum";
    };
  };
  approval_options?: number[];
}

type SetApprovals = (approvals: Approval[]) => void;

const ApprovalList = ({
  approvals,
  setApprovals,
  refreshData,
}: {
  approvals: Approval[];
  setApprovals: SetApprovals;
  refreshData: Function;
}) => {
  const canBulkApprove = useOrganisationSetting("bulk_approve");

  const typeGroup = _.groupBy(approvals, "approvable_type");

  const allApprovals = approvals;

  return (
    <div className="mb-5">
      {Object.values(typeGroup).map((approvals: Approval[], i) => {
        const description = approvals[0].approvable_type_description;

        const userList = _.groupBy(approvals, "user.name");

        return (
          <div key={i} className="row">
            <div>
              <FormHeader className="text-dark">{description}s</FormHeader>
            </div>
            <div className="space-y-5">
              {Object.values(userList).map((approvals: Approval[]) => {
                return (
                  <ApprovalTable
                    allApprovals={allApprovals}
                    key={approvals[0].id}
                    canBulkApprove={canBulkApprove}
                    approvals={approvals}
                    setApprovals={setApprovals}
                    refreshData={refreshData}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ApprovalTable = ({
  approvals,
  allApprovals,
  setApprovals,
  refreshData,
  canBulkApprove,
}: {
  approvals: Approval[];
  allApprovals: Approval[];
  setApprovals: SetApprovals;
  refreshData: Function;
  canBulkApprove?: OrganisationSetting;
}) => {
  const [selectedRows, setSelectedRows] = useState<Approval[]>([]);
  const [formValues, setFormValues] = useState<Partial<ApprovalReasonForm>>();
  const [tableInstance, setTableInstance] = useState<Table<any>>();

  const { toggle, modal } = useModal();

  const { takeAction, loading }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<ApprovalReasonForm> = (values) => {
    return takeAction("update", "approval-actions/multiple", {
      ...values,
      actions: values.actions.map((approval) => approval.uuid),
    })
      .then(() => {
        setNewApprovals(setApprovals, allApprovals, values.actions);

        toast.success("Approval(s) updated successfully");

        setSelectedRows([]);

        refreshData();
        tableInstance?.toggleAllRowsSelected(false);
        toggle();
      })
      .catch(formError);
  };

  const handleAction = (values: {
    status: ApprovalActionType;
    approvals: Approval[];
  }) => {
    if (values.status !== ApprovalActionType.Approve) {
      setFormValues({
        approval_status: values.status,
        actions: values.approvals,
      });
      toggle();
      return;
    }

    return takeAction("update", "approval-actions/multiple", {
      actions: values.approvals.map((approval) => approval.uuid),
      approval_status: values.status,
    }).then(() => {
      setNewApprovals(setApprovals, allApprovals, values.approvals);

      toast.success("Approval(s) updated successfully");
      refreshData();
      tableInstance?.toggleAllRowsSelected(false);
      setSelectedRows([]);
    });
  };

  const approvalOptions = [
    {
      label: "Approve",
      value: ApprovalActionType.Approve,
    },
    {
      label: "Redo",
      value: ApprovalActionType.Redo,
    },
    {
      label: "Deny",
      value: ApprovalActionType.Deny,
    },
  ];

  const currentApprovalOptions = approvals[0].approval_options ?? [];

  const filteredApprovalOptions =
    currentApprovalOptions.length > 0
      ? approvalOptions.filter(({ value }) =>
          currentApprovalOptions.includes(value),
        )
      : approvalOptions;

  const columns = [
    {
      accessorKey: "title",
      header: "Approval",
      footer: canBulkApprove?.meta_value
        ? `${selectedRows.length} selected`
        : null,
      cell: (info: any) => {
        const approval = info.row.original;
        return <Link to={approval.link}>{approval.title}</Link>;
      },
    },
  ];

  if (approvals[0].extra_information) {
    Object.entries(approvals[0].extra_information).forEach(([key, value]) => {
      const newColumn = {
        accessorKey: `extra_information.${key}.value`,
        header: value.label,
      };

      if (value.footer === "sum") {
        // @ts-ignore
        newColumn.footer = `Total: ${_.sumBy(
          approvals,
          (a) => a.extra_information?.[key].value ?? 0,
        ).toFixed(2)}`;
      }

      // @ts-ignore
      columns.push(newColumn);
    });
  }

  if (canBulkApprove?.meta_value) {
    columns.push({
      accessorKey: "approve",
      header: "Action",
      cell: (info: any) => {
        const approval = info.row.original;
        return (
          <div className="d-flex space-x-2">
            {filteredApprovalOptions.map(({ label, value }) => (
              <Button
                onClick={() => {
                  handleAction({
                    status: value,
                    approvals: [approval],
                  });
                }}
                key={value}
                size="sm"
                color="link"
              >
                {label}
              </Button>
            ))}
          </div>
        );
      },
      // @ts-ignore
      footer:
        selectedRows.length > 0
          ? filteredApprovalOptions.map(({ label, value }) => (
              <Button
                onClick={() =>
                  handleAction({
                    status: value,
                    approvals: selectedRows,
                  })
                }
                key={value}
                size="sm"
                color="link"
              >
                {label}
              </Button>
            ))
          : null,
    });
  }

  return (
    <>
      <div className="shadow-sm bg-white">
        <div className="bg-light p-3 border-top border-start border-end position-sticky top-0 z-20 rounded-top">
          <p className="mb-0 text-dark">
            <span className="fw-bolder">
              {approvals[0].user.name}'s{" "}
              {approvals[0].approvable_type_description}s
            </span>
          </p>
        </div>
        <ReactTable
          wrapperClasses="white-table border-start border-end border-top table-responsive"
          enableMultiSelect={!!canBulkApprove?.meta_value}
          disableSearch
          data={approvals}
          setSelectedRows={setSelectedRows}
          //@ts-ignore
          columns={columns}
          getTableInstance={setTableInstance}
          loading={loading}
        />
      </div>
      <ReasonModal
        formValues={formValues}
        onSubmit={onSubmit}
        toggle={toggle}
        modal={modal}
        id={approvals[0].id}
      />
    </>
  );
};

const setNewApprovals = (
  setApprovals: SetApprovals,
  allApprovals: Approval[],
  approvals: Approval[],
) => {
  setApprovals(
    allApprovals.filter((approval) => {
      return !approvals.some((approval2) => approval2.id === approval.id);
    }),
  );
};

interface ApprovalReasonForm {
  remarks: string;
  actions: Approval[];
  approval_status: ApprovalActionType;
}

const ReasonModal = ({
  modal,
  toggle,
  onSubmit,
  formValues,
  id,
}: {
  modal: boolean;
  toggle: () => void;
  formValues?: Partial<ApprovalReasonForm>;
  onSubmit: FormSubmitHandler<ApprovalReasonForm>;
  id: number;
}) => {
  return (
    <FormModal
      modal={modal}
      toggle={toggle}
      form={`APPROVAL_REASON_${id}`}
      title="Reason"
      onSubmit={onSubmit}
      initialValues={formValues}
    >
      <div className="col-lg-12 form-group">
        <Field component={RenderField} name="remarks" textarea label="Reason" />
      </div>
    </FormModal>
  );
};

export default ApprovalList;
