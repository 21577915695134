import React, { useEffect, useState } from "react";
import useApi from "../api/useApi";
import { CPIRRiskLevels } from "../CPIRs/cpirTypes";
import useFilter, { IFilter } from "../hooks/useFilter";
import useObserver from "../hooks/useObserver";
import usePagination from "../hooks/usePagination";
import { Range } from "../hooks/useProductivityRange";
import Empty from "../utils/Empty";
import FilterDropdown from "../utils/FilterDropdown";
import flattenInfinitePages from "../utils/flattenInfinitePages";
import CustomScaleLoader from "../utils/scaleLoader";
import useManagementCpirs from "./hooks/useManagementCpirs";
import ManagementCPIR from "./ManagementCPIR";
import ManagementCPIRCharts from "./ManagementCPIRCharts";
import { Group } from "./managementDashboardTypes";
import { CPIR } from "./managementTypes";

export type CPIRFilterType = "risk" | "status" | "cpir_categories.name";

export type CPIRFilters = IFilter<CPIRFilterType, number | string>;
interface ManagementCPIRsProps {
  selectedGroup?: Group;
  range: Range;
}

const ManagementCPIRs = ({ selectedGroup, range }: ManagementCPIRsProps) => {
  const { searchQuery, onSearch } = usePagination();

  const { data: cpirCategories }: { data: { name: string }[] } = useApi(
    "cpir-categories",
    [],
  );

  const [cpirFilters, setCpirFilters] = useState(baseCpirFilters);

  useEffect(() => {
    setCpirFilters([
      ...baseCpirFilters,
      {
        label: "Category",
        name: "cpir_categories.name",
        options: cpirCategories.map((category) => {
          return {
            label: category.name,
            value: category.name,
          };
        }),
      },
    ]);
  }, [cpirCategories]);

  const { stringified, filters, toggleFilter, filterCount, activeFilters } =
    useFilter<CPIRFilterType, CPIRRiskLevels | string>(cpirFilters);

  const { data, isFetchingNextPage, fetchNextPage, isFetching } =
    useManagementCpirs(
      selectedGroup,
      rangeQuery(range) ? searchQuery + stringified + rangeQuery(range) : "",
    );

  const intersection = useObserver(selectedGroup ? fetchNextPage : undefined);
  const cpirs = flattenInfinitePages<CPIR>(data);
  const meta = data?.pages?.[0].meta;

  return (
    <div>
      <ManagementCPIRCharts group={selectedGroup} range={range} />
      <div className="d-flex align-items-center mb-3">
        <div className="search-box shadow-sm flex-grow-1 d-flex">
          <input
            autoComplete="off"
            placeholder="Search..."
            className="form-control w-100"
            type="search"
            style={{ zIndex: 1 }}
            onChange={onSearch}
          />
          <button className="btn btn-primary ms-auto" type="button">
            <i
              className={`fa fa-${isFetching ? "spinner fa-spin" : "search"}`}
            />
          </button>
        </div>
        <FilterDropdown
          filters={filters}
          toggleFilter={toggleFilter}
          filterCount={filterCount}
        />
      </div>
      <div>
        {meta?.total && meta?.total > 0 ? (
          <p>
            Filtered {meta?.total} CPIR{meta?.total > 1 ? "S" : ""}.
          </p>
        ) : null}
      </div>
      <div className="space-y-5">
        {!isFetching && cpirs?.length === 0 && (
          <div className="mt-5">
            <Empty width="25%" height="50%">
              {activeFilters.length > 0 ? (
                <div className="d-block text-center">
                  <p className="tx-inverse tx-16 fw-bolder mb-2">
                    No Results found for the following filters:
                  </p>
                  <div className="mb-3">
                    {activeFilters.map((filter, index) => {
                      return (
                        <p key={index} className="mb-0">
                          <span className="tx-inverse">
                            {filter.header_label}
                          </span>{" "}
                          - {filter.label}
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p className="tx-inverse fw-bolder">No Results found</p>
              )}
            </Empty>
          </div>
        )}

        {cpirs?.map((cpir) => {
          return <ManagementCPIR cpir={cpir} key={cpir.uuid} />;
        })}
      </div>

      <div ref={intersection} />

      {isFetchingNextPage && (
        <CustomScaleLoader>Fetching More CPIRS...</CustomScaleLoader>
      )}
    </div>
  );
};

export const rangeQuery = (range: Range): string => {
  if (!range) {
    return "";
  }

  return `&filter[period]=${range.start?.format(
    "YYYY-MM-DD",
  )},${range.end?.format("YYYY-MM-DD")}`;
};

export const baseCpirFilters: CPIRFilters[] = [
  {
    label: "Risk Level",
    name: "risk",
    options: [
      {
        label: "None",
        value: 0,
      },
      {
        label: "Low",
        value: CPIRRiskLevels.Low,
      },
      {
        label: "Medium",
        value: CPIRRiskLevels.Medium,
      },
      {
        label: "High",
        value: CPIRRiskLevels.High,
      },
      {
        label: "Extreme",
        value: CPIRRiskLevels.Extreme,
      },
    ],
  },
  {
    label: "Status",
    name: "status",
    options: [
      {
        label: "Closed",
        value: "closed",
      },
      {
        label: "Open",
        value: "open",
        selected: true,
      },
      {
        label: "Overdue",
        value: "overdue",
      },
      {
        label: "Waiting Approval",
        value: "under-approval",
      },
    ],
  },
];

export default ManagementCPIRs;
