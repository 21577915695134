import React from "react";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import EmploymentHeroLeaveCategoryCard from "./EmploymentHeroLeaveCategoryCard";

export interface LeaveReasonToSync {
  name: string;
  uuid: string;
  employment_hero_leave_reason?: {
    name: string;
    id: string;
  };
}

const SyncLeaveReasons = () => {
  const {
    data,
    loading,
    setData: setLeaveReasons,
  }: IUseApi<LeaveReasonToSync[]> = useApi(
    "integrations/employment-hero/leavecategories",
    [],
    true,
  );

  const leaveReasons = data as LeaveReasonToSync[];

  const { takeAction }: IUseApi = useApi();

  const syncEmploymentHeroLeaveCategories = async () => {
    return takeAction(
      "index",
      "integrations/employment-hero/leavecategories",
    )
      .then(({ data }) => {
        toast.success(
          `Employment Hero Leave Categories has been successfully sync into your organisation!`,
        );
      })
      .catch(errorSwal);
  };

  if (loading) {
    return (
      <CustomScaleLoader>Fetching leave reasons to sync...</CustomScaleLoader>
    );
  }

  return (
    <div className="row">
      <div className="mb-4">
        <div className="d-flex align-items-center gap-2">
          <div>
            Import your Employment Hero leave categories: 
          </div>
          <Button
            type="button"
            onClick={syncEmploymentHeroLeaveCategories}
            color="secondary"
            outline
            disabled={loading}
          >
            {!loading ? "Import Leave Categories" : "Importing Leave Categories..."}
          </Button>
        </div>
      </div>

      <div>
        {leaveReasons.map((leaveReason) => {
          return (
            <EmploymentHeroLeaveCategoryCard
              leaveReason={leaveReason}
              leaveReasons={leaveReasons}
              setLeaveReasons={setLeaveReasons}
              key={leaveReason.uuid}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SyncLeaveReasons;
