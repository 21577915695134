import { Field, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import CustomForm from "../customFields/CustomForm";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";

import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import LaboratoryDetails from "./LaboratoryDetails";

const BranchForm = (props) => {
  const { user } = useAuth();
  const { onDelete, initialValues, handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-4 form-group">
          <Field
            label="Tracking Site"
            name="tracking_site_id"
            component={SelectInputAsync}
            url="/trackingsites/sites"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Division"
            name="division_id"
            component={SelectInputAsync}
            url="/organisationdivisions/divisions"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-4 form-group">
          <Field
            label="Branch Manager"
            name="branch_manager_id"
            component={SelectInputAsync}
            url="/users/staff"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-12 form-group">
          <Field
            label="Pause Deliverables"
            name="pause_deliverables"
            component={renderToggleInput}
          />
        </div>

        <LaboratoryDetails form={props.form} />

        <CustomForm
          customForm={initialValues?.custom_form ?? props.customFields}
          {...props}
          shouldError={true}
        />

        <div className="col-lg-12 mg-t-25 d-none d-lg-block" />

        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
        {initialValues?.uuid && user.is_admin && (
          <div className="form-group col-lg-3">
            <button
              type="button"
              className="btn btn-outline-danger form-control"
              onClick={() => {
                onDelete(initialValues.uuid);
              }}
            >
              <i className="fa fa-trash mg-r-5" /> Delete
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({});

export default form(BranchForm);
