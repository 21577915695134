import { useEffect, useState } from "react";
import CardDash from "../dashboard/CardDash";
import DashboardViews from "../dashboard/DashboardViews";
import fetchDashboard from "../utils/fetchDashboard";
import CustomScaleLoader from "../utils/scaleLoader";
import DashboardCards from "./DashboardCards";

const Costs = ({ project, from, to, allDates }) => {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [selectedView, setSelectedView] = useState("");

  const fetch = () => {
    return fetchDashboard(
      "App\\Models\\Project",
      project.id,
      allDates ? null : from,
      allDates ? null : to,
      setDashboard,
      false,
    ).then(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedView("");
    if ((from && to) || allDates) {
      setLoading(true);
      fetch();
    }
  }, [from, to, allDates]);

  if (loading)
    return <CustomScaleLoader>Fetching Overview...</CustomScaleLoader>;

  return (
    <>
      <DashboardCards
        dashboard={dashboard}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        render={(toShow) => {
          return <CardDash key={toShow.name} {...toShow} outerClass="mt-3" />;
        }}
      />
      <DashboardViews
        from={from}
        to={to}
        ignoreDates={allDates}
        selected={{
          model: "App\\Models\\Project",
          value: project.id,
        }}
        view={selectedView}
      />
    </>
  );
};

export default Costs;
