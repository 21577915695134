import React from "react";
import Prices from "./Prices";
import NotFound from "../404";
import AddPayment from "./AddPayment";
import ResourcePurchaseList from "../purchases/ResourcePurchaseList";
import ProfileSingleFile from "../utils/ProfileSingleFile";

const ShowPage = (props) => {
  const {
    match,
    recurringPurchase,
    setRecurringPurchase,
    isAccounting,
  } = props;
  const { page } = match.params;

  const whenSubmitted = ({ data }) => {
    setRecurringPurchase({
      ...recurringPurchase,
      quote: data.data,
    });
  };

  switch (page) {
    case "details":
      return <Prices {...props} initialValues={recurringPurchase} />;
    case "quote":
      return (
        <ProfileSingleFile
          {...props}
          whenSubmitted={whenSubmitted}
          link={recurringPurchase.quote.link}
          mime_type={recurringPurchase.quote.mime_type}
          documentable_id={recurringPurchase.uuid}
          documentable_type="App\Models\RecurringPayment"
          bucket={`recurring-payment-quotes/${recurringPurchase.uuid}`}
        />
      );
    case "purchases":
      return (
        <ResourcePurchaseList
          {...props}
          purchases={recurringPurchase.purchases}
        />
      );
    case "add-purchase":
      return isAccounting && !recurringPurchase.archived_at ? (
        <AddPayment
          {...props}
          initialValues={{ recurring_payment_id: recurringPurchase.id }}
        />
      ) : (
        <NotFound />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
