import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getPurchases = ({ pageParam = 1 }, search: string, uuid: string) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `projects/${uuid}/purchases?page=${pageParam}&filter[search]=${search}`,
    )
    .then(({ data }) => data);
};

export default function useProjectPurchases(search: string, uuid: string) {
  return useInfiniteQuery(
    ["project-purchases", { search, uuid }],
    (pageParam) => getPurchases(pageParam, search, uuid),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }
        const nextPage = meta.current_page + 1;

        return nextPage;
      },
    },
  );
}
