import dayjs from "dayjs";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import useApi from "../api/useApi";
import ApprovalAlert from "../approvals/ApprovalAlert";
import ApprovalButton from "../approvals/ApprovalButton";
import ApprovalComments from "../approvals/ApprovalComments";
import SideList from "../comments/SideList";
import DownloadDropdown from "../customJobs/jobs/DownloadDropdown";
import HeaderPage from "../header/HeaderPage";
import ApprovalStatus from "../user/ApprovalStatus";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import CompleteButton from "./CompleteButton";
import GenerateJobButton from "./GenerateJobButton";
import ShowPage from "./ShowPage";

const Profile = () => {
  const { page, uuid } = useParams();
  const location = useLocation();

  const redirect = location.state?.equipment
    ? `/equipment/${location.state.equipment.uuid}/calibrations`
    : "/equipment";

  const {
    loading,
    data: calibration,
    setData: setCalibration,
    refreshData,
  } = useApi(`equipment-calibrations/${uuid}`, {}, true, redirect);

  const showFile =
    (calibration?.custom_form?.length === 0 && !calibration?.document?.link) ||
    calibration?.document?.link;

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={calibration.name}
        crumbs={[
          { link: "/equipment", name: "Equipment" },
          {
            link: calibration.equipment.link,
            name: calibration.equipment.name,
          },
          {
            link: `/equipment/${calibration.equipment.uuid}/calibrations`,
            name: "Calibrations",
          },
          {
            link: "",
            name: calibration.calibration_type.procedures,
            active: true,
          },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <DashboardHeader
            progress={!calibration.completed_at ? calibration.percent : 100}
            progressBar
            invertColor={calibration.completed_at}
          >
            <DashboardH3>{calibration.name}</DashboardH3>
            <DashboardSubTitle>
              <DueBadge calibration={calibration} />
            </DashboardSubTitle>
            <DashboardSubTitle>
              Equipment:{" "}
              <Link to={calibration.equipment.link}>
                {calibration.equipment.name}
              </Link>
            </DashboardSubTitle>
            {calibration.job.link && (
              <DashboardSubTitle>
                Job:{" "}
                <Link to={calibration.job.link}>{calibration.job.name}</Link>
              </DashboardSubTitle>
            )}
            {calibration.completed_by ? (
              <DashboardSubTitle>
                Completed by: {calibration.completed_by.name}
              </DashboardSubTitle>
            ) : null}
          </DashboardHeader>
          <div className="d-block d-lg-none mt-3">
            <ApprovalStatus
              status={calibration.approval_status}
              className="w-100 py-2"
            />
          </div>
          <ApprovalAlert
            approvalAction={calibration.approval_action}
            approval={calibration.approval}
          />
          <DownloadDropdown
            model={{
              model: "App\\Models\\EquipmentCalibration",
              id: calibration?.id,
            }}
            documents={calibration?.template?.documents}
          />

          <CompleteButton
            calibration={calibration}
            setCalibration={setCalibration}
          />
          <ShowPage
            calibration={calibration}
            setCalibration={setCalibration}
            showFile={showFile}
          />
        </div>
        <div className="col-lg-4">
          <TabList>
            <Tab
              link={`/calibrations/${uuid}/details`}
              active={page === "details"}
            >
              Details
            </Tab>
            {showFile && (
              <Tab
                link={`/calibrations/${uuid}/file-history`}
                active={page === "file-history"}
              >
                File History
              </Tab>
            )}
          </TabList>

          <div className="d-none d-lg-block mt-3">
            <ApprovalStatus
              status={calibration.approval_status}
              className="w-100 py-2"
            />
          </div>

          {calibration.approval_action && (
            <ApprovalButton
              canTransfer
              onSuccess={refreshData}
              approvalAction={calibration.approval_action}
            />
          )}
          <GenerateJobButton
            calibration={calibration}
            setCalibration={setCalibration}
          />
          <ApprovalComments
            modelType="App\Models\EquipmentCalibration"
            modelId={calibration.id}
            className="mt-3"
          />
          <div className="mt-5">
            <SideList
              commentableType="App\Models\EquipmentCalibration"
              commentableId={calibration.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const DueBadge = ({ calibration }) => {
  if (calibration?.approval_status?.status === "To be Approved") {
    return (
      <Badge color="info">
        To be approved by {calibration.approval_status.by}
      </Badge>
    );
  }

  if (calibration?.approval_status?.status === "To be Redone") {
    return (
      <Badge color="warning">
        To be redone by {calibration.approval_status.by}
      </Badge>
    );
  }

  return (
    <Badge color={calibration.completed_at ? "success" : "warning"}>
      {calibration.completed_at ? (
        "Completed"
      ) : calibration.date ? (
        <>Due on {dayjs(calibration.date).format("DD/MM/YYYY")}</>
      ) : (
        "Not Complete"
      )}
    </Badge>
  );
};
export default Profile;
