import { useState } from "react";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import ReminderType, { getDescription } from "../../enums/ReminderType";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import errorSwal from "../utils/errorSwal";
import ApprovalReminder from "./ApprovalReminder";
import JobReminder from "./JobReminder";
import ProjectReminder from "./ProjectReminder";
import PurchaseReminder from "./PurchaseReminder";

const ReminderModal = (props) => {
  const { currentReminderType, reminder, modalIndex, setModalIndex } = props;
  const { takeAction } = useApi();

  const [reminderIndex, setReminderIndex] = useState(0);

  const currentReminder = reminder?.[reminderIndex];

  const moveForward = () => {
    if (reminderIndex + 1 === reminder.length) {
      setModalIndex(modalIndex + 1);
      setReminderIndex(0);
      return;
    }
    setReminderIndex(reminderIndex + 1);
  };

  const onSubmit = (values, dispatch) => {
    return takeAction(
      "update",
      `${currentReminderType.update_url}/${currentReminder.uuid}`,
      values,
    )
      .then(() => {
        toast.success("Updated");
        dispatch(reset("Reminder"));
        moveForward();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      {...props}
      onSubmit={onSubmit}
      initialValues={currentReminder}
      form="Reminder"
      headerTag={({ children }) => <h5 className="w-100">{children}</h5>}
      disabled={!currentReminderType?.update_url}
      title={
        <div className="d-flex align-items-center">
          <p className="mb-0">
            You have outstanding {getDescription(currentReminderType?.type)}s
          </p>
          <small className="ms-auto">
            {reminderIndex + 1} / {reminder?.length}
          </small>
        </div>
      }
    >
      {currentReminder && (
        <ShowForm
          {...props}
          type={currentReminderType?.type}
          currentReminder={currentReminder}
          setReminderIndex={setReminderIndex}
          reminderIndex={reminderIndex}
          moveForward={moveForward}
        />
      )}
    </FormModal>
  );
};

const ShowForm = (props) => {
  const { type } = props;

  if (type === undefined) return null;

  switch (type) {
    case ReminderType.Purchase:
      return <PurchaseReminder {...props} />;
    case ReminderType.Project:
      return <ProjectReminder {...props} />;
    case ReminderType.Job:
      return <JobReminder {...props} />;
    case ReminderType.Approval:
      return <ApprovalReminder {...props} />;
  }
};

export default ReminderModal;
