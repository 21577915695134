import React from "react";

type TextButtonProps = {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  disabled?: boolean;
  id?: string;
  tabIndex?: number;
  children: React.ReactNode;
  style?: any;
};

const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  className = "",
  children,
  disabled = false,
  tabIndex,
  style = {},
}) => {
  return (
    <button
      type="button"
      className={`bg-transparent border-0 p-0 ${className}`}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex ?? undefined}
      style={style}
    >
      {children}
    </button>
  );
};

export default TextButton;
