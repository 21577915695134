import React, { useState } from "react";
import { toast } from "react-toastify";
import Confetti from "react-dom-confetti";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import SuccessModal from "./SuccessModal";
import InformationAlert from "../utils/InformationAlert";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import UnsuccessfulModal from "./UnsuccessfulModal";

const config = {
  angle: 90,
  spread: 45,
  startVelocity: 45,
  elementCount: "199",
  dragFriction: 0.1,
  duration: "5500",
  stagger: "1",
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const TenderSuccessButtons = (props) => {
  const { tender, setTender } = props;
  const [successful, setSuccessful] = useState(false);
  const { toggle: toggleSuccess, modal: successModal } = useModal();
  const { toggle: toggleUnsuccessful, modal: unSuccessfulModal } = useModal();

  const { takeAction, loading: marking } = useApi();

  const updateStatus = (status) => {
    if (status) return toggleSuccess();
    if (status === false) return toggleUnsuccessful();

    if (status === null && tender.successful) {
      return deleteSwal(
        "",
        "Marking this tender as unssuccesful will delete the project associated to this tender.",
      ).then(() => updateTender(status));
    }

    return updateTender(status);
  };

  const updateTender = (successful) => {
    return takeAction("update", `/tender-status/${tender.uuid}`, {
      successful,
    })
      .then(({ data }) => {
        toast.success(`${data.data.name} updated.`);
        return setTender(data.data);
      })
      .catch(errorSwal);
  };

  if (!tender.file.link || !tender.submitted_on) {
    return (
      <InformationAlert
        type="info"
        title="Action Required"
        body="Mark tender as submitted and upload a quote to be able to mark tender as
      successful or unsuccessful."
        className="mb-3"
      />
    );
  }

  return (
    <>
      <SuccessModal
        modal={successModal}
        toggle={toggleSuccess}
        {...props}
        setSuccessful={setSuccessful}
        initialValues={{
          project_manager: tender.job.project_manager,
          client: !tender.client ? { name: tender.client_name } : null,
          scheduled_start_date: tender.work_start_date,
          scheduled_finish_date: tender.work_finish_date,
        }}
      />
      <UnsuccessfulModal
        toggle={toggleUnsuccessful}
        modal={unSuccessfulModal}
        {...props}
      />
      <ul className="nav nav-activity-profile mg-y-20">
        {(tender.successful || tender.successful === null) && (
          <>
            <li className="nav-item">
              <button
                type="button"
                onClick={() =>
                  updateStatus(tender.successful === null ? true : null)
                }
                className="pointer-hover w-100 nav-link"
              >
                {isSubmitting(
                  marking,
                  <>
                    {!tender.successful && (
                      <i className="icon ion-checkmark tx-success" />
                    )}
                    {tender.successful ? "Unm" : "M"}ark Tender as Successful
                  </>,
                  "Marking",
                )}
              </button>
            </li>
            <Confetti active={successful} config={config} />
          </>
        )}

        {(tender.successful === false || tender.successful === null) && (
          <li className="nav-item">
            <button
              type="button"
              onClick={() =>
                updateStatus(tender.successful === null ? false : null)
              }
              className="pointer-hover w-100 nav-link"
            >
              {isSubmitting(
                marking,
                <>
                  {tender.successful === null && (
                    <i className="icon ion-close tx-danger" />
                  )}
                  {tender.successful !== null ? "Unm" : "M"}ark Tender as
                  Unsuccessful
                </>,
                "Marking",
              )}
            </button>
          </li>
        )}
      </ul>
    </>
  );
};

export default TenderSuccessButtons;
