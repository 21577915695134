import React from "react";
import { toast } from "react-toastify";
import { Field, FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import formError from "../utils/formError";
import FormModal from "../utils/FormModal";
import SelectInputAsync from "../utils/SelectInputAsync";
import { DocumentPermissions, StandardDocument } from "./standardDocumentTypes";

interface PermissionsModalProps {
  toggle: Function;
  modal: boolean;
  document: StandardDocument;
  setDocuments: (documents: StandardDocument[]) => void;
  handleDoubleClick: (e: React.MouseEvent) => void;
  documents: StandardDocument[];
}

const PermissionsModal = (props: PermissionsModalProps) => {
  const {
    toggle,
    modal,
    document,
    documents,
    setDocuments,
    handleDoubleClick,
  } = props;

  const { takeAction }: IUseApi<{}, { data: StandardDocument }> = useApi();

  const onSubmit: FormSubmitHandler<StandardDocument> = (values) => {
    return takeAction(
      "update",
      `standard-documents/${document.uuid}/permissions`,
      values,
    )
      .then(({ data }) => {
        setDocuments(
          documents.map((d) => (d.uuid !== data.data.uuid ? d : data.data)),
        );
        toast.success("Permissions set successfully");
        toggle();
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      modal={modal}
      title={`Edit Permissions for ${document.name}`}
      onSubmit={onSubmit}
      initialValues={document}
      form="EditDocumentPermissions"
      handleDoubleClick={handleDoubleClick}
    >
      <div className="col-lg-12 form-group">
        <Field
          component={SelectInput}
          name="permissions"
          label="Permissions"
          options={[
            {
              label: "Admin",
              value: DocumentPermissions.Admin,
            },
            {
              label: "Senior",
              value: DocumentPermissions.Senior,
            },
            {
              label: "Junior",
              value: DocumentPermissions.Junior,
            },
            {
              label: "Basic",
              value: DocumentPermissions.Basic,
            },
          ]}
        />
      </div>
      <div className="col-lg-12 form-group">
        {
          //Using async as we don't have a proper api endpoint to get users from
        }
        <Field
          component={SelectInputAsync}
          multiple
          url="/users/staff"
          label="Users"
          name="user_permissions"
        />
      </div>
      <small>
        Staff can only see documents where the permission is below their highest
        level of access.
      </small>
    </FormModal>
  );
};

export default PermissionsModal;
