import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";

const ProductivityList = ({ productivity }) => {
  const columns = [
    {
      accessorKey: "start_time",
      header: "Date",
      cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "user",
      header: "User",
      cell: (info) => (
        <a href={`mailto:${info.row.original.user_email}`}>{info.getValue()}</a>
      ),
    },
    {
      accessorKey: "job_full_name",
      header: "Job",
      cell: (info) => (
        <Link to={info.row.original.job_link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "time_in_hours",
      header: "Hours",
    },
    {
      accessorKey: "chargeout_amount",
      header: "Charge Out",
      cell: (info) => <SpreadPrice price={info.getValue()} />,
    },
  ];

  return <ReactTable columns={columns} data={productivity} />;
};

export default ProductivityList;
