import React from "react";
import { useHistory } from "react-router-dom";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import ProfileButtons from "../utils/ProfileButtons";
import isSubmitting from "../utils/submitting";

const Add = ({ project }) => {
  const { takeAction, loading } = useApi();

  const buttons = [
    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" />{" "}
          {isSubmitting(loading, "Add Invoice", "Adding...")}
        </>
      ),
      onClick: () => addInvoice("invoices"),
      disabled: loading,
    },

    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" />{" "}
          {isSubmitting(loading, "Add Credit Note", "Adding...")}
        </>
      ),
      onClick: () => addInvoice("credit-notes"),
      disabled: loading,
    },
  ];

  const history = useHistory();

  const addInvoice = (style = "invoices") => {
    return takeAction("store", `projects/${project.uuid}/${style}`)
      .then(({ data }) => {
        history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  return <ProfileButtons buttons={buttons} />;
};

export default Add;
