import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/api";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";

import DatabaseListView from "../listView/DatabaseListView";
import errorSwal from "../utils/errorSwal";
import Spinner from "../utils/Spinner";
import isSubmitting from "../utils/submitting";
import { Project } from "../../enums/Model";

dayjs.extend(localizedFormat);

const Suppliers = () => {
  const { data: suppliers, loading } = useApi("suppliers", [], true);

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage="Suppliers"
        pageDescription={
          <>
            Add and manage suppliers that provide goods and/or services to your
            business.
          </>
        }
        relatedLinks={[{ name: "Projects", link: "/projects", model: Project }]}
        crumbs={[{ link: "", name: "Suppliers", active: true }]}
      />
      <div>
        <DatabaseListView
          add={{ link: "suppliers/add", name: "Supplier" }}
          list={suppliers}
          name="suppliers"
          buttons={[<DownloadButton />]}
        />
      </div>
    </>
  );
};

const DownloadButton = () => {
  const [downloading, setDownloading] = useState(false);

  const downloadData = () => {
    setDownloading(true);

    axios
      .post(`/supplier-exports`)
      .then((res) => {
        fileDownload(
          res.data,
          `suppliers ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        );
      })
      .catch(errorSwal)
      .then(() => setDownloading(false));
  };

  return (
    <button
      className="btn btn-link ms-2"
      type="button"
      onClick={downloadData}
      disabled={downloading}
    >
      {isSubmitting(
        downloading,
        <>
          <i className="fa fa-download tx-primary tx-16" /> Download CSV
        </>,
        "Generating...",
      )}
    </button>
  );
};

export default Suppliers;
