import * as _ from "lodash";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormValues,
} from "redux-form";
import FieldType from "../../enums/FieldType";
import ReadyOnly from "../form/ReadyOnly";
import ShowFormulaField from "../form/ShowFormulaField";
import StarRating from "../form/StarRating";
import CustomFileInput from "../upload/CustomFileInput";
import buttonGroup from "../utils/buttonGroup";
import DateTime, { formatting } from "../utils/dateTime";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import signaturePad from "../utils/signaturePad";
import CustomFieldArray from "./CustomFieldArray";
import FieldInformation from "./FieldInformation";
import MoistureField from "./MoistureField";
import RenderChartField from "./RenderChartField";
import CustomTimeInput from "./CustomTimeInput";
import CustomPromptInput from "./CustomPromptInput";

const CustomField = (props) => {
  const { name } = props;

  const formatter = props.type === FieldType.Date ? formatting : {};
  let passes = true;

  if (props.field_attributes.conditional) {
    passes = Object.entries(
      _.groupBy(props.field_attributes.conditionals, "field"),
    )[props.field_attributes.logical_operator](([key, conditions]) => {
      const field = props?.formValues?.custom_fields
        ? Object.values(props?.formValues?.custom_fields)
        : null;

      const conditionalField = field?.find(({ uuid }) => key == uuid);

      const fieldValue =
        conditionalField?.type === FieldType.Toggle &&
        conditionalField?.value === undefined
          ? false
          : conditionalField?.value;

      return conditions.some((condition) => {
        const functionString = getFunctionString(condition, fieldValue);

        const func = new Function(functionString);
        return func();
      });
    });
  }

  const urlString = getUrlString(
    props.field_attributes?.url,
    props.field_attributes?.filters,
    props.branchId,
    props.fieldUrlAppends,
    props.valueMapper,
  );

  useEffect(() => {
    if (!passes && props.dirty) {
      props.change(`${name}.${props.uuid}.value`, null);
    }
  }, [passes]);

  if (!passes) {
    return null;
  }

  if (props.type === FieldType.FieldArray) {
    return (
      <FieldArray
        name={`${name}.${props.uuid}.value`}
        component={CustomFieldArray}
        change={props.change}
        customForm={props.field_attributes.custom_fields}
        label={props.field_attributes.label}
        templates={props.templates ?? []}
        parentUuid={props.parentUuid}
        fieldUuid={props.uuid}
        formValues={props.formValues}
        form={props.form}
        valueMapper={props.valueMapper}
      />
    );
  }
  
  if (props.type === FieldType.Separator) {
    return (
      <hr />
    );
  }

  return (
    <div
      className={`col-lg-${props?.field_attributes?.width ?? "6"} form-group`}
    >
      <Field
        component={FieldToUse(props)}
        {...props.field_attributes}
        label={
          props.field_attributes.fsize ? (
            <span style={{ fontSize: `${props.field_attributes.fsize}px` }}>
              {props.field_attributes.label}
            </span>
          ) : (
            props.field_attributes.label
          )
        }
        name={`${name}.${props.uuid}.value`}
        onBlur={props.saveOnBlur ? props.submit : null}
        url={props.field_attributes?.options_selection === 1 ? urlString : null}
        formatData={formatData}
        {...formatter}
        textarea={!props.field_attributes?.type}
        extraProps={props.field_attributes}
        rows={1}
        change={props.change}
        dateFormat={props?.field_attributes?.date_format}
        validate={
          props?.field_attributes?.required && props.shouldError
            ? required
            : null
        }
        templates={props.templates ?? []}
        parentUuid={props.parentUuid}
        fieldUuid={props.uuid}
        closeMenuOnSelect={!props?.field_attributes.multiple}
        onPrompt={props.onPrompt}
        generatingPrompt={props.generatingPrompt}
        modelType={props.modelType}
        modelId={props.modelId}
      />
      <FieldInformation {...props} />
    </div>
  );
};

export const getUrlString = (
  url,
  filters,
  branchId,
  fieldUrlAppends = "",
  valueMapper,
) => {
  if (!url) {
    return null;
  }

  let filterString = "?";

  if (branchId && !url.includes("registers")) {
    filterString += `&filter[branch_id]=${branchId}`;
  }

  if (!filters || filters?.length === 0) {
    return `${url}${filterString}${fieldUrlAppends}`;
  }

  filters.forEach((filter) => {
    const value = valueMapper?.[filter.key] ?? filter.value;

    filterString += `&filter[${filter.key}]=${filter.sign ?? ""}${value}`;
  });

  return `${url}${filterString}${fieldUrlAppends}`;
};

const formatData = ({ data }) =>
  data.map((option) => ({
    label: option.name,
    value: option.id,
    disabled: option.disabled ?? false,
  }));

const FieldToUse = (props) => {
  const { type } = props;

  switch (type) {
    case FieldType.Text:
      return RenderField;
    case FieldType.Select:
      return SelectInputAsync;
    case FieldType.Toggle:
      return renderToggleInput;
    case FieldType.File:
      return CustomFileInput;
    case FieldType.Constant:
      return ReadyOnly;
    case FieldType.Date:
      return DateTime;
    case FieldType.Formula:
      return ShowFormulaField;
    case FieldType.RadioButton:
      return buttonGroup;
    case FieldType.StarRating:
      return StarRating;
    case FieldType.Signature:
      return signaturePad;
    case 12:
      return MoistureField;
    case FieldType.CustomValue:
      return ReadyOnly;
    case FieldType.Chart:
      return RenderChartField;
    case FieldType.Time:
      return CustomTimeInput;
    case 16:
      return CustomPromptInput;
    case FieldType.Separator:
      return ReadyOnly;
    default:
      return RenderField;
  }
};

const getFunctionString = (condition, fieldValue) => {
  if (fieldValue === undefined || fieldValue === null) {
    return "return false";
  }

  if (Array.isArray(fieldValue)) {
    const allValues = fieldValue.map((value) => {
      return getFunctionValue(condition, value?.value);
    });

    return `return ${allValues.join(" || ")}`;
  }

  return `return ${getFunctionValue(condition, fieldValue)}`;
};

const getFunctionValue = (condition, fieldValue) => {
  return `${typeof fieldValue === "string" ? `'${fieldValue}'` : fieldValue} ${
    condition.condition
  } ${
    typeof condition?.value === "string"
      ? `'${condition?.value}'`
      : condition?.value
  }`;
};

const mapStateToProps = (state, { form, name, uuid }) => {
  const selector = formValueSelector(form);

  return {
    formValues: getFormValues(form)(state),
    currentValue: selector(state, `${name}.${uuid}.value`),
  };
};

export default connect(mapStateToProps)(CustomField);
