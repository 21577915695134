import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Logo } from "../../svgs/logo.svg";
import Search from "../search/Search";
import { AdministrationLevels } from "../user/userTypes";

const HeaderLogo = () => {
  const { user } = useAuth();
  const originalLogoRatio = 1.414;
  const width = 50;
  const height = width * originalLogoRatio;

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;

  return (
    <div className="slim-header-left d-none d-lg-flex">
      <h2 className="slim-logo">
        <Link to="/home">
          <Logo
            height={height}
            width={width}
            title="theBOSSapp"
            className="header-logo"
          />
        </Link>
      </h2>
      {isBasic ? null : <Search />}
    </div>
  );
};

export default HeaderLogo;
