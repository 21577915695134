import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import errorSwal from "../utils/errorSwal";
import FormStaffRole from "./Form";
import { useQueryClient } from "react-query";

const AddEmploymentDetails = (props) => {
  const { history } = props;
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const onSubmit = (values) => {
    return takeAction("store", "staffroles", values)
      .then(({ data }) => {
        toast.success("Employment Detail created successfully!");
        queryClient.invalidateQueries(["employment-details"])
        history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Employment Detail"
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "employment-details", name: "Employment Details" },
          {
            link: "employment-details/add",
            name: "New Employment Detail",
            active: true,
          },
        ]}
      />
      <FormStaffRole onSubmit={onSubmit} />
    </>
  );
};

export default AddEmploymentDetails;
