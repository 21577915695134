import { useQueryClient } from "react-query";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormModal from "../utils/FormModal";
import { JobOverviewDashboardProject as IJobOverviewDashboardProject } from "./dashboardTypes";
import { toast } from "react-toastify";
import { UserIndex } from "../user/userTypes";
import FormjobType from "../jobs/register/JobForm";
import dayjs from "dayjs";
import formError from "../utils/formError";
import SelectInputAsync from "../utils/SelectInputAsync";
import { connect } from "react-redux";
import required from "../utils/required";

const AddJobModal = ({
  droppedUser,
  project,
  toggle,
  modal,
  branches,
  to,
  from,
}: {
  droppedUser?: UserIndex;
  project: IJobOverviewDashboardProject;
  toggle: () => void;
  modal: boolean;
  branches: { label: string; value: string }[];
  to: string;
  from?: string;
}) => {
  const queryClient = useQueryClient();

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("store", `projectjobs`, values)
      .then(() => {
        toast.success("Job added successfully");
        toggle();
        queryClient.invalidateQueries(["job-overview-dashboard"]);

        queryClient.invalidateQueries(["job-overview-dashboard-users"]);
      })
      .catch(formError);
  };

  return (
    <FormModal
      modal={modal}
      onSubmit={onSubmit}
      form={`ADD_JOB_DROP_${project.uuid}`}
      toggle={toggle}
      title={`Add new Job to ${project.name} for ${droppedUser?.full_name}`}
      initialValues={{
        project_manager: droppedUser?.id,
        project_id: project.id,
        name: `${droppedUser?.full_name} Duties${
          from ? ` - ${dayjs(from).format("DD/MM/YYYY")}` : ""
        }`,
        scheduled_start_date: from,
        scheduled_finish_date: to,
        branch_id: branches?.length === 1 ? branches?.[0]?.value : null,
      }}
    >
      {({ change }: InjectedFormProps) => {
        return (
          <>
            <FormjobType projectUuid={project.uuid} />
            <div className="col-lg-12 form-group">
              <Field
                component={SelectInputAsync}
                url="jobtypes/types"
                name="type"
                validate={required}
                required
                onChangeValue={(value: {
                  id: number;
                  label: string;
                  link: string;
                  type: string;
                  value: string;
                }) => {
                  change("job_type_id", value.id);
                  change("job_type_type", value.type);
                }}
                label="Job Type"
              />
            </div>
          </>
        );
      }}
    </FormModal>
  );
};

const selector = formValueSelector("JOB_OVERVIEW_DASHBOARD_FILTERS");

const mapStateToProps = (state: any) => {
  return {
    branches: selector(state, "branches"),
    to: selector(state, "to"),
    from: selector(state, "from"),
  };
};

export default connect(mapStateToProps)(AddJobModal);
