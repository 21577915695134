import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import Form from "./Form";

const Edit = (props) => {
  const { initialValues, setCalibrationType } = props;
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `equipment-calibration-types/${uuid}`, values)
      .then(({ data }) => {
        setCalibrationType(data.data);
        toast.success("Updated successfully");
      })
      .catch(formError);
  };

  return (
    <Form
      onSubmit={onSubmit}
      form="EquipmentCalibrationType"
      {...props}
      initialValues={{
        ...initialValues,
        unit_type: initialValues.unit_frequency_units ? 1 : 0,
        is_tailored_calibration_job_deadlines: initialValues.job_creation_units || initialValues.job_creation_length ? 1 : 0,
      }}
    />
  );
};

export default Edit;
