import { ITemplate } from "../templates/templateTypes";
import { CustomField } from "./../customFields/customFieldTypes";

export interface CustomJob {
  id: number;
  name: string;
  uuid?: string;
  custom_fields: CustomField[];
  organisation: {
    id: number;
    name: string;
  };
  template?: ITemplate;
}

export interface CustomJobTemplate {
  uuid?: string;
  id?: number;
  custom_job_id: number;
  manager: string;
  job_type_type: string;
  job_type_id: number;
  name: string;
  description: string;
  estimated_hours: number;
  estimated_value: number;
  length: number;
  length_units: number;
}

export interface CustomJobState {
  customJob: CustomJob;
  setCustomJob: (job: CustomJob) => void;
  canEditCustomJob: boolean;
}

export interface CustomJobAggregate {
  name: string;
  code: string;
  uuid?: string;
  id?: string;
  in_progress: boolean;
  output_type?: AggregateOutputType;
}

export enum AutoCreateCustomJobType {
  singleJob = "singleJob",
  recurringJob = "recurringJob",
}

export enum AggregateOutputType {
  CSV,
  TXT,
}
