import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import useTestWorksheets from "./hooks/useTestWorksheets";
import { TestWorksheet } from "./testWorksheetTypes";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";

const TestWorksheetList = () => {
  return (
    <>
      <HeaderPage titlePage="Test Worksheets" crumbs={[]} />
      <PaginatedList
        originalFilters={[]}
        indexHook={useTestWorksheets}
        addLink="/test-worksheets/add"
        itemCard={({ item }: { item: TestWorksheet }) => (
          <PaginatedCard
            key={item.uuid}
            info={info(item)}
            header={<TestWorksheetHeader item={item} />}
          />
        )}
      />
    </>
  );
};

const TestWorksheetHeader = ({ item }: { item: TestWorksheet }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={item.document.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`test-worksheets/${item.uuid}`}>
              {item.document.name}
            </Link>
          </p>
          <p className="mb-0">{item.test_methods.length} Test Methods</p>
        </div>
      </div>
    </div>
  );
};

const info = (item: TestWorksheet): PaginatedInformation[] => {
  return item.test_methods.map((method) => {
    return {
      name: method.method,
      value: "",
    };
  });
};

export default TestWorksheetList;
