import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPageAdminFilter from "../header/HeaderPageAdminFilter";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { DocketType } from "./docketTypeTypes";
import useDocketTypes from "./hooks/useDocketTypes";
import { Template, ProjectJob } from "../../enums/Model";

const DocketTypeList = () => {
  return (
    <>
      <HeaderPageAdminFilter
        titlePage="Docket Types"
        pageDescription={
          <>
            A docket is a document used to track and manage completed work at a
            site, ensuring quality and serving as a reference for future work,
            and can be attached to deliverables and jobs, and can contain
            deliverables across multiple jobs within the same project.
          </>
        }
        relatedLinks={[
          { name: "Templates", link: "/templates", model: Template },
          { name: "Jobs", link: "/jobs", model: ProjectJob },
        ]}
        crumbs={[{ name: "Docket Types", link: "/docket-types", active: true }]}
      />
      <PaginatedList
        originalFilters={[]}
        indexHook={useDocketTypes}
        addLink="/docket-types/add"
        itemCard={({ item }: { item: DocketType }) => {
          return (
            <PaginatedCard
              key={item.id}
              info={[]}
              header={<Header type={item} />}
            />
          );
        }}
      />
    </>
  );
};

const Header = ({ type }: { type: DocketType }) => {
  const tooltipId = useMemo(() => `type${type.uuid}`, [type.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={type.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/docket-types/${type.uuid}/details`}>{type.name}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {type.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default DocketTypeList;
