import React, { useState } from "react";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";

import HeaderPage from "../header/HeaderPage";
import useS3Uploader from "../hooks/useS3Uploader";
import errorSwal from "../utils/errorSwal";
import FormCPIR from "./Form";
import StopWorkModal from "./StopWorkModal";
import validate from "./validate";

const AddCPIR = (props) => {
  const { history, handleSubmit } = props;
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const { user } = useAuth();
  const { takeAction } = useApi();

  const { upload } = useS3Uploader();

  const onSubmit = (values) => {
    return upload(
      values.document,
      `organisations/${user.active_organisation.uuid}/cpirs`,
    )
      .then((document) => {
        if (document) {
          values.document = document;
        }

        return takeAction("store", "cpirs", values);
      })
      .then(({ data }) => {
        toast.success("CPIR added successfully");
        history.push(`/cpirs/${data.data.uuid}/details`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <HeaderPage
        titlePage="New Corrective, Preventative or Improvement Recommendation (CPIR)"
        crumbs={[
          {
            link: "cpirs",
            name: "CPIRs",
          },
          {
            link: "",
            name: "New CPIR",
            active: true,
          },
        ]}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-layout">
          <FormCPIR {...props} />
        </div>
      </form>
      <StopWorkModal toggle={toggle} modal={modal} />
    </>
  );
};

const form = reduxForm({ form: "CPIR", validate });
export default form(AddCPIR);
