import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import useUploadCustomFields from "../hooks/useUploadCustomFields";
import errorSwal from "../utils/errorSwal";

import ProjectForm from "./ProjectForm";
import { ProjectStatus } from "./projectTypes";
import RequestProjectFeedbackModal from "./RequestProjectFeedbackModal";
import DocsModal from "../utils/DocsModal";

const ProjectDetail = (props) => {
  const { handleSubmit, project, setProject } = props;
  const [feedback, setFeedback] = useState([]);
  const { user } = useAuth();
  const { toggle, modal } = useModal();

  const { number } = useParams();

  const { takeAction } = useApi();

  const { takeAction: generatePrompt, loading: generatingPrompt } = useApi();

  const onPrompt = (fieldUuid, prompt, name, change) => {
    return generatePrompt("store", `custom-fields/${fieldUuid}/prompts`, {
      prompts: [
        {
          role: "user",
          content: prompt,
        },
      ],
      modelType: "App\\Models\\Project",
      modelId: props.project?.id,
    })
      .then(({ data }) => {
        toast.success("Prompt added");
        change(name, data.data);
      })
      .catch(errorSwal);
  };

  const { upload } = useUploadCustomFields(
    `${user.active_organisation.uuid}/projects/${number}/required-information`,
  );

  const onSubmit = (values) => {
    return upload(values)
      .then((data) => takeAction("update", `projects/${project.uuid}`, data))
      .then(({ data }) => {
        toast.success(`${data.data.name} updated successfully`);
        if (
          !data.data.internal &&
          project.status == ProjectStatus.OPEN &&
          values.status == ProjectStatus.CLOSED
        ) {
          toggle();
        }

        setProject({
          ...data.data,
          contacts: project.contacts,
        });
      })
      .catch(errorSwal);
  };

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/project-profile/" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProjectForm
          {...props}
          onPrompt={onPrompt}
          generatingPrompt={generatingPrompt}
        />
      </form>
      <RequestProjectFeedbackModal
        setFeedback={setFeedback}
        feedback={feedback}
        project={project}
        toggle={toggle}
        modal={modal}
      />
    </>
  );
};

const form = reduxForm({ form: "Project" });
export default form(ProjectDetail);
