import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { FormSubmitHandler, reset } from "redux-form";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import HeaderPage from "../header/HeaderPage";
import { ClientType } from "./clientTypeTypes";
import ClientTypeModal from "./ClientTypeModal";
import useClientTypes from "./hooks/useClientTypes";
import PaginatedList from "../pagination/PaginatedList";
import { useQueryClient } from "react-query";
import { IUseApi } from "../api/apiTypes";
import { Client } from "../../enums/Model";

const ClientTypesList = () => {
  const { takeAction }: IUseApi<any, { data: ClientType }> = useApi();

  const queryClient = useQueryClient();

  const [selectedClientType, setSelectedClientType] = useState<
    ClientType | undefined
  >(undefined);

  const { toggle, modal } = useModal();

  const { toggle: toggleEdit, modal: editModal } = useModal();

  const addClientType: FormSubmitHandler<ClientType> = (
    values,
    dispatch: any,
  ) => {
    return takeAction("store", "client-types", values)
      .then(({ data }) => {
        dispatch(reset("AddClientType"));
        toast.success(`${data.data.name} created`);
        queryClient.invalidateQueries(["client-types"]);
        toggle();
      })
      .catch(errorSwal);
  };

  const editClientType: FormSubmitHandler<ClientType> = (values) => {
    return takeAction("update", `client-types/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${data.data.name} updated`);
        queryClient.invalidateQueries("client-types");
        toggleEdit();
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => {
        return (
          <Button
            color="link"
            onClick={() => {
              setSelectedClientType(info.row.original);
              toggleEdit();
            }}
          >
            {info.getValue()}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <HeaderPage
        titlePage="Client Business Activities"
        pageDescription={
          <>
            Define the area of business in which the client normally operates as
            a way to categorise your clients.
          </>
        }
        relatedLinks={[{ name: "Clients", link: "/clients", model: Client }]}
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "",
            name: "Client Business Activities",
            active: true,
          },
        ]}
      />
      <Button outline color="primary" className="mb-3" onClick={toggle}>
        Add Client Business Activity
      </Button>
      <PaginatedList
        indexHook={useClientTypes}
        indexHookArguments={[]}
        originalFilters={[]}
        list={(data: any) => (
          <div className="col-12">
            {data.data?.length > 0 && (
              <ReactTable disableSearch data={data.data} columns={columns} />
            )}
          </div>
        )}
      />
      <ClientTypeModal
        onSubmit={addClientType}
        title="Add Client Business Activity"
        modal={modal}
        toggle={toggle}
        form="AddClientType"
      />
      <ClientTypeModal
        onSubmit={editClientType}
        title={`Edit ${selectedClientType?.name}`}
        modal={editModal}
        toggle={toggleEdit}
        form="EditClientType"
        initialValues={selectedClientType}
      />
    </>
  );
};

export default ClientTypesList;
