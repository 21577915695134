import { Link } from "react-router-dom";
import JobStatus from "../../enums/JobStatus";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import { statusDetails } from "../projectManagers/ProjectManagerJob";
import Avatar from "../utils/Avatar";
import useSupportRequests from "./hooks/useSupportRequests";
import { SupportRequest } from "./supportRequestTypes";

const SupportRequests = () => {
  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Support Requests" />
      <PaginatedList
        originalFilters={statusFilters}
        itemCard={({ item }: { item: SupportRequest }) => {
          return (
            <PaginatedCard
              header={<SupportRequestHeader request={item} />}
              info={info(item)}
            />
          );
        }}
        indexHook={useSupportRequests}
      />
    </>
  );
};

const statusFilters = [
  {
    name: "status",
    label: "Status",
    multiple: true,
    options: [
      {
        label: "Pending",
        value: JobStatus.Pending,
      },
      {
        label: "In Progress",
        value: JobStatus.InProgress,
      },
      {
        label: "On Hold",
        value: JobStatus.OnHold,
      },
      {
        label: "Cancelled",
        value: JobStatus.Cancelled,
      },
      {
        label: "Completed",
        value: JobStatus.Completed,
      },
    ],
  },
];

const SupportRequestHeader = ({ request }: { request: SupportRequest }) => {
  const statusInfo = statusDetails(request);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={request.name} colour={statusInfo.color} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/support-requests/${request.uuid}`}>{request.name}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const info = (item: SupportRequest): PaginatedInformation[] => {
  return [
    {
      name: "Status",
      value: item.string_status,
    },
    {
      name: "Type",
      value: item.project.name,
    },
    {
      name: "Comment Count",
      value: item.comments.length.toString(),
    },
  ];
};

export default SupportRequests;
