import { Field, FieldArray, InjectedFormProps, reduxForm } from "redux-form";
import SelectInput from "../form/SelectInput";
import { IDocument } from "../documents/documentTypes";
import { TestMethod } from "../testRequests/testRequestTypes";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import EquipmentTypeWrapper from "./EquipmentTypeWrapper";
import RegisterWrapper from "./RegisterWrapper";
import useApi from "../api/useApi";
import { IUseApiWithData } from "../api/apiTypes";

export interface TestWorksheetFormValues {
  document_id: number;
  id?: number;
  test_method_ids: {
    label: JSX.Element;
    value: number;
    text: string;
  }[];
  equipment_types: {
    equipment_type_id: number;
    custom_field_ids: {
      label: string;
      value: number;
    }[];
  }[];
  registers: {
    register_id: number;
  }[];
}

const TestWorksheetForm = (
  props: InjectedFormProps<TestWorksheetFormValues>,
) => {
  const { handleSubmit, initialValues } = props;

  const { setUrl, data: worksheets }: IUseApiWithData<string[]> = useApi(
    initialValues?.id ? `workbook-sheets?id=${initialValues.document_id}` : "",
    [],
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert {...props} />
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            url="documents?filter[extensions]=xlsx,xlsm&filter[is_current]=true"
            name="document_id"
            label="Document"
            required
            changeValue={({ value }: { value: number }) => {
              if (value) {
                setUrl(`workbook-sheets?id=${value}`);
              }
            }}
            filterOption={(option: any, input: any) => {
              return (
                !input ||
                option.data.text.toLowerCase().includes(input.toLowerCase())
              );
            }}
            formatData={(data: IDocument[]) =>
              data.map((d) => ({
                label: (
                  <>
                    <p className="text-dark mb-0">
                      <i className="fa fa-file-excel-o tx-success me-2" />
                      {d.name}
                    </p>
                  </>
                ),
                value: d.id,
                text: d.name,
              }))
            }
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={SelectInput}
            name="test_method_ids"
            label="Tests"
            required
            url="test-methods"
            isMulti
            filterOption={(option: any, input: any) => {
              return (
                !input ||
                option.data.text.toLowerCase().includes(input.toLowerCase())
              );
            }}
            formatData={(data: TestMethod[]) => {
              return data.map((d) => {
                return {
                  label: (
                    <div>
                      <p className="text-dark mb-0">{d.name}</p>
                      <p className="mb-0 text-muted">{d.method}</p>
                    </div>
                  ),
                  value: d.id,
                  text: `${d.name} ${d.method}`,
                };
              });
            }}
          />
        </div>
        <div className="col-12 form-group">
          <Field
            component={RenderField}
            name="number_of_samples"
            required
            validate={required}
            label="Number of Samples Per Sheet"
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            name="logo_sheet"
            label="Logo Sheet"
            validate={required}
            options={worksheets.map((d) => ({
              label: d,
              value: d,
            }))}
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="logo_cell"
            label="Logo Cell"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="logo_settings.height"
            label="Logo Heigh (px)"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="logo_settings.width"
            label="Logo Width (px)"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={SelectInput}
            options={worksheets.map((d) => ({
              label: d,
              value: d,
            }))}
            name="signature_sheet"
            label="Signature Sheet"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="signature_cell"
            label="Signature Cell"
            validate={required}
            required
          />
        </div>

        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="signature_settings.height"
            label="Signature Height (px)"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={RenderField}
            name="signature_settings.width"
            label="Signature Width (px)"
          />
        </div>

        <FieldArray<{
          form: string;
          worksheets: string[];
        }>
          form={props.form}
          component={EquipmentTypeWrapper}
          name="equipment_types"
          worksheets={worksheets}
        />
        <FieldArray<{ form: string }>
          component={RegisterWrapper}
          name="registers"
        />
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<TestWorksheetFormValues>({});

export default form(TestWorksheetForm);
