import React from "react";
import { Link } from "react-router-dom";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedList from "../pagination/PaginatedList";
import useInvoices from "./hooks/useInvoices";
import InvoiceTable from "./InvoiceTable";
import { Invoice } from "./invoiceTypes";
import { Client } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

type InvoiceFilters = IFilter<
  "notRequested" | "uninvoiced" | "invoiced",
  boolean | boolean | boolean
>;

const InvoicesList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/adding-an-invoice/" />
      <HeaderPage
        titlePage="Invoices"
        pageDescription={
          <>
            To add an invoice, navigate to the project you wish to add an
            invoice to and click on the 'Invoices' tab.
          </>
        }
        relatedLinks={[
          { name: "Clients", link: "/clients", model: Client },
          { name: "Invoice Reminders", link: "/invoice-reminders" },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "", name: "Invoices", active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <p className="ms-2 mb-1 tx-inverse">All prices exclude GST</p>
        </div>
      </div>
      <PaginatedList
        indexHook={useInvoices}
        originalFilters={invoiceFilters}
        list={({ data }: { data?: Invoice[] }) => (
          <div className="col-12">
            <InvoiceTable data={data} />
          </div>
        )}
      />
    </>
  );
};

export const invoiceFilters: InvoiceFilters[] = [
  {
    label: "Filters",
    name: "notRequested",
    options: [
      {
        label: "Invoice Not Requested",
        value: true,
      },
    ],
  },
  {
    label: "",
    name: "uninvoiced",
    options: [
      {
        label: "Uninvoiced",
        value: true,
      },
    ],
  },
  {
    label: "",
    name: "invoiced",
    options: [
      {
        label: "Invoiced",
        value: true,
      },
    ],
  },
];

export default InvoicesList;
