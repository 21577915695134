import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState } from "react";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const CompleteButton = ({ calibration, setCalibration }) => {
  const { takeAction, loading } = useApi();

  const [startedAt] = useState(dayjs().format("HH:mm"));

  const markComplete = () => {
    takeAction("update", `equipment-calibration-status/${calibration.uuid}`, {
      completed_at: calibration.completed_at
        ? null
        : dayjs().format("YYYY-MM-DD HH:mm:ss"),
      tracking_time: {
        scheduled_start_time: startedAt,
        scheduled_finish_time: dayjs().format("HH:mm"),
      },
    })
      .then(({ data }) => {
        toast.success(`${calibration.name} updated!`);
        setCalibration(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          onClick={markComplete}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            loading,
            <>
              {!calibration.completed_at && (
                <i className="icon ion-checkmark tx-success" />
              )}
              {calibration.completed_at ? "Unm" : "M"}ark Calibration Complete
            </>,
            "Marking...",
          )}
        </button>
      </li>
    </ul>
  );
};

export default CompleteButton;
