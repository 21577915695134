import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import useBillableItemUnits from "./hooks/useBillableItemUnits";
import { Pricing, BillableItem } from "../../enums/Model";

const BillableItemUnitsList = () => {
  const { user } = useAuth();

  return (
    <>
      <HeaderPage
        titlePage="Billable Item Units"
        pageDescription={
          <>
            The Billable Items and Billable Item Units feature enables tracking
            and billing for project items and services, with units of
            measurement specified for each item, and can be linked to
            deliverables and prices for accurate client billing, and optionally
            marked as requiring insurance.
          </>
        }
        relatedLinks={[
          {
            name: "Standard Price List",
            link: "/standard-pricing",
            model: Pricing,
          },
          {
            name: "Billable Items",
            link: "/billable-items",
            model: BillableItem,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          {
            link: "billable-item-units",
            name: "Billable Item Units",
            active: true,
          },
        ]}
      />

      <PaginatedList
        indexHook={useBillableItemUnits}
        originalFilters={[]}
        addLink={
          user.hasAccessTo("App\\Models\\BillableItemUnit", "create")
            ? "/billable-item-units/add"
            : ""
        }
        itemCard={({ item }) => (
          <PaginatedCard
            key={item.uuid}
            info={[]}
            header={<BillableItemUnitHeader unit={item} />}
          />
        )}
      />
    </>
  );
};

const BillableItemUnitHeader = ({ unit }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={unit.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/billable-item-units/${unit.uuid}`}>{unit.name}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillableItemUnitsList;
