import React from "react";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import ArchiveModal from "../purchaseOrders/ArchiveModal";
import swal from "sweetalert";

const ArchiveButton = (props) => {
  const { recurringPurchase, setRecurringPurchase } = props;

  const { toggle, modal, setModal } = useModal();

  const { takeAction, loading } = useApi();

  const archive = () => {
    if (!recurringPurchase.archived_at) {
      toggle();
      return;
    }

    swal({
      title: `Unarchive ${recurringPurchase.name}`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          closeModal: true,
          visible: true,
        },
        delete: {
          text: "Unarchive",
          value: true,
          closeModal: true,
          className: "btn btn-danger align-baseline",
        },
      },
    })
      .then((remove) => {
        if (remove) {
          return takeAction(
            "destroy",
            `archived-recurring-payments/${recurringPurchase.uuid}`,
          );
        }
      })
      .then((response) => {
        if (response) {
          toast.success("Contract agreement unarchived");
          return setRecurringPurchase(response.data.data);
        }
      })
      .catch(errorSwal);
  };

  const onSubmit = (values) => {
    return takeAction(
      "store",
      `archived-recurring-payments/${recurringPurchase.uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success("Contract agreement archived");
        setModal(false);
        setRecurringPurchase(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <Button color="danger" outline block onClick={archive} disabled={loading}>
        {isSubmitting(
          loading,
          recurringPurchase.archived_at ? "Unarchive" : "Archive",
          recurringPurchase.archived_at ? "Unarchiving..." : "Archiving...",
        )}
      </Button>
      <ArchiveModal
        form="ArchiveRecurringPurchase"
        title={`Archive ${recurringPurchase.name}`}
        toggle={toggle}
        modal={modal}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ArchiveButton;
