import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import StatusBadge from "./StatusBadge";

const formatPurchaseDate = (purchase) => {
  return purchase.date
    ? `${dayjs(purchase.date).format("DD/MM/YYYY")}`
    : "None given.";
};

const ResourcePurchaseList = (props) => {
  const { purchases } = props;

  if (purchases.length < 1) {
    return null;
  }

  const columns = [
    {
      accessorKey: "name",
      header: "Purchase Number",
      cell: (info) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: (info) => {
        const description = info.getValue();
        if (!description) {
          return "-";
        }
        return description;
      },
    },
    {
      accessorKey: "supplier",
      header: "Supplier",
      cell: (info) => {
        const supplier = info.getValue();
        if (!supplier.name) {
          return "-";
        }

        if (!supplier.uuid) {
          return supplier.name;
        }

        return (
          <Link to={`/suppliers/${supplier.uuid}/details`}>
            {supplier.name}
          </Link>
        );
      },
    },
    {
      accessorKey: "date",
      header: "Purchase Date",
      cell: (info) => formatPurchaseDate(info.row.original),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => <StatusBadge purchase={info.row.original} />,
    },
    {
      accessorKey: "realised_total_cost",
      header: "Purchase Value",
      cell: (info) => (
        <SpreadPrice price={info.row.original.realised_total_cost} />
      ),
    },
    {
      accessorKey: "invoice",
      header: "Invoice",
      cell: (info) => info.getValue()?.number ?? "-",
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <p className="tx-inverse mb-0">All prices exclude GST.</p>
      </div>
      <ReactTable disableSearch columns={columns} data={purchases} />
    </>
  );
};

export default ResourcePurchaseList;
