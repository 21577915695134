import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import Empty from "../utils/Empty";
import AddQuestionModal from "./AddQuestionModal";
import HazardReportQuestion from "./HazardReportQuestion";
import { IHazardReportQuestion } from "./types";
import { useAuth } from "../../context/auth-context";

import NotFound from "../404";

const HazardReportQuestions = () => {
  const { toggle, modal } = useModal();
  const {
    data: questions,
    setData: setQuestions,
  }: IUseApi<IHazardReportQuestion[]> = useApi(`hazard-report-questions`, []);

  const { user } = useAuth();

  const canView = user?.hasAccessTo(
    "App\\Models\\HazardReportQuestion",
    "view",
  );

  const canAdd = user?.hasAccessTo(
    "App\\Models\\HazardReportQuestion",
    "create",
  );

  if (!canView) {
    return <NotFound />;
  }

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Take 5 Questions" />

      {canAdd && (
        <>
          <Button color="primary" outline size="sm" onClick={toggle}>
            Add New
          </Button>
          <AddQuestionModal
            questions={questions}
            setQuestions={setQuestions}
            toggle={toggle}
            modal={modal}
          />
        </>
      )}

      {questions && questions.length > 0 ? (
        <div className="bg-white border mt-3">
          {questions?.map((question) => {
            return (
              <HazardReportQuestion
                question={question}
                questions={questions}
                setQuestions={setQuestions}
              />
            );
          })}
        </div>
      ) : (
        <div className="mt-5">
          <Empty width="350" top={false}>
            <div className="text-center">
              <p className="mt-4 mb-0 tx-inverse tx-semibold">
                No Take 5 Questions Found
              </p>
              <p className="mt-1">
                Take 5 questions give organisation the ability to have your
                staff undertake take 5s.
              </p>
            </div>
          </Empty>
        </div>
      )}
    </>
  );
};

export default HazardReportQuestions;
