import axios from "../api/api";
import {
  FETCH_NUCLEAR_DENSOMETERS,
  UPDATE_NUCLEAR_DENSOMETER,
} from "../actions/types";

export const fetchNuclearDensometers =
  (id = "") =>
  (dispatch) => {
    return axios
      .get(`/nuclear-densometers/${id}`)
      .then((response) => response.data.data)
      .then((nuclearDensometers) =>
        dispatch({
          type: FETCH_NUCLEAR_DENSOMETERS,
          payload: nuclearDensometers,
        }),
      );
  };

export const updateNuclearDensometer = (postData) => (dispatch) => {
  return axios
    .put(`/nuclear-densometers/${postData.uuid}`, postData)
    .then(({ data }) =>
      dispatch({
        type: UPDATE_NUCLEAR_DENSOMETER,
        payload: data.data,
      }),
    );
};
