import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import employment_basis from "../../data/employment_basis";
import { EmploymentAward } from "../employmentAwards/types";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import PenaltyRateAllowances from "./PenaltyRateAllowances";
import PenaltyRateHours from "./PenaltyRateHours";
import { PenaltyRate } from "./penaltyRateTypes";

type SetPenaltyRateType = (rate: PenaltyRate) => void;

const PenaltyRateForm = (
  props: InjectedFormProps<
    PenaltyRate,
    { setPenaltyRate?: SetPenaltyRateType }
  > & { setPenaltyRate?: SetPenaltyRateType },
) => {
  const { handleSubmit, initialValues, setPenaltyRate, reset } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row position-relative">
        <div className="col-md-6 form-group">
          <Field
            component={RenderField}
            name="name"
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={SelectInput}
            options={employment_basis}
            name="employment_basis"
            label="Employment Basis"
            validate={required}
            required
          />
        </div>
        <div className="col-md-6 form-group">
          <Field
            component={SelectInput}
            options={[
              {
                label: "Public Holiday",
                value: 0,
              },
              {
                label: "Saturday",
                value: 1,
              },
              {
                label: "Sunday",
                value: 2,
              },
              {
                label: "Night Shift",
                value: 3,
              },
              {
                label: "Ordinary Hours",
                value: 4,
              },
            ]}
            name="timesheet_type"
            label="Timesheet Type"
            validate={required}
            required
          />
        </div>

        <div className="col-md-6 form-group">
          <Field
            component={SelectInput}
            name="award_id"
            url="/employment-awards"
            label="Award"
            required
            validate={required}
            formatData={(data: EmploymentAward[]) =>
              data.map(({ abbreviation, id }) => ({
                label: abbreviation,
                value: id,
              }))
            }
          />
        </div>

        <FieldArray<any>
          component={PenaltyRateHours}
          name="hours"
          initialValues={initialValues}
          setPenaltyRate={setPenaltyRate}
          reset={reset}
        />
        <FieldArray<any>
          component={PenaltyRateAllowances}
          name="allowances"
          initialValues={initialValues}
          setPenaltyRate={setPenaltyRate}
          reset={reset}
        />
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </Form>
  );
};

const form = reduxForm<PenaltyRate, { setPenaltyRate?: SetPenaltyRateType }>({
  enableReinitialize: true,
});

export default form(PenaltyRateForm);
