import { useAuth } from "../../context/auth-context";
import { IXeroIntegration } from "./xeroTypes";

const XeroIntegration = ({
  isIntegrated,
}: {
  isIntegrated?: IXeroIntegration;
}) => {
  const { user } = useAuth();
  const state = user?.uuid;

  const xeroRedirect = `${window.location.origin}/xero-integration/successful`;
  const scopes =
    "offline_access accounting.settings accounting.transactions accounting.contacts accounting.reports.read";

  if (isIntegrated) {
    return (
      <div className="col-lg-4">
        {/* <Link to={`/integrations/xero/${isIntegrated.uuid}/contacts`}> */}
        <div className="bg-white border rounded-lg p-4 d-flex justify-content-center align-items-center">
          <img
            style={{
              width: "70px",
            }}
            src="/img/xero.png"
            alt="Microsoft"
          />
          <div>Synced</div>
        </div>
        {/* </Link> */}
      </div>
    );
  }

  return (
    <div className="col-lg-4">
      <a
        href={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=13C1F587DCE54ECF86F7AF9EB1C711B1&redirect_uri=${xeroRedirect}&scope=${scopes}&state=${state}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="bg-white border rounded-lg p-4 d-flex justify-content-center align-items-center">
          <img
            style={{
              width: "70px",
            }}
            src="/img/xero.png"
            alt="Microsoft"
          />
        </div>
      </a>
    </div>
  );
};

export default XeroIntegration;
