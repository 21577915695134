import React from "react";
import FormModal from "../utils/FormModal";
import { Timesheet } from "./timesheetTypes";
import { Button } from "reactstrap";
import useModal from "../hooks/useModal";
import AddressFields from "../addresses/AddressFields";
import { Field, FormSubmitHandler, InjectedFormProps } from "redux-form";
import renderToggleInput from "../utils/renderToggleInput";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import { useParams } from "react-router-dom";

const form = "UPDATE_DISTANCE_ALLOWANCE";

const UpdateDistanceAllowanceModal = ({
  timesheet,
  setTimesheet,
}: {
  timesheet: Timesheet;
  setTimesheet: (value: Timesheet) => void;
}) => {
  const { toggle, modal } = useModal();
  const { uuid } = useParams<{ uuid: string }>();
  const { takeAction }: IUseApi<{}, { data: Timesheet }> = useApi();

  const onSubmit: FormSubmitHandler<any> = (values) => {
    return takeAction(
      "store",
      `timesheets/${uuid}/updated-distance-allowances`,
      values,
    )
      .then(({ data }) => {
        setTimesheet(data.data);
        toggle();
        toast.success("Distance Allowances Updated");
      })
      .catch(formError);
  };

  //TODO Check if timesheet is paid

  return (
    <>
      <Button onClick={toggle} outline size="sm" className="mt-3">
        Update Distance Allowances
      </Button>
      <FormModal
        initialValues={timesheet.user_address}
        form={form}
        toggle={toggle}
        modal={modal}
        title="Update Distance Allowances"
        onSubmit={onSubmit}
      >
        {({ change }: InjectedFormProps) => {
          return (
            <>
              <AddressFields form={form} change={change} />
              <div className="col-lg-12 form-group">
                <Field
                  component={renderToggleInput}
                  name="update_previous_timesheets"
                  label="Update previous timesheets?"
                />
              </div>
            </>
          );
        }}
      </FormModal>
    </>
  );
};

export default UpdateDistanceAllowanceModal;
