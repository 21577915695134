import FormModal from "../utils/FormModal";
import FormDeliverable from "./form";

const DeliverablesModal = (props) => {
  const { modal, toggle, jobId } = props;

  return (
    <FormModal
      title="Add New Deliverable"
      form="DeliverableDetails"
      onSubmit={props.onSubmit}
      toggle={toggle}
      modal={modal}
      initialValues={props.initialValues}
    >
      {({ change }) => (
        <FormDeliverable file name jobId={jobId} change={change} {...props} />
      )}
    </FormModal>
  );
};

export default DeliverablesModal;
