import { useParams } from "react-router-dom";
import Edit from "./Edit";
import NotFound from "../404";
import ProfileForm from "../customFields/ProfileForm";

const ShowPage = (props) => {
  const { page } = useParams();

  const { calibrationType, setCalibrationType } = props;

  switch (page) {
    case "details":
      return <Edit {...props} initialValues={props.calibrationType} />;
    case "fields":
      return (
        <ProfileForm
          initialValues={calibrationType}
          {...props}
          modelId={calibrationType.id}
          modelType="App\Models\EquipmentCalibrationType"
          onSuccess={(data) =>
            setCalibrationType({
              ...calibrationType,
              custom_fields: data.data,
            })
          }
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
