import React, { useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import axios from "../api/api";

const MarkAsSubmittedButton = ({ tender, setTender }) => {
  const [submitting, setSubmitting] = useState(false);

  const updateSubmitted = () => {
    setSubmitting(true);
    return axios
      .put(`/submitted-tenders/${tender.uuid}`, {
        submitted_on: tender.submitted_on ? null : dayjs().format("YYYY-MM-DD"),
      })
      .then(({ data }) => {
        toast.success(
          `Tender ${data.data.submitted_on ? "" : "un"}marked as submitted`,
        );
        setTender(data.data);
      })
      .catch(errorSwal)
      .then(() => setSubmitting(false));
  };

  if (tender.successful !== null) return null;

  return (
    <ul className="nav nav-activity-profile mg-y-20">
      <li className="nav-item">
        <button
          type="button"
          onClick={updateSubmitted}
          className="pointer-hover w-100 nav-link"
        >
          {isSubmitting(
            submitting,
            <>
              {!tender.submitted_on && (
                <i className="icon ion-checkmark tx-success" />
              )}
              {tender.submitted_on ? "Unm" : "M"}ark Tender as Submitted
            </>,
          )}
        </button>
      </li>
    </ul>
  );
};

export default MarkAsSubmittedButton;
