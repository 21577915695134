import _ from "lodash";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Empty from "../utils/Empty";
import Spinner from "../utils/Spinner";
import { Topic } from "./topicType";
import { Document } from "../../enums/Model";

const TopicsList = () => {
  const { data, loading }: IUseApi<Topic[]> = useApi("topics", [], true);
  const { user } = useAuth();

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage="Knowledge Base"
        pageDescription={
          <>
            Topics in a knowledgebase organize and provide easy access to
            relevant information, guidance, and solutions for users.
          </>
        }
        relatedLinks={[
          {
            name: "Controlled Documents",
            link: "/documents",
            model: Document,
          },
        ]}
        crumbs={[]}
      />
      {user?.hasAccessTo("App\\Models\\Topic", "create") && (
        <p className="mb-2">
          <Link to="/topics/add">Add New Topic</Link>
        </p>
      )}
      {data?.length === 0 ? (
        <div className="mt-5">
          <Empty top={false}>
            <div className="text-center mt-4">
              <p className="fw-bolder text-dark mb-2">No Topics added.</p>
              <p className="mb-1">
                Topics allow you to organise your knowledge base into discrete
                sections.
              </p>
              {user?.hasAccessTo("App\\Models\\Topic", "create") ? (
                <p className="mb-0">
                  To get started <Link to="/topics/add">click here</Link> or
                  press 'Add New Topic' Above.
                </p>
              ) : (
                <p className="mb-0">
                  Ask an administrator to add topics to get started!
                </p>
              )}
            </div>
          </Empty>
        </div>
      ) : (
        <div className="space-y-5">
          {data?.map((topic) => {
            const flattenedItems = _.flatten(
              topic?.subjects.map(({ items }) => items),
            );

            const readingCount = flattenedItems.filter(
              ({ requires_reading }) => requires_reading,
            ).length;

            return (
              <div className="bg-white p-3 border d-flex">
                <p className="text-dark mb-0">
                  <Link to={`/topics/${topic.uuid}/subjects`}>
                    {topic.name}
                  </Link>
                </p>
                {readingCount && readingCount > 0 ? (
                  <Badge
                    color="info"
                    className="rounded-pill shadow-lg ms-auto"
                  >
                    {readingCount} item
                    {readingCount === 1 ? null : "s"} require
                    {readingCount ? "s" : null} review
                  </Badge>
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TopicsList;
