import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/api";
import errorSwal from "../utils/errorSwal";
import CardModal from "./CardModal";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const { paymentMethod, setPaymentMethod } = props;
  const [modal, setModal] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const toggle = () => setModal(!modal);

  const submit = () => {
    setSubmitting(true);
    stripe
      .confirmCardSetup(props.intent.client_secret, {
        payment_method: {
          card: elements.getElement("card"),
        },
      })
      .then(({ setupIntent }) => {
        console.log(setupIntent);
        return axios.post(`/payment-methods`, {
          paymentMethod: setupIntent.payment_method,
        });
      })
      .then(({ data }) => {
        toast.success("Card Updated Successfully");
        setPaymentMethod(data.data);
        toggle();
      })
      .catch(errorSwal)
      .then(() => setSubmitting(false));
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h6 className="slim-card-title">Payment Information</h6>
          <p>Add or update your payment infromation.</p>
          <div className="row">
            <div className="form-group col-12">
              {paymentMethod ? (
                <>
                  <label className="form-control-label tx-inverse tx-semibold">
                    Current Payment Method
                  </label>
                  <input
                    disabled
                    value={`**** **** **** ${paymentMethod.last4}`}
                    className="form-control"
                  />
                </>
              ) : (
                <p>
                  No card information found, please add one before selecting a
                  plan.
                </p>
              )}
            </div>
            <div className="col-12 d-flex">
              <Button
                type="button"
                color="success"
                className="ms-auto text-white"
                onClick={toggle}
              >
                Update Card Information
              </Button>
            </div>
          </div>
        </div>
      </div>
      <CardModal
        submit={submit}
        submitting={submitting}
        modal={modal}
        toggle={toggle}
      />
    </>
  );
};

export default CheckoutForm;
