import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import useUserGroup from "../hooks/useUserGroup";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import PaginationCardHeader from "../pagination/PaginationCardHeader";
import errorSwal from "../utils/errorSwal";
import { BootstrapColour } from "../utils/utilTypes";
import useTenders from "./hooks/useTenders";
import { Tender } from "./tenderTypes";
import dayjs from "dayjs";
import { JobStatus } from "../jobs/jobTypes";

const TendersList = () => {
  const { user } = useAuth();

  const { takeAction, loading }: IUseApi = useApi();

  const downloadTenders = (searchQuery: string) => {
    return takeAction("show", `tenders/csv${searchQuery}`)
      .then(({ data }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  const userIsPartOfBusinessDevelopment = useUserGroup("Business Development");

  const canSeeDownloadButton =
    userIsPartOfBusinessDevelopment || user?.is_admin;

  return (
    <>
      <HeaderPage
        titlePage="Tenders"
        crumbs={[
          {
            link: "tenders",
            name: "Tenders",
            active: true,
          },
        ]}
      />
      <PaginatedList
        addLink="/tenders/add"
        indexHook={useTenders}
        originalFilters={tenderFilters}
        extraButtons={({ searchQuery }) => {
          if (!canSeeDownloadButton) {
            return null;
          }

          return (
            <Button
              onClick={() => downloadTenders(`?filter[search]=${searchQuery}`)}
              disabled={loading}
              color="link"
            >
              {loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <FiDownload className="tx-16" />
              )}
            </Button>
          );
        }}
        itemCard={({ item }: { item: Tender }) => {
          return (
            <PaginatedCard
              key={item.uuid}
              info={info(item)}
              header={
                <PaginationCardHeader
                  colour={getStatus(item).colour}
                  title={item.job.name}
                  link={item.link}
                  uuid={item.uuid}
                  subTitle={
                    <Link to={`/${item.project.link}`}>
                      {item.project.name}
                    </Link>
                  }
                />
              }
              bottomContent={<TenderFooter tender={item} />}
            />
          );
        }}
      />
    </>
  );
};

const TenderFooter = ({ tender }: { tender: Tender }) => {
  const status = getStatus(tender);

  return (
    <div className="mt-2">
      <Badge className="rounded-pill shadow" color={status.colour}>
        {status.status}
      </Badge>
    </div>
  );
};

export const getStatus = (
  tender: Tender,
): {
  colour: BootstrapColour;
  status:
    | "Draft"
    | "Submitted"
    | "Successful"
    | "Unsuccessful"
    | "Overdue"
    | "Cancelled";
} => {
  if (tender.job.status === JobStatus.Cancelled) {
    return {
      status: "Cancelled",
      colour: "secondary",
    };
  }

  if (tender.successful !== null) {
    return tender.successful
      ? {
          status: "Successful",
          colour: "success",
        }
      : {
          status: "Unsuccessful",
          colour: "danger",
        };
  }

  if (
    !tender.submitted_on &&
    dayjs(tender.job.scheduled_finish_date).isBefore(dayjs())
  ) {
    return {
      status: "Overdue",
      colour: "danger",
    };
  }

  return tender.submitted_on
    ? {
        status: "Submitted",
        colour: "info",
      }
    : {
        status: "Draft",
        colour: "warning",
      };
};

const info = (tender: Tender): PaginatedInformation[] => {
  return [
    {
      name: "Type",
      value: tender.type.name,
    },
    {
      name: "Client",
      value: tender.client_name ?? tender.client?.name,
    },
    {
      name: "Has Been Submitted",
      value: tender.submitted_on ? "Yes" : "No",
    },
  ];
};

export const tenderFilters: IFilter<"status", number | string>[] = [
  {
    label: "Status",
    name: "status",
    options: [
      {
        label: "Overdue",
        value: "overdue",
      },
      {
        label: "Draft",
        value: "draft",
      },
      {
        label: "Submitted",
        value: "submitted",
      },
      {
        label: "Successful",
        value: "successful",
      },
      {
        label: "Unsuccessful",
        value: "unsuccessful",
      },
      {
        label: "Cancelled",
        value: "cancelled",
      },
    ],
  },
];

export default TendersList;
