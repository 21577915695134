import React from "react";
import { useParams, Link } from "react-router-dom";
import useApi from "../api/useApi";
import Spinner from "../utils/Spinner";
import HeaderPage from "../header/HeaderPage";
import ProfilePage from "../profile";
import {
  DashboardHeader,
  DashboardH3,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import ShowPage from "./ShowPage";

const Profile = () => {
  const { uuid } = useParams();

  const {
    response,
    loading,
    data: calibrationType,
    setData: setCalibrationType,
  } = useApi(`equipment-calibration-types/${uuid}`);

  const tabs = [
    {
      link: `/equipment-calibration-types/${uuid}/details`,
      icon: "",
      page: "details",
      label: "Details",
    },
    {
      link: `/equipment-calibration-types/${uuid}/fields`,
      icon: "",
      page: "fields",
      label: "Fields",
    },
  ];

  if (!response || loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage titlePage={calibrationType.procedures} crumbs={[]} />
      <ProfilePage
        tabs={tabs}
        header={
          <DashboardHeader>
            <DashboardH3>{calibrationType.procedures}</DashboardH3>
            <DashboardSubTitle>
              <Link
                to={`/equipment-types/${calibrationType.equipment_type.uuid}/details`}
              >
                {calibrationType.equipment_type.type}
              </Link>
            </DashboardSubTitle>
          </DashboardHeader>
        }
        content={
          <ShowPage
            calibrationType={calibrationType}
            setCalibrationType={setCalibrationType}
          />
        }
      />
    </>
  );
};

export default Profile;
