import React from "react";
import { WrappedFieldProps } from "redux-form";

interface BasicSelectInputProps {
  label: React.ReactNode;
  options: { value: string; label: string }[];
  wrapperClass?: string;
}

const BasicSelectInput = (props: WrappedFieldProps & BasicSelectInputProps) => {
  const { input, options, wrapperClass = "" } = props;

  return (
    <div className={wrapperClass}>
      <label className="form-control-label tx-inverse tx-semibold">
        {props.label}
      </label>
      <select className="form-control" {...input}>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default BasicSelectInput;
