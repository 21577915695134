import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import {
  formatAccountCodes,
  formatBranches,
} from "../purchaseItems/PurchaseItems";
import FormHeader from "../utils/FormHeader";
import ItemFields from "./ItemFields";

const PurchaseItems = (props) => {
  const { fields, initialValues, title } = props;
  const { user } = useAuth();

  const [branches, setBranches] = useState([]);
  const [accountCodes, setAccountCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((!initialValues || !initialValues.uuid) && fields.length === 0) {
      fields.push({});
    }

    axios
      .get(`user-branches`)
      .then(({ data }) => setBranches(formatBranches(data.data)))
      .then(() => axios.get(`chart-of-accounts?purchaseOrders=1`))
      .then(({ data }) => setAccountCodes(formatAccountCodes(data.data)))
      .then(() => setLoading(false));
  }, []);

  const canEdit =
    !initialValues?.uuid || initialValues?.purchases?.length === 0;

  return (
    <>
      <FormHeader>{title || "Add Items Below"}</FormHeader>
      {fields.map((member, index) => {
        const newProps = {
          member,
          branches,
          fields,
          index,
          accountCodes,
          loading,
          key: index,
        };
        return <ItemFields canEdit={canEdit} {...props} {...newProps} />;
      })}

      {canEdit && (
        <div className="col-12 form-group d-flex">
          <button
            className="btn btn-link ms-auto"
            type="button"
            onClick={() => fields.push({ branch_id: user.default_branch_id })}
          >
            Add Item
          </button>
        </div>
      )}
    </>
  );
};

export default PurchaseItems;
