import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { Button } from "reactstrap";
import { getFormValues, WrappedFieldProps } from "redux-form";

import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import ChatModal from "./ChatModal";

const CustomPromptInput = (
  props: WrappedFieldProps & {
    label: string;
    formValues: any;
    prompt: any;
    onPrompt: (
      fieldUuid: string,
      prompt: string,
      name: string,
      change: Function,
    ) => Promise<any>;
    generatingPrompt: boolean;
    fieldUuid: string;
    change: Function;
    modelType: string;
    modelId: number;
  },
) => {
  const { input, onPrompt, fieldUuid } = props;
  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const { toggle, modal } = useModal();

  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    const newValues = Object.entries(
      _.orderBy(props.formValues?.custom_fields, "order") ?? [],
    )
      .filter(
        ([key, field]: any) =>
          `custom_fields.${key}.value` !== props.input.name,
      )
      .map(([key, field]: any) => {
        const variable = `$`.concat(
          `{${field?.label
            ?.trim()
            .replace(/{|}/g, "")
            .replace(/\s+/g, "-")
            .toLowerCase()}}`,
        );
        return { key: variable, value: field?.value };
      });

    setValues(newValues);
  }, [props.formValues?.custom_fields]);

  let toEval: string = props.prompt;

  values.forEach((field: any) => {
    toEval = toEval.replaceAll(`${field.key}`, field?.value ?? "-");
  });
  useEffect(() => {
    if (textAreaRef.current) {
      const height = `${textAreaRef.current.scrollHeight + 5}px`;
      const currentHeight = textAreaRef.current.style.height;

      const currentWithoutPx = parseInt(
        currentHeight.substring(0, currentHeight.length - 2)
          ? currentHeight.substring(0, currentHeight.length - 2)
          : "0",
      );

      if (textAreaRef.current.scrollHeight - currentWithoutPx > 0) {
        textAreaRef.current.style.height = height;
      }
    }
  }, [input.value]);

  if (!onPrompt) {
    return (
      <div>
        <p>Prompts are currently unavailable for these fields</p>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex">
        <label
          htmlFor={input.name}
          className="form-control-label tx-inverse tx-semibold"
        >
          {props.label}
        </label>
        <div className="d-flex ms-auto align-items-center">
          <Toggle
            className="ms-auto toggle-sm"
            checked={modal}
            onChange={toggle}
            icons={false}
          />
          <span className="tx-sm ms-1">Toggle Prompt</span>
        </div>
      </div>

      <div className="form-control d-block">
        <textarea {...input} ref={textAreaRef} className="border-0 w-100" />
        {modal && <small className="d-block text-secondary">{toEval}</small>}
      </div>
      <div className="d-flex space-x-3 mt-3">
        <Button
          onClick={() => onPrompt(fieldUuid, toEval, input.name, props.change)}
          size="sm"
          color="primary"
          outline
          disabled={props.generatingPrompt}
        >
          {isSubmitting(
            props.generatingPrompt,
            "Generate Response",
            "Generating...",
          )}
        </Button>
        {input.value ? (
          <ChatModal
            modelType={props.modelType}
            modelId={props.modelId}
            initialChat={input.value}
            fieldUuid={fieldUuid}
            change={props.change}
            name={input.name}
          />
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state: any, { meta }: { meta: { form: string } }) => {
  return { formValues: getFormValues(meta.form)(state) };
};

export default connect(mapStateToProps)(CustomPromptInput);
