import { Field } from "redux-form";
import FormModal from "../utils/FormModal";
import renderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";

const AddEditModal = (props) => {
  const { taxCodes, types } = props;

  return (
    <FormModal {...props}>
      <div className="form-group col-lg-4">
        <Field
          required
          validate={required}
          component={renderField}
          name="name"
          label="Name"
        />
      </div>
      <div className="form-group col-lg-2">
        <Field
          required
          validate={required}
          component={renderField}
          name="code"
          label="Code"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          options={types}
          name="type"
          label="Type"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          required
          validate={required}
          component={SelectInputAsync}
          name="tax_code"
          label="Tax Code"
          options={taxCodes}
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          required
          validate={required}
          component={renderField}
          textarea
          name="description"
          label="Description"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Payment"
          name="credit_card"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Purchase Order"
          name="purchase_order"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Expense Claim"
          name="expense_claim"
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          label="Sales Account"
          name="sales_account"
        />
      </div>
      <div className="form-group col-lg-12">
        <Field
          component={renderToggleInput}
          label="Budget Control"
          name="budget_control"
        />
        <small className="d-block">
          Check this if you want purchases attributed to this account be blocked
          if they are over budget.
        </small>
      </div>
    </FormModal>
  );
};

export default AddEditModal;
