import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "../api/api";
import { primary } from "./Colours";
import FieldInformationPopOver from "./FieldInformationPopOver";

class SelectInputAsync extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      value: this.props.input.value,
      asyncCount: null,
    };
    this.url = this.props.url;
    this.placeholder = this.props.placeholder;
    this.multiple = this.props.multiple;
    this.options = this.props.options;
    this.hasApproval = this.props.hasApproval;
  }

  componentDidMount = () => {
    const { url, order = true } = this.props;
    if (url) {
      this.setState({ loading: true });
      axios.get(this.props.url).then(({ data }) => {
        let formatted = data;

        if (this.props.formatData) {
          formatted = this.props.formatData(data);
        }

        this.setState({
          options: order
            ? _.orderBy(formatted, [(d) => d?.label?.toLowerCase()])
            : formatted,
          loading: false,
          asyncCount: formatted.length,
        });
      });
    }
  };

  onChangeMultiple(value) {
    this.setState({ value });

    this.props.input.onChange(value);
  }

  onChangeSingle(event) {
    if (this.props.onChangeValue) {
      //This sucks.....
      this.props.onChangeValue(event);
    }

    if (this.props.input.onChange && event != null) {
      if (event.value === 0) {
        event.value = "0";
      }
      this.props.input.onChange(event.value);

      if (this.props.getCallback) {
        this.props.getCallback(event.value);
      }
    } else {
      this.props.input.onChange(null);
    }
  }

  render() {
    const {
      label,
      meta: { touched, error },
      multiple,
      required,
      ariaLabel,
      disabled,
      order = true,
      input,
      noResults,
    } = this.props;

    const showLabel = label ? (
      <React.Fragment>
        <label className="tx-inverse tx-semibold">
          {this.props.label}
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver {...this.props} />
        </label>
      </React.Fragment>
    ) : (
      ""
    );

    const filteredOptions = this.props.options?.filter((o) => o);

    const optionOrder = order
      ? _.orderBy(filteredOptions ?? [], [(d) => d?.label?.toLowerCase()])
      : filteredOptions ?? [];

    const value = multiple
      ? input.value ?? null
      : optionOrder.filter(({ value }) => filterValues(value, input?.value));

    const theme = (theme) => {
      return {
        ...theme,
        borderRadius: "0.3rem",
        spacing: {
          ...theme.spacing,
          controlHeight: "calc(1.5em + 1.188rem + 2px)",
        },

        colors: {
          ...theme.colors,
          primary: primary,
        },
      };
    };

    return (
      <React.Fragment>
        {this.props.options?.length > 0 && (
          <React.Fragment>
            {showLabel}
            <Select
              isClearable
              {...this.props}
              onBlur={() => {
                input.onBlur();
              }}
              styles={{
                menu: (provided) => {
                  return {
                    ...provided,
                    zIndex: 10,
                  };
                },
                option: (styles, { isFocused }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused
                      ? "#e6e6e6"
                      : styles.backgroundColor,
                    color: isFocused ? "gray" : styles.color,
                  };
                },
              }}
              theme={theme}
              placeholder={this.placeholder || ""}
              value={value}
              onChange={
                multiple
                  ? this.onChangeMultiple.bind(this)
                  : this.onChangeSingle.bind(this)
              }
              options={optionOrder}
              aria-label={ariaLabel}
              isMulti={multiple}
              isDisabled={disabled}
              className={`${error && touched ? "was-validated" : ""} ${
                this.props.className
              } shadow-sm`}
              menuContainerStyle={{ zIndex: 5 }}
              isOptionDisabled={(option) => option.disabled}
              optionClassName="needsclick"
              closeMenuOnSelect={!multiple}
              blurInputOnSelect={!multiple}
            />
            {touched && error && (
              <span className={`${error ? "parsley-errors-list " : ""}d-block`}>
                {error}
              </span>
            )}
          </React.Fragment>
        )}

        {this.url && this.multiple && (
          <React.Fragment>
            {showLabel}
            <Select
              isClearable
              {...this.props}
              onBlur={() => {
                input.onBlur();
              }}
              theme={theme}
              styles={{
                menu: (provided) => {
                  return {
                    ...provided,
                    zIndex: 10,
                  };
                },
                option: (styles, { isFocused }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused
                      ? "#e6e6e6"
                      : styles.backgroundColor,
                    color: isFocused ? "gray" : styles.color,
                  };
                },
              }}
              loadingIndicator={<ClipLoader size={20} />}
              menuContainerStyle={{ zIndex: 5 }}
              placeholder={this.placeholder || ""}
              value={value}
              onChange={this.onChangeMultiple.bind(this)}
              options={this.state.options}
              aria-label={ariaLabel}
              isMulti
              isLoading={this.state.loading || this.props.loading}
              joinValues
              isDisabled={disabled}
              className={`${error && touched ? "was-validated" : ""} shadow-sm`}
              isOptionDisabled={(option) => option.disabled}
              optionClassName="needsclick"
              closeMenuOnSelect={!multiple}
              blurInputOnSelect={!multiple}
            />
            {touched && error && (
              <span className={`${error ? "parsley-errors-list " : ""}d-block`}>
                {error}
              </span>
            )}
            {this.state.asyncCount === 0 ? noResults : null}
          </React.Fragment>
        )}

        {this.url && !this.multiple && (
          <React.Fragment>
            {showLabel}
            <Select
              isClearable
              {...this.props}
              onBlur={() => {
                input.onBlur();
              }}
              theme={theme}
              menuContainerStyle={{ zIndex: 5 }}
              placeholder={this.placeholder || ""}
              value={
                this.state.options.filter(({ value }) =>
                  filterValues(value, this.props.input.value),
                ) || ""
              }
              onChange={this.onChangeSingle.bind(this)}
              options={this.state.options}
              isLoading={this.state.loading || this.props.loading}
              aria-label={ariaLabel}
              isDisabled={disabled}
              className={`${error && touched ? "was-validated" : ""} shadow-sm`}
              isOptionDisabled={(option) => option.disabled}
              optionClassName="needsclick"
              styles={{
                menu: (provided) => {
                  return {
                    ...provided,
                    zIndex: 10,
                  };
                },
                option: (styles, { isFocused }) => {
                  return {
                    ...styles,
                    backgroundColor: isFocused
                      ? "#e6e6e6"
                      : styles.backgroundColor,
                    color: isFocused ? "gray" : styles.color,
                  };
                },
              }}
            />
            {touched && error && (
              <span className={`${error ? "parsley-errors-list " : ""}d-block`}>
                {error}
              </span>
            )}
            {this.state.asyncCount === 0 ? noResults : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const filterValues = (value, input) => {
  if (value === 0) {
    return input === value;
  }

  return input == value;
};

export default SelectInputAsync;
