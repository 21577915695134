interface IUser {
  name: string;
  email: string;
}

export interface ApprovalAction {
  user: IUser;
  submitted_by: IUser;
  uuid: string;
  completed_at: string;
  seen_at: string;
  remarks: string;
  action_type: "Approve" | "Redo";
  previous_action?: {
    remarks: string;
    user: IUser;
    created_at: string;
  };
  user_id: number;
}

export enum ApprovalActionType {
  Approve = 1,
  Redo = 2,
  Deny = 3
}

export interface Approval {
  uuid: string;
  approvable_type: string;
  fields: string;
  label: string;
  message: string;
  reason: string;
  due_date: string;
  status: ApprovalStatuses;
  user: {
    name: string;
    uuid: string;
    email: string;
    phone: string;
  };
  user_to_approve: number;
  current_action?: ApprovalAction;
}

export enum ApprovalStatuses {
  PENDING,
  APPROVED,
  NOT_APPROVED,
  DISMISSED,
}

type ApprovalStatusStrings =
  | "Pending"
  | "Approved"
  | "Marked Redo"
  | "Rejected"
  | "Deletion Rejected"
  | "To be Approved"
  | "To be Redone"
  | "To be Approved to Delete";

export interface ApprovalStatus {
  status: ApprovalStatusStrings;
  at?: string;
  by: string;
}

export enum ApprovalOptions {
  Approve = 1,
  Redo = 2,
  Deny = 3,
}
