import React from "react";
import { Button } from "reactstrap";
import { Field, WrappedFieldArrayProps } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import { ProjectAllowanceEnum } from "../projects/projectTypes";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import { PenaltyRate, PenaltyRateAllowance } from "./penaltyRateTypes";

const PenaltyRateAllowances = (
  props: WrappedFieldArrayProps<PenaltyRateAllowance> & {
    setPenaltyRate?: (rate: PenaltyRate) => void;
    reset(): void;
    initialValues?: PenaltyRate;
  },
) => {
  const { fields, setPenaltyRate, reset, initialValues } = props;

  const { takeAction }: IUseApi<{}, { data: PenaltyRate }> = useApi();

  return (
    <React.Fragment>
      <FormHeader>Allowances</FormHeader>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field}>
            <div className="col-lg-2 form-group">
              <Field
                component={RenderField}
                name={`${field}.name`}
                label="Name"
              />
            </div>
            <div className="col-lg-3 form-group">
              <Field
                component={SelectInput}
                options={[
                  {
                    label: "Hours",
                    value: ProjectAllowanceEnum.Hours,
                  },
                  {
                    label: "Per Day",
                    value: ProjectAllowanceEnum.Day,
                  },
                ]}
                name={`${field}.unit`}
                label="Unit"
              />
            </div>
            <div className="col-lg-2 form-group">
              <Field
                component={RenderField}
                name={`${field}.from`}
                label="From"
              />
            </div>
            <div className={`col-lg-2 form-group`}>
              <Field
                component={RenderField}
                name={`${field}.amount`}
                label="Amount"
                {...money}
              />
            </div>
            <div className="col-lg-3 form-group align-self-end d-flex">
              <Button
                onClick={() => {
                  const hour = initialValues?.allowances?.[index];

                  if (hour && setPenaltyRate) {
                    return deleteSwal()
                      .then(() =>
                        takeAction(
                          "destroy",
                          `/penalty-rate-allowances/${hour?.uuid}`,
                        ),
                      )
                      .then(({ data }) => {
                        setPenaltyRate(data.data);
                        reset();
                      })
                      .catch(errorSwal);
                  }

                  fields.remove(index);
                }}
                color="danger"
                className="justify-self-end"
              >
                Delete
              </Button>
            </div>
          </React.Fragment>
        );
      })}
      <div className="col-12 form-group">
        <Button
          color="primary"
          outline
          onClick={() =>
            fields.push({
              name: "",
              amount: 0,
              unit: ProjectAllowanceEnum.Day,
            })
          }
        >
          Add Allowance
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PenaltyRateAllowances;
