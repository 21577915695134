import { useState } from "react";

const InformationAlert = ({
  type = "primary",
  body,
  title,
  closable = true,
}) => {
  const [display, setDisplay] = useState(true);
  if (!title && !body) return null;
  if (!display) return null;
  return (
    <div
      className={`bd-l bd-3 bd-${type} bg-gray-200 pd-x-20 pd-y-25 d-flex shadow-sm rounded`}
      role="alert"
    >
      <div>
        {title && (
          <h5 className={`tx-${type} ${!body ? "mb-0" : ""}`}>{title}</h5>
        )}
        {body && <p className="mg-b-0 white-space">{body}</p>}
      </div>
      {closable && (
        <button
          type="button"
          className="close ms-auto bg-transparent border-0 p-0"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => setDisplay(false)}
        >
          <span aria-hidden="true">×</span>
        </button>
      )}
    </div>
  );
};

export default InformationAlert;
