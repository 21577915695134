import { SubscribedSku } from "@microsoft/microsoft-graph-types";
import { FiMoreHorizontal } from "react-icons/fi";
import useModal from "../hooks/useModal";
import LicenceModal from "./LicenceModal";
import { OfficeSuitePosition } from "./microsoftTypes";

const MicrosoftPositionCard = ({
  position,
  deletePosition,
  addPosition,
  skus,
  syncedPositions,
  setPositions,
}: {
  position: { id: number; name: string };
  deletePosition: (id: number) => void;
  addPosition: (id: number) => void;
  skus: SubscribedSku[];
  syncedPositions: OfficeSuitePosition[];
  setPositions: (positions: OfficeSuitePosition[]) => void;
}) => {
  const syncedPosition = syncedPositions?.find(
    ({ position_id }) => position_id === position.id,
  );
  const { toggle, modal } = useModal();

  return (
    <>
      <div key={position.id} className="col-lg-6 form-group">
        <div className="bg-white w-100 d-flex text-start align-items-start rounded-lg border p-3">
          <div>
            <button
              type="button"
              onClick={() => {
                syncedPosition
                  ? deletePosition(position.id)
                  : addPosition(position.id);
              }}
              className="bg-transparent border-0 px-0"
            >
              <div>
                <p className="mb-0 tx-inverse fw-bolder">{position.name}</p>
              </div>
            </button>
            {syncedPosition ? (
              <small className="text-secondary d-block">
                Synced ({syncedPosition.licences.length} licences assigned)
              </small>
            ) : null}
          </div>
          {syncedPosition && (
            <div className="ms-auto">
              <button
                type="button"
                className="text-secondary border-0 bg-transparent p-0"
                onClick={toggle}
              >
                <FiMoreHorizontal />
              </button>
            </div>
          )}
        </div>
      </div>
      <LicenceModal
        syncedPosition={syncedPosition}
        toggle={toggle}
        modal={modal}
        skus={skus}
        positions={syncedPositions}
        setPositions={setPositions}
        position={position}
      />
    </>
  );
};

export default MicrosoftPositionCard;
