import { Field } from "redux-form";
import CustomForm from "../customFields/CustomForm";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import CpirParentForm from "./CpirParentForm";
import OldForm from "./OldForm";

const CPIRForm = (props) => {
  if (props.initialValues?.type) {
    return <OldForm {...props} />;
  }

  return (
    <CpirParentForm
      {...props}
      typeField={(props) => {
        return (
          <Field
            required
            validate={required}
            name="type_id"
            label="Type"
            changeValue={(type) => {
              props.setCpirTypeUrl(`cpir-types/${type.uuid}`);
              props.change("custom_fields", "");
            }}
            component={SelectInput}
            url="custom-fields/cpir-types"
            formatData={(data) => {
              return data
                .filter((d) => {
                  return !d.disabled || d.id === props.initialValues?.type_id;
                })
                .map((d) => {
                  return {
                    label: d.name,
                    value: d.id,
                    uuid: d.uuid,
                    disabled: d.disabled,
                  };
                });
            }}
          />
        );
      }}
    >
      {(props) => {
        if (!props.initialValues) {
          return null;
        }

        return (
          <CustomForm
            customForm={props.cpirType?.custom_fields ?? []}
            {...props}
          />
        );
      }}
    </CpirParentForm>
  );
};

export default CPIRForm;
