import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { reset } from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import Modal from "./Modal";
import ReactTable from "../table/ReactTable";
import { useAuth } from "../../context/auth-context";
import { Equipment, EquipmentType } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const EquipmentTypeGroupList = () => {
  const { data: equipmentTypeGroups, setData, takeAction, loading } = useApi(
    "equipment-type-groups",
    [],
  );

  const [selectedGroup, setSelectedGroup] = useState();

  const { user } = useAuth();

  const { toggle, modal } = useModal();

  const { toggle: toggleEdit, modal: editModal } = useModal();

  const deleteEquipmentGroup = equipmentTypeGroup => {
    return deleteSwal(equipmentTypeGroup.name)
      .then(() =>
        takeAction(
          "destroy",
          `equipment-type-groups/${equipmentTypeGroup.uuid}`,
        ),
      )
      .then(() => {
        toast.success("Equipment Type Group Deleted");
        setData(
          equipmentTypeGroups.filter(g => g.uuid !== equipmentTypeGroup.uuid),
        );
      })
      .catch(errorSwal);
  };

  const addEquipmentGroup = (values, dispatch) => {
    return takeAction("store", "equipment-type-groups", values)
      .then(({ data }) => {
        dispatch(reset("AddEquipmentTypeGroup"));
        toast.success(`${values.name} created`);
        setData([...equipmentTypeGroups, data.data]);
        toggle();
      })
      .catch(errorSwal);
  };

  const editEquipmentGroup = values => {
    return takeAction("update", `equipment-type-groups/${values.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated`);
        setData(
          equipmentTypeGroups.map(g =>
            g.uuid !== data.data.uuid ? g : data.data,
          ),
        );
        toggleEdit();
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
      cell: info => {
        return user.hasAccessTo("App\\Models\\EquipmentTypeGroup", "update") ? (
          <Button
            color="link"
            className="p-0"
            onClick={() => {
              setSelectedGroup(info.row.original);
              toggleEdit();
            }}
          >
            {info.getValue()}
          </Button>
        ) : (
          info.getValue()
        );
      },
    },
    {
      accessorKey: "equipment_type_count",
      header: "Equipment Type Count",
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: info => {
        if (info.row.original.equipment_type_count !== 0) {
          return "Unable to delete groups with equipment types";
        }

        return (
          <Button
            color="danger"
            size="sm"
            outline
            onClick={() => deleteEquipmentGroup(info.row.original)}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/equipment-asset-management/equipment-type-groups/" />
      <HeaderPage
        titlePage="Equipment Type Groups"
        pageDescription={
          <>
            Equipment type groups simplify equipment organization and management
            by grouping similar equipment together and facilitating tracking,
            usage, maintenance, calibration, and servicing.
          </>
        }
        relatedLinks={[
          {
            name: "Equipment",
            link: "/equipment",
            model: Equipment,
          },
          {
            name: "Equipment Types",
            link: "/equipment-types",
            model: EquipmentType,
          },
        ]}
        crumbs={[
          {
            link: "organisations/settings/links",
            name: "Organisation",
          },
          {
            link: "",
            name: "Equipment Type Groups",
            active: true,
          },
        ]}
      />
      {user.hasAccessTo("App\\Models\\EquipmentTypeGroup", "create") && (
        <Button outline color="primary" className="mb-3" onClick={toggle}>
          Add Equipment Type Group
        </Button>
      )}

      <ReactTable
        loading={loading}
        columns={columns}
        data={equipmentTypeGroups}
      />
      <Modal
        onSubmit={addEquipmentGroup}
        title="Add New Group"
        modal={modal}
        toggle={toggle}
        form="AddEquipmentTypeGroup"
      />
      <Modal
        onSubmit={editEquipmentGroup}
        title={`Edit ${selectedGroup?.name}`}
        modal={editModal}
        toggle={toggleEdit}
        form="EditEquipmentTypeGroup"
        initialValues={selectedGroup}
      />
    </>
  );
};

export default EquipmentTypeGroupList;
