import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import { Test } from "./testRequestTypes";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import ChangeReportModal from "./tests/ChangeReportModal";
import { useMemo } from "react";
import useModal from "../hooks/useModal";
import isSubmitting from "../utils/submitting";
import { DropdownItem } from "reactstrap";
import { TestWorksheet } from "../testWorksheets/testWorksheetTypes";
import _ from "lodash";

const TestReportButtons = ({
  tests,
  selectedTests,
  setTests,
  setSelectedTests,
  testRequestDeliverables,
  testWorksheets,
  toggle,
  fetchTests,
}: {
  tests: Test[];
  selectedTests: Test[];
  setTests: (tests: Test[]) => void;
  setSelectedTests: (tests: Test[]) => void;
  testRequestDeliverables: { name: string; uuid: string }[];
  testWorksheets: TestWorksheet[];
  toggle: Function;
  fetchTests: Function;
}) => {
  const { takeAction: deleteAction, loading: deleting } = useApi();
  const { takeAction: createAction, loading: creating } = useApi();
  const { takeAction: exportAction, loading: exporting } = useApi();

  const { takeAction: downloadAction, loading: downloading } = useApi();

  const { modal, toggle: toggleReportModal } = useModal();

  const availableWorksheets = useMemo(
    () => calculateAvailableWorksheets(selectedTests, testWorksheets),
    [selectedTests, testWorksheets],
  );

  const {
    takeAction: generateTestWorksheetAction,
    loading: generatingWorksheet,
  } = useApi();

  const {
    takeAction: removeTestFromWorksheetAction,
    loading: removingFromWorksheet,
  } = useApi();

  const removeTestFromWorksheet = () => {
    return removeTestFromWorksheetAction(
      "store",
      `/tests/worksheets/tests/remove`,
      {
        tests: selectedTests.map(({ id }) => id),
      },
    )
      .then(() => {
        toast.success("Test removed from worksheet");
        toggle();
        fetchTests();
      })
      .catch(errorSwal);
  };

  const generateTestWorksheet = () => {
    return generateTestWorksheetAction(
      "store",
      `/test-requests/${uuid}/worksheets`,
      availableWorksheets,
    )
      .then(({ data }: { data: { data: Test[] } }) => {
        toast.success("Test Worksheet generated");
        toggle();
        fetchTests();
      })
      .catch(errorSwal);
  };

  const { uuid } = useParams<{ uuid: string }>();

  const someSelectedHaveReports = selectedTests.some((test) => test.report);

  const deleteReports = () => {
    return deleteAction("store", `/test-requests/${uuid}/reports/remove`, {
      tests: selectedTests.filter((test) => test.report).map(({ id }) => id),
    })
      .then(({ data }: { data: { data: Test[] } }) => {
        toast.success("Reports deleted");
        setSelectedTests(
          data.data.filter((test) =>
            selectedTests.some((selectedTest) => selectedTest.id === test.id),
          ),
        );
        setTests(data.data);
      })
      .catch(errorSwal);
  };

  const createReport = () => {
    return createAction("store", `/test-requests/${uuid}/reports`, {
      tests: selectedTests.map(({ id }) => id),
    })
      .then(({ data }: { data: { data: Test[] } }) => {
        toast.success("Report created");
        setSelectedTests(
          data.data.filter((test) =>
            selectedTests.some((selectedTest) => selectedTest.id === test.id),
          ),
        );
        setTests(data.data);
      })
      .catch(errorSwal);
  };

  const downloadSelected = () => {
    return downloadAction("store", "download-selected-test-reports", {
      tests: selectedTests.map(({ id }) => id),
    })
      .then(({ data }: { data: { data: string } }) => {
        checkForUrl(data.data);
      })
      .catch(errorSwal);
  };

  const checkForUrl = (cacheKey: string) => {
    downloadAction("show", `download-reports/check/${cacheKey}`).then(
      ({ data }: { data: { data?: string } }) => {
        if (data.data) {
          window.location.href = data.data;
          return;
        }

        setTimeout(() => checkForUrl(cacheKey), 1000);
      },
    );
  };

  const createExport = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return exportAction("store", "export-test-request", {
      tests: selectedTests.map(({ id }) => id),
      timezone: tz,
    })
      .then(({ data }: { data: { data: string } }) => {
        window.location.href = data.data;
      })
      .catch(errorSwal);
  };

  const buttons = useMemo(() => {
    const buttons = [
      {
        text: isSubmitting(
          creating,
          <>
            <i className="icon ion-compose tx-18" /> Add Report
          </>,
          "Adding...",
        ),
        onClick: createReport,
        disabled: creating,
        id: "add-report",
      },
      {
        text: isSubmitting(
          deleting,
          <>
            <i className="fa fa-trash" /> Delete Report
          </>,
          "Deleting...",
        ),
        id: "delete-report",
        onClick: deleteReports,
        disabled: deleting,
      },
      {
        text: (
          <>
            <i className="icon ion-compose tx-18 text-secondary" /> Add to
            Existing
          </>
        ),
        id: "add-to-existing",
        onClick: toggleReportModal,
      },
      {
        text: isSubmitting(
          exporting,
          <>
            <i className="fa fa-download text-secondary" /> Download TR
          </>,
          "Generating TR...",
        ),
        onClick: createExport,
        id: "download-tr",
        disabled: exporting,
      },
      {
        text: isSubmitting(
          downloading,
          <>
            <i className="fa fa-download text-secondary" /> Download Selected
            Reports
          </>,
          "Generating Zip...",
        ),
        onClick: downloadSelected,
        id: "download-selected",
        disabled: downloading,
      },
      {
        text: isSubmitting(
          generatingWorksheet,
          <>
            <i className="icon ion-compose tx-18 text-secondary" /> Generate
            Worksheet
          </>,
          "Generating Worksheet...",
        ),
        onClick: generateTestWorksheet,
        id: "generate-worksheet",
        disabled: generatingWorksheet,
      },
    ];

    if (selectedTests.some((t) => t.test_request_worksheet)) {
      buttons.push({
        text: isSubmitting(
          removingFromWorksheet,
          <>
            <i className="fa fa-trash" /> Delete Report
          </>,
          "Deleting...",
        ),
        id: "remove-from-worksheet",
        onClick: removeTestFromWorksheet,
        disabled: false,
      });
    }

    return buttons;
  }, [
    tests,
    selectedTests,
    deleting,
    creating,
    exporting,
    downloading,
    generatingWorksheet,
    removingFromWorksheet,
  ]);

  if (selectedTests.length === 0) {
    return null;
  }

  const buttonsToShow = buttons.filter((button) => {
    if (button.id === "add-report" && someSelectedHaveReports) {
      return false;
    }

    if (button.id === "delete-report" && !someSelectedHaveReports) {
      return false;
    }

    if (
      button.id === "add-to-existing" &&
      testRequestDeliverables.length === 0
    ) {
      return false;
    }

    if (
      button.id === "generate-worksheet" &&
      availableWorksheets.length === 0
    ) {
      return false;
    }

    return true;
  });

  return (
    <>
      {buttonsToShow.map((button) => {
        return (
          <DropdownItem
            className="text-secondary"
            onClick={button.onClick}
            id={button.id}
            disabled={button.disabled}
          >
            {button.text}
          </DropdownItem>
        );
      })}
      <ChangeReportModal
        modal={modal}
        toggle={toggleReportModal}
        testRequestDeliverables={testRequestDeliverables}
        selectedTests={selectedTests}
        setSelectedTests={setSelectedTests}
        setTests={setTests}
      />
    </>
  );
};

const calculateAvailableWorksheets = (
  selectedTests: Test[],
  testWorksheets: TestWorksheet[],
) => {
  let possibleSheets: { sample_id: string; worksheet: TestWorksheet }[] = [];

  testWorksheets.forEach((worksheet) => {
    const samples = _.groupBy(selectedTests, "sample_id");

    Object.entries(samples).forEach(([sample_id, tests]) => {
      const isPossible = worksheet.test_methods.every((test_method) => {
        return tests.some((test) => {
          return test.test_method_id === test_method.id;
        });
      });

      if (isPossible) {
        possibleSheets.push({
          sample_id,
          worksheet,
        });
      }
    });
  });

  return Object.entries(_.groupBy(possibleSheets, "sample_id")).map(
    ([worksheet_id, samples]) => {
      return _.maxBy(samples, (sample) => sample.worksheet.test_methods.length);
    },
  );
};

export default TestReportButtons;
