import axios from "../api/api";
import { Component } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";

import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import signaturePad from "../utils/signaturePad";
import isSubmitting from "../utils/submitting";
import Items from "./Items";

class FormMachineDayRate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      equipment: [],
      descriptionOther: false,
    };
  }

  setEquipment = (branchId) => {
    return axios
      .get(
        `/react-select?select=id,number,padding,prefix,branch_id&model=${encodeURIComponent(
          "App\\Models\\Equipment",
        )}&label=name&value=id&where[branch_id]=${branchId}`,
      )
      .then(({ data }) => this.setState({ equipment: data }));
  };

  componentDidMount = () => {
    const { initialValues } = this.props;
    if (initialValues) return this.setEquipment(initialValues.job.branch_id);
  };

  onChange = (e, val) => this.setEquipment(val);

  onChangeDescription = (e, val) => {
    if (val == 10) return this.setState({ descriptionOther: true });
    return this.setState({ descriptionOther: false });
  };

  render() {
    const { submitting, initialValues, handleSubmit } = this.props;
    const { deleting, equipment } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-lg-4">
            <Field
              name="job.project_manager"
              component={SelectInputAsync}
              url="/projectjobs/jobmanager"
              placeholder="Supervisor"
              required
              label="Supervisor"
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="machine_day_rate.operator_id"
              component={SelectInputAsync}
              url="/projectjobs/jobmanager"
              placeholder="Operator"
              required
              label="Operator"
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="job.date"
              type="date"
              component={renderField}
              label="Date"
              // required
              // validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="job.scheduled_start_time"
              type="time"
              component={renderField}
              label="Scheduled Start Time"
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="job.scheduled_finish_time"
              type="time"
              component={renderField}
              label="Scheduled Finish Time"
              required
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="job.branch_id"
              onChange={this.onChange}
              component={SelectInputAsync}
              url="/organisationbranches/branches"
              placeholder="Select Branch"
              required
              label="Branch"
              validate={required}
            />
          </div>
          <div className="form-group col-12">
            <FieldArray
              initialValues={initialValues}
              component={Items}
              name="items"
              equipment={equipment}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={renderField}
              name="machine_day_rate.downtime_hours"
              type="text"
              label="Downtime Hours"
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              component={renderField}
              name="machine_day_rate.downtime_reason"
              label="Downtime Reason"
            />
          </div>
          <div className="col-lg-12 form-group">
            <Field
              component={renderField}
              name="machine_day_rate.remarks"
              textarea
              label="Remarks"
            />
          </div>
          <div className="col-lg-12 form-group">
            {initialValues?.machine_day_rate?.operator_signature ? (
              <>
                <label className="form-control-label tx-inverse tx-semibold">
                  Operator Signature
                </label>
                <img
                  className="w-100"
                  src={initialValues?.machine_day_rate?.operator_signature}
                  alt="signature"
                />
              </>
            ) : (
              <Field
                name="machine_day_rate.operator_signature"
                component={signaturePad}
                label="Operator Signature"
              />
            )}
          </div>
          <div className="col-lg-12 form-group">
            {initialValues?.machine_day_rate?.supervisor_signature ? (
              <>
                <label className="form-control-label tx-inverse tx-semibold">
                  Supervisor Signature
                </label>
                <img
                  className="w-100"
                  src={initialValues?.machine_day_rate?.supervisor_signature}
                  alt="signature"
                />
              </>
            ) : (
              <Field
                name="machine_day_rate.supervisor_signature"
                component={signaturePad}
                label="Supervisor Signature"
              />
            )}
          </div>
          <div className="form-group col-lg-3 mg-b-10">
            <button
              className="btn btn-primary bd-0"
              disabled={deleting || submitting}
            >
              {isSubmitting(submitting)}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const form = reduxForm({});

export default form(FormMachineDayRate);
