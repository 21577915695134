import { toast } from "react-toastify";
import { Field, FieldArray, reduxForm } from "redux-form";
import axios from "../api/api";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { formatNumber } from "../dashboard/OverviewCard";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import PaymentFieldArray from "./PaymentFieldArray";

const AddPayment = (props) => {
  const {
    handleSubmit,
    recurringPurchase,
    setRecurringPurchase,
    dispatch,
    totalPrice,
  } = props;

  const { takeAction } = useApi();
  const { uuid } = useParams();

  const { user } = useAuth();

  const onSubmit = (values, _, props) => {
    return uploadToS3(
      values.file,
      `organisations/${user.active_organisation.uuid}/purchase-invoices`,
    )
      .then((documents) => {
        const data = {
          documents,
          ...values,
        };

        return takeAction(
          "store",
          `recurring-payments/${uuid}/purchases`,
          data,
        );
      })
      .then(({ data }) => {
        setRecurringPurchase(data.data);
        toast.success("Payment Added!");
        props.reset({
          recurring_payment_id: recurringPurchase.id,
        });
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <TotalPrice totalPrice={totalPrice} />
        <div className="col-12 form-group">
          <Field
            label="Invoice"
            required
            validate={required}
            component={FileInput}
            name="file"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            label="Date"
            required
            validate={required}
            component={renderField}
            name="date"
            type="date"
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            label="Invoice Number"
            required
            validate={required}
            component={renderField}
            name="invoice_number"
          />
        </div>
        <FieldArray
          component={PaymentFieldArray}
          canAdd
          {...props}
          recurringPurchase={recurringPurchase}
          name="payment"
        />
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const TotalPrice = ({ totalPrice }) => {
  return (
    <div className="col-12 d-flex d-lg-none justify-content-end">
      <div className="d-h-t-right">
        <div className="summary-item">
          <h1>${formatNumber(totalPrice)}</h1>
          <span>Total Cost</span>
        </div>
      </div>
    </div>
  );
};

const asyncValidate = (values) => {
  if (!values.invoice_number) return Promise.resolve();
  return axios
    .post(`/recurring-payment-purchase-validation`, values)
    .catch(() => {
      throw {
        invoice_number:
          "That invoice number has already been added for this contract agreement.",
      };
    });
};

const form = reduxForm({
  form: "RecurringPayment",
  asyncValidate,
  asyncBlurFields: ["invoice_number"],
});
export default form(AddPayment);
