import _ from "lodash";
import { formatNumber } from "../dashboard/OverviewCard";
import UpdateDistanceAllowanceModal from "./UpdateDistanceAllowanceModal";
import { Timesheet } from "./timesheetTypes";

const AllowancesCard = ({
  timesheet,
  setTimesheet,
}: {
  timesheet: Timesheet;
  setTimesheet: (timesheet: Timesheet) => void;
}) => {
  if (!timesheet?.allowances || timesheet?.allowances?.length === 0) {
    return null;
  }

  return (
    <div className="my-3 border rounded-lg shadow bg-white p-3">
      <p className="tx-12 mb-2">
        Allowance{timesheet.allowances.length > 1 ? "s" : ""}:
      </p>
      <div className="space-y-2">
        {timesheet.allowances.map((allowance, index) => {
          return (
            <div>
              <p className="mb-0" key={index}>
                <span className="tx-inverse tx-bold">
                  {allowance.amount ?? 0}
                </span>{" "}
                <span className="fst-italic">{allowance.name} </span>
                {allowance.unit} at{" "}
                <span className="tx-bold tx-inverse">
                  ${formatNumber(allowance.rate)}
                </span>
                .{" "}
              </p>
              <p className="mb-0">Total: ${formatNumber(allowance.total)}</p>
            </div>
          );
        })}
      </div>

      <p className="mb-0 mt-3">
        Total:{" "}
        <span className="tx-inverse tx-16 fw-bolder">
          ${formatNumber(_.sumBy(timesheet.allowances, "total"))}
        </span>
      </p>
      {timesheet.allowances.find(
        (allowance) => allowance.unit === "Kilometre",
      ) && (
        <div className="mt-3">
          <small>
            Kilometre allowance based on {timesheet.user.name}'s address. Please
            click below to update if this is incorrect.
          </small>
          <UpdateDistanceAllowanceModal
            setTimesheet={setTimesheet}
            timesheet={timesheet}
          />
        </div>
      )}
    </div>
  );
};

export default AllowancesCard;
