import React from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Field,
  FormAction,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  isDirty,
  reduxForm,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
  SubmissionError,
} from "redux-form";
import JobStatus from "../../../enums/JobStatus";
import {
  CustomFieldValue,
  FieldType,
} from "../../customFields/customFieldTypes";
import CustomForm from "../../customFields/CustomForm";
import SelectInput from "../../form/SelectInput";
import RenderField from "../../utils/renderField";
import required from "../../utils/required";
import SubmitButton from "../../utils/SubmitButton";
import { CustomJobJob } from "./customJobTypes";
import { getFilterValueMapper } from "./getFilterValueMapper";

const JOB_STATUS = [
  { value: 0, label: "Pending" },
  { value: 1, label: "In Progress" },
  { value: 2, label: "On Hold" },
  { value: 3, label: "Completed" },
  { value: 4, label: "Cancelled" },
];

interface CustomJobJobFormProps extends PropsFromRedux {
  job: CustomJobJob;
  dispatch: (action: FormAction) => void;
  onSubmit: (values: any) => Promise<void>;
}

const Form = (
  props: InjectedFormProps<CustomJobJob, CustomJobJobFormProps> &
    CustomJobJobFormProps,
) => {
  const { job, form, formValues, dispatch, onSubmit, valid, status, isDirty } =
    props;

  const submitWhenNotValid = (e: React.FormEvent<HTMLFormElement>) => {
    const newValues = formValues as {
      custom_fields: CustomFieldValue[];
    };

    e.preventDefault();

    const v = getFlattenedValues(Object.values(newValues.custom_fields ?? {}));
    /** @ts-ignore */
    const v2 = getFlattenedValues(Object.values(job.custom_fields ?? {}));
    const isDirty = JSON.stringify(v) !== JSON.stringify(v2);

    dispatch(startSubmit(form));

    onSubmit({
      ...formValues,
      is_dirty: isDirty,
    })
      .then(() => {
        dispatch(stopSubmit(form));
        dispatch(setSubmitSucceeded(form));
      })
      .catch((err: SubmissionError) => {
        dispatch(stopSubmit(form, err?.errors));
        dispatch(setSubmitFailed(form));
      });
  };

  return (
    <form onSubmit={submitWhenNotValid} noValidate={true}>
      <div className="row">
        <CustomForm
          customForm={job.custom_form}
          {...props}
          shouldError={true}
          templates={job?.template?.documents ?? []}
          parentUuid={job.uuid}
          valueMapper={getFilterValueMapper(job)}
          modelType="App\Models\ProjectJob"
          modelId={job.id}
        />

        {job?.requires_approval &&
          valid &&
          job.approval_action?.action_type !== "Approve" && (
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                name="approver"
                options={job.to_approve ?? []}
                label="Approver"
              />
            </div>
          )}
        <div className="form-group col-lg-12">
          <Field
            name="status"
            label="Status"
            component={SelectInput}
            options={JOB_STATUS}
            placeholder="Job Status"
            order={false}
          />
        </div>
        {status == JobStatus.Cancelled && (
          <div className="form-group col-lg-12">
            <Field
              name="cancelled_reason"
              label="Reason for Cancellation"
              component={RenderField}
              required
              validate={required}
            />
          </div>
        )}

        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<CustomJobJob, CustomJobJobFormProps>({
  enableReinitialize: true,
});

const mapStateToProps = (state: any, { form }: { form: string }) => {
  const selector = formValueSelector(form);
  return {
    formValues: getFormValues(form)(state),
    status: selector(state, "status"),
    isDirty: isDirty(form)(state, "custom_fields"),
  };
};

const getFlattenedValues = (customFields: CustomFieldValue[]): any[] => {
  return customFields
    .map((value) => {
      /** @ts-ignore */
      if (value.type == FieldType.FieldArray) {
        /** @ts-ignore */
        return (
          value.value
            /** @ts-ignore */
            ?.flat()
            /** @ts-ignore */
            ?.map((v) => {
              /** @ts-ignore */
              return Object.values(v).map((v) => v.value);
            })
            ?.flat() ?? []
        );
      }

      return value.value;
    })
    .flat()
    .filter((v) => v);
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(form(Form));
