import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard, {
  PaginatedInformation,
} from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { Group } from "./groupTypes";
import useGroups from "./hooks/useGroups";
import { Document, Position, Equipment } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const GroupList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/groups/" />
      <HeaderPage
        titlePage="Groups"
        pageDescription={
          <>
            Categorise your employees and users into different groups, based on
            their roles and responsibilities within the organization, with each
            group having a designated "group leader" for certain approvals.
          </>
        }
        relatedLinks={[
          {
            name: "Controlled Documents",
            link: "/documents",
            model: Document,
          },
          {
            name: "Positions",
            link: "/positions",
            model: Position,
          },
          {
            name: "Equipment",
            link: "/equipment-types",
            model: Equipment,
          },
        ]}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "groups", name: "Groups", active: true },
        ]}
      />
      <PaginatedList
        indexHook={useGroups}
        originalFilters={[]}
        addLink="/groups/add"
        itemCard={({ item }: { item: Group }) => {
          return (
            <PaginatedCard
              key={item.id}
              info={info(item)}
              header={<Header group={item} />}
            />
          );
        }}
      />
    </>
  );
};

const Header = ({ group }: { group: Group }) => {
  const tooltipId = useMemo(() => `group${group.uuid}`, [group.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={group.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/groups/${group.uuid}`}>{group.name}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {group.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const info = (group: Group): PaginatedInformation[] => {
  return [
    {
      name: "Leader",
      value: group?.leader ? (
        <a href={`mailto:${group.leader?.email}`}>{group.leader?.name}</a>
      ) : (
        "No Leader Added"
      ),
    },
    {
      name: "Number of Users",
      value: (
        <Badge
          className="rounded-pill shadow"
          color={group.users_count === 0 ? "warning" : "success"}
        >
          {group.users_count}
        </Badge>
      ),
    },
  ];
};

export default GroupList;
