import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import ItemFieldArray from "./ItemFieldArray";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import InformationAlert from "../utils/InformationAlert";
import FrequencyFields from "./FrequencyFields";
import DownloadDropdown from "../customJobs/jobs/DownloadDropdown";

const Prices = (props) => {
  const { handleSubmit, recurringPurchase, setRecurringPurchase } = props;
  const { takeAction } = useApi();

  const submit = (values) => {
    return takeAction(
      "update",
      `recurring-payments/${recurringPurchase.uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success(`${recurringPurchase.name} updated successfully.`);
        setRecurringPurchase(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <>
      <DownloadDropdown
        model={{
          model: "App\\Models\\RecurringPayment",
          id: recurringPurchase?.id,
        }}
        documents={recurringPurchase?.template?.documents}
      />
      <form onSubmit={handleSubmit(submit)}>
        {recurringPurchase.purchases.length > 0 ? (
          <div className="mb-3">
            <InformationAlert
              type="info"
              title="Purchases Added"
              body="You are only able to update the 'description' and 'frequency' fields, no other fields will update."
            />
          </div>
        ) : null}
        <div className="row">
          <div className="col-12 form-group">
            <Field
              label="GST Options"
              name="includes_gst"
              component={SelectInputAsync}
              url="/gst-options"
            />
          </div>
          <div className="col-12 form-group">
            <Field
              label="Description"
              name="description"
              component={RenderField}
              required
              validate={required}
            />
          </div>
          <div className="col-12 form-group">
            <Field
              label="Quote Number"
              name="quote_number"
              component={RenderField}
            />
          </div>
          <FrequencyFields />
          {recurringPurchase.archived_at && (
            <div className="col-12 form-group">
              <Field
                label="Archived Reason"
                name="archive_reason"
                component={RenderField}
                textarea
              />
            </div>
          )}
          <FieldArray
            component={ItemFieldArray}
            title="Items"
            {...props}
            name="prices"
          />
          <div className="col-12">
            <SubmitButton {...props} />
          </div>
        </div>
      </form>
    </>
  );
};

const form = reduxForm({
  form: "RecurringPurchasePrices",
  enableReinitialize: true,
});

export default form(Prices);
