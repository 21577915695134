import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import FormErrorAlert from "../form/FormErrorAlert";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { PermissionGroup } from "./permissionGroupTypes";

const PermissionGroupForm = (props: InjectedFormProps<PermissionGroup>) => {
  const { handleSubmit, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            type="text"
            required
            validate={required}
            name="name"
            label="Name"
          />
        </div>
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<PermissionGroup>({});

export default form(PermissionGroupForm);
