import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import colors from "../../data/colors";

const MentionDisplay = ({ content }: { content: RawDraftContentState }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(content)),
  );

  const { plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps) {
        return (
          <Link
            style={{
              backgroundColor: colors.info.light,
              borderColor: colors.info.border,
              color: colors.info.main,
            }}
            className="link-hover p-1 rounded-lg"
            to={mentionProps.mention.link ?? ""}
          >
            {mentionProps.mention.name}
          </Link>
        );
      },
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  return (
    <Editor
      editorState={editorState}
      onChange={setEditorState}
      plugins={plugins}
      editorKey={"editor"}
      readOnly
    />
  );
};

export default MentionDisplay;
