import { useEffect, useState } from "react";
import api from "../../../api/api";
import { SampleListFilter } from "../SampleList";

interface SampleFilterCall {
  data: {
    name: string;
    id: number;
  }[];
}

const dataMapper = (data: { name: string; id: number }[]) => {
  return data.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  });
};

const useSampleDashboardFilters = (): SampleListFilter[] => {
  const [filters, setFilters] = useState<SampleListFilter[]>([
    {
      name: "date",
      label: "Date",
      options: [],
      hide: true,
    },
  ]);

  useEffect(() => {
    const filterValues: Promise<SampleListFilter>[] = [
      api.get<SampleFilterCall>("test-requests/projects").then(({ data }) => {
        return {
          name: "project_id",
          label: "Project",
          multiple: true,
          options: dataMapper(data.data),
        };
      }),
      api.get<SampleFilterCall>("test-requests/clients").then(({ data }) => {
        return {
          name: "client_id",
          label: "Client",
          multiple: true,
          options: dataMapper(data.data),
        };
      }),
      api.get<SampleFilterCall>("test-requests/branches").then(({ data }) => {
        return {
          name: "branch_id",
          label: "Branch",
          multiple: true,
          options: dataMapper(data.data),
        };
      }),
    ];

    Promise.all(filterValues).then((values) => {
      const newFilters = [...filters, ...values];

      setFilters(newFilters);
    });
  }, []);

  return filters;
};

export default useSampleDashboardFilters;
