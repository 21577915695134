import dayjs from "dayjs";
import _ from "lodash";
import TableRow from "./TableRow";

const TimesheetTable = ({ pay }) => {
  if (!pay) return null;

  const penaltyRateTimesheets = pay.timesheets
    .filter((timesheet) =>
      timesheet.hourly_rates.find((rate) => rate.rate_hour_id),
    )
    .map((timesheet) => timesheet.hourly_rates)
    .flat();

  const grouped = _.groupBy(
    penaltyRateTimesheets,
    (t) => t.rate_hour_id.toString() + t.role_id.toString(),
  );

  const timesheetsByPay = pay.timesheets
    .map((timesheet) => {
      return timesheet.hourly_rates.map((rate) => {
        return {
          ...timesheet,
          type: "timesheet",
          hourly_rate: rate.pay_rate,
          start_time: rate.start_time,
          finish_time: rate.finish_time,
          total_hours: rate.hours,
        };
      });
    })
    .flat();

  const rows = _.orderBy(
    [
      ...timesheetsByPay,
      ...pay.payments,
      ...pay.allowance_details.map((d) => ({ ...d, type: "allowance" })),
    ],
    (p) => dayjs(p.date),
  );

  return (
    <div className="w-100 table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Approved By</th>
            <th>Day of Week</th>
            <th>Start Time</th>
            <th>Finish Time</th>
            <th>Paid break</th>
            <th>Unpaid break</th>
            <th>Remarks</th>
            <th>Role</th>
            <th>Worked On</th>
            <th>Total Hours</th>
            <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <TableRow data={row} key={row.uuid} />
          ))}
          <tr>
            <td colSpan="10" />
            <td>
              <b>Ordinary Hours</b>
            </td>
            <td colSpan="2">{pay.total_standard_hours.toFixed(2)}</td>
          </tr>
          {pay.overtime_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Overtime Hours</b>
              </td>
              <td colSpan="2">{pay.overtime_hours.toFixed(2)}</td>
            </tr>
          )}
          {pay.not_in_pay_run_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Not This Pay Run Hours</b>
              </td>
              <td colSpan="2">{pay.not_in_pay_run_hours.toFixed(2)}</td>
            </tr>
          )}
          {pay.saturday_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Saturday Hours</b>
              </td>
              <td colSpan="2">{pay.saturday_hours.toFixed(2)}</td>
            </tr>
          )}
          {pay.sunday_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Sunday Hours</b>
              </td>
              <td colSpan="2">{pay.sunday_hours.toFixed(2)}</td>
            </tr>
          )}
          {pay.total_leave_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Leave Hours</b>
              </td>
              <td colSpan="2">{pay.total_leave_hours.toFixed(2)}</td>
            </tr>
          )}
          {pay.total_public_holiday_hours > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Public Holiday Hours</b>
              </td>
              <td colSpan="2">{pay.total_public_holiday_hours.toFixed(2)}</td>
            </tr>
          )}

          {Object.values(grouped).map((hours) => {
            return (
              <tr>
                <td colSpan="10" />
                <td>
                  <b>
                    Penalty {hours[0].rate} at {hours[0].rate_amount}
                  </b>
                </td>
                <td>{_.sumBy(hours, "hours")?.toFixed(2)}</td>
                <td>${hours[0].pay_rate?.toFixed(2)}</td>
              </tr>
            );
          })}
          {pay.allowances > 0 && (
            <tr>
              <td colSpan="10" />
              <td>
                <b>Allowances</b>
              </td>
              <td colSpan="2">${pay.allowances.toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <td colSpan="10" />
            <td>
              <b>Total</b>
            </td>
            <td colSpan="2">${pay.estimated_amount.toFixed(2)}</td>
          </tr>
          {pay.total_claims > 0 && (
            <tr>
              <td colSpan="10" />
              <td>Expense Claims</td>
              <td colSpan="2">${pay.total_claims.toFixed(2)}</td>
            </tr>
          )}
          {pay.total_deductions > 0 && (
            <tr>
              <td colSpan="10" />
              <td>Deductions</td>
              <td colSpan="2">${pay.total_deductions.toFixed(2)}</td>
            </tr>
          )}
        </tbody>
      </table>
      <small>
        * Please note that this time will be different from the time added on
        the timesheet.{" "}
      </small>
      <small className="ms-2 d-block">
        The amount will be the finish time, less the unpaid break.
      </small>
      <small className="ms-2 d-block">
        This is because, when adding the hours for the rate, we do not include
        the unpaid break.
      </small>
    </div>
  );
};

export default TimesheetTable;
