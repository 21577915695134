import React from "react";
import dayjs from "dayjs";

const color = (status) => {
  switch (true) {
    case status === "Approved":
      return "primary";
    case status === "To be Approved":
      return "info";
    case status === "To be Redone":
      return "warning";
    case status === "Deletion Rejected":
      return "primary text-warning";
    default:
      return "danger";
  }
};

const ApprovalStatus = ({ status, className = "" }) => {
  if (!status) {
    return null;
  }

  return (
    <span className={`badge bg-${color(status.status)} mg-r-5 ${className}`}>
      {status.status} by: {status.by} (
      {dayjs(status.at).format("DD/MM/YYYY HH:mm")})
    </span>
  );
};

export default ApprovalStatus;
