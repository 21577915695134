import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import FormModal from "../../utils/FormModal";
import renderField from "../../utils/renderField";
import required from "../../utils/required";

interface SampleDumpModalProps {
  onSubmit: Function;
  modal: boolean;
  toggle: Function;
}

const SampleDumpModal: React.FC<
  SampleDumpModalProps & InjectedFormProps<{}, SampleDumpModalProps>
> = (props: any) => {
  return (
    <FormModal {...props} title="Choose Sample Range">
      <div className="col-6 form-group">
        <Field
          component={renderField}
          name="from"
          label="Start Sample Number"
          validate={required}
          required
          type="number"
        />
      </div>
      <div className="col-6 form-group">
        <Field
          component={renderField}
          name="to"
          label="End Sample Number"
          validate={required}
          required
          type="number"
        />
      </div>
    </FormModal>
  );
};

const form = reduxForm<{}, SampleDumpModalProps>({
  asyncBlurFields: [],
});
export default form(SampleDumpModal);
