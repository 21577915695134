import _ from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import Papa from "papaparse";
import { Button } from "reactstrap";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import SearchInput from "../search/SearchInput";
import CustomScaleLoader from "../utils/scaleLoader";
import { IBranchStaff } from "./branchTypes";

const BranchStaff = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [search, setSearch] = useState("");

  const { data, loading }: IUseApi = useApi(
    `organisation-branches/${uuid}/staff`,
    [],
    true,
  );

  const staff = data as IBranchStaff[];

  const filteredStaff = staff.filter((staff) =>
    staff.name.toLowerCase().includes(search.toLowerCase()),
  );

  const generateCsvFile = () => {
    const headers = [
      "Staff Name",
      "Position",
      "Start Date",
      "Last Timesheet Submitted",
    ];
    const csvData = staff
      .map((staffRole) => {
        return staffRole.positions.map((position) => [
          staffRole.name,
          position.name,
          dayjs(position.start_date).format("DD/MM/YYYY"),
          position.latest_timesheet_date
            ? dayjs(position.latest_timesheet_date).format("DD/MM/YYYY")
            : "No Timesheets Added",
        ]);
      })
      .flat();
    const csvString = Papa.unparse({ fields: headers, data: csvData });
    const fileBlob = new Blob([csvString], { type: "text/csv" });
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.download = `Branch-Staff-List-${dayjs().format(
      "DD-MM-YYYY-HH-mm-ss",
    )}.csv`;
    link.href = dataUrl;
    link.click();
  };

  if (loading) {
    return <CustomScaleLoader>Fetching staff...</CustomScaleLoader>;
  }

  return (
    <>
      <div className="d-flex mb-3">
        <SearchInput
          setValue={setSearch}
          wrapperclass="search-box shadow-sm d-flex flex-grow-1"
        />
        <Button
          outline
          size="sm"
          className="btn m-1"
          onClick={() => generateCsvFile()}
        >
          Export CSV
        </Button>
      </div>
      <div className="space-y-3">
        {filteredStaff.map((staff) => {
          return (
            <div key={staff.id} className="bg-white p-3 border rounded-lg">
              <p className="mb-3 tx-inverse fw-bolder">{staff.name}</p>
              <p className="tx-14 mb-1 tx-inverse">
                Position{staff.positions.length > 1 && "s"}
              </p>
              {staff.positions.map((position, index) => {
                return (
                  <React.Fragment key={position.uuid}>
                    <hr />
                    <p className="mb-0">
                      {position.name}
                      <br />
                      Start Date:{" "}
                      <span className="text-primary">
                        {dayjs(position.start_date).format("DD/MM/YYYY")}
                      </span>
                      <br />
                      Last Submitted Timesheet Date:{" "}
                      <span className="text-primary">
                        {position?.latest_timesheet_date
                          ? dayjs(position.latest_timesheet_date).format(
                              "DD/MM/YYYY",
                            )
                          : "No Timesheets Added"}
                      </span>
                    </p>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BranchStaff;
