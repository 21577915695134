import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Field, initialize, reduxForm } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import AddDocketToNameButton from "./AddDocketToNameButton";
import DeliverablePricing from "./DeliverablePricing";

const DetailsForm = (props) => {
  const { initialValues, handleSubmit, dispatch, setDeliverable } = props;
  const { takeAction } = useApi();
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const [projectContacts, updateProjectContacts] = useState([]);

  useEffect(() => {
    takeAction("index", `projects/${initialValues.project.uuid}/contacts`).then(
      ({ data }) => {
        const projectContacts = data.data.map((contact) => ({
          label: contact.name,
          value: contact.contact_id,
        }));
        updateProjectContacts(projectContacts);
      },
    );
  }, []);

  const submit = (values) => {
    return takeAction("update", `/deliverables/${initialValues.uuid}`, values)
      .then(({ data }) => {
        dispatch(initialize("deliverableDetails", { ...data.data }));
        setDeliverable(data.data);
        return toast.success(`${values.name} updated successfully`);
      })
      .catch((err) => {
        return errorSwal(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-lg-12 form-group">
          <Field
            name="name"
            component={renderField}
            required
            label="Name"
            validate={required}
          />
        </div>
        <AddDocketToNameButton {...props} job={initialValues.job} />
        <div className="col-lg-12 form-group">
          <Field
            name="purchase_order"
            component={renderField}
            label="Line Item Prefix"
            placeholder="Leave blank if no prefix required."
          />
          <small>Add in a note that will be prepended to all line items.</small>
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="recipients"
            multiple
            options={projectContacts}
            component={SelectInputAsync}
            label="Recipients"
          />
          <small className="form-text text-muted">
            Please select the people who you want to send this to.
          </small>
        </div>

        <div className="col-lg-6 form-group">
          <Field
            name="contacts"
            multiple
            url={`/users/staff`}
            component={SelectInputAsync}
            label="Contacts"
          />
          <small className="form-text text-muted">
            Please select the people from{" "}
            {user.active_organisation.display_name} who you want to act as
            contacts for this.
          </small>
        </div>

        <div className="col-12">
          <DeliverablePricing {...props} />
        </div>
        <div className="form-group col-lg-12 mg-b-10">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "deliverableDetails",
  enableReinitialize: true,
});

export default form(DetailsForm);
