import React from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import formatDate from "../utils/formatDate";
import dayjs from "dayjs";
import { statusBadgeColor } from "../CPIRs/CpirList";
import riskDetails from "../CPIRs/risk";

const jobColumns = [
  {
    accessorKey: "project.full_name",
    header: "Project",
    cell: (info: any) => (
      <Link to={`/${info.row.original.project.link}`}>{info.getValue()}</Link>
    ),
  },
  {
    accessorKey: "full_name",
    header: "Job",
    cell: (info: any) => {
      const job = info.row.original;
      return (
        <>
          <Link to={job.link}>{info.getValue()}</Link>
          <Button
            id={`job_${job.uuid}`}
            type="button"
            color="link"
            className="p-0 ms-1"
          >
            <i className="fa fa-info-circle tx-inverse" />
          </Button>
          <UncontrolledPopover placement="auto" target={`job_${job.uuid}`}>
            <PopoverHeader>{job.name} Description</PopoverHeader>
            <PopoverBody className="white-space">{job.description}</PopoverBody>
          </UncontrolledPopover>
        </>
      );
    },
  },
  {
    accessorKey: "status_badge",
    header: "Status",
    cell: (info: any) => {
      const status = info.getValue();
      return <Badge color={status.color}>{status.label}</Badge>;
    },
  },
  {
    accessorKey: "scheduled_finish_date",
    header: "Scheduled Finish Date",
    cell: (info: any) => formatDate(info.getValue()),
  },
];

const tableColumns = {
  Projects: [
    {
      accessorKey: "client.display_name",
      header: "Client",
      cell: (info: any) => info.getValue() || "Internal",
    },
    {
      accessorKey: "name",
      header: "Project",
      cell: (info: any) => {
        const project = info.row.original;

        return (
          <React.Fragment>
            <Link to={`/${project.link}`}>{info.getValue()}</Link>
            <Button
              id={`project_${project.uuid}`}
              type="button"
              color="link"
              className="p-0 ms-1"
            >
              <i className="fa fa-info-circle tx-inverse" />
            </Button>
            <UncontrolledPopover
              placement="auto"
              target={`project_${project.uuid}`}
            >
              <PopoverHeader>{project.name} Description</PopoverHeader>
              <PopoverBody className="white-space">
                {project.description}
              </PopoverBody>
            </UncontrolledPopover>
          </React.Fragment>
        );
      },
    },
    {
      accessorKey: "scheduled_finish_date",
      header: "Scheduled Finish Date",
      cell: (info: any) => formatDate(info.getValue()),
    },
  ],
  Jobs: jobColumns,
  CPIRs: [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => (
        <div>
          <Link to={info.row.original.link} className="mb-0 text-dark">
            {info.row.original.title}
          </Link>
          <p className="mb-0">{info.getValue()}</p>
        </div>
      ),
    },
    {
      accessorKey: "type",
      header: "Type",
      cell: (info: any) => info.getValue() || info.row.original.original_type,
    },
    {
      accessorKey: "risk_level",
      header: "Risk Level",
      cell: (info: any) => {
        if (!info.getValue()) return "-";

        const risk = riskDetails(info.row.original.risk_score);

        return (
          <Badge className="rounded-pill shadow" color={risk.colour}>
            {info.getValue()}
          </Badge>
        );
      },
    },
    {
      header: "Raised By",
      accessorKey: "raised_by",
    },
    {
      header: "Raised To",
      accessorKey: "raised_to",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (info: any) => (
        <Badge
          className="shadow rounded-pill"
          color={statusBadgeColor(info.row.original)}
        >
          {info.getValue()}
        </Badge>
      ),
    },
    {
      header: "Target Date for Closure",
      accessorKey: "target_date_for_closure",
      cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY"),
    },
    {
      header: "Completed On",
      accessorKey: "completed_on",
      cell: (info: any) =>
        info.getValue()
          ? dayjs(info.getValue()).format("DD/MM/YYYY")
          : "Incomplete",
    },
  ],
  Calibrations: [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: (info: any) => formatDate(info.getValue()),
    },
  ],
  Competencies: jobColumns,
  Approvals: [
    {
      accessorKey: "title",
      header: "Name",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
  ],
  Invoices: [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "client.name",
      header: "Client",
      cell: (info: any) => (
        <Link to={info.row.original.client.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info: any) => (
        <Link to={`/projects/${info.row.original.project.uuid}/invoices`}>
          {info.getValue()}
        </Link>
      ),
    },
  ],
  Purchases: [
    {
      accessorKey: "name",
      header: "Name",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
    },
    {
      accessorKey: "supplier.name",
      header: "Supplier",
      cell: (info: any) => (
        <Link to={`/suppliers/${info.row.original.supplier.uuid}/details`}>
          {info.getValue()}
        </Link>
      ),
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info: any) => (
        <Link to={`/projects/${info.row.original.project.uuid}/purchases`}>
          {info.getValue()}
        </Link>
      ),
    },
  ],
};

export default tableColumns;
