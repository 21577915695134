import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector } from "redux-form";
import { useAuth } from "../../context/auth-context";
import AddressFields from "../addresses/AddressFields";
import useApi from "../api/useApi";
import CustomForm from "../customFields/CustomForm";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import {
  default as renderField,
  default as RenderField,
} from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import requireDashes from "../utils/requireDashes";
import CustomScaleLoader from "../utils/scaleLoader";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";

const ProjectForm = (props) => {
  const { user } = useAuth();

  const { internal, initialValues, estimated_value, adding } = props;

  const [warning, setWarning] = useState({});

  const { data: brandingThemes } = useApi("branding-themes", []);

  const [requiredInformation, setRequiredInformation] = useState(null);
  const [estimatedProjectValue, setEstimatedProjectValue] = useState(null);

  const { takeAction, loading } = useApi();

  const isPositionAdmin = user.is_position_admin;

  const { data: orgStandardJobTypes } = useApi(
    "organisations-standard-job-types",
    [],
  );
  const [hasJobTypeProjectForm, setHasJobTypeProjectForm] = useState(false);

  useEffect(() => {
    if (orgStandardJobTypes) {
      setHasJobTypeProjectForm(
        !!orgStandardJobTypes.find(
          (jobType) => jobType.job_type_type === "App\\Models\\ProjectForm",
        ),
      );
    }
  }, [orgStandardJobTypes]);

  const [isNewRecord, setIsNewRecord] = useState(true);
  useEffect(() => {
    if (initialValues.id) {
      setIsNewRecord(false);
    }
  }, [initialValues]);

  const updateInformation = (estimatedValue) => {
    setEstimatedProjectValue(estimatedValue);
    takeAction(
      "show",
      `estimated-value-project-information?estimated_value=${estimatedValue}${
        initialValues?.uuid ? `&project_uuid=${initialValues.uuid}` : ""
      }`,
    ).then(({ data }) => {
      setRequiredInformation(data.data);
    });
  };

  useEffect(() => {
    if (initialValues?.estimated_value) {
      updateInformation(initialValues.estimated_value);
      setEstimatedProjectValue(initialValues.estimated_value);
    }
  }, []);

  const warnName = () => {
    if (warning.name) {
      return (
        <small>
          The project{" "}
          <Link to={`/${warning.name.link}`}>{warning.name.name}</Link> has a
          similar name
        </small>
      );
    }

    return undefined;
  };

  const checkProjectName = (e, value) => {
    if (!value) return;

    return takeAction("store", "projects/validation", {
      validate: "name",
      value,
    }).then(({ data }) => {
      setWarning({ ...warning, ...data.data });
    });
  };

  return (
    <div className="row">
      {user?.hasAccessTo("App\\Models\\Project", "edit_number") && (
        <>
          <div className="form-group col-lg-6">
            <Field component={renderField} name="prefix" label="Prefix" />
            <small>
              Leave prefix and number blank to have number automatically created
              from your organisation settings
            </small>
          </div>
          <div className="form-group col-lg-6">
            <Field
              component={renderField}
              name="numeral_number"
              label="Project Number"
            />
          </div>
        </>
      )}
      <div className="form-group col-lg-12">
        <Field
          name="name"
          type="text"
          component={renderField}
          required
          label="Project Name"
          validate={required}
          onBlur={checkProjectName}
        />
        {warnName()}
      </div>

      <div className="form-group col-lg-2">
        <Field
          name="internal"
          component={renderToggleInput}
          label="Internal"
          link={
            <a
              href="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/internal-and-confidential-projects/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle tx-inverse" />
            </a>
          }
        />
      </div>

      <div className="col-lg-2 form-group align-items-center">
        <Field
          name="is_confidential"
          component={renderToggleInput}
          label="Confidential"
          link={
            <a
              href="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/internal-and-confidential-projects/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-info-circle tx-inverse" />
            </a>
          }
        />
      </div>

      {!internal && (
        <>
          <div className="form-group col-lg-4">
            <Field
              name="client_id"
              component={SelectInputAsync}
              url="/organisations/clients"
              required
              label="Client"
              validate={required}
            />
          </div>
          <div className="form-group col-lg-4">
            <Field name="care_of" component={RenderField} label="Care Of" />
          </div>
          <div className="form-group col-lg-4">
            <Field
              name="industry_id"
              component={SelectInputAsync}
              required
              label="Industry"
              validate={required}
              url={`/react-select?select=id,name&label=name&value=id&model=${encodeURIComponent(
                "App\\Models\\Industry",
              )}`}
            />
          </div>
          {brandingThemes.length > 0 ? (
            <div className="form-group col-12">
              <Field
                component={SelectInput}
                options={brandingThemes.map((theme) => ({
                  label: theme.name,
                  value: theme.id,
                }))}
                name="branding_theme_id"
                label="Branding Theme"
              />
              <small>If left blank will use organisation branding</small>
            </div>
          ) : null}
        </>
      )}

      <div className="form-group col-lg-4">
        <Field
          name="project_manager"
          component={SelectInputAsync}
          url="/users/projectmanagers"
          required
          label="Project Manager"
          validate={required}
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="team_members"
          component={SelectInputAsync}
          url="/users/staff"
          label="Team Members"
          multiple
        />
      </div>

      {!internal && (
        <>
          <div className="form-group col-lg-4">
            <Field
              name="purchase_order"
              component={renderField}
              label="Purchase Order"
              required
              validate={requireDashes}
            />
          </div>
          {(isPositionAdmin || !initialValues?.uuid) && (
            <div className="form-group col-lg-4">
              <Field
                name="estimated_value"
                required
                validate={required}
                component={renderField}
                label="Estimated Project Value"
                onBlur={() => updateInformation(estimated_value)}
                {...money}
              />
            </div>
          )}

          {/*
          Comment out until ready to roll out
           {isNewRecord && hasJobTypeProjectForm && (
            <ProjectFormFields
              {...props}
              estimatedProjectValue={estimatedProjectValue}
            />
          )} */}
        </>
      )}

      <div className="form-group col-lg-12">
        <Field
          name="description"
          type="textarea"
          textarea
          rows={3}
          cols={64}
          component={renderField}
          label="Notes"
          required
          validate={requireDashes}
        />
      </div>

      <div className={`form-group ${adding ? "col-lg-6" : "col-lg-4"}`}>
        <Field
          name="scheduled_start_date"
          type="date"
          component={renderField}
          label="Start Date of Work"
          required
          validate={required}
          extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
        />
      </div>

      <div className={`form-group ${adding ? "col-lg-6" : "col-lg-4"}`}>
        <Field
          name="scheduled_finish_date"
          type="date"
          component={renderField}
          label="Expected Finish Date of Work"
          required
          validate={required}
          extraProps={{ min: "1900-01-01", max: "2999-12-31" }}
        />
      </div>

      {!adding && (
        <div className="form-group col-lg-4">
          <Field
            name="status"
            component={SelectInputAsync}
            required
            options={[
              { label: "Open", value: "0" },
              { label: "Closed", value: "1" },
            ]}
            label="Status"
            validate={required}
          />
        </div>
      )}

      {!internal && (
        <AddressFields streetAddress="Project Street Address" {...props} />
      )}

      {loading && (
        <div className="col-12">
          <CustomScaleLoader>
            Fetching Extra Information Required...
          </CustomScaleLoader>
        </div>
      )}
      {requiredInformation && (
        <>
          <FormHeader>Extra Information Required</FormHeader>
          <CustomForm
            customForm={requiredInformation?.custom_fields}
            {...props}
          />
        </>
      )}

      <div className="form-layout-footer form-group col-lg-12">
        <SubmitButton {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    internal: selector(state, "internal"),
    estimated_value: selector(state, "estimated_value"),
  };
};

export default connect(mapStateToProps, {})(ProjectForm);
