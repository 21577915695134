import { useParams } from "react-router";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import ReactTable from "../table/ReactTable";
import DocsModal from "../utils/DocsModal";

const RequestedLeave = () => {
  const { userId } = useParams<{ userId: string }>();

  const { data, loading } = useApi(`users/${userId}/requested-leave`, [], true);

  const columns = [
    {
      accessorKey: "period_string",
      header: "Date/s",
      cell: (info: any) => (
        <Link to={info.row.original.link}>{info.getValue()}</Link>
      ),
      sort: true,
    },
    {
      accessorKey: "reason.reason",
      header: "Leave Reason",
    },
    {
      accessorKey: "total_hours",
      header: "Total Hours Requested",
    },
  ];

  return (
    <>
      <ReactTable columns={columns} data={data ?? []} loading={loading} />
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/leave/" />
    </>
  );
};

export default RequestedLeave;
