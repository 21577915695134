import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import DetailsForm from "./DetailsForm";

const ShowPage = (props) => {
  const { page, uuid } = useParams();
  const { user } = useAuth();
  const { deliverable } = props;
  const { document } = deliverable.current_revision;

  switch (page) {
    case "deliverable":
      return (
        <ProfileSingleFile
          disableUpload
          link={document?.link}
          mime_type={document?.mime_type}
        />
      );
    case "details":
      return <DetailsForm {...props} initialValues={deliverable} />;
    case "documents":
      return (
        <>
          <p>Upload extra files to be sent to the client.</p>
          <ProfileDocuments
            {...props}
            model="App\Models\Deliverable"
            documentableId={uuid}
            documentPath={`organisations/${user.active_organisation.uuid}/documents/deliverables/${uuid}`}
            homeUrl={`projects/${uuid}/documents`}
            noFolders
          />
        </>
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
