import React from "react";
import ProjectTable from "./ProjectTable";
import Add from "./Add";
import DocsModal from "../utils/DocsModal";

const Page = props => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/adding-an-invoice/" />
      <Add {...props} />
      <ProjectTable {...props} />
    </>
  );
};

export default Page;
