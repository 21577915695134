import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const EmploymentDetailsProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const {
    data: employmentDetails,
    setData: setEmploymentDetails,
    loading,
  } = useApi(`staffroles/${uuid}`, null, true, "/employment-details");

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={employmentDetails.name}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          { link: "employment-details", name: "Staff Roles" },
          {
            link: `employment-details/${employmentDetails.uuid}`,
            name: employmentDetails.name,
            active: true,
          },
        ]}
      />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{employmentDetails.name}</DashboardH3>
          </DashboardHeader>
        }
        content={
          <ShowPage
            employmentDetails={employmentDetails}
            setEmploymentDetails={setEmploymentDetails}
          />
        }
        tabs={tabs(uuid)}
      />
    </>
  );
};

const tabs = (uuid: string): ProfileTab<"details" | "holds">[] => {
  return [
    {
      page: "details",
      label: "Details",
      link: `/employment-details/${uuid}/details`,
    },
    {
      page: "holds",
      label: "Holds",
      link: `/employment-details/${uuid}/holds`,
    },
  ];
};

export default EmploymentDetailsProfile;
