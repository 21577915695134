import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, Form, InjectedFormProps, reduxForm, submit } from "redux-form";
import { approvalOptions as defaultOptions } from "../../data/approval_options";
import buttonGroup from "../utils/buttonGroup";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import { ApprovalOptions } from "./approvalTypes";

interface ApprovalModalProps {
  toggle: () => void;
  modal: boolean;
  validateComments?: boolean;
  approvalOptions?: {
    value: ApprovalOptions;
    label: string | "Approve" | "Redo" | "Deny";
  }[];
  dispatch?: Function;
  form?: string;
  canTransfer?: true;
}

const ApprovalModal: React.FC<
  ApprovalModalProps & InjectedFormProps<{}, ApprovalModalProps>
> = (props) => {
  const {
    toggle,
    modal,
    handleSubmit,
    dispatch,
    validateComments = false,
    approvalOptions = defaultOptions,
    form = "ApprovalModal",
  } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="modal-dialog modal-lg wd-md-600"
      data-keyboard="false"
      form={form}
    >
      <ModalHeader toggle={toggle}>Update Approval</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-12">
              <Field
                component={buttonGroup}
                name={"approval_status"}
                options={approvalOptions}
                groupClass="w-100"
                buttonClass="w-100"
                validate={required}
              />
            </div>
            <div className="form-group col-12">
              <Field
                label="Remarks"
                component={renderField}
                name="approval_remarks"
                textarea
                validate={validateComments ? required : null}
              />
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex">
        <SubmitButton
          onClick={() => dispatch?.(submit(form))}
          {...props}
          save="Update Approval"
          saving="Updating..."
          className="ms-3"
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm<{}, ApprovalModalProps>({});

export default form(ApprovalModal);
