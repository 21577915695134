import dayjs from "dayjs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { EmploymentDetail } from "../employmentDetails/employmentDetailTypes";
import { money } from "../form/formatters";
import BottomLogo from "../utils/BottomLogo";
import CustomScaleLoader from "../utils/scaleLoader";

const UsersStaffRoles = () => {
  const { userId } = useParams<{ userId: string }>();
  const { user } = useAuth();

  const { data, loading }: IUseApi<EmploymentDetail[]> = useApi(
    `users/${userId}/staff-roles`,
    [],
    true,
  );

  const payUnits: string[] = ["", "per Hour", "per Annum"];

  if (loading) {
    return (
      <CustomScaleLoader>Fetching Employment Details...</CustomScaleLoader>
    );
  }

  return (
    <div className="space-y-3">
      {data?.map((staffRole) => {
        return (
          <div className="bg-white border shadow-sm position-relative hover-no-shadow">
            <Link to={user?.is_admin ? staffRole.link : "#"}>
              <div className="p-3">
                <p className="mb-3 fw-bolder tx-inverse tx-16">
                  {staffRole.role_branch}
                </p>
                <p className="mb-1 text-secondary">
                  {staffRole.employment_basis} -{" "}
                  <span className="tx-inverse">
                    {money.format(staffRole.remuneration)}{" "}
                  </span>
                  {payUnits[staffRole.units]}
                </p>
                <p className="mb-0 text-secondary">
                  {staffRole.award_abbreviation} Award
                </p>
                <div className="d-flex space-x-1 align-items-center">
                  {staffRole.start_date && (
                    <p className="mb-1 text-secondary">
                      Starting on the{" "}
                      <span className="tx-inverse fw-bolder">
                        {dayjs(staffRole.start_date).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  )}
                  {staffRole.finish_date && (
                    <p className="mb-1 text-secondary">
                      - Ending on the{" "}
                      <span className="tx-inverse fw-bolder">
                        {dayjs(staffRole.finish_date).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  )}
                  <div className="ms-auto">
                    <div
                      className={`bg-${
                        staffRole.is_active ? "success" : "secondary"
                      } shadow rounded-pill text-white tx-10 text-center px-2`}
                    >
                      {staffRole.is_active ? "Active" : "Finished"}
                    </div>
                  </div>
                </div>
                <BottomLogo />
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default UsersStaffRoles;
