import { AiOutlineClose } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import { toast } from "react-toastify";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { LeaveReasonToSync } from "./SyncLeaveReasons";

interface EmploymentHeroLeaveCategoryCardProps {
  leaveReason: LeaveReasonToSync;
  leaveReasons: LeaveReasonToSync[];
  setLeaveReasons: (leaveReasons: LeaveReasonToSync[]) => void;
}

const EmploymentHeroLeaveCategoryCard = ({
  leaveReason,
  leaveReasons,
  setLeaveReasons,
}: EmploymentHeroLeaveCategoryCardProps) => {
  const { takeAction, loading }: IUseApi = useApi();

  const filterLeaveReasons = () => {
    return leaveReasons.filter((u) => u.uuid !== leaveReason.uuid);
  };

  const createLeaveReason = () => {
    return takeAction(
      "store",
      `integrations/employment-hero/leavecategories/sync/${leaveReason.uuid}`,
    )
      .then(() => {
        toast.success(`${leaveReason.name} synced to Employment Hero`);
        setLeaveReasons(filterLeaveReasons());
      })
      .catch(errorSwal);
  };

  const syncLeaveReason = () => {
    return takeAction(
      "update",
      `integrations/employment-hero/leavecategories/sync/${leaveReason.uuid}`,
      {
        employment_hero_id: leaveReason.employment_hero_leave_reason?.id,
      },
    )
      .then(() => {
        toast.success(`${leaveReason.name} synced to Employment Hero`);
        setLeaveReasons(filterLeaveReasons());
      })
      .catch(errorSwal);
  };

  return (
    <div className="mb-3 col-lg-6">
      <div className="bg-white border rounded-lg">
        <div className="p-3">
          <div className="mb-1 d-flex">
            <p className="mb-0 tx-inverse fw-bolder">{leaveReason.name}</p>
            <p className="mb-0 ms-auto">
              {leaveReason?.employment_hero_leave_reason ? (
                <FiCheck className="tx-success" />
              ) : (
                <AiOutlineClose className="tx-danger" />
              )}
            </p>
          </div>
          <p className="mb-0">{leaveReason.name}</p>
          {leaveReason?.employment_hero_leave_reason ? (
            <div className="mt-3 mb-0 tx-12">
              Employment Hero name:{" "}
              <span className="tx-inverse">
                {leaveReason.employment_hero_leave_reason.name}
              </span>
            </div>
          ) : null}
        </div>
        <button
          type="button"
          disabled={loading}
          onClick={() => {
            if (leaveReason?.employment_hero_leave_reason) {
              syncLeaveReason();
              return;
            }
            createLeaveReason();
          }}
          className={`p-3 border-top border-0 tx-inverse bg-light rounded-bottom w-100 text-start`}
        >
          {isSubmitting(
            loading,
            leaveReason?.employment_hero_leave_reason
              ? `Sync to ${leaveReason?.employment_hero_leave_reason.name}`
              : "Create new Employment Hero leave category",
            "Syncing...",
          )}
        </button>
      </div>
    </div>
  );
};

export default EmploymentHeroLeaveCategoryCard;
