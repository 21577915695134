import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import { Job, JobStatus } from "./jobTypes";

const DeleteJobButton = ({ job }: { job: Job }) => {
  const { user } = useAuth();

  const { takeAction, loading } = useApi();

  const history = useHistory();

  const deleteJob = () => {
    return deleteSwal(job.name)
      .then(() => takeAction("destroy", `projectjobs/${job.uuid}`))
      .then(() => {
        toast.success("Job Deleted");
        history.push("/jobs");
      })
      .catch(errorSwal);
  };

  if (!user?.hasAccessTo("App\\Models\\ProjectJob", "delete")) {
    return null;
  }

  if (job.deliverables_count !== 0 || job.status !== JobStatus.Pending) {
    return null;
  }

  return (
    <Button
      onClick={deleteJob}
      disabled={loading}
      outline
      color="danger"
      block
      className="mt-3"
    >
      {isSubmitting(loading, "Delete", "Deleting...")}
    </Button>
  );
};

export default DeleteJobButton;
