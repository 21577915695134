import React from "react";
import { toast } from "react-toastify";
import { initialize } from "redux-form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import FormPosition from "./Form";

const EditPosition = (props) => {
  const { takeAction } = useApi();

  const { position, setPosition, match } = props;

  const onSubmit = (values, dispatch) => {
    return takeAction(
      "update",
      `/organisation-positions/${match.params.uuid}`,
      {
        ...values,
        checks_productivity: values.checks_productivity ?? false,
      },
    )
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        setPosition(data.data);
        dispatch(initialize("Position", data.data));
      })
      .catch(errorSwal);
  };

  return (
    <FormPosition
      {...props}
      position={position}
      setPosition={setPosition}
      initialValues={position}
      onSubmit={onSubmit}
    />
  );
};

export default EditPosition;
