import { Link, useParams } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import useApi from "../api/useApi";
import ChatModal from "../chat/ChatModal";
import UnpaidAmountBadges from "../clients/UnpaidAmountBadges";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import { AiOutlineSync } from "react-icons/ai";
import TemplateCopyButton from "./TemplateCopyButton";

const ProjectHeader = ({ project }) => {
  const { number } = useParams();

  const externalLinks = project.external_links?.filter((link) => link.url);

  const { data: hazardReportQuestions } = useApi(`hazard-report-questions`, []);

  const getStatus = (status) => {
    switch (parseInt(status)) {
      case 0:
        return (
          <span className="badge mg-t-10 bg-info rounded-pill shadow">
            Open
          </span>
        );
      case 1:
        return (
          <span className="badge mg-t-10 bg-secondary rounded-pill shadow">
            Closed
          </span>
        );
    }
  };
  return (
    <DashboardHeader
      model={{
        type: "App\\Models\\Project",
        id: project.id,
      }}
      extraButtons={
        <>
          {hazardReportQuestions.length > 0 && (
            <Link to={`/hazard-reports/project/${project.id}/add`}>
              Add Take 5
            </Link>
          )}
          <ChatModal
            modelId={project.id}
            postUrl="projects/chat"
            modelType="App\Models\Project"
          />
        </>
      }
    >
      <div>
        <div className="d-flex mb-3">
          <div>
            <p className="mb-0 tx-16">{project.number}</p>
            <DashboardH3 className="mb-0 tx-24 text-dark">
              {project.name}
            </DashboardH3>
          </div>
          <div className="ms-auto space-x-2 d-flex align-items-center">
            <TemplateCopyButton project={project} />
            <ExternalLinks
              wrapperClass="d-md-flex d-none space-x-2"
              links={externalLinks}
            />
          </div>
        </div>
        <UnpaidAmountBadges
          unpaid={project.total_unpaid}
          overdue={project.total_overdue}
          link={`/projects/${number}/invoices`}
        />
      </div>

      <div className="d-flex">
        {project.internal ? (
          <DashboardSubTitle>Internal Project</DashboardSubTitle>
        ) : (
          <div>
            <DashboardSubTitle>
              Client:{" "}
              <Link className="text-secondary" to={project.client.link}>
                {project.client.display_name}{" "}
              </Link>
              {project.client.hold && <Badge color="danger">On Hold</Badge>}
            </DashboardSubTitle>
            <DashboardSubTitle>
              Address:{" "}
              {project.formatted_address != "Not Provided" ? (
                <a
                  target="__blank"
                  className="text-secondary"
                  href={`http://maps.google.com/maps?q=${project.formatted_address}`}
                >
                  {project.formatted_address}
                </a>
              ) : (
                project.formatted_address
              )}
            </DashboardSubTitle>
          </div>
        )}
      </div>

      <DashboardSubTitle>
        {project.tender && project.tender.job && (
          <div className="card-profile-position">
            Tender:{"   "}
            <Link to={project.tender.link}>{project.tender.name} </Link>
          </div>
        )}
      </DashboardSubTitle>
      <div className="d-flex align-items-end space-x-3">
        {getStatus(project.status)}
      </div>
    </DashboardHeader>
  );
};

const ExternalLinks = ({ links }) => {
  const hasTeams = links?.filter((link) => link.name === "Teams").length > 0;

  const { number } = useParams();

  const { takeAction, loading } = useApi();

  if (!links) return null;

  if (links.length === 0) return null;

  return (
    <>
      {links.map((link) => (
        <>
          <a
            id={link.name}
            key={link.url}
            href={link.url}
            target="__blank"
            className="text-left"
            rel="noopener noreferrer"
          >
            <img
              height="25"
              style={{ width: "25px" }}
              src={link.icon}
              alt={link.name}
            />
          </a>
          <UncontrolledTooltip target={link.name}>
            Open in {link.name}
          </UncontrolledTooltip>
        </>
      ))}
      {hasTeams && (
        <>
          <Button
            onClick={() => {
              takeAction("show", `projects/${number}/teams-sync`).then(
                ({ data }) => {
                  location.href = data.data;
                },
              );
            }}
            color="link"
            className={`p-0 ${loading ? "spin" : ""}`}
            disabled={loading}
            id="sync-teams"
          >
            <AiOutlineSync className="tx-18" />
          </Button>
          <UncontrolledTooltip target="sync-teams">
            Sync in OneDrive
          </UncontrolledTooltip>
        </>
      )}
    </>
  );
};

export default ProjectHeader;
