import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import Fields from "../purchases/Fields";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const Edit = (props) => {
  const { handleSubmit, setPurchaseOrder, purchaseOrder, includesGst } = props;

  const { takeAction } = useApi();

  const includesGstChanged = purchaseOrder.includes_gst != includesGst;

  const submit = (values, _, props) => {
    return takeAction(
      "update",
      `/purchase-orders/${purchaseOrder.uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success("Purchase Order updated!");
        setPurchaseOrder(data.data);
        props.initialize(data.data);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <Fields
          purchaseOrder
          {...props}
          setItem={setPurchaseOrder}
          item={purchaseOrder}
          disabled={purchaseOrder.sent_at}
        />
        {includesGstChanged && (
          <div className="form-group col-12">
            <Field
              component={RenderField}
              name="includes_gst_change_reason"
              label="Reason for GST Change"
              textarea
              validate={required}
              required
            />
          </div>
        )}
        {(!purchaseOrder.sent_at || includesGstChanged) && (
          <div className="form-group col-12">
            <SubmitButton {...props} />
          </div>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "EditPurchaseOrder",
});

const mapStateToProps = (state) => {
  const selector = formValueSelector("EditPurchaseOrder");

  return {
    includesGst: selector(state, "includes_gst"),
  };
};

export default connect(mapStateToProps, {})(form(Edit));
