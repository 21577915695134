import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import formatMoney from "../utils/formatMoney";
import InvoiceBadge from "./InvoiceBadge";
import ExpectedPaymentDate from "./ExpectedPaymentDate";
import AverageDaysToPay from "./AverageDaysToPay";
import InvoiceDate from "./InvoiceDate";

const ProfileHeader = ({ invoice, setInvoice }) => (
  <DashboardHeader>
    <DashboardH3>{invoice.name}</DashboardH3>
    <DashboardSubTitle>
      Client: <Link to={invoice.client.link}>{invoice.client.name}</Link>
    </DashboardSubTitle>
    <DashboardSubTitle className="mb-2">
      Project:{" "}
      <Link to={`/projects/${invoice.project.uuid}/invoices`}>
        {invoice.project.name}
      </Link>
    </DashboardSubTitle>
    <InvoiceDate invoice={invoice} setInvoice={setInvoice} />
    <ExpectedPaymentDate invoice={invoice} setInvoice={setInvoice} />
    {invoice.created_by && (
      <DashboardSubTitle className="mb-2">
        Created By:{" "}
        <a href={`mailto:${invoice.created_by.email}`}>
          {invoice.created_by.name}
        </a>
        <br />
        Created At:{" "}
        <span className="fw-bolder">
          {dayjs(invoice.created_by.created_at).format("DD/MM/YYYY")}
        </span>
      </DashboardSubTitle>
    )}
    <AverageDaysToPay resource={invoice.client} />
    <DashboardSubTitle className="mg-t-10 ">
      <InvoiceBadge invoice={invoice} />
    </DashboardSubTitle>

    {invoice.amount_paid !== null &&
      invoice.amount_paid !== 0 &&
      !invoice.paid_at && (
        <DashboardSubTitle className="mg-t-10 ">
          <Badge color="info">
            ${formatMoney(invoice.amount_paid)} of $
            {formatMoney(invoice.total_due * 1.1)} Paid
          </Badge>
        </DashboardSubTitle>
      )}
  </DashboardHeader>
);

export default ProfileHeader;
