import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { Link } from "react-router-dom";
import theme from "./theme";
import axios from "../api/api";

class Search extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      value: "",
      suggestions: [],
    };

    this.lastRequestId = null;
  }

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }

    this.setState({ isLoading: true });

    this.lastRequestId = setTimeout(() => {
      this.fetchSearch(value);
    }, 500);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  fetchSearch(keyword) {
    axios.get(`/search?q=${keyword}`).then(({ data }) => {
      this.setState({
        isLoading: false,
        suggestions: data,
      });
    });
  }

  render() {
    const { value, suggestions, isLoading } = this.state;
    const inputProps = {
      placeholder: "Search...",
      value,
      onChange: this.onChange,
    };
    const status = isLoading ? "fa fa-spinner fa-spin" : "fa fa-search";

    return (
      <div className="search-box shadow-sm d-flex">
        <Autosuggest
          className="form-control"
          onSuggestionSelected={() => {
            this.setState({ value: "" });
          }}
          multiSection={true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          inputProps={inputProps}
          theme={theme}
        />

        <button className="btn btn-primary">
          <i className={status} />
        </button>
      </div>
    );
  }
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  if (suggestion.link_type === "document") {
    return (
      <>
        <div
          className="d-flex flex-row flex-fill"
          style={{
            padding: "8px 20px",
          }}
        >
          <div className="w-50 me-2">{suggestion.name}</div>
          <div className="d-flex flex-row flex-fill">
            <div className="d-block w-100">
              <a
                className=""
                target="_blank"
                rel="noopener noreferrer"
                href={suggestion.link}
              >
                Download
              </a>
            </div>
            <div className="d-block w-100">
              <Link
                to={
                  suggestion.folder_uuid
                    ? `/documents/${suggestion.folder_uuid}`
                    : "/documents"
                }
              >
                Open Folder
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Link
      className="w-100"
      style={{
        padding: "8px 20px",
      }}
      to={suggestion.link}
    >
      {suggestion.name}
    </Link>
  );
}

function renderSectionTitle(section) {
  if (section.results.length > 0) {
    return (
      <strong>
        <Link to="/projects">{section.title.toUpperCase()}</Link>
      </strong>
    );
  }
}

function getSectionSuggestions(section) {
  return (
    section?.results ?? {
      title: "",
      results: [],
    }
  );
}

function shouldRenderSuggestions(value) {
  return value.trim().length > 2;
}

export default Search;
