import { toast } from "react-toastify";
import HeaderPage from "../header/HeaderPage";
import axios from "../api/api";
import formError from "../utils/formError";
import ConfidentialTenderNotification from "./ConfidentialTenderNotification";
import AddTenderForm from "./AddTenderForm";
import { useParams } from "react-router-dom";

const Add = (props) => {
  const { history, match } = props;
  const { projectUuid } = useParams();

  const submit = (values) => {
    const data = {
      ...values,
      project_uuid: projectUuid,
    };

    return axios
      .post(`/tenders`, data)
      .then(({ data }) => {
        toast.success("Tender created successfully");
        return history.push(`/tenders/${data.data.uuid}/details`);
      })
      .catch(formError);
  };
  
  return (
    <>
      <HeaderPage
        titlePage="Add New Tender"
        crumbs={[
          { link: "/jobs", name: "Jobs" },
          { link: "/jobs/create", name: "Create New Job" },
          { link: "", name: "Add New Tender", active: true },
        ]}
      />
      <div className="mb-4">
        <ConfidentialTenderNotification />
      </div>
      <AddTenderForm onSubmit={submit} form="AddTender" showProject={!projectUuid} />
    </>
  );
};

export default Add;
