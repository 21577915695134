import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Field, reduxForm, reset } from "redux-form";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import SelectInput from "../form/SelectInput";
import errorSwal from "../utils/errorSwal";
import required from "../utils/required";
import isSubmitting from "../utils/submitting";

const ItemForm = (props) => {
  const { handleSubmit, setInvoice, invoice, uninvoicedDeliverables } = props;
  const [deliverables, setDeliverables] = useState([]);
  const { takeAction } = useApi();
  const { user } = useAuth();

  const invoiceDeliverableIds = invoice.deliverables.map((d) => d.id);

  const filteredDeliverables = uninvoicedDeliverables
    .filter((d) => !invoiceDeliverableIds.includes(d.id))
    .map((d) => ({
      value: d.id,
      label: `${d.job.name} - ${d.name}`,
    }));

  const all = (status) => {
    props.change("deliverable_ids", status ? filteredDeliverables : "");
  };

  const submit = (values, dispatch) => {
    if (invoice?.integration_estimation?.length > 0 && !user.is_admin) {
      return swal({
        title: "Unable to edit",
        text: "It looks like this invoice is out of sync with your accounting integration, please ask an admin to sync up before continuing.",
        icon: "warning",
      });
    }

    return takeAction("store", `invoices/${invoice.uuid}/items`, {
      ...values,
      disable_sync: invoice?.integration_estimation?.length > 0,
    })
      .then(({ data }) => {
        setInvoice(data.data);
        toast.success("Deliverable Added");
        dispatch(reset("InvoiceItem"));
      })
      .catch((err) => catchError(err, invoice));
  };

  if (uninvoicedDeliverables.length === 0 || invoice.requested_at) return null;

  if (filteredDeliverables.length == 0) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row d-flex">
        <div className="d-flex align-items-end form-group">
          <div className="w-75">
            <Field
              name="deliverable_ids"
              label="Add Deliverables"
              component={SelectInput}
              options={filteredDeliverables}
              placeholder="Deliverable"
              multiple
              isMulti
              validate={required}
              extraProps={{ style: { zIndex: "1000" } }}
            />
            {filteredDeliverables.length === 0 && (
              <small>No deliverables found.</small>
            )}
          </div>
          <Button
            disabled={props.submitting}
            outline={!props.valid}
            className="ms-3 w-25"
            color="primary"
          >
            {isSubmitting(props.submitting, "Add", "Adding...")}
          </Button>
        </div>
        <div className="col-lg-6 form-group">
          <button
            type="button"
            onClick={() => all(true)}
            className="btn btn-outline-secondary w-100"
          >
            Add All
          </button>
        </div>
        <div className="col-lg-6 form-group">
          <button
            type="button"
            onClick={() => all(false)}
            className="btn btn-outline-secondary w-100"
          >
            Remove All
          </button>
        </div>
      </div>
    </form>
  );
};

const catchError = (err, invoice) => {
  if (err.response?.data?.errors?.jobs_incomplete) {
    const el = document.createElement("div");

    err.response.data.errors.jobs_incomplete.forEach((job) => {
      el.innerHTML += `<p class="tx-inverse"><a href=${window.location.origin}${job.link} target="_blank" rel="noopener noreferrer">${job.name}</a> incomplete.</p>`;
    });

    el.innerHTML += `<p class="tx-inverse">Unable to add deliverables.</p>`;
    return swal({
      icon: "warning",
      title: "Incomplete Jobs",
      content: el,
    });
  }

  if (err.response?.data?.errors?.incorrect_deliverables) {
    const el = document.createElement("div");

    err.response.data.errors.incorrect_deliverables.forEach((deliverable) => {
      el.innerHTML += `<p class="tx-inverse"><a href=${window.location.origin}${deliverable.link} target="_blank" rel="noopener noreferrer">${deliverable.name}</a> has the wrong pricing.</p>`;
    });

    el.innerHTML += `<p class="tx-inverse">Please ensure all pricing is ${
      invoice.is_credit_note ? "negative" : "positive"
    }.</p>`;
    el.innerHTML += `<p class="tx-inverse">Unable to add deliverables.</p>`;

    return swal({
      icon: "warning",
      title: "Incorrect Pricing",
      content: el,
    });
  }

  return errorSwal(err);
};

const form = reduxForm({ form: "InvoiceItem" });
export default form(ItemForm);
