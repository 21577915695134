import { useParams } from "react-router-dom";
import Details from "./Details";
import { MicrosoftIntegrationPages, MicrosoftProps } from "./microsoftTypes";
import PositionsToSync from "./PositionsToSync";
import SyncUsers from "./SyncUsers";

const ShowMicrosoftPage = (props: MicrosoftProps) => {
  const { page } = useParams<{ page: MicrosoftIntegrationPages }>();

  switch (page) {
    case "details":
      return <Details initialValues={props.microsoft} />;
    case "positions-to-sync":
      return <PositionsToSync {...props} />;
    case "sync-users":
      return <SyncUsers />;
    default:
      return null;
  }
};

export default ShowMicrosoftPage;
