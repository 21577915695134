import React from "react";
import Toggle from "react-toggle";
import FieldInformationPopOver from "./FieldInformationPopOver";

export const renderToggleInput = (field) => {
  const {
    meta: { touched, error },
    label,
    required,
  } = field;

  return (
    <>
      {label && (
        <div className="d-flex space-x-1">
          <label
            htmlFor={field.input.name}
            className="form-control-label d-block tx-inverse tx-semibold"
          >
            {label}
          </label>
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver {...field} />
        </div>
      )}
      <Toggle
        aria-label={field.ariaLabel}
        checked={field.input.value ? true : false}
        onChange={field.input.onChange}
        icons={true}
        disabled={field.disabled ? field.disabled : false}
        id={field.input.name}
      />
      {touched && error && (
        <span className="parsley-errors-list d-block">{error}</span>
      )}
    </>
  );
};
export default renderToggleInput;
