import { useState, useEffect } from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsLocalizer from "../utils/dayjsLocalizer";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import useProjectCalendarJobs from "./hooks/useProjectCalendarJobs";
import { useHistory, useParams } from "react-router-dom";

dayjs.locale("en");
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const localizer = dayjsLocalizer(dayjs);

const ProjectJobCalendar = () => {
  const { takeAction, loading } = useApi();
  const [dateRange, setDateRange] = useState({
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const history = useHistory();
  const { number: uuid } = useParams();

  const {
    data: jobs,
    status,
    error,
    isLoading,
    refetch,
  } = useProjectCalendarJobs(uuid, dateRange);

  useEffect(() => {
    refetch();
  }, [uuid, dateRange]);

  if (status === "error") {
    errorSwal(error);
  }

  if (isLoading || loading) {
    return <CustomScaleLoader>Fetching Jobs...</CustomScaleLoader>;
  }

  const formattedEvents =
    isLoading || error
      ? []
      : jobs.data.map((job) => {
          return {
            ...job,
            title: job.name,
            start: dayjs(job.scheduled_start_date).add("1", "day").toDate(),
            end: dayjs(job.scheduled_finish_date).add("1", "day").toDate(),
          };
        });

  const onEventResize = ({ event, start, end }) => {
    const values = {
      ...event,
      scheduled_start_date: dayjs(start)
        .subtract("1", "day")
        .format("YYYY-MM-DD"),
      scheduled_finish_date: dayjs(end)
        .subtract("1", "day")
        .format("YYYY-MM-DD"),
    };

    return takeAction("update", `projectjobs/${event.uuid}`, values)
      .then(() => {
        refetch();
        toast.success(`${event.full_name} updated successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <>
      {status === "error" ? (
        <span className="text-warning">
          Unable to fetch project jobs. Please try again.
        </span>
      ) : (
        <Calendar
          defaultDate={dateRange.start}
          date={dateRange.start}
          localizer={localizer}
          events={formattedEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "800px" }}
          views={["month", "week", "day"]}
          className="p-3 bg-white border"
          onSelectEvent={(e) => history.push(e.link)}
          onNavigate={(date, view) => {
            setDateRange({
              start: dayjs(date).startOf(view).format("YYYY-MM-DD"),
              end: dayjs(date).endOf(view).format("YYYY-MM-DD"),
            });
          }}
          onView={(view) => {
            setDateRange({
              start: dayjs(dateRange.start).startOf(view).format("YYYY-MM-DD"),
              end: dayjs(dateRange.start).endOf(view).format("YYYY-MM-DD"),
            });
          }}
          eventPropGetter={(event) => {
            return {
              className: `bg-${event.status_badge.color}`,
            };
          }}
          onEventDrop={onEventResize}
          onEventResize={onEventResize}
          resizable
          selectable
        />
      )}
    </>
  );
};

export default ProjectJobCalendar;
