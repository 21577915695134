import { useParams } from "react-router";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import {
  AdministrationLevels,
  AuthUser,
  GroupType,
  Tab as ITab,
} from "./userTypes";
import { useEffect, useState } from "react";

const UserProfileTabs = ({ user }: { user: AuthUser }) => {
  const { page, userId } = useParams<any>();

  const { user: authUser } = useAuth();

  const { data } = useApi("integrations/microsoft", null, false, null, false);

  const isAdmin = authUser?.is_admin;

  const isIT = authUser?.groups.includes(GroupType.IT);

  const isBasic =
    authUser?.position_administration_level == AdministrationLevels.Basic &&
    !isAdmin;

  const isCurrentUser = authUser?.id === user?.id;

  const showAdminTwoFactor =
    user?.has_mfa && !isCurrentUser && (isAdmin || isIT);

  const [tabs, setTabs] = useState<Array<ITab>>([
    {
      link: `/users/${userId}/profile`,
      page: "profile",
      icon: "fa fa-pencil",
      label: "Details",
    },
    {
      link: `/users/${userId}/documents`,
      page: "documents",
      icon: "fa fa-pencil",
      label: "Documents",
    },
    {
      link: `/users/${userId}/timesheets/add`,
      page: "timesheets",
      icon: "fa fa-clock-o",
      label: "Timesheets",
    },
    {
      link: `/users/${userId}/requested-leave`,
      page: "requested-leave",
      icon: "fa fa-pencil",
      label: "Requested Leave",
    },
    {
      link: `/users/${userId}/pending-approvals`,
      page: "pending-approvals",
      icon: "fa fa-check",
      label: "Pending Approvals",
    },
    {
      link: `/users/${userId}/employment-details`,
      page: "employment-details",
      icon: "fa fa-address-book",
      label: "Employment Details",
    },
    {
      link: `/users/${userId}/emails`,
      page: "emails",
      icon: "fa fa-windows",
      label: "Emails",
    },
    {
      link: `/users/${userId}/${showAdminTwoFactor ? "admin-mfa" : "mfa"}`,
      page: `${showAdminTwoFactor ? "admin-mfa" : "mfa"}`,
      icon: "fa fa-windows",
      label: "Two Factor Authentication",
    },
  ]);

  useEffect(() => {
    if (isCurrentUser || isAdmin) {
      setTabs((prev) => [
        ...prev,
        {
          link: `/users/${userId}/expense-claims`,
          page: "expense-claims",
          icon: "fa fa-money",
          label: "Expense Claims",
        },
      ]);
    }

    if (!isBasic) {
      setTabs((prev) => [
        ...prev,
        {
          link: `/project-manager/${userId}`,
          page: "project-manager",
          icon: "fa fa-building-o",
          label: "Project Manager Dashboard",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (data) {
      setTabs((prev) => [
        ...prev,
        {
          link: `/users/${userId}/microsoft`,
          page: "microsoft",
          icon: "fa fa-windows",
          label: "Microsoft",
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    // Set tabs for users in IT groups
    if (isIT && !isCurrentUser && !isAdmin) {
      const itTabs = tabs.filter(
        (tab) => tab.page === "admin-mfa" || tab.page === "microsoft",
      );
      setTabs(itTabs);
    }
  }, [user]);

  return (
    <TabList>
      {tabs.map((tab, idx) => (
        <Tab
          key={idx}
          link={tab.link}
          active={page === tab.page}
          icon={`${tab.icon} text-capitalize`}
        >
          {tab.label}
        </Tab>
      ))}
    </TabList>
  );
};
export default UserProfileTabs;
