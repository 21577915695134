import { ApprovalAction } from "./../approvals/approvalTypes";
import { Branch } from "./../branches/branchTypes";
import { ChartOfAccount } from "./../chartOfAccounts/chartOfAccountTypes";
import { BootstrapColour } from "./../utils/utilTypes";

interface IRelation {
  name: string;
  uuid: string;
}

export interface PurchaseItem {
  account?: any,
  over_budget_approval_action?: ApprovalAction;
  uuid?: string;
  quantity: number;
  cost?: number;
  id?: number;
  tests?: any;
  method?: string;
  purchase?: {
    name: string;
    link: string;
    project: IRelation;
    client: IRelation;
  };
  price?: number;
  branch_id?: number;
  chart_of_accounts_id?: number;
  budget?:
    | {
        total_cost: number;
        percentage_spent: number;
        date_range: string;
        description_array: [number, string, string];
      }
    | [];
  chart_of_account?: ChartOfAccount;
  branch?: Branch;
  description?: string;
}

export interface Purchase {
  id: number;
  uuid: string;
  user: {
    name: string;
  };
  date: string;
  created_at: string;
  name: string;
  link: string;
  billed_at?: string;
  project: IRelation;
  client: IRelation;
  supplier: IRelation;
  recurring_payment: boolean;
  archived_at?: string;
  ready_to_be_billed: boolean;
  realised_total_cost: number;
  invoice: {
    number?: string;
  };
  delivery_date: string;
  items: PurchaseItem[] | [];
  approved: boolean;
  is_purchase_order: boolean;
  description: boolean;
  planned_payment_date?: string;
  status: {
    text: string;
    color: BootstrapColour;
  };
}

export enum PurchaseStatus {
  Archived,
  ReadyToBeBilled,
  NotReadyToBeBilled,
  Billed,
}

export enum PurchaseGSTType {
  GSTIncluded,
  GSTExcluded,
  NA,
}

export enum PurchaseType {
  CreditCard,
  PurchaseOrder,
  RecurringPurchase,
}

export enum PurchaseArchiveStatus {
  Archived,
  NotArchived,
}
