import { useParams } from "react-router";
import CalibrationTable from "./CalibrationTable";
import PaginatedList from "../pagination/PaginatedList";
import useEquipmentCalibrations from "./hooks/useEquipmentCalibrations";

const EquipmentCalibrations = (props) => {
  const { uuid } = useParams();

  return (
    <PaginatedList
      indexHook={useEquipmentCalibrations}
      indexHookArguments={[uuid]}
      originalFilters={[]}
      list={({ data }) => (
        <div className="col-12">
          {data?.length > 0 && (
            <CalibrationTable
              calibrations={data}
              deliverables={data}
              useSelected={false}
            />
          )}
        </div>
      )}
    />
  );
};

export default EquipmentCalibrations;
