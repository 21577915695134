import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { Button } from "reactstrap";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import useApi from "../../api/useApi";
import HeaderPage from "../../header/HeaderPage";
import useFilter, { IFilter } from "../../hooks/useFilter";
import usePagination from "../../hooks/usePagination";
import useSort, { ISorter } from "../../hooks/useSort";
import flattenInfinitePages from "../../utils/flattenInfinitePages";
import useSampleListFilters from "./hooks/useSampleListFilters";
import useModal from "../../hooks/useModal";
import { Link } from "react-router-dom";
import errorSwal from "../../utils/errorSwal";
import SetHead from "../../utils/SetHead";
import useSamples from "./hooks/useSamples";
import SampleDumpModal from "./SampleDumpModal";
import SamplesListFilterModal from "./SamplesListFilterModal";
import { SampleIndex } from "./sampleTypes";
import List from "./List";
import TestStatusDump from "./TestStatusDump";

dayjs.extend(localizedFormat);

export type SampleListFilterKeys =
  | "project_id"
  | "client_id"
  | "branch_id"
  | "date";

export type SampleListSortKeys = "number";

export type SampleListFilter = IFilter<SampleListFilterKeys, number | boolean>;

const SampleList = () => {
  const { modal, toggle } = useModal();
  const { modal: filterModal, toggle: toggleFilterModal } = useModal();
  const { takeAction } = useApi();
  const { searchQuery, onSearch } = usePagination();
  const originalFilters = useSampleListFilters();

  const {
    sorters,
    toggleSorter,
    stringified: sortString,
  } = useSort(sampleSorters);

  const [dateFilter, setDateFilter] = useState<string[]>();

  const {
    stringified,
    filters,
    toggleFilter,
    filterCount,
    activeFilters,
    toggleMulti,
  } = useFilter<SampleListFilterKeys, number | boolean>(originalFilters);

  let queryString = searchQuery + stringified + sortString;

  if (dateFilter?.length === 2) {
    queryString += `&filter[date_between]=${dateFilter[0]},${dateFilter[1]}`;
  }

  const { data, isFetchingNextPage, fetchNextPage, isFetching } =
    useSamples(queryString);

  const generateDump = (data: any) => {
    if (parseInt(data.from) > parseInt(data.to)) {
      return toast.warning(
        "The end sample number must be greater than or equal to the start sample number.",
      );
    }

    return takeAction(
      "store",
      `samples/dump?${`&filter[search]=`.concat(
        searchQuery + stringified,
      )}&filter[number_between]=${data.from},${data.to}&sort=number`,
    )
      .then((res: any) => {
        fileDownload(
          res.data,
          `Sample-Export ${dayjs().format("DD-MM-YYYY, LT")}.csv`,
        );
        toast.success("Downloading File!");
        toggle();
      })
      .catch(errorSwal);
  };

  const flattenedData = flattenInfinitePages<SampleIndex>(data);

  return (
    <>
      <SetHead hTitle="Samples" />
      <HeaderPage
        titlePage="Samples"
        crumbs={[{ link: "samples", name: "Samples", active: true }]}
      />
      <div className="row mb-3">
        <div className="col text-end">
          <Button
            onClick={toggleFilterModal}
            outline
            size="sm"
            color="primary"
            className="mt-1"
          >
            Filters {filterCount > 0 ? ` (${filterCount})` : ""}
          </Button>
          <Button
            onClick={toggle}
            outline
            size="sm"
            color="primary"
            className="ms-3 mt-1"
          >
            Download Samples
          </Button>
          <TestStatusDump />
          <Link
            className="btn btn-outline-primary btn-sm ms-3 mt-1"
            to="/moistures"
          >
            Moistures (beta)
          </Link>
          <Link
            className="btn btn-outline-primary btn-sm ms-3 mt-1"
            to="/test-dashboard"
          >
            Testing Dashboard (beta)
          </Link>
        </div>
        <SamplesListFilterModal
          toggleFilter={toggleFilter}
          filters={filters}
          modal={filterModal}
          toggleMulti={toggleMulti}
          toggle={toggleFilterModal}
          setDateFilter={setDateFilter}
        />
        <SampleDumpModal
          form="SampleDump"
          onSubmit={generateDump}
          modal={modal}
          toggle={toggle}
        />
      </div>
      <List
        activeFilters={activeFilters}
        fetchNextPage={fetchNextPage}
        flattenedData={flattenedData}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        onSearch={onSearch}
        sorters={sorters}
        toggleSorter={toggleSorter}
      />
    </>
  );
};

const sampleSorters: ISorter<SampleListSortKeys>[] = [
  {
    label: "Number",
    name: "number",
    sortBy: "desc",
  },
];

export default SampleList;
