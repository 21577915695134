import { useInfiniteQuery } from "react-query";
import api from "../../../api/api";

const getMailItems = ({ pageParam = 1 }, folderId?: string) => {
  if (!pageParam) {
    return;
  }

  const url = folderId
    ? `microsoft/users/folders/${folderId}/messages`
    : "microsoft/users/messages";

  return api.get(`${url}?page=${pageParam}`).then(({ data }) => data);
};

export default function useMailMessages(search?: string, folderId?: string) {
  return useInfiniteQuery(
    ["user-mail", { folderId }],
    (pageParam) => getMailItems(pageParam, folderId),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      refetchOnWindowFocus: false,
    },
  );
}
