import { toast } from "react-toastify";
import { Field, reset } from "redux-form";
import useApi from "../api/useApi";
import DateTime, { formatting } from "../utils/dateTime";
import errorSwal from "../utils/errorSwal";
import FormModal from "../utils/FormModal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import { useQueryClient } from "react-query";

const CopyJobModal = (props) => {
  const { toggle, modal, setJobs, jobs, job } = props;
  const { takeAction } = useApi();

  const queryClient = useQueryClient();

  const onSubmit = (values, dispatch, { form }) => {
    return takeAction("store", "projectjobs", values)
      .then(({ data }) => {
        toast.success(`${values.name} added successfully`);
        setJobs([...jobs, data.data]);
        toggle();
        dispatch(reset(form));
        queryClient.invalidateQueries("project-jobs");
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      title={`Copy ${job.name}`}
      toggle={toggle}
      onSubmit={onSubmit}
      enableReinitialize={true}
      initialValues={job}
      modal={modal}
      form="CopyJob"
    >
      <div className="form-group col-12">
        <Field
          component={RenderField}
          name="name"
          label="name"
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          component={SelectInputAsync}
          name="branch_id"
          validate={required}
          url="/organisationbranches/branches"
          label="Branch"
          required
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          name="project_manager"
          validate={required}
          component={SelectInputAsync}
          url="/projectjobs/jobmanager"
          required
          label="Job Manager"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          name="scheduled_start_date"
          validate={required}
          component={DateTime}
          timeFormat={false}
          {...formatting}
          required
          label="Scheduled Start Date"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          name="scheduled_finish_date"
          validate={required}
          component={DateTime}
          timeFormat={false}
          {...formatting}
          required
          label="Scheduled Finish Date"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          name="description"
          label="Notes"
          textarea
          rows={3}
          cols={64}
          component={RenderField}
        />
      </div>
    </FormModal>
  );
};

export default CopyJobModal;
