import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Field, formValueSelector } from "redux-form";
import axios from "../api/api";
import { money } from "../form/formatters";
import SelectInput from "../form/SelectInput";
import renderField from "../utils/renderField";
import required from "../utils/required";
import usePricingTypes from "./usePricingTypes";

const Fields = (props) => {
  const [items, setItems] = useState([]);
  const { change, itemType, showAmount } = props;

  const pricingTypes = usePricingTypes();

  useEffect(() => {
    axios.get(`/pricing-items`).then(({ data }) => setItems(data.data));
  }, []);

  const displayItems = items.filter((item) => item.type === itemType);

  const hasMultiplePricingTypes = pricingTypes.length > 1;

  useEffect(() => {
    if (pricingTypes.length === 1 && !itemType) {
      change("item_type", pricingTypes[0]?.value);
    }
  }, [pricingTypes]);

  return (
    <div className="row">
      {hasMultiplePricingTypes && (
        <div className="col-lg-12 form-group">
          <Field
            required
            validate={required}
            label="Item Type"
            name="item_type"
            options={pricingTypes}
            component={SelectInput}
            changeValue={() => {
              change("item_id", null);
            }}
          />
        </div>
      )}
      <div className="col-lg-12 form-group">
        <Field
          required
          validate={required}
          label="Item"
          name="item_id"
          options={displayItems}
          component={SelectInput}
        />
      </div>
      <div
        className={`${props.showAmount ? "col-lg-4" : "col-lg-6"} form-group`}
      >
        <Field
          required
          validate={required}
          label="Units"
          name="billable_item_units_id"
          type="number"
          url="billable-item-units"
          component={SelectInput}
          formatData={(data) => {
            return data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          }}
        />
      </div>
      <div
        className={`${props.showAmount ? "col-lg-4" : "col-lg-6"} form-group`}
      >
        <Field
          required
          validate={required}
          label="Price"
          name="price"
          {...money}
          component={renderField}
        />
      </div>
      {showAmount && (
        <div className="col-lg-4 form-group">
          <Field component={renderField} label="Amount" name="amount" />
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (state, { form }) => {
  const selector = formValueSelector(form);
  return {
    itemType: selector(state, "item_type"),
  };
};

export default connect(mapStatetoProps, {})(Fields);
