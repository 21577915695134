export enum EquipmentStatus {
  InService,
  OutOfService,
  Withdrawn,
  LoanedOut,
  LoanedIn,
  HiredOut,
  Hiring,
  OnHired,
  OffHired,
}

export interface Equipment {
  nextDueCalibrations?: any[];
  branch_id: number;
  call_sign: string;
  make: string;
  condition: 0 | 1;
  date_received: string;
  model: string;
  serial_number: string;
  uuid: string;
  withdrawn: EquipmentStatus;
  type_type: string;
  sub_location: string;
  id: number;
  type: {
    type: string;
    has_unit_frequency: boolean;
    link: string;
  };
  branch: {
    name: string;
  };
  group: {
    name: string;
  };
  name: string;
  type_id: number;
  type_group: string;
  manager_id: number;
  branch_owner_id: number;
  is_in_calibration: boolean;
  project: {
    name?: string;
    link?: string;
  };
}

export interface StoodDownEquipment {
  uuid: string;
  date: string;
  reason?: string;
}
