import React from "react";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import PaginatedList from "../pagination/PaginatedList";
import useRecurringPurchases from "./hooks/useRecurringPurchases";
import RecurringPurchaseTable from "./RecurringPurchaseTable";
import { RecurringPurchase } from "./recurringPurchaseTypes";
import { Purchase } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

type RecurringPurchaseFilters = IFilter<"archived", boolean>;

const RecurringPurchasesList = () => {
  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/contract-agreements/" />
      <HeaderPage
        titlePage="Contract Agreements"
        pageDescription={
          <>
            The Contract Agreement feature simplifies creating and managing
            recurring supplier purchases, optimizing long-term relationships for
            regular goods or services orders.
          </>
        }
        relatedLinks={[
          {
            name: "Upload Purchase Orders",
            link: "/upload-purchase-order",
          },
          {
            name: "Purchases",
            link: "/purchases",
            model: Purchase,
          },
        ]}
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "/", name: "Contract Agreements", active: true },
        ]}
      />
      <div className="row">
        <div className="col-lg-8">
          <p className="ms-2 mb-1 tx-inverse">All prices exclude GST</p>
        </div>
      </div>
      <PaginatedList
        indexHook={useRecurringPurchases}
        originalFilters={recurringPurchaseFilter}
        list={({ data }: { data?: RecurringPurchase[] }) => (
          <div className="col-12">
            <RecurringPurchaseTable data={data} />
          </div>
        )}
      />
    </>
  );
};

export const recurringPurchaseFilter: RecurringPurchaseFilters[] = [
  {
    label: "Filter",
    name: "archived",
    options: [
      {
        label: "Archived",
        value: true,
      },
    ],
  },
];

export default RecurringPurchasesList;
