import queryString from "query-string";
import React, { useEffect, useState } from "react";
import errorSwal from "../utils/errorSwal";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import DocumentBreadcrumbs from "./DocumentBreadcrumbs";
import DownloadFolderButton from "./DownloadFolderButton";
import StandardFolderDropdown from "./StandardFolderDropdown";
import Upload from "./Upload";
import DocsModal from "../utils/DocsModal";

const NewProfileDocuments = props => {
  const { folderUuid } = useParams();

  const { homeUrl, documentPath, model, documentableId, noFolders } = props;

  const { takeAction, loading } = useApi();
  const [documents, setDocuments] = useState([]);
  const [path, setPath] = useState([]);
  const [highlightedDocuments, setHighlightedDocuments] = useState([]);

  useEffect(() => {
    showOrIndex();
    setHighlightedDocuments([]);
  }, [folderUuid]);

  const query = {
    documentableId,
    documentableType: model,
  };
  const showOrIndex = () => {
    if (folderUuid) {
      return takeAction("show", `documents/standard/${folderUuid}`)
        .then(({ data }) => {
          setDocuments(data.data.children);
          setPath(data.data.file_path);
        })
        .catch(errorSwal);
    }

    const string = queryString.stringify(query);

    return takeAction("index", `documents/standard?${string}`).then(
      ({ data }) => {
        setDocuments(data.data);
        setPath([]);
      },
    );
  };

  if (loading) {
    return <CustomScaleLoader>Fetching Documents...</CustomScaleLoader>;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/miscellaneous/controlled-documents/" />
      {!noFolders && (
        <>
          <StandardFolderDropdown
            margin="mg-b-20"
            {...props}
            documents={documents}
            setDocuments={setDocuments}
            path={documentPath}
          />
          <div className="d-flex justify-content-between mb-3">
            <DocumentBreadcrumbs
              path={path}
              {...props}
              url={homeUrl}
              highlightedDocuments={highlightedDocuments}
              setHighlightedDocuments={setHighlightedDocuments}
              setDocuments={setDocuments}
              documents={documents}
            />
            <DownloadFolderButton
              query={query}
              documents={documents}
              setDocuments={setDocuments}
              highlightedDocuments={highlightedDocuments}
            />
          </div>
        </>
      )}

      <Upload
        documents={documents}
        {...props}
        setDocuments={setDocuments}
        setPath={setPath}
        highlightedDocuments={highlightedDocuments}
        setHighlightedDocuments={setHighlightedDocuments}
      />
    </>
  );
};

export default NewProfileDocuments;
