import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import { Button } from "reactstrap";
import useBranchFilters from "../branches/hooks/useBranchFilters";
import HeaderPage from "../header/HeaderPage";
import { IFilter } from "../hooks/useFilter";
import useModal from "../hooks/useModal";
import PaginatedList from "../pagination/PaginatedList";
import AddMoistureModal from "./AddMoistureModal";
import DateFilter from "./DateFilter";
import useMoistures from "./hooks/useMoistures";
import MoisturesTable from "./MoisturesTable";
import { Moisture } from "./moistureTypes";

const MoisturesList = () => {
  const branchFilters = useBranchFilters();

  const { toggle, modal } = useModal();

  const [selectedDay, setSelectedDay] = useState<Date>();

  const [filters, setFilters] = useState<
    IFilter<
      "organisation_branches.id" | "is_completed" | "missing_masses",
      number | boolean
    >[]
  >([]);

  useEffect(() => {
    setFilters([
      ...completedFilter,
      {
        label: "Branch",
        name: "organisation_branches.id",
        options: branchFilters.map((branch: { name: string; id: number }) => {
          return {
            label: branch.name,
            value: branch.id,
          };
        }),
      },
    ]);
  }, [branchFilters]);

  return (
    <>
      <HeaderPage
        beta={true}
        crumbs={[
          {
            name: "Samples",
            link: "/samples",
          },
          {
            name: "Moistures",
            link: "/moistures",
            active: true,
          },
        ]}
        titlePage="Moistures"
      />
      <PaginatedList<
        "organisation_branches.id" | "is_completed" | "missing_masses",
        number | boolean,
        Moisture
      >
        originalFilters={filters ?? []}
        indexHookArguments={
          selectedDay ? [dayjs(selectedDay).format("YYYY-MM-DD")] : []
        }
        indexHook={useMoistures}
        extraButtons={() => (
          <>
            <DateFilter
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
            <Button size="sm" onClick={toggle} color="link">
              <RiAddFill aria-label="Add New Resource" className="tx-24 ms-1" />
            </Button>
          </>
        )}
        list={({ data }) => {
          return <MoisturesTable moistures={data ?? []} />;
        }}
      />
      <AddMoistureModal toggle={toggle} modal={modal} />
    </>
  );
};

const completedFilter: IFilter<"is_completed" | "missing_masses", boolean>[] = [
  {
    label: "Completed",
    name: "is_completed",
    options: [
      {
        label: "Complete",
        value: true,
      },
      {
        label: "Incomplete",
        value: false,
        selected: true,
      },
    ],
  },
  {
    label: "Missing Dry Masses",
    name: "missing_masses",
    options: [
      {
        label: "Is Missing",
        value: true,
      },
      {
        label: "Is not Missing",
        value: false,
      },
    ],
  },
];

export default MoisturesList;
