import React from "react";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import errorSwal from "../utils/errorSwal";
import GeneralForm from "./GeneralForm";
import useApi from "../api/useApi";

const ProfileForm = (props) => {
  const { handleSubmit, setJob } = props;
  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `projectjobs/${values.uuid}`, values)
      .then(({ data }) => {
        setJob(data.data);
        toast.success(`${data.data.name} updated successfully`);
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GeneralForm {...props} />
    </form>
  );
};

const form = reduxForm({
  form: "JobProfileForm",
});

export default form(ProfileForm);
