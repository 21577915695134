import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import NotFound from "../404";
import useApi from "../api/useApi";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import Edit from "./Edit";
import FileHistory from "./FileHistory";

const ShowPage = (props) => {
  const { calibration, setCalibration, showFile } = props;
  const { page } = useParams();
  const { takeAction } = useApi();

  const { calibration_type } = calibration;

  //2 is days
  const updateDate =
    calibration_type.frequency_units == 2 &&
    calibration_type.frequency == 1 &&
    !calibration.completed_at;

  const whenSubmitted = ({ data }) => {
    setCalibration({
      ...calibration,
      document: data.data,
    });

    return takeAction(
      "update",
      `equipment-calibration-status/${calibration.uuid}`,
      {
        updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      },
    ).then(({ data }) => {
      setCalibration(data.data);
    });
  };

  switch (page) {
    case "details":
      return (
        <>
          <Edit
            initialValues={{
              ...calibration,
              date: updateDate
                ? dayjs().format("YYYY-MM-DD")
                : calibration.date,
            }}
            calibration={calibration}
            setCalibration={setCalibration}
          />
          {showFile && (
            <ProfileSingleFile
              link={calibration?.document?.link}
              mime_type={calibration?.document?.mime_type}
              whenSubmitted={whenSubmitted}
              documentable_id={calibration.uuid}
              documentable_type="App\Models\EquipmentCalibration"
              bucket={`calibrations/${calibration.equipment.uuid}`}
              disableUpload={
                calibration.completed_at && !calibration.approval_action
              }
            />
          )}
        </>
      );
    case "file-history":
      return <FileHistory documents={calibration.documents} />;
    default:
      return <NotFound />;
  }
};
export default ShowPage;
