export enum InvoiceStatus {
  AwaitingInvoice,
  Invoiced,
  Paid,
  InvoiceNotRequired,
}

export enum JobStatus {
  Pending,
  InProgress,
  OnHold,
  Completed,
  Cancelled,
  Invoiced,
  InvoiceRequested,
}

export interface Job {
  id: number;
  status: JobStatus;
  name: string;
  number: string;
  uuid: string;
  is_overdue: boolean;
  invoiceless_job_request?: InvoicelessJobRequest;
  scheduled_start_time?: string;
  scheduled_finish_time?: string;
  deliverables_count: number;
  invoice_status: InvoiceStatus;
}

export interface IndexJob {
  id: number;
  description: string;
  uuid: string;
  name: string;
  number: string;
  link: string;
  created_at: string;
  project: {
    name: string;
    link: string;
  };
  branch: {
    name: string;
  };
  type: {
    name: string;
  };
  manager: {
    name: string;
  };
  scheduled_start_date: string;
  scheduled_finish_date: string;
  actual_start_date?: string;
  actual_finish_date?: string;
  status: JobStatus;
  is_overdue: boolean;
  estimated_value?: number;
  completed_value?: number;
}

export interface InvoicelessJobRequest {
  uuid: string;
  accepted_at?: string;
  user_id: number;
  job_id: number;
}

export interface ExternalJobRequest {
  id: number;
  uuid: string;
  name?: string;
  email: string;
  job_id: number;
  filled_at?: string;
}
