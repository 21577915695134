import dayjs from "dayjs";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { destroyForm } from "../../actions/utilActions";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import HeaderPage from "../header/HeaderPage";
import SyncIntegrationEstimation from "../purchases/SyncIntegrationEstimation";
import CostWidget from "../utils/CostWidget";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import Edit from "./Edit";
import FilePage from "./FilePage";
import MarkAsBilledButton from "./MarkAsBilledButton";

const ShowPage = (props) => {
  const { receipt } = props;
  const { page } = useParams();

  switch (page) {
    case "details":
      return (
        <>
          {!receipt.integration && <MarkAsBilledButton {...props} />}
          <Edit {...props} initialValues={receipt} />
        </>
      );
    case "file":
      return <FilePage {...props} />;
    default:
      return null;
  }
};

const Profile = (props) => {
  useEffect(() => {
    return () => {
      props.destroyForm("EditCreditCardReceipt");
    };
  }, []);

  const { uuid, page } = useParams();

  const {
    data: receipt,
    setData: setReceipt,
    loading,
    setUrl,
  } = useApi(`credit-card-receipts/${uuid}`, null, true, "/home");

  useEffect(() => {
    setUrl(`credit-card-receipts/${uuid}`);
  }, [uuid]);

  if (loading) return <Spinner loading />;

  const crumbs = [{ link: "", name: receipt?.name, active: true }];

  if (receipt?.project?.name) {
    crumbs.unshift({
      link: `projects/${receipt.project.uuid}/purchases`,
      name: `${receipt.project.name} Purchases`,
    });
  }
  return (
    <>
      <HeaderPage titlePage={receipt.name} crumbs={crumbs} />
      <div className="row row-sm">
        <div className="col-lg-8">
          <DashboardHeader>
            <DashboardH3>{receipt.name}</DashboardH3>
            <DashboardSubTitle>
              {receipt.date
                ? dayjs(receipt.date).format("DD/MM/YYYY")
                : "No date added"}
            </DashboardSubTitle>
            <DashboardSubTitle>
              <a href={`mailto:${receipt.user.email}`}>{receipt.user.name}</a>
            </DashboardSubTitle>
            <DashboardSubTitle>
              {receipt.includes_gst_description}
            </DashboardSubTitle>
          </DashboardHeader>
          {!receipt.file.link && (
            <InformationAlert
              title="No File Uploaded"
              body="Please go to the 'File' tab and upload a copy of the receipt."
              type="warning"
            />
          )}
          <div className="d-lg-none mb-3">
            <CostWidget
              price={receipt.total_cost}
              name={receipt.name}
              description="Please ensure this matches the total amount on the receipt"
            />
          </div>

          <SyncIntegrationEstimation
            purchase={receipt}
            setPurchase={setReceipt}
          />
          <ShowPage setReceipt={setReceipt} receipt={receipt} {...props} />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <TabList>
            <Tab
              link={`/credit-card-receipts/${uuid}/details`}
              active={page === "details"}
              icon="icon ion-clipboard text-capitalize"
            >
              Details
            </Tab>
            <Tab
              link={`/credit-card-receipts/${uuid}/file`}
              active={page === "file"}
              icon="icon ion-clipboard text-capitalize"
            >
              File
            </Tab>
          </TabList>
          <div className="d-none mt-3 d-lg-block">
            <CostWidget
              price={receipt.total_cost}
              name={receipt.name}
              description="Please ensure this matches the total amount on the receipt."
            />
          </div>
          <div className="mt-3">
            <SideList
              commentableType="App\Models\Purchase"
              commentableId={receipt.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { destroyForm })(Profile);
