import React from "react";
import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useSupplierPurchases from "./hooks/useSupplierPurchases";
import ResourcePurchaseList from "./ResourcePurchaseList";
import { PurchaseArchiveStatus, PurchaseGSTType } from "./purchaseTypes";

const SupplierPurchases = () => {
  const { uuid } = useParams<{ uuid: string }>();
  return (
    <PaginatedList
      indexHook={useSupplierPurchases}
      indexHookArguments={[uuid]}
      originalFilters={purchaseFilters}
      list={({ data }) => {
        return (
          <div className="col-12">
            <ResourcePurchaseList purchases={data ?? []} />
          </div>
        );
      }}
    />
  );
};

export const purchaseFilters = [
  {
    label: "Status",
    name: "status",
    options: [
      {
        label: "Billed",
        value: 3,
      },
      {
        label: "Ready To Be Billed",
        value: 1,
      },
      {
        label: "Not Ready To Be Billed",
        value: 2,
      },
      {
        label: "Paid",
        value: 4,
      },
    ],
  },
  {
    label: "Archive Status",
    name: "archive_status",
    options: [
      {
        label: "Archived",
        value: PurchaseArchiveStatus.Archived,
      },
      {
        label: "Not Archived",
        value: PurchaseArchiveStatus.NotArchived,
      },
    ],
  },
  {
    label: "Tax Type",
    name: "includes_gst",
    options: [
      {
        label: "GST Included",
        value: PurchaseGSTType.GSTIncluded,
      },
      {
        label: "GST Excluded",
        value: PurchaseGSTType.GSTExcluded,
      },
      {
        label: "N/A",
        value: PurchaseGSTType.NA,
      },
    ],
  },
];

export default SupplierPurchases;
