import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import Invoices from "../invoices/Page";
import ProjectJobsTabsList from "./ProjectJobsTabsList";
import ProjectPurchases from "../purchases/ProjectPurchases";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProjectDeliverables from "./deliverables";
import ProjectDetail from "./Detail";
import Overview from "./overview";
import ProjectAllowances from "./ProjectAllowances";
import ProjectEquipment from "./ProjectEquipment";
import ProjectEmails from "./ProjectEmails";
import ProjectFormsTabsList from "./projectForms/TabsList";
import AuditLog from "../auditLog/AuditLog";

const ShowPage = (props) => {
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const canViewOverview = user.hasAccessTo(
    "App\\Models\\Project",
    "view_overview",
  );

  switch (props.page) {
    case "main":
      return (canViewOverview && isPositionAdmin) ||
        user.id === props?.project?.project_manager ? (
        <Overview {...props} />
      ) : (
        <NotFound />
      );
    case "details":
      return <ProjectDetail initialValues={props.project} {...props} />;
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\Project"
          documentableId={props.number}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/projects/${props.number}`}
          homeUrl={`projects/${props.number}/documents`}
        />
      );
    case "jobs":
      return <ProjectJobsTabsList {...props} />;
    case "deliverables":
      return isPositionAdmin ? <ProjectDeliverables /> : <NotFound />;
    case "invoices":
      return !props.project.internal && isPositionAdmin ? (
        <Invoices {...props} />
      ) : (
        <NotFound />
      );
    case "purchases":
      return isPositionAdmin ? <ProjectPurchases {...props} /> : <NotFound />;
    case "allowances":
      return user.is_admin || user.id == props.project.project_manager ? (
        <ProjectAllowances />
      ) : (
        <NotFound />
      );
    case "equipment":
      return <ProjectEquipment project={props.project} />;
    case "emails":
      return (
        <ProjectEmails
          messagableId={props.project.id}
          messagableType="App\\Models\\Project"
        />
      );
    case "audit-log":
      return isPositionAdmin ? (
        <AuditLog
          subjectType="App\Models\Project"
          uuid={props.number}
          {...props}
        />
      ) : (
        <NotFound />
      );
    case "project-forms":
      return (
        <ProjectFormsTabsList {...props} />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
