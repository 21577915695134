import dayjs from "dayjs";
import { FocusEvent } from "react";
import { useQueryClient } from "react-query";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { DryMoistureMass, Moisture } from "./moistureTypes";

const MoisturesTable = ({ moistures }: { moistures: Moisture[] }) => {
  const { takeAction }: IUseApi<{}, { data: DryMoistureMass }> = useApi();
  const queryClient = useQueryClient();
  const updateDryMoistureMass = (
    e: FocusEvent<HTMLInputElement>,
    moisture: Moisture,
    moistureMass?: DryMoistureMass,
  ) => {
    if (!e.target.value) {
      return;
    }

    return createOrUpdate(e, moisture, moistureMass)
      .then(({ data }) => {
        queryClient.invalidateQueries(["moistures"]);
      })
      .catch((err) => {
        //
      });
  };

  const createOrUpdate = (
    e: FocusEvent<HTMLInputElement>,
    moisture: Moisture,
    dryMass?: DryMoistureMass,
  ) => {
    if (dryMass) {
      return takeAction("update", `dry-moisture-masses/${dryMass.uuid}`, {
        mass: e.target.value,
      });
    }

    return takeAction("store", `/moistures/${moisture.uuid}/dry-masses`, {
      mass: e.target.value,
    });
  };

  if (moistures.length === 0) {
    return null;
  }

  return (
    <>
      <table className="table">
        <thead className="bg-gray-400 p-3">
          <tr className="text-dark">
            <th>Sample</th>
            <th>Tin</th>
            <th>Branch</th>
            <th>Taken At</th>
            <th>Tin Weight</th>
            <th>Wet Weight</th>
            <th>Dry Moisture 1</th>
            <th>Dry Moisture 2</th>
            <th>Dry Moisture 3</th>
            <th>Dry Moisture 4</th>
          </tr>
        </thead>
        <tbody className="p-3">
          {moistures.map((moisture) => {
            return (
              <tr key={moisture.uuid}>
                <td>{moisture.sample ?? "-"}</td>
                <td>{moisture.container_id}</td>
                <td>{moisture.branch?.name}</td>
                <td>{dayjs(moisture.taken_at).format("DD/MM/YYYY HH:mm")}</td>
                <td>{moisture.mass_of_tin}</td>
                <td>{moisture.mass_of_tin_and_wet_soil}</td>
                {[0, 1, 2, 3].map((value) => {
                  return (
                    <td>
                      <input
                        type="number"
                        step="0.01"
                        name={`moisture[${moisture.id}].dry_moisture[${value}]`}
                        defaultValue={
                          moisture.dry_moisture_masses[value]?.mass ?? ""
                        }
                        id={`moisture[${moisture.id}].dry_moisture[${value}]`}
                        onBlur={(e) => {
                          updateDryMoistureMass(
                            e,
                            moisture,
                            moisture.dry_moisture_masses[value],
                          );
                        }}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default MoisturesTable;
