import { IFilter } from "./useFilter";
import { useState } from "react";

export interface ISorter<StringLiteral> {
  sortBy?: "asc" | "desc";
  name: StringLiteral;
  label: string;
}

interface UseSortReturn<StringLiteral> {
  stringified: string;
  sorters: ISorter<StringLiteral>[];
  toggleSorter: (sorterName: StringLiteral) => void;
}

const useSort = <StringLiteral>(
  initialSorters: ISorter<StringLiteral>[],
): UseSortReturn<StringLiteral> => {
  const [sorters, setSorters] =
    useState<ISorter<StringLiteral>[]>(initialSorters);

  const activeSorter = sorters.find((sorter) => sorter.sortBy);

  if (!activeSorter) {
    throw new Error(
      "No active sorters found in the useSort hook. Please ensure that you pass one active sorter by passing 'asc' or 'desc' to a sortBy key.",
    );
  }

  const toggleSorter = (sorterName: StringLiteral): void => {
    const newSorters = sorters.map<ISorter<StringLiteral>>((sorter) => {
      if (sorter.name === sorterName) {
        return {
          ...sorter,
          sortBy: sorter.sortBy === "asc" ? "desc" : "asc",
        };
      }

      return {
        ...sorter,
        sortBy: undefined,
      };
    });

    setSorters(newSorters);
  };

  return {
    stringified: `&sort=${activeSorter.sortBy === "desc" ? "-" : ""}${
      activeSorter.name
    }`,
    sorters: sorters,
    toggleSorter,
  };
};

export default useSort;
