import React from "react";
import { Field } from "redux-form";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import FormModal from "../utils/FormModal";

const Modal = (props) => {
  return (
    <FormModal {...props}>
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Name"
          required
          validate={required}
        />
      </div>
    </FormModal>
  );
};

export default Modal;
