import { Component, Fragment } from "react";
import dayjs from "dayjs";
import dayjsLocalizer from "../utils/dayjsLocalizer";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Calendar } from "react-big-calendar";
import { connect } from "react-redux";
import { ResizableBox } from "react-resizable";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  createStaffSchedule,
  deleteStaffSchedule,
  updateStaffSchedule,
} from "../../actions/staffScheduleActions";
import { fetchToDos } from "../../actions/toDoActions";
import ChangeStep from "../user/ChangeStep";
import ScheduleModal from "../user/ScheduleModal";
import ToggleBranchSchedule from "../user/ToggleBranchSchedule";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import "react-resizable/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

dayjs.locale("en");
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const localizer = dayjsLocalizer(dayjs);
class UserSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedTimes: { start: null, end: null },
      modal: false,
      selectedEvent: "",
      eventModal: false,
      calendarTimes: {
        start: dayjs().startOf("week").format("YYYY-MM-DD"),
        end: dayjs().endOf("week").format("YYYY-MM-DD"),
      },
      calendarDate: new Date(),
      height: localStorage.getItem("schedule_height")
        ? parseInt(localStorage.getItem("schedule_height"))
        : 800,
      jobTypeFilter: localStorage.getItem("schedule_filter")
        ? JSON.parse(localStorage.getItem("schedule_filter"))
        : null,
      menuOpen: false,
      stepSize: localStorage.getItem("schedule_step")
        ? parseInt(localStorage.getItem("schedule_step"))
        : 30,
      rightClick: {},
    };
  }

  onResize = (e, data) => {
    const { size } = data;
    const { height } = size;
    if (Number.isInteger(height)) {
      localStorage.setItem("schedule_height", height);
    }
    this.setState({
      height,
    });
  };

  componentDidMount = () => {
    const { fetchToDos } = this.props;
    const { calendarTimes } = this.state;
    if (shouldClearScheduleFilter()) {
      localStorage.setItem("schedule_filter", "");
      this.setState({ jobTypeFilter: null });
    }
    return fetchToDos(calendarTimes.start, calendarTimes.end)
      .catch(() => errorSwal("Unable to fetch calender details"))
      .then(() => this.setState({ loading: false }));
  };

  handleSelect = ({ start, end }) => {
    if (this.state.rightClick.x) return;
    this.setState({ selectedTimes: { start, end } });
    return this.setState({ modal: !this.state.modal });
  };

  toggle = () => this.setState({ modal: !this.state.modal, selectedEvent: "" });

  selectEvent = (event) => {
    if (this.state.rightClick.x) return;
    if (event.type === "App\\Models\\StaffSchedule")
      return this.setState({
        selectedEvent: event,
        eventModal: !this.state.eventModal,
      });

    this.props.history.push(event.link);
  };

  toggleEvent = () =>
    this.setState({ eventModal: !this.state.eventModal, selectedEvent: "" });

  isUpdating = () => (this.state.selectedEvent ? true : false);

  submit = (values) => {
    const { userId, fetchToDos } = this.props;
    const { selectedEvent, calendarTimes } = this.state;
    const data = {
      start_time: `${values.start_time_date} ${values.start_time_time}`,
      finish_time: `${values.finish_time_date} ${values.finish_time_time}`,
      job_id: values.job_id,
      title: values.title,
      comments: values.comments,
      uuid: values.uuid,
    };

    if (!selectedEvent.id) data.user_uuid = userId;

    return this.submitForm(data)
      .then(({ payload }) => {
        return fetchToDos(calendarTimes.start, calendarTimes.end).then(
          () => payload,
        );
      })
      .then((payload) => {
        toast.success(
          `${payload.title} ${this.isUpdating() ? "updated" : "added"}!`,
        );
        return this.isUpdating() ? this.toggleEvent() : this.toggle();
      })
      .catch((err) => {
        errorSwal(err);
      });
  };

  changeRange = (dateRange) => {
    const range = {};
    if (dateRange.start) {
      range.start = dayjs(dateRange.start).format("YYYY-MM-DD");
      range.end = dayjs(dateRange.end).format("YYYY-MM-DD");
    } else {
      range.start = dayjs(dateRange[0]).format("YYYY-MM-DD");
      range.end = dayjs(dateRange[dateRange.length - 1]).format("YYYY-MM-DD");
    }
    this.setState({ calendarTimes: { start: range.start, end: range.end } });
    return this.props.fetchToDos(range.start, range.end);
  };

  handleNavigate = (calendarDate) => this.setState({ calendarDate });

  submitForm = (values) =>
    values.uuid
      ? this.props.updateStaffSchedule(values)
      : this.props.createStaffSchedule(values);

  eventClassGetter = (event, start) => {
    let classType = "";
    let borderColor = "";
    switch (event.type) {
      case "App\\Models\\ProjectJob":
        classType = "info";
        borderColor = "#4d81bc";
        break;
      case "App\\Models\\StaffSchedule":
        classType = "primary";
        borderColor = "#00468e";
        break;
      case "App\\Models\\ApprovalAction":
        classType = "warning";
        break;
    }
    let className = `bg-${classType}`;
    return {
      className,
      style: {
        borderColor,
      },
    };
  };

  handleChange = (jobTypeFilter) => {
    localStorage.setItem("schedule_filter", JSON.stringify(jobTypeFilter));
    this.setState({ jobTypeFilter });
  };

  changeStep = (e) => {
    const val = e.target.value;

    if (val <= 0) {
      toast.warning("Step size must be larger than 0");

      return;
    }

    if (val) {
      localStorage.setItem("schedule_step", val);
      this.setState({
        stepSize: val,
      });
    }
  };

  contextMenu = (e) => {
    this.setState({ rightClick: { x: e.clientX, y: e.clientY } });
    e.preventDefault();
  };

  hideContextMenu = () => this.setState({ rightClick: {} });

  render() {
    const { rawEvents, deleteStaffSchedule } = this.props;
    const {
      modal,
      loading,
      selectedTimes: { start, end },
      selectedEvent,
      eventModal,
      calendarDate,
      height,
      jobTypeFilter,
      menuOpen,
      stepSize,
      rightClick,
      calendarTimes,
    } = this.state;

    const events = rawEvents.reduce((events, ev) => {
      const event = {
        id: ev.uuid,
        title: ev.title,
        start: dayjs(ev.start).toDate(),
        end: dayjs(ev.end).toDate(),
        job: ev.job,
        job_id: ev.job_id,
        comments: ev.comments,
        type: ev.type,
        link: ev.link,
      };
      if (!jobTypeFilter) {
        events.push(event);
        return events;
      }
      if (event.type !== "App\\Models\\ProjectJob") {
        events.push(event);
        return events;
      }

      if (
        event.type === "App\\Models\\ProjectJob" &&
        jobTypeFilter?.map(({ value }) => value).includes(ev.job_type)
      ) {
        events.push(event);
      }

      return events;
    }, []);

    const jobs = rawEvents.filter((event) => {
      return event.type === "App\\Models\\ProjectJob";
    });

    const options = [...new Set(jobs.map((x) => x.job_type))].map((option) => ({
      label: option,
      value: option,
    }));

    const stepSizeInt = parseInt(stepSize);

    return (
      <Fragment>
        <div className="form-group mb-0">
          <label>Filter by Job Type</label>
          <Select
            options={options}
            value={jobTypeFilter}
            isClearable
            onChange={this.handleChange}
            isMulti
            styles={{
              menu: (provided) => {
                return {
                  ...provided,
                  zIndex: 10,
                };
              },
            }}
            onMenuOpen={() => this.setState({ menuOpen: true })}
            onMenuClose={() =>
              setTimeout(() => this.setState({ menuOpen: false }), 500)
            }
          />
        </div>
        <div className="d-flex justify-content-end my-2">
          <ToggleBranchSchedule {...this.props} calendarTimes={calendarTimes} />
        </div>
        <div style={{ height: localStorage.getItem("schedule_height") }}>
          {loading ? (
            <CustomScaleLoader />
          ) : (
            <div
              onContextMenu={this.contextMenu}
              onClick={this.hideContextMenu}
            >
              <ResizableBox
                height={height}
                className="box"
                onResize={this.onResize}
                axis="y"
                minConstraints={[10, 250]}
              >
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  selectable
                  scrollToTime={new Date(1970, 1, 1, 7)}
                  defaultView="week"
                  onSelectSlot={!menuOpen ? this.handleSelect : null}
                  onSelectEvent={
                    !menuOpen ? (event) => this.selectEvent(event) : null
                  }
                  eventPropGetter={this.eventClassGetter}
                  onRangeChange={this.changeRange}
                  date={calendarDate}
                  onNavigate={this.handleNavigate}
                  step={stepSizeInt}
                />
              </ResizableBox>
            </div>
          )}
        </div>
        {modal && (
          <ScheduleModal
            initialValues={{
              start_time_date: dayjs(start).format("YYYY-MM-DD"),
              finish_time_date: dayjs(end).format("YYYY-MM-DD"),
              start_time_time: dayjs(start).format("HH:mm:ss"),
              finish_time_time: dayjs(end).format("HH:mm:ss"),
            }}
            modal={modal}
            toggle={this.toggle}
            whenSubmitted={this.submit}
            onDelete={deleteStaffSchedule}
            isEditable={true}
          />
        )}
        {selectedEvent && (
          <ScheduleModal
            initialValues={{
              start_time_date: dayjs(selectedEvent.start).format("YYYY-MM-DD"),
              finish_time_date: dayjs(selectedEvent.end).format("YYYY-MM-DD"),
              start_time_time: dayjs(selectedEvent.start).format("HH:mm:ss"),
              finish_time_time: dayjs(selectedEvent.end).format("HH:mm:ss"),
              uuid: selectedEvent.id,
              job_id: selectedEvent.job_id,
              project: selectedEvent.job.project_id,
              title: selectedEvent.title,
              comments: selectedEvent.comments,
            }}
            selectedEvent={selectedEvent}
            modal={eventModal}
            toggle={this.toggleEvent}
            whenSubmitted={this.submit}
            onDelete={deleteStaffSchedule}
            update={true}
            isEditable={true}
          />
        )}
        <ChangeStep
          // rightClick={this}
          changeStep={this.changeStep}
          stepSize={stepSize}
          rightClick={rightClick}
        />
      </Fragment>
    );
  }
}

const shouldClearScheduleFilter = () => {
  //This function is only used as we changed how the schedule filter works.
  //Will be able to delete it in the future.

  return (
    localStorage.getItem("schedule_filter") &&
    !Array.isArray(JSON.parse(localStorage.getItem("schedule_filter")))
  );
};

const mapStateToProps = (state) => ({
  rawEvents: state.toDos.items,
});

export default connect(mapStateToProps, {
  fetchToDos,
  createStaffSchedule,
  updateStaffSchedule,
  deleteStaffSchedule,
})(UserSchedule);
