import { Link, useParams } from "react-router-dom";
import { IUseApi, IUseApiWithData } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import PaginatedList from "../../pagination/PaginatedList";
import useMailMessages from "./hooks/useMailMessages";
import { Message } from "@microsoft/microsoft-graph-types";
import { useState } from "react";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { PiFloppyDisk } from "react-icons/pi";
import _ from "lodash";
import { ReactComponent as Project } from "../../../svgs/construction.svg";
import api from "../../../api/api";
import Skeleton from "react-loading-skeleton";
import AsyncSelect from "react-select/async";
import { SelectOption } from "../../utils/utilTypes";
import { SingleValue } from "react-select";

import "react-loading-skeleton/dist/skeleton.css";
import useModal from "../../hooks/useModal";
import SaveEmailToProjectModal from "./SaveEmailToProjectModal";

type ConversableMessage = Message & {
  conversable?: { link: string; name: string; type: "App\\Models\\Project" };
};

const MicrosoftInbox = () => {
  const { folderId, mailId } = useParams<{
    folderId?: string;
    mailId?: string;
  }>();

  const { toggle, modal } = useModal();

  const {
    takeAction: getMessage,
    data: message,
    setUrl,
    setData,
    loading,
  }: IUseApi<ConversableMessage | undefined> = useApi();

  const [selectedEmails, setSelectedEmails] = useState<ConversableMessage[]>(
    [],
  );

  const [selectedProject, setSelectedProject] =
    useState<SingleValue<SelectOption>>();

  const {
    data,
  }: IUseApiWithData<
    {
      displayName: string;
      totalItemCount: number;
      unreadItemCount: number;
      id: string;
    }[]
  > = useApi("microsoft/users/folders", []);

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-2">
          <div className="">Folders</div>
          {data.map((d) => {
            return (
              <Link
                onClick={() => {
                  setSelectedEmails([]);
                  setData(undefined);
                }}
                to={`/users/inbox/${d.id}`}
                className="d-block"
              >
                {d.displayName}
              </Link>
            );
          })}
        </div>
        <div className="col-lg-3 overflow-auto " style={{ maxHeight: "70vh" }}>
          <PaginatedList
            indexHook={useMailMessages}
            indexHookArguments={[folderId]}
            originalFilters={[]}
            list={({ data }: { data?: ConversableMessage[] }) => {
              return (
                <>
                  {data && data?.length > 0 && (
                    <div className="col-12 w-100 ">
                      <div className="bg-white border rounded-lg">
                        {data?.map((d) => {
                          return (
                            <button
                              onClick={(e) => {
                                if (e.altKey) {
                                  setSelectedEmails(_.xor(selectedEmails, [d]));
                                  return;
                                }
                                const selected = selectedEmails.includes(d)
                                  ? []
                                  : [d];
                                setSelectedEmails(selected);
                                setUrl(`/microsoft/users/messages/${d.id}`);
                              }}
                              className={`border-0 tn-300 w-100 text-start py-2 border-top ${
                                selectedEmails
                                  ?.map(({ id }) => id)
                                  .includes(d.id)
                                  ? "bg-info-light"
                                  : "bg-white"
                              }`}
                            >
                              <p
                                style={{ maxWidth: "75%" }}
                                className="text-dark mb-0"
                              >
                                {d.sender?.emailAddress?.name}
                              </p>
                              <p
                                style={{ maxWidth: "75%" }}
                                className="text-dark mb-0 no-wrap"
                              >
                                {d.subject}
                              </p>
                              <p
                                className="mb-0 text-secondary no-wrap"
                                style={{ maxWidth: "70%" }}
                              >
                                {d.bodyPreview}
                              </p>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
        <div className="col-lg-7">
          {loading && (
            <>
              <div className="bg-white p-2 rounded-lg shadow-sm mb-2">
                <Skeleton />
              </div>
              <div className="rounded-lg p-2 bg-white shadow-sm border">
                <div className="mb-3">
                  <Skeleton count={2} />
                </div>
                <div className="p-4">
                  <Skeleton count={10} />
                </div>
              </div>
            </>
          )}
          {message && !loading && (
            <div className="overflow-auto" style={{ maxHeight: "70vh" }}>
              <div className="bg-white d-flex p-2 rounded-lg shadow-sm mb-2">
                <div>
                  <h5 className="text-dark mb-0">{message.subject}</h5>
                  {message.conversable && (
                    <Link
                      to={`/${message.conversable.link}`}
                      className="text-secondary"
                    >
                      {message.conversable.name}
                    </Link>
                  )}
                </div>
                {!message.conversable && (
                  <Button onClick={toggle} color="link" className="ms-auto p-0">
                    <PiFloppyDisk className="text-secondary tx-18" />
                  </Button>
                )}
              </div>
              <div className="rounded-lg bg-white shadow-sm border">
                <div className="p-2 d-flex">
                  <div>
                    <p className="text-dark mb-0">
                      {message.sender?.emailAddress?.name}{" "}
                      {`<${message.sender?.emailAddress?.address}>`}
                    </p>
                    <p className="text-dark mb-0">
                      To: {message.toRecipients?.[0].emailAddress?.name}
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p className="mb-0">
                      {dayjs(message?.receivedDateTime)
                        .utc()
                        .local()
                        .format("DD/MM/YYYY hh:mm A")}
                    </p>
                  </div>
                </div>
                <div
                  className="p-4"
                  dangerouslySetInnerHTML={{
                    /** @ts-ignore */
                    __html: message.body?.content,
                  }}
                />
              </div>
            </div>
          )}
          {selectedEmails.length > 1 && (
            <div className="d-flex align-items-center justify-content-center p-5">
              <div>
                <h5 className="text-center text-dark">
                  Add Selected Emails to a Project
                </h5>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    //
                  }}
                >
                  <AsyncSelect
                    value={selectedProject}
                    onChange={(value) => setSelectedProject(value)}
                    loadOptions={loadOptions}
                    isClearable
                  />
                  <button>Save</button>
                </form>
                <Project className="active" height="400px" width="400px" />
              </div>
            </div>
          )}
        </div>
      </div>
      <SaveEmailToProjectModal
        toggle={toggle}
        modal={modal}
        conversations={
          selectedEmails?.map((e) => e?.conversationId ?? "-") ?? []
        }
      />
    </>
  );
};

const loadOptions = (
  inputValue: string,
  callback: (options: SelectOption[]) => void,
) => {
  api
    .get(`/schedule-projects?filter[search]=${inputValue}`)
    .then(({ data }) => {
      console.log(data);
      callback(data.data);
    });
};
// api
// .get(`/schedule-projects?filter[search]=${inputValue}`)
// .then(({ data }) => data.data)

export default MicrosoftInbox;
