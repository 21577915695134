import { useEffect } from "react";
import { connect } from "react-redux";
import { ChangeAction, Field, formValueSelector } from "redux-form";
import SelectInput from "../form/SelectInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import RenderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import selectInputAsync from "../utils/SelectInputAsync";
import { SelectOption } from "../utils/utilTypes";
import { TimeUnit } from "./RecurringJobFormModal";
import SelectInputAsync from "../utils/SelectInputAsync";

const RecurringJobFields = ({
  change,
  recurringFrequencyUnits,
  project,
  pauseOnWeekends,
  jobBeforeOrAfterDate,
  toCreateAtAmount,
}: {
  change: ChangeAction;
  recurringFrequencyUnits: TimeUnit;
  project: any;
  pauseOnWeekends: boolean;
  jobBeforeOrAfterDate: boolean;
  toCreateAtAmount?: number;
}) => {
  useEffect(() => {
    if (toCreateAtAmount) {
      change("job_before_or_after_date", true);
    }
  }, []);

  return (
    <>
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          name="name"
          label="Name"
          validate={required}
          required
        />
        <small className="d-block">
          {
            "Use the ${date} variable in the name to get the date the job will be created."
          }
        </small>
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={selectInputAsync}
          name="manager"
          label="Job Manager"
          url="/projectjobs/jobmanager"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={selectInputAsync}
          name="branch_id"
          label="Branch"
          url="/organisationbranches/branches"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="estimated_hours"
          label="Estimated Hours"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="estimated_value"
          label="Estimated Value"
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          label="Job Type"
          name="job_type"
          url="/jobtypes/types?filter[]=JobTypes&filter[]=CustomJobs"
          required
          validate={required}
          component={selectInputAsync}
          onChangeValue={({ type, id }: { type: string; id: number }) => {
            change("job_type_type", type);
            change("job_type_id", id);
          }}
        />
      </div>
      <div className="form-group col-lg-6">
        <Field
          name="users"
          label="Staff"
          multiple="true"
          component={SelectInputAsync}
          url="/users/staff"
        />
        <small>
          Select Staff from Your Organisation that will be working on this job
        </small>
      </div>
      <div className="col-12 form-group">
        <Field
          name="description"
          label="Notes"
          textarea
          rows={3}
          cols={64}
          component={RenderField}
        />
        <small className="d-block">
          {
            "Use the ${date} variable in the notes to get the date the job will be created."
          }
        </small>
      </div>

      <div className="col-lg-6 form-group">
        <Field
          name="length"
          component={RenderField}
          label="Length Of Job"
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name="length_units"
          component={selectInputAsync}
          label="Length of Job Units"
          url="/enums?enum=TimeUnitType"
          formatData={formatData}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="scheduled_start_time"
          label="Scheduled Start Time"
          type="time"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          component={RenderField}
          name="scheduled_finish_time"
          label="Scheduled Start Time"
          type="time"
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          name="recurring_frequency"
          component={RenderField}
          label="Frequency"
          required
          validate={required}
        />
      </div>

      <div className="col-lg-6 form-group">
        <Field
          name="recurring_frequency_units"
          component={selectInputAsync}
          label="Units"
          url="/enums?enum=TimeUnitType"
          formatData={formatData}
          required
          validate={required}
        />
      </div>
      {!project.internal && (
        <div className="col-lg-12 form-group">
          <Field
            component={RenderToggleInput}
            name="requires_invoice"
            label="Requires Invoice"
          />
        </div>
      )}
      <div className="col-lg-4 form-group">
        <Field
          name="start"
          label="Start Date"
          type="date"
          required
          validate={required}
          component={RenderField}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name="end"
          label="End Date"
          type="date"
          component={RenderField}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name="repetitions"
          label="Repetitions"
          type="number"
          component={RenderField}
        />
      </div>
      <FormHeader
        labelClass="d-flex justify-content-between "
        extra={
          <>
            <p>
              Click above toggle to create a job before or after the job is
              created. For example, if you want to create a job 1 week before
              the job is created, set the create before field to 1 and the
              create units field to week.
            </p>
          </>
        }
      >
        <div>Create Job Before/After Job Date</div>
        <div className="ms-2 me-2">
          <Field
            component={RenderToggleInput}
            onChange={(e: any) => {
              if (!e.target.checked) {
                change("to_create_at_amount", null);
                change("to_create_at_unit", null);
                change("to_create_at_tense", null);
              }
            }}
            name="job_before_or_after_date"
          />
        </div>
      </FormHeader>

      {jobBeforeOrAfterDate && (
        <>
          <div className="col-lg-4 form-group">
            <Field
              name="to_create_at_amount"
              label="Amount"
              type="number"
              component={RenderField}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="to_create_at_unit"
              component={selectInputAsync}
              label="Units"
              url="/enums?enum=TimeUnitType"
              formatData={formatData}
              required
              validate={required}
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              name="to_create_at_tense"
              label="To Create at"
              component={SelectInput}
              options={[
                { label: "Before", value: 0 },
                { label: "After", value: 1 },
              ]}
              required
              validate={required}
            />
          </div>
        </>
      )}

      {recurringFrequencyUnits == 1 && (
        <>
          <FormHeader>Pause creation</FormHeader>
          <div className="col-lg-6 form-group">
            <Field
              name="pause_on_weekends"
              label="Pause on Weekends"
              component={RenderToggleInput}
            />
          </div>
          {!pauseOnWeekends && (
            <div className="col-lg-6 form-group">
              <Field
                name="days_to_create"
                label="Days to Create"
                component={SelectInput}
                options={daysOfTheWeek}
                isMulti
                menuPlacement="top"
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
              />
              <small>Leave blank to create every day</small>
            </div>
          )}
        </>
      )}
    </>
  );
};

//This matches with Carbon/Carbon
//Days of week
const daysOfTheWeek = [
  {
    label: "Sunday",
    value: 0,
  },
  {
    label: "Monday",
    value: 1,
  },
  {
    label: "Tuesday",
    value: 2,
  },
  {
    label: "Wednesday",
    value: 3,
  },
  {
    label: "Thursday",
    value: 4,
  },
  {
    label: "Friday",
    value: 5,
  },
  {
    label: "Saturday",
    value: 6,
  },
];

const mapStateToProps = (state: any, props: any) => {
  const selector = formValueSelector(props.form);

  return {
    recurringFrequencyUnits: selector(state, "recurring_frequency_units"),
    pauseOnWeekends: selector(state, "pause_on_weekends"),
    jobBeforeOrAfterDate: selector(state, "job_before_or_after_date"),
    toCreateAtAmount: selector(state, "to_create_at_amount"),
  };
};

const formatData = (data: { data: SelectOption[] }): SelectOption[] => {
  return data.data
    .filter((d) => d.label !== "Initial")
    .map((d) => {
      return {
        ...d,
      };
    });
};

export default connect(mapStateToProps)(RecurringJobFields);
