import React from "react";
import HeaderPage from "../header/HeaderPage";
import { useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import { IXeroIntegration } from "./xeroTypes";
import useApi from "../api/useApi";
import CustomScaleLoader from "../utils/scaleLoader";
import Profile from "../profile";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import ShowXeroIntegrationPage from "./ShowXeroIntegrationPage";

export type XeroIntegrationProfilePages =
  | "accounts"
  | "contacts"
  | "tracking-categories";

const XeroIntegrationProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  return (
    <>
      <HeaderPage crumbs={[]} titlePage="Xero" />
      <Profile<XeroIntegrationProfilePages>
        header={
          <DashboardHeader>
            <DashboardH3>Xero</DashboardH3>
          </DashboardHeader>
        }
        tabs={[
          {
            label: "Contacts",
            link: `/integrations/xero/${uuid}/contacts`,
            page: "contacts",
          },
          {
            label: "Accounts",
            link: `/integrations/xero/${uuid}/accounts`,
            page: "accounts",
          },
          {
            label: "Tracking Categories",
            link: `/integrations/xero/${uuid}/tracking-categories`,
            page: "tracking-categories",
          },
        ]}
        content={<ShowXeroIntegrationPage />}
      />
    </>
  );
};

export default XeroIntegrationProfile;
