import { SubscribedSku } from "@microsoft/microsoft-graph-types";
import { AiOutlineCheck } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUseApi } from "../../api/apiTypes";
import useApi from "../../api/useApi";
import errorSwal from "../../utils/errorSwal";
import LoadingOverlay from "../../utils/LoadingOverlay";
import { AssignedOfficeLicense, LicenseData } from "./UserMicrosoftDetails";

const UserLicenseDetail = ({
  licenceData,
  licence,
  setLicences,
}: {
  licenceData: LicenseData;
  licence: SubscribedSku;
  setLicences: (licences: LicenseData) => void;
}) => {
  const { userId } = useParams<{ userId: string }>();
  const { takeAction, loading }: IUseApi<{}, { data: AssignedOfficeLicense }> =
    useApi();

  const currentUserLicence = licenceData?.user_licences.find(
    (userLicence) => userLicence.licence_id === licence.skuId,
  );
  const addLicence = (licence: SubscribedSku) => {
    return takeAction("store", `users/${userId}/assigned-office-licences`, {
      licence_id: licence.skuId,
    })
      .then(({ data }) => {
        setLicences({
          organisation_licences: licenceData?.organisation_licences ?? [],
          user_licences: [...(licenceData?.user_licences ?? []), data.data],
        });
        toast.success("Licence added");
      })
      .catch(errorSwal);
  };

  const deleteLicence = (licence: AssignedOfficeLicense) => {
    return takeAction("destroy", `assigned-office-licences/${licence.uuid}`)
      .then(() => {
        setLicences({
          organisation_licences: licenceData?.organisation_licences ?? [],
          user_licences:
            licenceData?.user_licences.filter((l) => l.uuid !== licence.uuid) ??
            [],
        });
      })
      .catch(errorSwal);
  };

  return (
    <div className="col-lg-6 form-group">
      <button
        type="button"
        onClick={() =>
          currentUserLicence
            ? deleteLicence(currentUserLicence)
            : addLicence(licence)
        }
        className={`${
          currentUserLicence ? "bg-light" : "bg-white"
        } p-3 border shadow-sm rounded w-100 d-flex align-items-center position-relative`}
      >
        <LoadingOverlay loading={loading} />
        <div className="text-start">
          <p className="mb-0">{licence.skuPartNumber}</p>
          {currentUserLicence && (
            <small className="text-secondary">License Assigned</small>
          )}
        </div>
        {currentUserLicence && (
          <AiOutlineCheck className="ms-auto tx-18 text-success" />
        )}
      </button>
    </div>
  );
};

export default UserLicenseDetail;
