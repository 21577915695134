import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import useApi from "../api/useApi";

const ProjectDashboardTabs = ({ number, projectpage, project }) => {
  const { user } = useAuth();
  const { data: orgStandardJobTypes } = useApi("organisations-standard-job-types", []);
  const [hasJobTypeProjectForm, setHasJobTypeProjectForm] = useState(false);

  useEffect(() => {
    if (orgStandardJobTypes) {
      setHasJobTypeProjectForm(!!orgStandardJobTypes.find((jobType) => jobType.job_type_type === "App\\Models\\ProjectForm"));
    }
  }, [orgStandardJobTypes]);

  const isAdmin = user.is_position_admin;

  const internal = project?.internal;

  const canViewOverview = user.hasAccessTo(
    "App\\Models\\Project",
    "view_overview",
  );

  return (
    <TabList>
      <Tab
        link={`/projects/${number}/details`}
        active={projectpage === "details"}
        icon="fa fa-file-text-o"
      >
        Details
      </Tab>
      {((canViewOverview && isAdmin) ||
        user.id === project?.project_manager) && (
        <Tab
          link={`/projects/${number}/main`}
          active={projectpage === "main"}
          icon="fa fa-file-text-o"
        >
          Overview
        </Tab>
      )}
      <Tab
        link={`/projects/${number}/documents`}
        active={projectpage === "documents"}
        icon="fa fa-folder-open-o"
      >
        Documents
      </Tab>
      <Tab
        link={`/projects/${number}/jobs`}
        active={projectpage === "jobs"}
        icon="fa fa-pencil-square-o"
      >
        Jobs
      </Tab>
      {isAdmin && (
        <Tab
          link={`/projects/${number}/deliverables`}
          active={projectpage === "deliverables"}
          icon="fa fa-pencil-square-o"
        >
          Deliverables
        </Tab>
      )}
      {!project.internal && isAdmin && (
        <Tab
          link={`/projects/${number}/invoices`}
          active={projectpage === "invoices"}
          icon="fa fa-pencil-square-o"
        >
          Invoices
        </Tab>
      )}
      {isAdmin && (
        <Tab
          link={`/projects/${number}/purchases`}
          active={projectpage === "purchases"}
          icon="fa fa-pencil-square-o"
        >
          Purchases
        </Tab>
      )}
      {isAdmin && (
        <Tab
          link={`/projects/${number}/allowances`}
          active={projectpage === "allowances"}
          icon="fa fa-pencil-square-o"
        >
          Allowances
        </Tab>
      )}
      <Tab
        link={`/projects/${number}/equipment`}
        active={projectpage === "equipment"}
        icon="fa fa-pencil-square-o"
      >
        Equipment
      </Tab>
      <Tab
        link={`/projects/${number}/emails`}
        active={projectpage === "emails"}
        icon="fa fa-pencil-square-o"
      >
        Emails
      </Tab>
      {isAdmin && (
        <Tab
          link={`/projects/${number}/audit-log`}
          active={projectpage === "audit-log"}
          icon="fa fa-pencil-square-o"
        >
          Audit Log
        </Tab>
      )}

      {(hasJobTypeProjectForm && !internal) && (
        <Tab
          link={`/projects/${number}/project-forms`}
          active={projectpage === "project-forms"}
          icon="fa fa-pencil-square-o"
        >
          QA Forms
        </Tab>
      )}
    </TabList>
  );
};

export default ProjectDashboardTabs;
