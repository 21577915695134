import { SelectedSkill } from "./SkillStaffMatrix";
import Avatar from "../utils/Avatar";
import Empty from "../utils/Empty";
import PaginatedList from "../pagination/PaginatedList";
import useUserCompetencies from "./hooks/useUserCompetencies";

const SkillStaff = ({
  selectedSkills,
  loading,
}: {
  selectedSkills: SelectedSkill[];
  loading: boolean;
}) => {
  if (selectedSkills.length === 0) {
    return (
      <Empty top={false}>
        <div className="mt-5 text-center">
          <h6 className="text-dark">No Skills Selected</h6>
          <p>Select one or more skills from the left to see staff</p>
        </div>
      </Empty>
    );
  }

  return (
    <div
      className="custom-scrollbar"
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <div className="mb-4">
        <h6 className="slim-card-title mb-0">Users</h6>
      </div>
      <PaginatedList
        list={(props: { data?: any[]; pages: any[] }) => {
          const { data, pages } = props;

          const total = pages?.[0]?.meta?.total ?? 0;

          return (
            <div className="col-12">
              <div>
                <p className="mb-1 text-dark">
                  {total} user{total === 1 ? "" : "s"} found
                </p>
              </div>
              {data
                ?.filter((s) => s.competencies.length > 0)
                .map((staffMember) => {
                  return (
                    <div
                      key={staffMember.id}
                      className="bg-white d-flex p-3 border rounded-lg"
                    >
                      <Avatar
                        name={staffMember.name}
                        colour="info"
                        rounded="-circle"
                      />
                      <div className="ms-2">
                        <h6 className="mb-2 text-dark">{staffMember.name}</h6>
                        <p className="mb-0 text-secondary">
                          {staffMember.competencies
                            .map(
                              (competency: any) =>
                                competency.skill_level.full_name,
                            )
                            .join(", ")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }}
        originalFilters={[]}
        indexHook={useUserCompetencies}
        indexHookArguments={[selectedSkills]}
      />
      <div style={{ marginBottom: '30px' }}></div>
    </div>
  );
};

export default SkillStaff;
