import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import SelectInput from "../form/SelectInput";
import renderField from "../utils/renderField";
import required from "../utils/required";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../../api/api";

const ScheduleFields = (props) => {
  const {
    project,
    projectName,
    change,
    isBreak = false,
    initialValues,
  } = props;

  return (
    <div className="row">
      {!isBreak && (
        <>
          <div className="col-lg-8 form-group">
            <Field
              label="Start Date"
              name="start_time_date"
              type="date"
              component={renderField}
              validate={required}
              required
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              label="Start Time"
              name="start_time_time"
              type="time"
              component={renderField}
              validate={required}
              required
            />
          </div>
          <div className="col-lg-8 form-group">
            <Field
              label="Finish Date"
              name="finish_time_date"
              component={renderField}
              type="date"
              validate={required}
              required
            />
          </div>
          <div className="col-lg-4 form-group">
            <Field
              label="Finish Time"
              name="finish_time_time"
              type="time"
              component={renderField}
              validate={required}
              required
            />
          </div>

          <div className="col-lg-6 form-group">
            <Field
              label="Project"
              name="project"
              changeValue={(value) => {
                if (!value) {
                  return;
                }
                change("project_name", value.label);
                change("job_id", "");
                change("internal", value.internal);
              }}
              component={AsyncSelectInput}
              asyncFunction={(inputValue) =>
                api
                  .get(`/schedule-projects?filter[search]=${inputValue}`)
                  .then(({ data }) => data.data)
              }
              validate={required}
              required
            />
            <small>Type to see options.</small>
            <Field name="project_name" component={renderField} type="hidden" />
          </div>
          <div className="col-lg-6 form-group">
            <Field
              name="job_id"
              component={SelectInput}
              label="Job"
              validate={required}
              url={jobUrl(project)}
              changeValue={(value) => {
                if (!value) {
                  return;
                }
                change("job_name", value.label);
              }}
              empty={`Unable to find any jobs for ${projectName}.`}
              required
            />
            <Field name="job_name" type="hidden" component={renderField} />
          </div>
        </>
      )}
      <div className="col-lg-12 form-group">
        <Field
          name="comments"
          label="Comments"
          textarea
          component={renderField}
        />
      </div>
    </div>
  );
};

const jobUrl = (project) => {
  if (!project) {
    return;
  }

  return `schedule-jobs?project_id=${project}`;
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    project: selector(state, "project"),
    projectName: selector(state, "project_name"),
  };
};

export default connect(mapStateToProps, {})(ScheduleFields);
