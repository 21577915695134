import React, { createContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import axios from "../components/api/api";
import Spinner from "../components/utils/Spinner";
import { ProductivityEvent } from "../components/timesheets/timesheetTypes";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import errorSwal from "../components/utils/errorSwal";

export const TimesheetContext = createContext<{
  productivity: ProductivityEvent[];
  fetchTimesheet: () => void;
}>({
  productivity: [],
  fetchTimesheet: () => {},
});

const TimesheetProvider = (props: any) => {
  const [timesheet, setTimesheet] = useState();
  const [productivity, setOverProductivity] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { uuid } = useParams<{ uuid: string }>();
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    uuid && fetchTimesheet();
  }, [uuid]);

  const setProductivity = (productivity: any) => {
    setOverProductivity(productivity);

    dispatch(
      change(
        "AddTimesheet",
        "productivity",
        productivity.map((event: any) => {
          return {
            ...event,
            start_time: dayjs(event.start).format("YYYY-MM-DD HH:mm:ss"),
            finish_time: dayjs(event.end).format("YYYY-MM-DD HH:mm:ss"),
          };
        }),
      ),
    );
  };

  function fetchTimesheet() {
    axios
      .get(`timesheets/${uuid}`)
      .then(({ data }) => {
        updateTimesheet(data.data);
        setLoading(false);
      })
      .catch((err) => {
        errorSwal(err);
        setTimesheet(undefined);
        setProductivity([]);
        setLoading(false);

        if ([404, 403].includes(err?.response?.status)) {
          history.push("/home");
        }
      });
  }

  function updateTimesheet(timesheet: any) {
    setTimesheet(timesheet);

    setProductivity(
      timesheet.productivity.map((event: any) => ({
        id: event.uuid,
        title: event.title,
        start: dayjs(event.start_time).toDate(),
        start_time_date: dayjs(event.start_time).format("YYYY-MM-DD"),
        start_time_time: dayjs(event.start_time).format("HH:mm:ss"),
        end: dayjs(event.finish_time).toDate(),
        finish_time_date: dayjs(event.finish_time).format("YYYY-MM-DD"),
        finish_time_time: dayjs(event.finish_time).format("HH:mm:ss"),
        comments: event.comments,
        job_id: event.job_id,
        project: event.project_id,
        job_name: event.job_name,
        project_name: event.project_name,
        job_link: event.job_link,
        internal: event.internal,
        break_type: event.break_type,
      })),
    );
  }

  if (uuid && loading)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner loading />
      </div>
    );

  return (
    <TimesheetContext.Provider
      value={{
        timesheet,
        updateTimesheet,
        fetchTimesheet,
        productivity,
        setProductivity,
        loading,
      }}
      {...props}
    />
  );
};

const useUserTimesheet = () => {
  const context = React.useContext(TimesheetContext);

  if (context === undefined) {
    throw new Error(`useUserTimesheet must be used within a TimesheetProvider`);
  }
  return context;
};

export { TimesheetProvider, useUserTimesheet };
