import React from "react";
import { useParams } from "react-router-dom";
import { XeroIntegrationProfilePages } from "./XeroIntegrationProfile";
import XeroContacts from "./XeroContacts";
import NotFound from "../404";

const ShowXeroIntegrationPage = () => {
  const { page } = useParams<{ page: XeroIntegrationProfilePages }>();

  switch (page) {
    case "contacts":
      return <XeroContacts />;
    default:
      return <NotFound />;
  }

  return <div>ShowXeroIntegrationPage</div>;
};

export default ShowXeroIntegrationPage;
