import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import ApprovalStatus from "../user/ApprovalStatus";
import ApprovalButton from "../approvals/ApprovalButton";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import errorSwal from "../utils/errorSwal";
import formatDate from "../utils/formatDate";
import Spinner from "../utils/Spinner";
import ShowPage from "./ShowPage";

const DeductionProfile = () => {
  const { uuid } = useParams();

  const {
    loading,
    data: deduction,
    setData: setDeduction,
  } = useApi(`deductions/${uuid}`, null, true);

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    return takeAction("update", `deductions/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Deduction Updated.");
        setDeduction(data.data);
      })
      .catch(errorSwal);
  };

  const tabs = [
    {
      link: `/deductions/${uuid}/details`,
      page: "details",
      label: "Details",
    },
    {
      link: `/deductions/${uuid}/documents`,
      page: "documents",
      label: "Documents",
    },
  ];

  const title = `${deduction?.user?.name}'s Deduction for the ${formatDate(
    deduction?.date,
  )}`;

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage crumbs={[]} titlePage={title} />
      <Profile
        header={
          <DashboardHeader>
            <DashboardH3>{title}</DashboardH3>
          </DashboardHeader>
        }
        tabs={tabs}
        content={<ShowPage onSubmit={onSubmit} deduction={deduction} />}
        sideBar={
          <>
            <ApprovalButton approvalAction={deduction.approval_action} />
            <ApprovalStatus
              className="w-100 mt-3"
              status={deduction.approval_status}
            />
          </>
        }
      />
    </>
  );
};

export default DeductionProfile;
