import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "./Form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { reset } from "redux-form";
import formError from "../utils/formError";

const AddModal = (props) => {
  const { toggle, modal, equipmentType, setEquipmentType } = props;
  const { uuid } = useParams();

  const { takeAction } = useApi();

  const onSubmit = (values, dispatch) => {
    return takeAction(
      "store",
      `equipment-types/${uuid}/calibration-types`,
      values,
    )
      .then(({ data }) => {
        toggle();
        toast.success("Calibration Added");
        dispatch(reset("AddNewCalibration"));
        setEquipmentType({
          ...equipmentType,
          calibration_types: [...equipmentType.calibration_types, data.data],
        });
      })
      .catch(formError);
  };

  return (
    <Modal
      className="wd-md-1000 mx-wd-800"
      backdrop="static"
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Add New Calibration</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ approval_required: true }}
          onSubmit={onSubmit}
          form="AddNewCalibration"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddModal;
