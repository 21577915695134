import React from "react";
import { useParams } from "react-router-dom";
import PaginatedList from "../pagination/PaginatedList";
import useProjectPurchases from "./hooks/useProjectPurchases";
import ResourcePurchaseList from "./ResourcePurchaseList";
import PurchaseButtons from "./PurchaseButtons";
import { purchaseFilters } from "./PurchasesList";
import DocsModal from "../utils/DocsModal";

const ProjectPurchases = props => {
  const { number } = useParams();

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/accounts-9332/purchasing/" />
      <PurchaseButtons {...props} />
      <PaginatedList
        indexHook={useProjectPurchases}
        indexHookArguments={[number]}
        originalFilters={purchaseFilters}
        list={({ data }) => {
          return (
            <div className="col-12">
              <ResourcePurchaseList purchases={data ?? []} />
            </div>
          );
        }}
      />
    </>
  );
};

export default ProjectPurchases;
