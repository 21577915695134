import { useEffect, useState } from "react";
import { Field, FieldArray } from "redux-form";
import { useAuth } from "../../context/auth-context";
import FileInput from "../upload/FileInput";

import axios from "../api/api";
import renderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import AddDocketToNameButton from "./AddDocketToNameButton";
import PricingFieldArray from "./PricingFieldArray";

const FormDeliverable = (props) => {
  const { name, file, job } = props;
  const [projectContacts, setProjectContacts] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    axios.get(`projects/${job.project.uuid}/contacts`).then(({ data }) => {
      const projectContacts = data.data.map((contact) => ({
        label: contact.name,
        value: contact.contact_id,
        deliverable_recipient: contact.deliverable_recipient,
      }));

      props?.change?.(
        "deliverable_recipients",
        projectContacts.filter((contact) => contact.deliverable_recipient),
      );

      setProjectContacts(projectContacts);
    });
  }, []);

  return (
    <>
      {name && (
        <>
          <div className="col-lg-12">
            <div className="form-group">
              <Field
                name="name"
                component={renderField}
                required
                label="Name"
                validate={required}
              />
            </div>
          </div>
          <AddDocketToNameButton {...props} />
        </>
      )}
      <div className="col-lg-12 form-group">
        <Field
          name="purchase_order"
          component={renderField}
          label="Line Item Prefix"
          placeholder="Leave blank if no prefix required."
        />
        <small className="form-text text-muted">
          Add in a note that will be prepended to all line items.
        </small>
      </div>
      <div className="col-lg-6  form-group">
        <Field
          name="deliverable_recipients"
          multiple
          options={projectContacts}
          component={SelectInputAsync}
          label="Recipients"
        />
        <small className="form-text text-muted">
          Please select the people who you want to send this to.
        </small>
      </div>

      <div className="col-lg-6  form-group">
        <Field
          name="deliverable_contacts"
          multiple
          url="/users/staff"
          component={SelectInputAsync}
          label="Contacts"
        />
        <small className="form-text text-muted">
          Please select the people from {user.active_organisation.display_name}{" "}
          who you want to act as contacts for this.
        </small>
      </div>

      {file && (
        <div className="col-lg-12">
          <hr />
          <div className="form-group">
            <Field name="file" component={FileInput} label="Deliverable File" />
          </div>
        </div>
      )}
      <div className="col-lg-12">
        <hr />
        <FieldArray component={PricingFieldArray} {...props} name="pricing" />
      </div>
    </>
  );
};

export default FormDeliverable;
