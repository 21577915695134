import { Link, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import { Register } from "../registers/registerTypes";
import { RegisterItem } from "./registerItemTypes";
import useRegisterItems from "./hooks/useRegisterItems";
import PaginatedCard from "../pagination/PaginatedCard";
import Avatar from "../utils/Avatar";
import { UncontrolledTooltip } from "reactstrap";

const RegisterItemList = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data }: IUseApi<Register> = useApi(`registers/${uuid}`);

  return (
    <>
      <HeaderPage titlePage={data?.name ?? "-"} crumbs={[]} />
      <PaginatedList
        indexHook={useRegisterItems}
        originalFilters={[]}
        indexHookArguments={[uuid]}
        addLink={`/register-items/${uuid}/add`}
        itemCard={({ item }: { item: RegisterItem }) => {
          return (
            <PaginatedCard
              info={[]}
              header={<RegisterItemHeader item={item} />}
            />
          );
        }}
      />
    </>
  );
};

const RegisterItemHeader = ({ item }: { item: RegisterItem }) => {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={item.name} colour={"info"} />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p
            id={`register_title_${item.uuid}`}
            className="mb-0 fw-bolder tx-inverse no-wrap"
          >
            <Link to={`/register-items/${item.uuid}/details`}>{item.name}</Link>
          </p>
          <UncontrolledTooltip
            placement="top-start"
            target={`register_title_${item.uuid}`}
          >
            {item.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};
export default RegisterItemList;
