import React from "react";
import TypeFields from "./TypeFields";

const StaffRoleType = ({ fields, initialValues, roles }) => {
  return (
    <>
      {fields.map((member, index) => (
        <TypeFields
          roles={roles}
          member={member}
          key={index}
          fields={fields}
          index={index}
          initialValues={initialValues}
        />
      ))}
      <div className="form-group col-12 d-flex">
        <button
          className="btn btn-link ms-auto"
          type="button"
          onClick={e => {
            e.preventDefault();
            fields.push({});
          }}
        >
          Add Role Type
        </button>
      </div>
    </>
  );
};

export default StaffRoleType;
