import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import HeaderPage from "../header/HeaderPage";
import PaginatedCard from "../pagination/PaginatedCard";
import PaginatedList from "../pagination/PaginatedList";
import Avatar from "../utils/Avatar";
import { CPIRType } from "./cpirTypeTypes";
import useCpirTypes from "./hooks/useCpirTypes";

const CpirTypesList = () => {
  return (
    <>
      <HeaderPage crumbs={[]} titlePage="CPIR Types" />
      <PaginatedList
        originalFilters={[]}
        indexHook={useCpirTypes}
        addLink="/cpir-types/add"
        itemCard={({ item }: { item: CPIRType }) => {
          return (
            <PaginatedCard
              key={item.id}
              info={[]}
              header={<Header type={item} />}
            />
          );
        }}
      />
    </>
  );
};

const Header = ({ type }: { type: CPIRType }) => {
  const tooltipId = useMemo(() => `type${type.uuid}`, [type.uuid]);

  return (
    <div className="w-100">
      <div className="d-flex align-items-center w-100">
        <div style={{ maxWidth: "15%" }}>
          <Avatar name={type.name} colour="info" />
        </div>
        <div className="ms-1" style={{ width: "85%" }}>
          <p id={tooltipId} className="mb-0 fw-bolder tx-inverse no-wrap">
            <Link to={`/cpir-types/${type.uuid}/details`}>{type.name}</Link>
          </p>
          <UncontrolledTooltip placement="top-start" target={tooltipId}>
            {type.name}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default CpirTypesList;
