import _ from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import formatDate from "../utils/formatDate";
import Spinner from "../utils/Spinner";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import DeleteButton from "./DeleteButton";
import ProfileBar from "./ProfileBar";
import ProfileButton from "./ProfileButton";
import ProfileHeader from "./ProfileHeader";
import ShowPage from "./ShowPage";
import { Field, reduxForm } from "redux-form";
import RenderField from "../utils/renderField";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";

const Profile = (props) => {
  const { uuid, page } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [sortBy, setSortBy] = useState(
    localStorage.getItem("filter_pay_runs_by") ?? "first_name",
  );

  const {
    loading,
    data: payRun,
    setData: setPayRun,
  } = useApi(`pay-runs/${uuid}`, null, true, "/pay-runs");

  if (loading) return <Spinner loading />;

  const change = (newSelectedOptions) => setSelectedOptions(newSelectedOptions);

  const payRunDates = `${formatDate(payRun.start_date)} - ${formatDate(
    payRun.finish_date,
  )}`;

  const filteredPays = payRun.pays.filter((pay) => {
    if (selectedOptions.length === 0) return true;
    return selectedOptions.filter(({ value }) => value === pay.branch_name)
      .length;
  });

  const sortedPays = _.orderBy(
    filteredPays,
    [
      `user.${sortBy}`,
      `user.${sortBy === "first_name" ? "last_name" : "first_name"}`,
    ],
    ["asc", "asc"],
  );

  return (
    <>
      <HeaderPage
        titlePage={payRunDates}
        crumbs={[
          { link: "organisations/settings/links", name: "Organisation" },
          {
            link: "pay-runs",
            name: "Pay Runs",
          },
          {
            link: "/",
            name: payRunDates,
            active: true,
          },
        ]}
      />

      <div className="row row-sm">
        <div className="col-lg-8">
          <ProfileHeader payRun={payRun} payRunDates={payRunDates} />
          <ProfileButton />

          <ShowPage
            filteredPays={sortedPays}
            selectedOptions={selectedOptions}
            change={change}
            payRun={payRun}
            setPayRun={setPayRun}
            sortBy={sortBy}
            setSortBy={setSortBy}
            {...props}
          />
        </div>
        <div className="col col-lg-4">
          <TabList>
            <Tab
              link={`/pay-runs/${uuid}/pays`}
              icon="fa fa-file-text-o"
              active={page === "pays"}
            >
              Pays
            </Tab>
            <Tab
              link={`/pay-runs/${uuid}/branches`}
              icon="fa fa-file-text-o"
              active={page === "branches"}
            >
              Branches ({payRun.branches.length})
            </Tab>
            <Tab
              link={`/pay-runs/${uuid}/timesheets`}
              icon="fa fa-file-text-o"
              active={page === "timesheets"}
            >
              Timesheets
            </Tab>
          </TabList>
          <ProfileBar
            {...props}
            payRun={payRun}
            setPayRun={setPayRun}
            className="mg-y-20 d-none d-lg-block"
          />
          <DeleteButton payRun={payRun} />
        </div>
      </div>
    </>
  );
};

export const CompletedAt = (props) => {
  const { handleSubmit, setPayRun, initialValues, error } = props;

  const { takeAction } = useApi();

  const onSubmit = (values) => {
    const oldPayRun = initialValues;
    setPayRun(values);

    return takeAction("update", `pay-runs/${values.uuid}`, values).catch(
      (err) => {
        setPayRun(oldPayRun);

        return formError(err);
      },
    );
  };

  return (
    <form>
      <div className="row">
        <FormErrorAlert error={error} />
        <div className="form-group col-12">
          <Field
            component={RenderField}
            onBlur={handleSubmit(onSubmit)}
            name="paid_on"
            label="Paid On"
            type="date"
          />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({ form: "PAID_ON", enableReinitialize: true });

export const CompletedAtForm = form(CompletedAt);

export default Profile;
