import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { SampleListFilter, SampleListFilterKeys } from "./SampleList";
import Select from "react-select";
import { FilterOption } from "../../hooks/useFilter";
import FormHeader from "../../utils/FormHeader";
import { useState, useEffect } from "react";
interface SampleDashboardFilterModalProps {
  toggle: React.MouseEventHandler;
  modal: boolean;
  filters: SampleListFilter[];
  toggleFilter: (filterName: SampleListFilterKeys, optionValue: any) => void;
  toggleMulti: (
    filterName: SampleListFilterKeys,
    optionValue: readonly any[],
  ) => void;
  setDateFilter: (date: string[]) => void;
}

type SelectOption = FilterOption<number | boolean, SampleListFilterKeys>;

const SamplesListFilterModal = (props: SampleDashboardFilterModalProps) => {
  const { modal, toggle, filters, toggleFilter, toggleMulti, setDateFilter } =
    props;

  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();

  useEffect(() => {
    if (to && from) {
      setDateFilter([from, to]);
      return;
    }
    setDateFilter([]);
  }, [to, from]);

  return (
    <Modal
      className="wd-md-600 mx-wd-800"
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Filter Samples</ModalHeader>
      <ModalBody>
        <div className="row">
          {filters
            .filter((f) => !f.hide)
            .map((filter) => {
              return (
                <div key={filter.name} className="col-lg-6 form-group">
                  <label className="form-control-label tx-inverse tx-semibold">
                    {filter.label}
                  </label>
                  <Select<SelectOption, boolean>
                    options={filter.options}
                    isClearable
                    className="shadow-sm"
                    isMulti={filter.multiple}
                    value={filter.options.filter((option) => option.selected)}
                    onChange={(value, { action }) => {
                      if (action === "clear") {
                        toggleFilter(
                          filter.name,
                          filter.options.find((f) => f.selected)?.value,
                        );
                        return;
                      }

                      if (!value) {
                        return;
                      }

                      if ("value" in value) {
                        toggleFilter(filter.name, value.value);
                        return;
                      }

                      toggleMulti(filter.name, value);
                    }}
                  />
                </div>
              );
            })}
          <FormHeader>Date Between</FormHeader>
          <div className="col-6">
            <label className="form-control-label tx-inverse tx-semibold">
              From
            </label>
            <input
              type="date"
              className="form-control"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="col-6">
            <label className="form-control-label tx-inverse tx-semibold">
              To
            </label>
            <input
              type="date"
              className="form-control"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default SamplesListFilterModal;
