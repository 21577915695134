import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import CustomForm from "./CustomForm";
import { IUseApi } from "../api/apiTypes";

interface CustomFormPreviewProps {
  formValues: any;
}

const CustomFormPreview = (
  props: CustomFormPreviewProps & InjectedFormProps<{}, CustomFormPreviewProps>,
) => {
  const { formValues } = props;

  const {
    takeAction: generatePrompt,
    loading: generatingPrompt,
  }: IUseApi<{}, { data: string }> = useApi();

  const onPrompt = (
    fieldUuid: string,
    prompt: string,
    name: string,
    change: Function,
  ) => {
    return generatePrompt("store", "general-prompts", {
      prompts: [
        {
          role: "user",
          content: prompt,
        },
      ],
    }).then(({ data }) => {
      change(name, data.data);
    });
  };

  const [preview, setPreview] = useState([]);

  const { takeAction, loading } = useApi(undefined, [], true);

  useEffect(() => {
    if (formValues?.custom_fields?.length > 0) {
      const formWithOrder = {
        custom_fields: formValues.custom_fields.map(
          (field: any, index: number) => {
            return {
              ...field,
              order: index,
            };
          },
        ),
      };

      takeAction("store", "custom-form-preview", formWithOrder)
        .then((response: any) => {
          setPreview(response.data.data);
        })
        .catch(errorSwal);
    }
  }, []);

  if (!formValues?.custom_fields || formValues?.custom_fields?.length < 1) {
    return <div>Minimum one field is required.</div>;
  }

  if (loading) {
    return <CustomScaleLoader>Generating Preview...</CustomScaleLoader>;
  }

  return (
    <form>
      <div className="row">
        <CustomForm
          onPrompt={onPrompt}
          customForm={preview}
          generatingPrompt={generatingPrompt}
          {...props}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  formValues: getFormValues("ProfileCustomFields")(state),
});

const form = reduxForm<{}, CustomFormPreviewProps>({
  form: "CustomFieldPreview",
});

export default connect(mapStateToProps, {})(form(CustomFormPreview));
