import { useState, useMemo } from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import useApi from "../api/useApi";
import BossSearchBox from "../utils/algolia/BossSearchBox";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  useHits,
  RefinementList,
  Pagination,
} from "react-instantsearch";
import { SearchableMailMessage } from "../mailMessages/mailMessages";
import "instantsearch.css/themes/reset.css";
import useModal from "../hooks/useModal";
import BossSearchFilterModal from "./EmailSearchFilterModal";
import DateType from "../../enums/DateType";
import {
  getDaysAgoTimestamps,
  formatDateRangeForFilter,
  timestampToDate,
  dateToTimestamp,
} from "../utils/dateUtils";
import { IoMdArrowBack } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
import { FaPaperclip } from "react-icons/fa";
import getFileIcon from "../utils/getFileIcon";
import CustomScaleLoader from "../utils/scaleLoader";
import swal from "sweetalert";

interface EmailMessagesProps {
  messagableId: number;
  messagableType: string;
}

const ProjectEmails = ({
  messagableId,
  messagableType,
}: EmailMessagesProps) => {
  const { data } = useApi("user-search-token");

  if (!data) {
    return null;
  }

  return (
    <Search
      userToken={data.userToken}
      indexName={data.indexName}
      messagableId={messagableId}
      messagableType={messagableType}
    />
  );
};

interface FilterField {
  displayName: string;
  key: string;
  value: string | number | null | undefined;
  displayValue?: string | number | null | undefined;
  hide?: boolean;
}

const Search = ({
  userToken,
  indexName,
  messagableId,
  messagableType,
}: {
  userToken: string;
  indexName: string;
} & EmailMessagesProps) => {
  const searchClient = useMemo(() => {
    return algoliasearch("WIWY6UTQN9", userToken);
  }, []);
  const [selected, setSelected] = useState<SearchableMailMessage>();
  const { toggle, modal } = useModal();

  const [fromEmailAddress, setFromEmailAddress] = useState<string | null>(null);
  const [toEmailAddress, setToEmailAddress] = useState<string | null>(null);
  const [date, setDate] = useState<number | null>(DateType.any);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const initialFilterField: FilterField[] = [
    {
      displayName: "From",
      key: "messagable_id",
      value: messagableId,
      hide: true,
    },
    {
      displayName: "Type",
      key: "messagable_type",
      value: `'${messagableType}'`,
      hide: true,
    },
  ];

  const [filterData, setFilterData] =
    useState<FilterField[]>(initialFilterField);

  const handleClearFilter = () => {
    setFromEmailAddress(null);
    setToEmailAddress(null);
    setDate(DateType.any);
    setDateFrom(null);
    setDateTo(null);
    setFilterData(initialFilterField);
  };

  const onSubmit = (values: any) => {
    toggle();
    setFromEmailAddress(values.fromEmailAddress);
    setToEmailAddress(values.toEmailAddress);
    setDate(values.date);
    setDateFrom(values.dateFrom);
    setDateTo(values.dateTo);

    let filter: FilterField[] = initialFilterField;

    if (values.fromEmailAddress) {
      filter.push({
        displayName: "From",
        key: "from.emailAddress",
        value: values.fromEmailAddress,
      });
    }

    if (values.toEmailAddress) {
      filter.push({
        displayName: "To",
        key: "to.emailAddress",
        value: values.toEmailAddress,
      });
    }

    if (values.date && values.date != DateType.any) {
      const dateRangeValue =
        values.date === DateType.last7Days
          ? getDaysAgoTimestamps(7)
          : values.date === DateType.last30Days
          ? getDaysAgoTimestamps(30)
          : values.date === DateType.customDates
          ? formatDateRangeForFilter(values.dateFrom, values.dateTo)
          : null;

      if (dateRangeValue) {
        filter.push({
          displayName: "Date",
          key: "created_at",
          value: dateRangeValue?.value,
          displayValue: dateRangeValue?.displayValue,
        });
      }
    }

    setFilterData(filter);
  };

  return (
    <>
      <div className="mb-2">
        <h5 className="tx-12 text-dark fw-normal ">
          This feature is currently in BETA, please email{" "}
          <a href="mailto:info@thebossapp.com.au">info@thebossapp.com.au</a> if
          you find any issues or have any feedback
        </h5>
      </div>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure
          filters={filterData
            .map((filter) => `${filter.key}:${filter.value}`)
            .join(" AND ")}
        />
        {selected ? (
          <ShowEmail mailMessage={selected} setSelected={setSelected} />
        ) : (
          <>
            <div className="search-box shadow-sm d-flex mb-3">
              <BossSearchBox toggle={toggle}>
                <BossSearchFilterModal
                  handleSubmit={onSubmit}
                  toggle={toggle}
                  initialValues={{
                    fromEmailAddress,
                    toEmailAddress,
                    date,
                    dateFrom,
                    dateTo,
                  }}
                  modal={modal}
                />
              </BossSearchBox>
            </div>

            {/* Add RefinementList widgets for additional fields */}
            <RefinementList attribute="body" />
            {/* Add more RefinementList widgets for other fields as needed */}

            <Pagination />
            <Hits
              setSelected={setSelected}
              filterData={filterData}
              handleClearFilter={handleClearFilter}
            />
          </>
        )}
      </InstantSearch>
    </>
  );
};

const Hits = ({
  setSelected,
  filterData,
  handleClearFilter,
}: {
  setSelected: (message: SearchableMailMessage) => void;
  filterData: FilterField[];
  handleClearFilter: () => void;
}) => {
  const { hits } = useHits<SearchableMailMessage>();

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        {filterData.length > 1 && (
          <Button
            size="sm"
            outline
            className="ms-auto"
            onClick={handleClearFilter}
          >
            Clear Filter
          </Button>
        )}
      </div>
      <div className="d-flex align-items-center mb-2">
        {filterData.length > 0 && (
          <div>
            <ul>
              {filterData
                .filter((f) => !f.hide)
                .map((filter, index) => {
                  return (
                    <li key={index}>
                      {filter.displayName}:{" "}
                      {filter.displayValue ?? filter.value}
                    </li>
                  );
                  return null;
                })}
            </ul>
          </div>
        )}
      </div>

      {_.orderBy(hits, "created_at", "desc").map((hit) => {
        return <Hit key={hit.__position} hit={hit} setSelected={setSelected} />;
      })}
    </div>
  );
};

const Hit = ({
  hit,
  setSelected,
}: {
  hit: SearchableMailMessage;
  setSelected: (message: SearchableMailMessage) => void;
}) => {
  return (
    <button
      className={`bg-white p-3 border-end border-start border-0 border-bottom w-100 text-start ${
        hit.__position === 1 ? "border-top" : ""
      }`}
      onClick={() => setSelected(hit)}
    >
      <div className="d-flex">
        <h6 className="mb-0 text-dark">From: {hit.from.displayName}</h6>
        {hit.attachments?.length > 0 && (
          <p className="ms-auto mb-0">
            <FaPaperclip className="tx-16" />
          </p>
        )}
      </div>
      <div className="d-flex">
        <p className="mb-0 text-dark">{hit.subject ?? "No Subject"}</p>
        <p className="mb-0 tx-10 ms-auto ">
          {timestampToDate(dateToTimestamp(hit.created_at))}
        </p>
      </div>
      <p className="mb-0 tx-12 text-dark fw-normal ">
        Added By: {hit.added_by?.displayName}
      </p>
      <p className="mb-0 text-secondary">
        {hit.body?.slice(0, 100) ?? "No Body"}
      </p>
    </button>
  );
};

const ShowEmail = ({
  mailMessage,
  setSelected,
}: {
  mailMessage: SearchableMailMessage;
  setSelected: (message: SearchableMailMessage | undefined) => void;
}) => {
  const returnToListIfNotFound = (): void => {
    setSelected(undefined);
  };

  const { data, loading, response } = useApi(
    `mail-messages/${mailMessage.uuid}`,
    {},
    true,
    returnToListIfNotFound
  );
  
  if (data.messageNotFound) {
    setSelected(undefined);
    swal({
      title: "Email Synchronization Incomplete",
      text: data.message,
      icon: "info",
    });
  }
  
  if (loading) {
    return (
      <div className="mt-5">
        <CustomScaleLoader>Fetching Email Details...</CustomScaleLoader>
      </div>
    );
  }

  const excerpt = (text?: string, maxLength: number = 15): string => {
    if (!text) {
      return "No File Name Found";
    }

    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + "...";
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-2 tx-16">
        <Button
          onClick={() => setSelected(undefined)}
          color="link"
          className="ps-0"
        >
          <IoMdArrowBack className="tx-18 text-secondary" />
        </Button>
        <a href={data.link} color="link" className="ms-auto pe-0">
          <BsDownload className="tx-16 text-secondary" />
        </a>
      </div>

      <div className="bg-white p-4 rounded-lg border mb-5 s">
        {data.attachments &&
          data.attachments
            .reduce((chunks: any, attachment: any, index: any) => {
              const chunkIndex = Math.floor(index / 3);

              if (!chunks[chunkIndex]) {
                chunks[chunkIndex] = [];
              }

              chunks[chunkIndex].push(attachment);

              return chunks;
            }, [])
            .map((chunk: any, chunkIndex: any) => (
              <ul
                key={chunkIndex}
                className="list-group list-group-horizontal-lg mb-3"
              >
                {chunk.map((attachment: any, index: any) => (
                  <li
                    style={{
                      borderLeftWidth: "1px",
                      borderRadius: "0.3rem",
                      width: "33.33%",
                    }}
                    key={index}
                    className={`project-email-attachment list-group-item ${
                      index !== 0 ? "ms-2" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <i
                        className={`${getFileIcon(
                          attachment.file_name,
                          false,
                        )} me-1 tx-16`}
                      />
                      <span className="ps-1">
                        {excerpt(attachment.file_name, 15)}
                      </span>
                      <a
                        href={attachment.link}
                        color="link"
                        className="ms-auto pe-0 ps-3"
                      >
                        <BsDownload className="tx-16 text-secondary" />
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            ))}
        <div
          dangerouslySetInnerHTML={{ __html: data.body?.content ?? data.html }}
        />
      </div>
    </div>
  );
};

export default ProjectEmails;
