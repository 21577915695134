import {
  FETCH_NUCLEAR_DENSOMETERS,
  UPDATE_NUCLEAR_DENSOMETER
} from "../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_NUCLEAR_DENSOMETERS:
      return {
        ...state,
        items: action.payload
      };
    case UPDATE_NUCLEAR_DENSOMETER:
      return {
        ...state,
        items: state.items.map(item =>
          item.uuid !== action.payload.uuid ? item : action.payload
        )
      };
    default:
      return state;
  }
}
