import EditPosition from "./Edit";
import NotFound from "../404";
import ProfileSingleFile from "../utils/ProfileSingleFile";
import RequiredCompetencies from "../requiredCompetencies";
import { useAuth } from "../../context/auth-context";

const ShowPage = (props) => {
  const { match, position, setPosition } = props;
  const { user } = useAuth();

  const whenSubmitted = ({ data }) => {
    setPosition({
      ...position,
      document: data.data,
    });
  };

  switch (match.params.page) {
    case "details":
      return <EditPosition {...props} />;
    case "position-description":
      return (
        <ProfileSingleFile
          {...props}
          link={position.file?.link}
          mime_type={position.file?.mime_type}
          whenSubmitted={whenSubmitted}
          documentable_id={position.uuid}
          documentable_type="App\Models\OrganisationPosition"
          bucket={`positions/${position.uuid}`}
        />
      );
    case "required-competencies":
      return (
        <RequiredCompetencies model="OrganisationPosition" id={position.id} />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
