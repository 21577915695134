import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";
import SubmitButton from "../utils/SubmitButton";
import SelectInput from "../form/SelectInput";
import required from "../utils/required";
import FieldInformationPopOver from "../utils/FieldInformationPopOver";
import { connect } from "react-redux";

export interface DetailForm {
  extra_info: {
    user_email_template: string;
    user_email_template_domain: string;
  };
}

interface DetailsProps {
  template?: string;
  domain?: string;
}

const Details = (
  props: InjectedFormProps<DetailForm, DetailsProps> & DetailsProps,
) => {
  const { handleSubmit, template, domain } = props;

  const { takeAction }: IUseApi = useApi();

  const [templatePreview, setTemplatePreview] = useState("");

  useEffect(() => {
    previewEmail();
  }, [template, domain]);

  const previewEmail = () => {
    if (template && domain) {
      takeAction("store", "email-template-preview", {
        template: `${template}@${domain}`,
      }).then(({ data }) => setTemplatePreview(data.data));
    }
  };

  const onSubmit: FormSubmitHandler<DetailForm> = (values) => {
    return takeAction("update", "integrations/microsoft/details", values)
      .then(() => {
        toast.success("Details updated.");
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <div className="d-flex space-x-1 align-items-start">
            <label
              htmlFor="extra_info.user_email_template"
              className="form-control-label tx-inverse tx-semibold"
            >
              User Email Template
            </label>
            <span className="tx-danger"> *</span>
            <FieldInformationPopOver
              information={
                "This is the template for how you would like emails to get created in microsoft. Available variables are ${first_name}, ${last_name}, ${first_name_first_letter}, ${last_name_first_letter}, ${display_name}."
              }
            />
          </div>
          <div className="d-flex border bg-white">
            <Field
              component={RenderField}
              name="extra_info.user_email_template"
              placeholder="e.g. ${first_name}.${last_name}"
              validate={required}
              required
              className="border-0 w-50"
            />
            <div className="w-50">
              <Field
                component={SelectInput}
                validate={required}
                required
                name="extra_info.user_email_template_domain"
                className="border-0 w-50 bg-gray-100 rounded-0"
                controlStyles={{
                  border: "none",
                  borderRadius: "0 0.3rem 0.3rem 0",
                  backgroundColor: "#f2f2f2",
                }}
                url="/microsoft/domains"
                formatData={(data: { id: string }[]) =>
                  data.map((d) => ({
                    label: `@${d.id}`,
                    value: d.id,
                  }))
                }
              />
            </div>
          </div>
          {templatePreview && (
            <small className="mb-0">
              Your email from this template is {templatePreview}
            </small>
          )}
        </div>
        <div className="col-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<DetailForm, DetailsProps>({
  form: "MicrosoftDetails",
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("MicrosoftDetails");

  return {
    template: selector(state, "extra_info.user_email_template"),
    domain: selector(state, "extra_info.user_email_template_domain"),
  };
};

export default connect(mapStateToProps)(form(Details));
