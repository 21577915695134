import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { HazardReport } from "../hazardReports/types";
import CustomScaleLoader from "../utils/scaleLoader";

const ProjectHazardReports = ({ project }: { project: { id: number } }) => {
  const { number } = useParams<{ number: string }>();

  const { data, loading }: IUseApi<HazardReport[]> = useApi(
    `/projects/${number}/hazard-reports`,
    [],
    true,
  );

  if (loading) {
    return <CustomScaleLoader>Fetching Take 5s...</CustomScaleLoader>;
  }

  return (
    <>
      <div className="bg-white border">
        {data?.map((report) => {
          return (
            <Link
              to={`/hazard-reports/${report.uuid}`}
              className="p-3 d-flex align-items-center border-bottom text-secondary"
            >
              <div>
                <p className="mb-1 text-dark">{report.prompt}</p>
                <p className="mb-0">
                  Added by{" "}
                  <span className="text-dark">{report?.user?.name}</span>
                </p>
              </div>
              <div className="ms-auto">
                <FaCaretRight />
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default ProjectHazardReports;
