import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import Papa from "papaparse";
import {
  Fragment,
  HTMLProps,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "reactstrap";
import { ReactComponent as NoData } from "../../svgs/no_data.svg";
import LoadingOverlay from "../utils/LoadingOverlay";
import SVGContainer from "../utils/SVGContainer";
import ToggleColumns from "./ToggleColumns";

interface TableProps<RowType> {
  data: RowType[];
  columns: ColumnDef<RowType>[];
  wrapperClasses?: string;
  loading?: boolean;
  disableSearch?: boolean;
  enableCsvExport?: boolean;
  CsvFileName?: string;
  enableMultiSelect?: boolean;
  extraButtons?: JSX.Element;
  customEmpty?: JSX.Element;
  onRowClick?: (item: RowType, e: any, row: Row<RowType>) => void;
  setSelectedRows?: (rows: RowType[]) => void;
  getRowCanExpand?: (row: any) => boolean;
  renderSubComponent?: (row: any) => ReactElement;
  rowClasses?: (row: RowType) => string;
  searchWrapperClasses?: string;
  getTableInstance?: (table: any) => void;
  hideFooter?: boolean;
  enableColumnToggle?: boolean;
}

const ReactTable = <RowType extends object>(props: TableProps<RowType>) => {
  const {
    data = [],
    columns = [],
    wrapperClasses = "table-responsive card",
    loading = false,
    disableSearch,
    enableMultiSelect,
    enableCsvExport,
    CsvFileName,
    extraButtons,
    customEmpty = null,
    onRowClick = () => {},
    setSelectedRows = () => {},
    getRowCanExpand = () => false,
    renderSubComponent = () => null,
    rowClasses,
    searchWrapperClasses,
    getTableInstance,
    hideFooter = false,
    enableColumnToggle = false,
  } = props;

  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  enableMultiSelect &&
    columns[0]?.id !== "select" &&
    columns.unshift({
      id: "select",
      header: ({ table }: { table: any }) => (
        <MultiSelectCheckBox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: { row: any }) => (
        <div className="px-1">
          <MultiSelectCheckBox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    });

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  useEffect(() => {
    if (enableMultiSelect) {
      setSelectedRows(
        data?.filter((row, index) =>
          Object.keys(rowSelection).includes(index.toString()),
        ),
      );
    }
  }, [rowSelection]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      rowSelection,
    },
    getSubRows: (row) => row.subRows,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  useEffect(() => {
    getTableInstance?.(table);
  }, [table]);

  const generateCsvFile = ({
    CsvFileName,
    selectedRows = false,
  }: {
    CsvFileName: string | undefined;
    selectedRows: boolean;
  }) => {
    const csvRawData = selectedRows
      ? table.getSelectedRowModel()
      : table.getRowModel();

    const csvData = csvRawData.rows.map((row: any) =>
      row
        .getVisibleCells()
        .filter((cell: any) => cell.column.id !== "select")
        .map((cell: any) => {
          const context = cell.getContext();
          return _.has(context, "column.columnDef.csvFormatter")
            ? context.column.columnDef.csvFormatter(context.row.original)
            : context.getValue();
        }),
    );

    const headers = table
      .getAllColumns()
      .filter((col: any) => col?.id !== "select" && col.getIsVisible())
      .map((col) => {
        let name = col.columnDef.header;
        if (typeof name === "object" || typeof name === "function") {
          name = col.id;
        }
        return name ?? "-";
      });

    const csvString = Papa.unparse({ fields: headers, data: csvData });
    const fileBlob = new Blob([csvString], { type: "text/csv" });
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.download = `${CsvFileName ?? "export"}${
      selectedRows ? "-selected" : ""
    }-${dayjs().format("DD-MM-YYYY-HH-mm-ss")}.csv`;
    link.href = dataUrl;
    link.click();
  };

  return (
    <>
      {!disableSearch && (
        <div
          className={classNames(
            "d-flex mb-3 align-items-center",
            searchWrapperClasses,
          )}
        >
          <>
            <div className="search-box shadow-sm d-flex flex-grow-1">
              <SearchInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                style={{ zIndex: 1 }}
                placeholder="Search..."
              />
            </div>
            {extraButtons}
            {enableCsvExport && (
              <Button
                outline
                size="sm"
                className="btn m-1"
                onClick={() =>
                  generateCsvFile({ CsvFileName, selectedRows: false })
                }
              >
                Export CSV
              </Button>
            )}
            {enableColumnToggle && <ToggleColumns table={table} />}
            {enableMultiSelect &&
              enableCsvExport &&
              Object.keys(rowSelection).length > 0 && (
                <Button
                  outline
                  size="sm"
                  className="btn m-1 mb-3"
                  onClick={() =>
                    generateCsvFile({ CsvFileName, selectedRows: true })
                  }
                >
                  Export (Selected) CSV
                </Button>
              )}
          </>
        </div>
      )}
      <div className={`react-bootstrap-table ${wrapperClasses}`}>
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="position-relative">
            <LoadingOverlay loading={loading} />
            {data?.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row) => {
                  const item = row.original;
                  const calculatedRowClasses = rowClasses
                    ? rowClasses(row.original)
                    : "";

                  return (
                    <Fragment key={row.id}>
                      <tr
                        className={classNames(calculatedRowClasses)}
                        onClick={(e) => onRowClick(item, e, row)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            /** @ts-ignore */
                            style={cell.column.columnDef.style ?? {}}
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && (
                        <tr>
                          <td colSpan={row.getVisibleCells().length}>
                            {renderSubComponent(row)}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className="mt-3 mb-3">
                    <SVGContainer SVG={NoData} width="25%" top={false}>
                      <p className="mt-2 mb-0 tx-inverse tx-medium">
                        {loading
                          ? "Fetching Data..."
                          : customEmpty ?? "No data found"}
                      </p>
                    </SVGContainer>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {!hideFooter && (
            <tfoot>
              {table.getFooterGroups().map((group) => (
                <tr key={group.id}>
                  {group.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

function SearchInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <>
      <input
        {...props}
        className="form-control w-100"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <button className="btn btn-primary ms-auto" type="button">
        <i className="fa fa-search" />
      </button>
    </>
  );
}

function MultiSelectCheckBox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}

export default ReactTable;
