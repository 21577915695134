import React, { useEffect, useState } from "react";
import PricingFields from "./PricingFields";
import axios from "../api/api";
import useApi from "../api/useApi";

const PricingFieldArray = (props) => {
  const { fields, isInvoiced } = props;
  const [pricingItems, setPricingItems] = useState([]);
  const [units, setUnits] = useState([]);

  const { data: pricingTypes } = useApi(`pricing-types`, []);

  useEffect(() => {
    axios
      .get(`/pricing-items`)
      .then(({ data }) => setPricingItems(data.data))
      .then(() =>
        axios.get(
          `/react-select?model=${encodeURIComponent(
            "App\\Models\\BillableItemUnit",
          )}&select=id,name&label=name&value=id&orderyBy[name]=ASC`,
        ),
      )
      .then(({ data }) => setUnits(data));
  }, []);

  return (
    <>
      {fields.map((pricing, index) => (
        <PricingFields
          pricing={pricing}
          fields={fields}
          pricingItems={pricingItems}
          pricingTypes={pricingTypes}
          key={index}
          units={units}
          index={index}
          {...props}
        />
      ))}

      {!isInvoiced && (
        <div className="row">
          <div className="col-lg-12 d-flex form-group">
            <button
              type="button"
              className="btn ms-auto btn-link"
              onClick={() => fields.push()}
            >
              Add Pricing
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PricingFieldArray;
