import useApi from "../api/useApi";
import FormHeader from "../utils/FormHeader";
import XeroIntegration from "../xero/XeroIntegration";
import MicrosoftIntegration from "./microsoft//MicrosoftIntegration";
import { IUseApiWithData } from "../api/apiTypes";
import {
  Integration,
  IntegrationPlatform,
  IntegrationType,
} from "./integrationTypes";
import { TMicrosoftIntegration } from "../microsoft/microsoftTypes";
import { IXeroIntegration } from "../xero/xeroTypes";
import CustomScaleLoader from "../utils/scaleLoader";
import { IXeroPayrollIntegration } from "./xeroPayroll/xeroPayrollTypes";
import EmploymentHeroIntegration from "./EmploymentHero/EmploymentHeroIntegration";

const Integrations = () => {
  const { data, takeAction, loading }: IUseApiWithData<Integration[]> = useApi(
    "integrations",
    [],
    true,
  );

  const xeroIntegration = data.find(
    (integration) =>
      integration.name === IntegrationPlatform.Xero &&
      integration.type_description === IntegrationType.Accounting,
  ) as IXeroIntegration | undefined;

  const microsoftIntegration = data.find(
    (integration) => integration.name === IntegrationPlatform.Microsoft,
  ) as TMicrosoftIntegration | undefined;

  const xeroPayroll = data.find(
    ({ name, type_description }) =>
      name === IntegrationPlatform.Xero &&
      type_description === IntegrationType.Payroll,
  ) as IXeroPayrollIntegration | undefined;

  const employmentHero = data.find(
    ({ name, type_description }) =>
      name === IntegrationPlatform.EmploymentHero &&
      type_description === IntegrationType.Payroll,
  ) as IXeroPayrollIntegration | undefined;

  if (loading) {
    return <CustomScaleLoader>Fetching Integrations...</CustomScaleLoader>;
  }

  return (
    <>
      <div className="row">
        <FormHeader>Accounting Integrations</FormHeader>
        <XeroIntegration isIntegrated={xeroIntegration} />
      </div>

      <div className="row mt-5">
        <FormHeader>Office Suite Integrations</FormHeader>
      </div>
      <MicrosoftIntegration isIntegrated={microsoftIntegration} />
      <div className="row mt-5">
        <FormHeader>Payroll Integrations</FormHeader>
      </div>
      {/* <div>Coming Soon</div> */}
      {/* <XeroPayroll isIntegrated={xeroPayroll} /> */}
      <EmploymentHeroIntegration isIntegrated={employmentHero} />
    </>
  );
};

export default Integrations;
