import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CALIBRATIONS_TYPES from "../../data/calibration_types";
import CALIBRATIONS_TYPE_UNITS from "../../data/calibration_type_units";
import useApi from "../api/useApi";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import FormErrorAlert from "../form/FormErrorAlert";

const Form = (props) => {
  const { handleSubmit, frequencyUnits, unitType, overdueUnits, isTailoredCalibrationJobDeadlines } = props;
  const { response } = useApi(
    `react-select?model=${encodeURIComponent(
      "App\\Models\\CalibrationUnit",
    )}&select=name,id&label=name&value=id`,
    [],
  );

  const overdueUnitsInitial = overdueUnits == 3;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="form-group col-lg-12">
          <Field
            name="procedures"
            component={RenderField}
            label="Name"
            validate={required}
            required
          />
        </div>
        <div className="col-lg-6 mb-3">
          <Field
            component={SelectInputAsync}
            name="unit_type"
            label="Frequency Units"
            options={[
              { label: "Time", value: "0" },
              { label: "Units", value: 1 },
            ]}
          />
        </div>
        <div className="col-lg-6 form-group">
          <Field
            component={renderToggleInput}
            name="approval_required"
            label="Approval Required"
          />
        </div>
        <div className="form-group col-lg-3">
          <Field
            name="type"
            component={SelectInputAsync}
            options={CALIBRATIONS_TYPES}
            label="Type"
            validate={required}
            required
          />
        </div>
        <div className="form-group col-lg-3">
          <Field
            name={unitType == 0 ? "frequency_units" : "unit_frequency_units"}
            component={SelectInputAsync}
            options={
              unitType == 0 ? CALIBRATIONS_TYPE_UNITS : response?.data ?? []
            }
            label="Units"
            validate={required}
            required
            order={unitType == 0 ? false : true}
          />
        </div>
        {frequencyUnits != 3 && (
          <div className="form-group col-lg-3">
            <Field
              name="frequency"
              component={RenderField}
              label="Frequency"
              type="number"
              validate={required}
              required
            />
          </div>
        )}
        <div className="form-group col-lg-3">
          <Field
            name="source"
            component={RenderField}
            type="text"
            label="Source"
            validate={required}
            required
          />
        </div>
        {unitType == 0 ? (
          <>
            <div
              className={`${
                overdueUnitsInitial ? "col-lg-12" : "col-lg-6"
              } form-group`}
            >
              <Field
                name="critically_overdue_units"
                component={SelectInputAsync}
                options={CALIBRATIONS_TYPE_UNITS}
                label="Critically Overdue Units"
              />
            </div>
            {overdueUnitsInitial ? null : (
              <div className="col-lg-6 form-group">
                <Field
                  name="critically_overdue_amount"
                  component={RenderField}
                  label="Critically Overdue Amount"
                  type="number"
                />
              </div>
            )}

            <div className="col-lg-6 form-group">
              <Field
                component={renderToggleInput}
                name="is_tailored_calibration_job_deadlines"
                label="Customized Job Creation Deadline"
                information="When enabled, this field allows you to personalize the deadlines for calibration job creation, catering to your unique requirements. You can customize the scheduling process for job creation, ensuring precise generation of calibration tasks. For instance, you can set a specific time frame, like one month before the deadline, for the job to appear on the manager's screen."
              />
            </div>
          </>
        ) : null}

        {isTailoredCalibrationJobDeadlines == 1 && unitType == 0 ? (
          <>
            <div className="form-group col-lg-3">
                <Field
                    name="job_creation_units"
                    component={SelectInputAsync}
                    options={CALIBRATIONS_TYPE_UNITS.filter((item) => {
                      return item.value !== '3';
                    })}
                    label="Units"
                  />
            </div>

            <div className="form-group col-lg-3">
              <Field
                  name="job_creation_length"
                  component={RenderField}
                  label="Length"
                  type="number"
                />
            </div>
          </>
        ) : null}

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  initialValues: {
    unit_type: 0,
  },
});

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    frequencyUnits: selector(state, "frequency_units"),
    unitType: selector(state, "unit_type"),
    isTailoredCalibrationJobDeadlines: selector(state, "is_tailored_calibration_job_deadlines"),
    overdueUnits: selector(state, "critically_overdue_units"),
  };
};

export default connect(mapStateToProps, {})(form(Form));
