import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React from "react";
import { Organization, SubscribedSku } from "@microsoft/microsoft-graph-types";
import {
  OfficeSuitePosition,
  OfficeSuitePositionLicence,
} from "./microsoftTypes";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";

export interface ModalProps {
  toggle: React.MouseEventHandler<any>;
  modal: boolean;
  skus: SubscribedSku[];
  syncedPosition?: OfficeSuitePosition;
  positions: OfficeSuitePosition[];
  setPositions: (positions: OfficeSuitePosition[]) => void;
  position: { id: number; name: string };
}

const LicenceModal = ({
  toggle,
  modal,
  skus,
  syncedPosition,
  setPositions,
  positions,
  position,
}: ModalProps) => {
  const {
    takeAction,
  }: IUseApi<{}, { data: { licence_id: string; uuid: string } }> = useApi();

  const addLicence = (licence: SubscribedSku) => {
    return takeAction(
      "store",
      `office-suite-positions/${syncedPosition?.uuid}/licences`,
      {
        licence_id: licence.skuId,
      },
    )
      .then(({ data }) => {
        setPositions(
          positions?.map((p) =>
            p.id !== syncedPosition?.id
              ? p
              : {
                  ...syncedPosition,
                  licences: [...syncedPosition?.licences, data.data],
                },
          ),
        );
        toast.success("Licence added successfully");
      })
      .catch(errorSwal);
  };

  const removeLicence = (licence: OfficeSuitePositionLicence) => {
    return takeAction(
      "destroy",
      `office-suite-position-licences/${licence.uuid}`,
    ).then(() => {
      setPositions(
        positions?.map((p) =>
          p.id !== syncedPosition?.id
            ? p
            : {
                ...syncedPosition,
                licences: syncedPosition?.licences.filter(
                  (l) => l.uuid !== licence.uuid,
                ),
              },
        ),
      );
      toast.success("Licence removed successfully");
    });
  };

  return (
    <Modal
      className="wd-md-1000 mx-wd-800"
      backdrop="static"
      toggle={toggle}
      isOpen={modal}
    >
      <ModalHeader toggle={toggle}>
        Edit Licences for {position.name}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          {skus.map((sku) => {
            const selectedLicence = syncedPosition?.licences.find((licence) => {
              return licence.licence_id === sku?.skuId;
            });

            return (
              <div className="col-lg-4">
                <button
                  onClick={() => {
                    selectedLicence
                      ? removeLicence(selectedLicence)
                      : addLicence(sku);
                  }}
                  type="button"
                  className="p-3 border rounded text-start bg-transparent"
                >
                  <div className="d-flex align-items-center">
                    <p className="text-dark mb-0">{sku.skuPartNumber}</p>{" "}
                    {selectedLicence ? (
                      <FaCheck className="text-success ms-4" />
                    ) : null}
                  </div>
                  {selectedLicence ? (
                    <small className="text-secondary">Assigned</small>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            );
          })}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LicenceModal;
