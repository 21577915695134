import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import errorSwal from "../utils/errorSwal";
import CustomScaleLoader from "../utils/scaleLoader";
import AddModal from "./AddEditModal";
import List from "./List";
import { Invoice, Supplier } from "../../enums/Model";
import DocsModal from "../utils/DocsModal";

const ChartOfAccounts = () => {
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const userCanAdd = user.hasAccessTo("App\\Models\\ChartOfAccount", "create");

  const {
    takeAction,
    data: chartOfAccounts,
    setData: setChartOfAccounts,
    loading,
  } = useApi("chart-of-accounts", []);
  const { takeAction: storeAccount } = useApi();

  const [taxTypes, setTaxTypes] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    takeAction("index", "chart-of-account-types").then(({ data }) => {
      setTypes(data);
    });

    takeAction("index", "tax-types").then(({ data }) => {
      setTaxTypes(data.data);
    });
  }, []);

  const onSubmit = (values, dispatch) => {
    return storeAccount("store", "chart-of-accounts", values)
      .then(({ data }) => {
        toast.success("Chart of Account Added!");
        dispatch(reset("ChartOfAccounts"));

        const newSales = data.data.sales_account
          ? chartOfAccounts.map(account => ({
              ...account,
              sales_account: false,
            }))
          : chartOfAccounts;

        setChartOfAccounts([data.data, ...newSales]);
        toggle();
      })
      .catch(errorSwal);
  };

  const chartState = {
    chartOfAccounts,
    setChartOfAccounts,
  };

  const crumbs = [{ link: "", name: "Chart Of Accounts", active: true }];

  if (user.is_admin) {
    crumbs.unshift({
      link: "organisations/settings/links",
      name: "Organisation",
    });
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/organisation-setup-9316/groups/" />
      <HeaderPage
        titlePage="Chart of Accounts"
        pageDescription={
          <>
            A chart of accounts is required for handling invoices and purchases,
            offering advanced features to tailor and track financial
            transactions within your organization.
          </>
        }
        relatedLinks={[
          { name: "Invoices", link: "/invoices", model: Invoice },
          { name: "Suppliers", link: "/suppliers", model: Supplier },
        ]}
        crumbs={crumbs}
      />
      {loading ? (
        <CustomScaleLoader>Fetching Chart of Accounts...</CustomScaleLoader>
      ) : (
        <>
          {userCanAdd && (
            <button
              className="btn btn-outline-primary mb-2"
              type="button"
              onClick={toggle}
            >
              Add Account
            </button>
          )}
          <List taxCodes={taxTypes} {...chartState} types={types} />
          {userCanAdd && (
            <AddModal
              taxCodes={taxTypes}
              modal={modal}
              toggle={toggle}
              title="Add New Account"
              {...chartState}
              types={types}
              form="ChartOfAccounts"
              onSubmit={onSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChartOfAccounts;
