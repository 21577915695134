export interface StandardDocument {
  uuid: string;
  id: number;
  name: string;
  is_folder: boolean;
  created_at: string;
  has_files: boolean;
  mime_type: string;
  link: string;
  created_by: {
    name: string;
    email: string;
  };
  permissions: DocumentPermissions;
}

export enum DocumentPermissions {
  Admin,
  Senior,
  Junior,
  Basic,
}

export interface StandardDocumentShare {
  id: number;
  uuid: string;
  email: string;
  shareable_type: string;
  shareable_id: number;
  expires_at?: string;
}

export interface ParsedEmail {
  from: {
    email: string;
    name: string;
  };
  html: string;
  text: string;
  subject: string;
  to: {
    email: string;
    name: string;
  }[];
  cc: {
    name: string;
    email: string;
  }[];
  bcc: {
    name: string;
    email: string;
  }[];
}
