import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, FieldArray, Form, reduxForm, submit } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import { formatSuppliers } from "../purchases/Fields";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import errorSwal from "../utils/errorSwal";
import history from "../utils/History";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import SubmitButton from "../utils/SubmitButton";
import FrequencyFields from "./FrequencyFields";
import ItemFieldArray from "./ItemFieldArray";

const RecurringPurchaseModal = (props) => {
  const [suppliers, setSuppliers] = useState([]);
  const { toggle, modal, handleSubmit } = props;
  const { user } = useAuth();
  const { number } = useParams();

  const submitForm = (values) => {
    return uploadToS3(
      values.file,
      `organisations/${user.active_organisation.uuid}/recurring-payment-quotes`,
    )
      .then((documents) => {
        const data = {
          documents,
          ...values,
        };

        return axios.post(`projects/${number}/recurring-payments`, data);
      })
      .then(({ data }) => {
        toast.success("Recurring purchase added successfully");
        return history.push(data.data.link);
      })
      .catch(errorSwal);
  };

  useEffect(() => {
    axios.get(`suppliers`).then(({ data }) => {
      setSuppliers(formatSuppliers(data.data));
    });
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      style={{ maxWidth: "1000px" }}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Add Recurring Purchase</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="col-12 form-group">
              <Field
                label="GST Options"
                required
                url="/gst-options"
                component={SelectInputAsync}
                name="includes_gst"
              />
            </div>
            <div className="col-12 form-group">
              <Field label="Quote" component={FileInput} name="file" />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                label="Quote Number"
                component={renderField}
                name="quote_number"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                label="Description"
                required
                component={renderField}
                name="description"
              />
            </div>
            <div className="col-12 form-group mb-3">
              <Field
                label="Supplier"
                required
                component={SelectInputAsync}
                name="supplier_id"
                options={suppliers}
              />
            </div>
            <FrequencyFields />
            <FieldArray component={ItemFieldArray} {...props} name="pricing" />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton
          onClick={() => props.dispatch(submit("RecurringPurchase"))}
          {...props}
        />
      </ModalFooter>
    </Modal>
  );
};

const form = reduxForm({ form: "RecurringPurchase" });

export default form(RecurringPurchaseModal);
