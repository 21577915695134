import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import swal from "sweetalert";
import useApi from "../api/useApi";
import FormErrorAlert from "../form/FormErrorAlert";
import SelectInput from "../form/SelectInput";
import FileInput from "../upload/FileInput";
import FormHeader from "../utils/FormHeader";
import RenderField from "../utils/renderField";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";

const Form = (props) => {
  const {
    handleSubmit,
    change,
    multiDay,
    dateFrom,
    dateTo,
    fullDay,
    startTime,
    finishTime,
    date,
    initialValues,
    user,
    userLeaveBalances,
  } = props;

  const [approvers, setApprovers] = useState([]);
  const [leaveRequestEstimate, setLeaveRequestEstimate] = useState();

  const {
    loading: fetchingPublicHolidays,
    data: publicHolidays,
    setData,
    setUrl,
  } = useApi("", []);

  const { takeAction, loading, response } = useApi();

  const fetchApprovers = (branchId) => {
    return takeAction(
      "index",
      `timesheet-approval-users/users?branch_id=${branchId}`,
    ).then(({ data }) => {
      setApprovers(data.data);
    });
  };

  useEffect(() => {
    if (typeof multiDay == "undefined") {
      return;
    }

    if (multiDay) {
      change("start_time", null);
      change("finish_time", null);
      return;
    }

    change("date_from", null);
    change("date_to", null);
  }, [multiDay]);

  useEffect(() => {
    if (!multiDay && date) {
      setUrl(
        `public-holidays?filter[between_dates][]=${dayjs(date).format(
          "YYYY-MM-DD",
        )}&filter[between_dates][]=${dayjs(date).format("YYYY-MM-DD")}`,
      );
      return;
    }

    if (dateFrom && dateTo) {
      setUrl(
        `public-holidays?filter[between_dates][]=${dayjs(dateFrom).format(
          "YYYY-MM-DD",
        )}&filter[between_dates][]=${dayjs(dateTo).format("YYYY-MM-DD")}`,
      );
    }
  }, [multiDay, fullDay, dateFrom, dateTo, startTime, finishTime, date]);

  const calculateTotalHours = () => {
    if (!multiDay && fullDay) {
      return 7.6;
    }

    if (!multiDay && !fullDay && date && finishTime && startTime) {
      return dayjs(`${date} ${finishTime}`).diff(
        `${date} ${startTime}`,
        "hours",
        true,
      );
    }

    if (!dateFrom || !dateTo) {
      return 0;
    }

    const totalDays = dayjs(dateTo).diff(dateFrom, "d");
    let weekdayAmount = 0;

    let day;

    const publicHolidayDays = publicHolidays.map(({ date }) =>
      dayjs(date).format("YYYY-MM-DD"),
    );

    for (let i = 0; i <= totalDays; i++) {
      day = dayjs(dateFrom).add(i, "days");

      if (publicHolidayDays.includes(day.format("YYYY-MM-DD"))) {
        continue;
      }

      if ([0, 6].includes(day.day())) {
        continue;
      }

      weekdayAmount++;
    }

    return weekdayAmount * 7.6;
  };

  const onChangeRole = (values) => {
    change("approver", "");
    setApprovers([]);
    if (values?.branch_id) {
      fetchApprovers(values.branch_id);
    }
  };

  const calculatedHours = useMemo(calculateTotalHours, [
    multiDay,
    fullDay,
    dateFrom,
    dateTo,
    startTime,
    finishTime,
    date,
    publicHolidays,
  ]);

  const test = useMemo(() => {
    console.log(leaveRequestEstimate);
    return leaveRequestEstimate;
  }, [leaveRequestEstimate]);

  const fetchLeaveRequestEstimate = async () => {
    if (
      (dateFrom && dateTo) ||
      (date && startTime && finishTime) ||
      (date && !startTime && !finishTime)
    ) {
      let leaveRequestEstimateUrl = `integrations/employment-hero/users/leave-request-estimate/${user.uuid}`;
      let urlQueryString;

      if (multiDay) {
        urlQueryString = `?fromDate=${dateFrom}&toDate=${dateTo}`;
      } else if (fullDay) {
        urlQueryString = `?fromDate=${date}&toDate=${date}`;
      } else if (!fullDay) {
        const fromDate = startTime ? `${date} ${startTime}` : date;
        const toDate = finishTime ? `${date} ${finishTime}` : date;

        urlQueryString = `?fromDate=${fromDate}&toDate=${toDate}`;
      }

      const { data } = await takeAction(
        "index",
        `${leaveRequestEstimateUrl}${urlQueryString || ""}`,
      );
      console.log(data);
      setLeaveRequestEstimate(data.data.hours);
    }
  };

  useEffect(() => {
    fetchLeaveRequestEstimate();
  }, [multiDay, fullDay, dateFrom, dateTo, startTime, finishTime, date]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormHeader>Details</FormHeader>
        <FormErrorAlert error={props.error} />
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="leave_reason_id"
            validate={required}
            required
            label="Reason"
            formatData={(leaveReasons) =>
              formatLeaveReasons(leaveReasons, userLeaveBalances)
            }
            url="/leave-reasons"
            aria-label="Leave Reason"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={SelectInput}
            name="role_id"
            validate={required}
            required
            label="Role"
            formatData={formatRoles}
            url={`/users/${user.id}/staff-roles`}
            changeValue={onChangeRole}
            getOptionValues={(options, onChange) => {
              if (options.length === 1) {
                onChange(options[0].value);
                onChangeRole(options[0]);
              }
            }}
          />
        </div>
        {initialValues?.approval_action?.action_type === "Redo" ||
        !initialValues?.uuid ? (
          <div className="col-lg-4 form-group">
            <Field
              component={SelectInput}
              label="Approver"
              options={approvers}
              name="approver"
              required
              validate={required}
              isLoading={loading}
            />
            {loading && <small>Fetching approvers...</small>}
            {approvers.length === 0 && response && !loading && (
              <small>No approvers found for selected branch.</small>
            )}
          </div>
        ) : null}

        <div className="col-lg-12 form-group">
          <Field
            component={RenderField}
            name="remarks"
            textarea
            label="Remarks"
          />
        </div>
        {initialValues?.uuid ? null : (
          <div className="col-lg-12 form-group">
            <Field component={FileInput} name="file" label="Documentation" />
          </div>
        )}
        <div className="col-lg-12 form-group">
          <Field
            component={renderToggleInput}
            name="multi_day"
            label="Request for more than one day"
            ariaLabel="multi_day"
          />
        </div>

        {multiDay ? (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                type="date"
                name="date_from"
                label="From"
                required
                validate={required}
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                type="date"
                name="date_to"
                label="To"
                required
                validate={required}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name="date"
                label="Date"
                type="date"
                required
                validate={required}
              />
            </div>
            {initialValues?.uuid ? (
              <div className="col-lg-6 form-group"></div>
            ) : (
              <div className="col-lg-6 form-group">
                <Field
                  component={renderToggleInput}
                  name="full_day"
                  label="Full Day"
                />
              </div>
            )}
            {!fullDay && (
              <>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    type="time"
                    name="start_time"
                    label="Start Time"
                  />
                  <small>Leave blank for full day</small>
                </div>
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    type="time"
                    name="finish_time"
                    label="Finish Time"
                  />
                </div>
              </>
            )}
          </>
        )}
        {leaveRequestEstimate || (calculatedHours && calculatedHours !== 0) ? (
          <div className="col-12 form-group">
            <p className="mb-0 text-dark">
              {loading && fetchingPublicHolidays ? (
                "Loading..."
              ) : (
                <>
                  <span id="calculated-hours" className="fw-bolder">
                    {" "}
                    {leaveRequestEstimate || calculatedHours.toFixed(2)}
                  </span>{" "}
                  total hours
                </>
              )}
            </p>
          </div>
        ) : null}
      </div>

      <div className="row">
        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const formatRoles = (data) => {
  return data.map((staffRole) => {
    return {
      ...staffRole,

      label: staffRole.role_branch,
      value: staffRole.id,
      disabled: staffRole.is_disabled,
    };
  });
};

const formatLeaveReasons = (leaveReasons, userLeaveBalances) => {
  if (leaveReasons.length === 0) {
    swal({
      title: "No leave Reasons Found",
      text: `Your organisation has not added any leave reasons in yet.

      You are unable to add in leave without a "reason".
      
      Please talk to one of your organisation's administrators to rectify this.`,
      icon: "warning",
    });
    return [];
  }

  return leaveReasons.map((reason) => {
    if (userLeaveBalances && userLeaveBalances.length !== 0) {
      const leaveCategory = userLeaveBalances.find(
        (userLeaveBalance) =>
          userLeaveBalance.leaveCategoryName === reason.reason,
      );

      const label = leaveCategory
        ? `${reason.reason} (${
            leaveCategory?.accruedAmount
          } ${leaveCategory?.unitType.toLowerCase()})`
        : reason.reason;

      return {
        label,
        value: reason.id,
      };
    }

    return {
      label: reason.reason,
      value: reason.id,
    };
  });
};

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form);

  return {
    dates: selector(state, "dates"),
    multiDay: selector(state, "multi_day"),
    dateFrom: selector(state, "date_from"),
    dateTo: selector(state, "date_to"),
    startTime: selector(state, "start_time"),
    finishTime: selector(state, "finish_time"),
    fullDay: selector(state, "full_day"),
    date: selector(state, "date"),
  };
};

const form = reduxForm({});

export default connect(mapStateToProps, {})(form(Form));
