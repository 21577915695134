import { toast } from "react-toastify";
import { Field } from "redux-form";
import { DataResponse, IUseApi, IUseApiWithData } from "../api/apiTypes";
import useApi from "../api/useApi";
import { CustomFieldListItem } from "../customFields/customFieldTypes";
import SelectInput from "../form/SelectInput";
import FormModal from "../utils/FormModal";
import { EditTopicProps } from "./EditTopic";
import { Topic } from "./topicType";
import { Button } from "reactstrap";
import formError from "../utils/formError";

const TopicPositionModal = (
  props: EditTopicProps & { toggle: Function; modal: boolean },
) => {
  const { toggle, modal, topic, setTopic } = props;

  const { takeAction }: IUseApi = useApi();

  const { data }: IUseApiWithData<CustomFieldListItem[]> = useApi(
    "/custom-fields/positions",
    [],
  );

  const onSubmit = (values: any) => {
    return takeAction("store", `/topics/${topic?.uuid}/positions`, values)
      .then(({ data }: DataResponse<Topic>) => {
        setTopic(data.data);
        toast.success("Position/s Added");
        toggle();
      })
      .catch(formError);
  };

  const options = formatter(data, topic);

  return (
    <FormModal
      form="TopicPosition"
      onSubmit={onSubmit}
      modal={modal}
      toggle={toggle}
      title={`Add Position to ${topic?.name}`}
    >
      {({ change }: { change: Function }) => {
        return (
          <div className="col-12 form-group">
            <div className="d-flex align-items-center mb-2">
              <label className="tx-inverse tx-semibold mb-0">Positions</label>
              <div className="d-flex ms-auto align-items-center">
                <Button
                  size="sm"
                  outline
                  onClick={() => change("position_ids", options)}
                >
                  Add All
                </Button>
              </div>
            </div>
            <Field
              component={SelectInput}
              name="position_ids"
              options={options}
              isMulti
            />
          </div>
        );
      }}
    </FormModal>
  );
};

const formatter = (data: CustomFieldListItem[], topic?: Topic) => {
  const positionIds = topic?.positions.map(({ id }) => id) ?? [];

  return data
    .filter((position) => !positionIds.includes(position.id))
    .map((position) => {
      return {
        value: position.id,
        label: position.name,
      };
    });
};

export default TopicPositionModal;
