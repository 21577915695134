import React from "react";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Empty } from "../../svgs/empty.svg";
import useModal from "../hooks/useModal";
import StandardDocumentModal from "../standardDocuments/standardDocumentModal";
import FileCard from "../utils/FileCard";
import ProfileButtons from "../utils/ProfileButtons";
import SVGContainer from "../utils/SVGContainer";

const FilePage = (props) => {
  const { receipt, setReceipt } = props;
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const buttons = [
    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" /> Upload File
        </>
      ),
      onClick: toggle,
    },
    {
      text: (
        <>
          <i className="fa fa-download tx-primary tx-16 mx-2" />
          Download File
        </>
      ),
      onClick: () => (window.location.href = receipt.file.link),
    },
  ];

  return (
    <>
      <ProfileButtons buttons={buttons} />
      {receipt.file.link ? (
        <FileCard link={receipt.file.link} mime_type={receipt.file.mime_type} />
      ) : (
        <SVGContainer SVG={Empty}>
          <p className="mg-y-20">No receipt added, click above to add!</p>
        </SVGContainer>
      )}
      <StandardDocumentModal
        {...props}
        modal={modal}
        toggle={toggle}
        whenSubmitted={({ data }) => {
          setReceipt({
            ...receipt,
            file: data.data,
          });
        }}
        documentable_id={receipt.uuid}
        documentable_type="App\Models\Purchase"
        bucket={`organisations/${user.active_organisation.uuid}/credit-card-receipts/${receipt.uuid}`}
      />
    </>
  );
};

export default FilePage;
