import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../api/useApi";
import ApprovalButton from "../approvals/ApprovalButton";
import SideList from "../comments/SideList";
import GenerateDocket from "../dockets/generateFromButton";
import HeaderPage from "../header/HeaderPage";
import { useAuth } from "../../context/auth-context";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import InformationAlert from "../utils/InformationAlert";
import Spinner from "../utils/Spinner";
import DependenciesWidget from "./DependenciesWidget";
import InfoCard from "./InfoCard";
import JobHeader from "./JobHeader";
import LinkTenderButton from "./LinkTenderButton";
import ProfileNavigation from "./ProfileNavigation";
import ShowPage from "./ShowPage";
import { AdministrationLevels } from "../user/userTypes";
import UserMustSign from "../customJobs/jobs/UserMustSign";
import DeleteJobButton from "./DeleteJobButton";
import DocsModal from "../utils/DocsModal";

const JobProfile = (props) => {
  const { match } = props;
  const { jobUuid, number } = useParams();
  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const isBasic =
    user.position_administration_level == AdministrationLevels.Basic &&
    !user.is_admin;

  useEffect(() => {
    setUrl(`projectjobs/${jobUuid}`);
  }, [jobUuid]);

  const {
    loading,
    data: job,
    setData: setJob,
    setUrl,
    refreshData,
  } = useApi(`projectjobs/${jobUuid}`, "", true, "/jobs");

  if (loading) return <Spinner loading />;

  return (
    <>
      <HeaderPage
        titlePage={job.full_name}
        crumbs={[
          { link: "projects", name: "Projects" },
          {
            link: `projects/${number}/jobs`,
            name: job?.project?.number,
          },
          {
            link: "",
            name: job.number,
            active: true,
          },
        ]}
      />
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/project-management/jobs/" />

      <UserMustSign toSign={job.user_must_sign} />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={job}
            viewable_type={"App\\Models\\ProjectJob"}
          />
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-lg-8">
          <JobHeader job={job} {...props} setJob={setJob} />
          {job.can_only_edit_status && (
            <div className="mb-3">
              <InformationAlert
                title="Only Job Status Can Be Edited"
                body="Invoices created for all deliverables. Please add a new deliverable to edit the details."
                type="warning"
              />
            </div>
          )}

          <GenerateDocketButton job={job} className="d-lg-none" {...props} />

          <LinkTenderButton
            job={job}
            className="d-lg-none"
            onSuccess={refreshData}
          />
          <StandardJobLink className="d-lg-none w-100" {...job} />
          <ShowPage {...match.params} {...props} job={job} setJob={setJob} />
        </div>
        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <ProfileNavigation {...match.params} {...props} job={job} />

          <StandardJobLink className="d-lg-block d-none" {...job} />

          <GenerateDocketButton
            job={job}
            className="d-none d-lg-block"
            {...props}
          />

          <LinkTenderButton
            job={job}
            className="d-none d-lg-block"
            onSuccess={refreshData}
          />

          <ApprovalButton
            approvalAction={job?.invoiceless_job_request?.approval_action}
            approvalOptions={[
              { value: 1, label: "Approve" },
              { value: 3, label: "Deny" },
            ]}
          />

          {isPositionAdmin && <InfoCard job={job} />}
          <DeleteJobButton job={job} />
          <div className="mt-3">
            <SideList
              commentableType="App\Models\ProjectJob"
              commentableId={job.id}
            />
          </div>
          <DependenciesWidget
            jobs={job.dependants}
            title="Dependants (Parents)"
          />
          <DependenciesWidget
            jobs={job.dependencies}
            title="Dependencies (Children)"
          />
        </div>
      </div>
    </>
  );
};

const GenerateDocketButton = (props) => {
  const { job, match, className } = props;
  if (job.docket || !job.client) return null;
  return (
    <div className={`my-3 ${className}`}>
      <GenerateDocket
        title={job.name}
        {...props}
        {...match.params}
        initialValues={job}
      />
    </div>
  );
};

const StandardJobLink = (props) => {
  const { is_standard, standard_job_type, className, job_type, job_type_type } =
    props;

  if (!is_standard && !job_type.link) return null;

  const link = is_standard ? standard_job_type.link : job_type.link;

  const name = is_standard ? standard_job_type.type : job_type.name;

  if (job_type_type === "App\\Models\\Integration") {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={`btn btn-outline-primary my-3 ${className}`}
        href={link}
      >
        Go to {name}
      </a>
    );
  }

  return (
    <Link className={`btn btn-outline-primary my-3 ${className}`} to={link}>
      Go to {name}
    </Link>
  );
};

export default JobProfile;
