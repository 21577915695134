import { useState } from "react";
import { toast } from "react-toastify";
import { Field, reset } from "redux-form";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import { draftField } from "../form/formatters";
import MentionField from "../form/MentionField";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import errorSwal from "../utils/errorSwal";
import FileList from "../utils/FileList";
import FormModal from "../utils/FormModal";
import required from "../utils/required";

const AddModal = (props) => {
  const { commentableType, commentableId, toggle, setComments, comments } =
    props;
  const [files, setFiles] = useState([]);

  const { user } = useAuth();

  const form = "AddCommentModal";
  const { takeAction } = useApi();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user.active_organisation.uuid}/comments`,
  );

  const onSubmit = (values, dispatch) => {
    return upload(values.documents ?? [])
      .then((documents) => {
        const data = {
          commentable_type: commentableType,
          commentable_id: commentableId,
          ...values,
          documents,
        };

        return takeAction("store", "comments", data);
      })
      .then(({ data }) => {
        setComments([data.data, ...comments]);
        toast.success("Comment added.");
        dispatch(reset(form));
        setFiles([]);
        toggle();
      })
      .catch(errorSwal);
  };

  return (
    <FormModal
      form={form}
      title="Add new Comment"
      onSubmit={onSubmit}
      {...props}
    >
      {(formProps) => (
        <>
          <div className="col-12 form-group">
            <Field
              component={MentionField}
              name="comment"
              textarea
              label="Comment"
              validate={required}
              required
              spellCheck={true}
              {...draftField}
            />
          </div>
          <div className="col-12 form-group">
            <FileList {...formProps} files={files} setFiles={setFiles} />
          </div>
        </>
      )}
    </FormModal>
  );
};

export default AddModal;
