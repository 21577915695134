import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { Button, Progress } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { percentage } from "../form/formatters";
import HeaderPage from "../header/HeaderPage";
import Profile from "../profile";
import { ProfileTab } from "../tabs/tabTypes";
import { DashboardH3, DashboardHeader } from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import ShowTopicPage from "./ShowTopicPage";
import { Topic } from "./topicType";
import { BiTrash } from "react-icons/bi";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

export type TopicProfilePages = "details" | "subjects" | "positions";

const TopicProfile = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const history = useHistory();

  const {
    data: topic,
    loading,
    setData: setTopic,
  }: IUseApi<Topic> = useApi(`/topics/${uuid}`, null, true);

  const { takeAction } = useApi();

  const restoreTopic = () => {
    return takeAction("update", `/topics/${uuid}/restore`).then(() => {
      history.push(`/topics/${uuid}/subjects`);
      toast.success("Topic restored successfully.");
    });
  };

  const deleteTopic = () => {
    return deleteSwal(
      topic?.name,
      "You will no longer be able to view any content within this topic.",
    )
      .then(() => {
        return takeAction("destroy", `/topics/${uuid}`).then(() => {
          history.push("/topics");
          toast.success(
            <div className="d-flex align-items-center">
              <p className="mb-0">Topic deleted successfully.</p>
              <Button onClick={restoreTopic} className="ms-auto" color="link">
                Undo
              </Button>
            </div>,
          );
        });
      })
      .catch(errorSwal);
  };

  const flattenedItems = _.flatten(topic?.subjects.map(({ items }) => items));

  const readingCount = flattenedItems.filter(
    ({ requires_reading }) => !requires_reading,
  ).length;

  let percent = 0;

  if (
    typeof readingCount === "number" &&
    typeof flattenedItems.length === "number"
  ) {
    percent = (readingCount / flattenedItems.length) * 100;
  }

  if (loading) {
    return <Spinner loading />;
  }

  return (
    <>
      <HeaderPage
        titlePage={topic?.name ?? "-"}
        crumbs={[
          {
            name: "Topics",
            link: "/topics",
          },
          {
            name: topic?.name ?? "-",
            active: true,
          },
        ]}
      />
      <Profile
        content={<ShowTopicPage topic={topic} setTopic={setTopic} />}
        header={<Header topic={topic} />}
        tabs={Tabs()}
        sideBar={
          <>
            {topic?.subjects.length === 0 ? null : (
              <div className="mt-3">
                <div className="d-flex">
                  <p className="text-dark mb-1 fw-bolder">Your Progress</p>
                  <p className="ms-auto mb-1">{percentage.format(percent)}</p>
                </div>
                <Progress
                  striped
                  animated
                  className="rounded-pill"
                  value={percent}
                />
              </div>
            )}
            {user?.hasAccessTo("App\\Models\\Topic", "delete") && (
              <Button
                onClick={deleteTopic}
                color="danger"
                outline
                className="mt-3"
                block
              >
                <BiTrash /> Delete Topic
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

const Tabs = (): ProfileTab<TopicProfilePages>[] => {
  const { uuid } = useParams<{ uuid: string }>();

  const { user } = useAuth();

  const tabs: ProfileTab<TopicProfilePages>[] = [
    {
      label: "Subjects",
      link: `/topics/${uuid}/subjects`,
      page: "subjects",
    },
  ];

  if (user?.hasAccessTo("App\\Models\\Topic", "update")) {
    tabs.push({
      label: "Details",
      link: `/topics/${uuid}/details`,
      page: "details",
    });
  }

  if (user?.hasAccessTo("App\\Models\\Topic", "update_positions")) {
    tabs.push({
      label: "Positions",
      link: `/topics/${uuid}/positions`,
      page: "positions",
    });
  }

  return tabs;
};

const Header = ({ topic }: { topic?: Topic }) => {
  return (
    <DashboardHeader>
      <DashboardH3>{topic?.name}</DashboardH3>
    </DashboardHeader>
  );
};

export default TopicProfile;
