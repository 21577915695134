import axiosWithoutAuthenticationHeaders from "axios";
import { useState } from "react";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";

const useUploadMultipleToS3 = (uploadBucket) => {
  const { takeAction } = useApi();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [bucket, setBucket] = useState(uploadBucket);

  const upload = (filesToUpload = [], withFilePath = false) => {
    if (!bucket) {
      throw new Error(
        "No bucket found to upload to, please pass a bucket variable to the useUploadMultipleToS3 hook.",
      );
    }

    setLoading(true);

    setFiles(filesToUpload);

    return Promise.all(
      filesToUpload.map((file, index) =>
        uploadFile(file, index, filesToUpload, withFilePath),
      ),
    );
  };

  const uploadFile = (file, index, f, withFilePath = false) => {
    if (!file || !(file instanceof File)) {
      return Promise.resolve(file);
    }

    let path = "";

    return takeAction("store", "/pre-signed-urls", {
      name: file.name,
      bucket,
    })
      .then(({ data }) => {
        path = data.data.path;
        return axiosWithoutAuthenticationHeaders.put(data.data.url, file, {
          "Content-Type": "multipart/form-data",
          onUploadProgress: ({ loaded, total }) => {
            setFiles(
              f.map((current, i) => {
                if (i === index) {
                  current.progress = Math.round((loaded * 100) / total);
                }
                return current;
              }),
            );
          },
        });
      })
      .then(() => {
        const document = {
          path,
          name: file.name,
          file_size: file.size,
          mime_type: file.type,
        };

        if (withFilePath) {
          document.filePath = file.path;
        }

        return document;
      })
      .then((data) => {
        setLoading(false);
        return data;
      })
      .catch((err) => {
        errorSwal(err);
      });
  };

  return {
    files,
    setFiles,
    loading,
    upload,
    setBucket,
  };
};

export default useUploadMultipleToS3;
