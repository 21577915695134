import React, { useState, useEffect } from "react";
import { ResizableBox } from "react-resizable";
// import "react-resizable/css/styles.css";

const FileCard = ({
  link,
  mime_type,
  cardHeight = 930,
  cardBodyClassName = "card-body",
  cardBodyStyle = {},
}) => {
  const isImage = mime_type && mime_type.substring(0, 5) === "image";

  const [height, setHeight] = useState(cardHeight);
  const onResize = (e, data) => {
    const { size } = data;
    const { height: newHeight } = size;
    setHeight(newHeight);
  };

  useEffect(() => {
    if (image.current) {
      image.current.onload = () => {
        if (image.current) {
          setHeight(image.current.height + 35);
        }
      };
    }
  }, [link]);

  const image = React.createRef();

  const style = {
    borderWidth: "0",
    borderStyle: "initial",
    width: "100%",
    height: `${height - 40}px`,
  };

  return (
    <div className="card border-0">
      <ResizableBox
        height={isImage ? undefined : height}
        className="box"
        onResize={onResize}
        axis="y"
        minConstraints={[10, 250]}
      >
        <span>
          <div className={cardBodyClassName} style={cardBodyStyle}>
            {isImage ? (
              <div className="w-100 h-100">
                <img ref={image} src={link} style={{ maxWidth: "100%" }} />
              </div>
            ) : (
              <iframe
                title="deliverable-file"
                style={style}
                src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
                  link,
                )}`}
              />
            )}
          </div>
        </span>
      </ResizableBox>
    </div>
  );
};

export default FileCard;
