import * as Sentry from "@sentry/react";
import React from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import useApi from "../api/useApi";
import useS3Uploader from "../hooks/useS3Uploader";
import FileInput from "../upload/FileInput";
import errorSwal from "../utils/errorSwal";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInputAsync from "../utils/SelectInputAsync";
import Spinner from "../utils/Spinner";
import SubmitButton from "../utils/SubmitButton";

const BugModal = (props) => {
  const { handleSubmit, toggle, modal, submitting, form, reset } = props;

  const { takeAction } = useApi();
  const { upload, uploadPercent, setUploadPercent } = useS3Uploader();

  const onSubmit = (values) => {
    if (values.file) {
      return upload(values.file, "bug-reports", (document) => {
        return store({ ...values, document });
      });
    }
    return store(values);
  };

  const store = (values) => {
    values.name = `${values.name}\n\n Sentry ID: ${Sentry.captureMessage(
      values.name,
    )}`;

    return takeAction("store", "bug-reports", {
      ...values,
      path: window.location.href,
    })
      .then(() => {
        toast.success(
          `${values.type == "bug" ? "Bug" : "Feature request"} submitted`,
        );
        setUploadPercent(0);
        toggle();
        reset(form);
      })
      .catch(errorSwal);
  };

  return (
    <Modal
      backdrop="static"
      isOpen={modal}
      toggle={toggle}
      className="modal-md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Suggest Improvement</ModalHeader>
        <ModalBody>
          <div className="row">
            {submitting && <Spinner loading={submitting} />}

            <div className="col-lg-12 form-group">
              <Field
                component={SelectInputAsync}
                options={[
                  { label: "Bug", value: "bug" },
                  { label: "Feature Request", value: "request" },
                  {
                    label: "Support Request",
                    value: "support_request",
                  },
                ]}
                label="Bug or Feature Request"
                name="type"
                validate={required}
                required
              />
            </div>

            <div className="col-lg-12 form-group">
              <Field
                name="name"
                type="textarea"
                textarea
                rows={6}
                cols={64}
                component={RenderField}
                validate={required}
                required
              />
            </div>
            <div className="col-lg-12 form-group">
              <Field
                name="file"
                component={FileInput}
                label="File"
                percent={uploadPercent}
              />
              <small>
                If possible, please upload a screenshot of your issue. This will
                help us to get your issue fixed as soon as possible.
              </small>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <SubmitButton {...props} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

const form = reduxForm({
  form: "BugReport",
  initialValues: { type: "support_request" },
});

export default form(BugModal);
