import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import BugModal from "../reportBug/BugModal";
import FloatingActionButton from "./FloatingActionButton.jsx";
import { useCreateZip } from "../../context/createZip-context";
import ZippingDocument from "../standardDocuments/ZippingDocument";

const Footer = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { user } = useAuth();
  const { creatingZip, zipUrl, setZipUrl } = useCreateZip();

  return (
    <>
      <div className="footer" style={{ zIndex: 10 }}>
        <FloatingActionButton toggleBug={toggle} />

        <div className="slim-footer d-none d-lg-block">
          <div className="container">
            <p>
              Copyright {new Date().getFullYear()} &copy; All Rights Reserved.
              Protest Software.
            </p>
          </div>
        </div>
        <ZippingDocument
          zipUrl={zipUrl}
          creatingZip={creatingZip}
          resetZipEvent={() => setZipUrl(undefined)}
        />
        <div className="slim-footer d-none d-lg-block">
          <div className="container">
            <div className="col-8 me-auto">
              <p>
                Copyright {new Date().getFullYear()} &copy; All Rights Reserved.
              </p>
            </div>
            {/* <div className="col-2 text-center">
                <a target="_blank" href="https://status.protestboss.com">
                  Service Status
                </a>
              </div> */}
            <div className="col-2 text-center">
              <Link to="/cpirs/add">Add CPIR</Link>
            </div>
            {user.active_organisation.beta && (
              <div className="col-2">
                <button type="button" className="btn btn-link" onClick={toggle}>
                  Suggest Improvement
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <BugModal modal={modal} toggle={toggle} />
    </>
  );
};

export default Footer;
