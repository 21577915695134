import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, formValueSelector } from "redux-form";
import { updateNuclearDensometer } from "../../actions/nuclearDensometerActions";
import SelectInput from "../form/SelectInput";
import errorSwal from "../utils/errorSwal";

import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import density_specs from "./density_specs";
import moisture_specs from "./moisture_specs";
import test_depths from "./test_depths";
import validate from "./validate";

class FormNuclearDensometer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      updating: [],
    };
  }

  submit = (index) => (values) => {
    validate(index, values);
    this.setState({ updating: [...this.state.updating, index] });

    return this.props
      .updateNuclearDensometer(values.nucs[index])
      .then(() => toast.success("Nuc Updated"))
      .catch(errorSwal)
      .then(() =>
        this.setState({
          updating: this.state.updating.filter((up) => up !== index),
        }),
      );
  };

  render() {
    const {
      fields,
      initialValues,
      handleSubmit,
      densityGauges,
      nucForm,
      change,
    } = this.props;

    return (
      <div className="row flex-nowrap" style={{ overflowX: "auto" }}>
        {fields.map((nuc, index) => {
          const nucData = initialValues.nucs[index];

          return (
            <div key={index} className="col-lg-3 col-md-6 col-12">
              <div className="row align-items-center">
                <div className="col-6 mb-3">
                  <h6 className="tx-inverse tx-bold mb-0 p">
                    {nucData.sample_number}
                  </h6>
                </div>
                <div className="col-6 mb-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => copyPrevious(nucForm, index, change)}
                    disabled={index === 0}
                  >
                    Copy Previous
                  </button>
                </div>
                <div className="form-group col-12">
                  <Field
                    required
                    component={SelectInput}
                    options={densityGauges}
                    label="Nuclear Densometer"
                    name={`${nuc}.equipment_id`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                  {densityGauges.length === 0 && (
                    <small>
                      No Nuclear Densometers found in your equipment list.
                      Please add one before continuing.
                    </small>
                  )}
                </div>
                <div className="form-group col-12">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    extraProps={{ step: "0.01" }}
                    label="Layer Depth"
                    name={`${nuc}.layer_depth`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-12">
                  <Field
                    required
                    component={SelectInputAsync}
                    options={test_depths}
                    label="Test Depth"
                    name={`${nuc}.test_depth`}
                    onBlur={handleSubmit(this.submit(index))}
                    order={false}
                  />
                </div>

                <div className="form-group col-12  ">
                  <Field
                    required
                    component={SelectInputAsync}
                    options={density_specs}
                    order={false}
                    label="Density Spec"
                    name={`${nuc}.density_spec`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>

                <div className="form-group col-12  ">
                  <Field
                    required
                    options={moisture_specs}
                    component={SelectInputAsync}
                    order={false}
                    label="Moisture Spec"
                    name={`${nuc}.moisture_spec`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    label="Location 1"
                    name={`${nuc}.sample.location_1`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    label="Location 2"
                    name={`${nuc}.sample.location_2`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    label="Location 3"
                    name={`${nuc}.sample.location_3`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    component={renderField}
                    label="Location 4"
                    name={`${nuc}.sample.location_4`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>

                <div className="form-group col-6  ">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    label="Standard DC"
                    name={`${nuc}.standard_density_count`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    required
                    label="Standard MC"
                    component={renderField}
                    type="number"
                    name={`${nuc}.standard_moisture_count`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    label="DC"
                    name={`${nuc}.density_count`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-6">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    label="MC"
                    name={`${nuc}.moisture_count`}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-12">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    label="Wet Density"
                    name={`${nuc}.wet_density`}
                    extraProps={{ step: "0.01" }}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="form-group col-12">
                  <Field
                    required
                    component={renderField}
                    type="number"
                    label="Moisture Percent"
                    name={`${nuc}.moisture_percent`}
                    extraProps={{ step: "0.01" }}
                    onBlur={handleSubmit(this.submit(index))}
                  />
                </div>
                <div className="col-12">
                  <small>Last updated by:</small>
                  <p className="tx-inverse  mb-0">{nucData.activity.user}</p>
                  <small>at:</small>
                  <p className="tx-inverse ">{nucData.activity.created_at}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const copyPrevious = (nucForm, index, change) => {
  const previousNuc = _.pick(nucForm[index - 1], [
    "layer_depth",
    "test_depth",
    "standard_density_count",
    "standard_moisture_count",
    "equipment_id",
    "density_spec",
    "moisture_spec",
  ]);

  const newValues = nucForm.map((values, i) => {
    return i === index
      ? {
          ...values,
          ...previousNuc,
        }
      : values;
  });
  change("nucs", newValues);
};

const selector = formValueSelector("nuclearDensometer");

const mapStateToProps = (state) => {
  return { nucForm: selector(state, "nucs") };
};

export default connect(mapStateToProps, { updateNuclearDensometer })(
  FormNuclearDensometer,
);
