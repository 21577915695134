import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ReactLinkify from "react-linkify";
import { useQueryClient } from "react-query";
import { Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useModal from "../hooks/useModal";
import useCreateLike from "../likes/hooks/useCreateLike";
import LikeModal from "../likes/LikeModal";
import InitialAvatar from "../utils/InitialAvatar";
import OpenGraph from "../utils/OpenGraph";
import isSubmitting from "../utils/submitting";
import NoticeCardDropdown from "./NoticeCardDropdown";
import NoticeComments from "./NoticeComments";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import { BiDownload } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import PreviewModal from "../documents/PreviewModal";

dayjs.extend(relativeTime);

const Notice = ({ notice }) => {
  const { toggle, modal: show } = useModal();
  const { toggle: toggleModal, modal: showModal } = useModal();
  const { user } = useAuth();

  /** @param {import("../../types/typedefs").LikeQuery} likeQuery */
  const likeQuery = {
    likeable_id: notice.id,
    likeable_type: "App\\Models\\Noticeboard",
  };

  const queryData = useQueryClient();

  const { mutateAsync: addLike, isLoading } = useCreateLike(likeQuery);

  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(notice.documents ?? []);

  const { toggle: togglePreview, modal: previewModal } = useModal();

  return (
    <>
      <div className="bg-white border">
        <div className="p-4">
          <div className="d-flex mb-4">
            <p className="slim-card-title">{notice.name}</p>
            {(user.is_admin || user.id === notice.user_id) && (
              <div className="ms-auto align-self-start">
                <NoticeCardDropdown
                  noticeId={notice.id}
                  noticeName={notice.name}
                  queryData={async () => queryData.invalidateQueries("notices")}
                />
              </div>
            )}
          </div>
          <div className="mb-4 d-flex align-items-center">
            <InitialAvatar
              userName={`${notice.user.first_name} ${notice.user.last_name}`}
            />
            <div>
              <EmailLink user={notice.user} />
              {notice.noticeable ? (
                <p className="mb-0">
                  Posted in{" "}
                  <span className="tx-inverse">{notice.noticeable.name}</span>
                </p>
              ) : null}
            </div>
            <p className="ms-auto mb-0">{dayjs(notice.created_at).fromNow()}</p>
          </div>
          <div className="mt-4 white-space">
            {notice.from_external ? (
              <div dangerouslySetInnerHTML={{ __html: notice.notice }} />
            ) : (
              <ReactLinkify>{notice.notice}</ReactLinkify>
            )}
            <div className="mt-4 white-space">
              <div class="row">
                {notice.documents &&
                  notice.documents.map((document, index) => (
                    <div className="col-3 mb-3">
                      <FileDisplay
                        document={document}
                        setSelectedDocument={setSelectedDocument}
                        toggle={togglePreview}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <OpenGraph text={notice.notice} wrapperClass="mt-3" />
          </div>
          {notice.likes_count > 0 && (
            <p className="mb-0 mt-3">
              Liked By {notice.likes.map((like) => like.user.name).join(", ")}{" "}
              {notice.likes_count > 3 && (
                <Button onClick={toggleModal} color="link" className="p-0">
                  {" "}
                  and others
                </Button>
              )}
            </p>
          )}
          <LikeModal
            likeQuery={likeQuery}
            modal={showModal}
            toggle={toggleModal}
            title={`Likes for ${notice.name}`}
          />
        </div>
        {!notice.from_external && (
          <>
            <div
              className={`card-footer p-0 border-top ${
                show ? "border-bottom" : ""
              }`}
            >
              <Button onClick={toggle} color="link p-3 px-4 border-end">
                Comment ({notice.comments_count})
              </Button>
              <Button
                color="link p-3 px-4 border-end"
                disabled={isLoading}
                onClick={() =>
                  addLike(
                    {},
                    {
                      onSuccess: async () =>
                        queryData.invalidateQueries("notices"),
                    },
                  )
                }
              >
                {isSubmitting(
                  isLoading,
                  `Like (${notice.likes_count})`,
                  "Liking...",
                )}
              </Button>
            </div>
            <NoticeComments show={show} toggle={toggle} notice={notice} />
          </>
        )}
      </div>
      <PreviewModal
        document={selectedDocument}
        modal={previewModal}
        toggle={togglePreview}
        changeDocument={changeDocument}
      />
    </>
  );
};

const EmailLink = ({ user }) => {
  if (!user.email) {
    return (
      <span className="tx-inverse">
        {user.first_name} {user.last_name}
      </span>
    );
  }
  return (
    <a href={`mailto:${user.email}`}>
      {user.first_name} {user.last_name}
    </a>
  );
};

const FileDisplay = ({ document, setSelectedDocument, toggle }) => {
  const style = {
    borderWidth: "0",
    borderStyle: "initial",
    width: "100%",
    height: `490px`,
  };

  const isImage =
    document.mime_type && document.mime_type.substring(0, 5) === "image";

  return (
    <div className="position-relative d-flex align-items-center justify-content-center rounded">
      {isImage ? (
        <img src={document.link} style={{ maxWidth: "100%" }} />
      ) : (
        <iframe
          title="deliverable-file"
          style={style}
          src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
            document.link,
          )}`}
        />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        className="rounded opacity-0 hover-opacity-100 tn-300 bg-black-5 position-absolute z-1000 d-flex align-items-center justify-content-center"
      >
        <a href={document.link} className="p-1 text-white" size="sm">
          <BiDownload className="tx-20" />
        </a>
        <Button
          onClick={() => {
            setSelectedDocument(document);
            toggle();
          }}
          color="link"
          className="p-1 text-white"
          size="sm"
        >
          <AiOutlineEye className="tx-20" />
        </Button>
      </div>
    </div>
  );
};

export default Notice;
