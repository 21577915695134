import renderField from "../utils/renderField";
import { Field, InjectedFormProps } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import Toggle from "react-toggle";
import SelectInput from "../form/SelectInput";
import { percentage } from "../form/formatters";
import FormErrorAlert from "../form/FormErrorAlert";
import renderToggleInput from "../utils/renderToggleInput";
import { useState } from "react";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../../api/api";
import useOrganisationSetting from "../hooks/useOrganisationSetting";

const AddTenderFields = (
  props: InjectedFormProps<any, { showProject?: boolean }> & {
    showProject?: boolean;
    showSave?: boolean;
  },
) => {
  const { showProject = false, showSave = true, change } = props;

  const hasDefaultTender = useOrganisationSetting("tender_default_project");

  const [newClient, setNewClient] = useState(false);

  return (
    <>
      <FormErrorAlert error={props.error} />
      {showProject && hasDefaultTender?.meta_value == 0 && (
        <div className="col-12 form-group">
          <Field
            label="Project"
            name="project_id"
            component={AsyncSelectInput}
            asyncFunction={(inputValue: string) =>
              api
                .get(`/schedule-projects?filter[search]=${inputValue}`)
                .then(({ data }: any) => data.data)
            }
            validate={required}
            required
          />
          <small className="text-muter">Type to see options.</small>
        </div>
      )}
      <div className="form-group col-lg-3">
        <Field
          component={SelectInput}
          name="tender.type_id"
          label="Type"
          required
          validate={required}
          url="/tender-types"
          formatData={(data: any) => {
            return data.map((type: any) => ({
              label: type.name,
              value: type.id,
            }));
          }}
          empty="Unable to find any tender types, please ask admin to add before continuing."
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderField}
          name="tender.project_name"
          label="Project/Job Name"
          information="Enter a name here for the Project/Job. This name will be applied once the Tender is marked as successful."
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={SelectInputAsync}
          name="job.project_manager"
          label="Tender Manager"
          url="/users/staff"
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          name="tender.confidential"
          label="Confidential"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          component={SelectInputAsync}
          name="job.branch_id"
          label="Branch"
          url="/organisationbranches/branches"
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-4">
        <div className="d-flex align-items-center mb-2">
          <label className="tx-inverse tx-semibold mb-0">Client</label>
          <div className="d-flex ms-auto align-items-center">
            <Toggle
              checked={newClient}
              onChange={() => {
                setNewClient(!newClient);
                change("tender.client_name", null);
                change("tender.client_id", null);
              }}
              className="toggle-sm"
              icons={false}
            />
            <span className="tx-sm ms-1">New Client</span>
          </div>
        </div>
        <Field
          name={newClient ? "tender.client_name" : "tender.client_id"}
          component={newClient ? renderField : SelectInputAsync}
          url="/organisations/clients"
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.users"
          label="Staff"
          multiple="true"
          component={SelectInputAsync}
          url="/users/staff"
        />
        <small>
          Staff from Your Organisation that will be working on this job
        </small>
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name="job.scheduled_start_date"
          type="date"
          component={renderField}
          required
          label="Tender Start Date"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.scheduled_finish_date"
          type="date"
          component={renderField}
          required
          label="Tender Due"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.estimated_hours"
          type="number"
          component={renderField}
          label="Estimated Hours to Complete Tender"
          validate={required}
          required
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.work_start_date"
          type="date"
          component={renderField}
          required
          validate={required}
          label="Expected Start Date of Work"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.work_finish_date"
          type="date"
          component={renderField}
          required
          validate={required}
          label="Expected Finish Date of Work"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.probability_of_project_starting"
          component={renderField}
          label="Probability of Work Commencing"
          {...percentage}
        />
      </div>
      {showSave && (
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      )}
    </>
  );
};

export default AddTenderFields;
