import { ColumnDef, Table } from "@tanstack/react-table";
import _ from "lodash";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as PiggyBank } from "../../svgs/piggy_bank.svg";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import { money } from "../form/formatters";
import ReactTable from "../table/ReactTable";
import DeleteButton from "../utils/DeleteButton";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import SpreadPrice from "../utils/SpreadPrice";
import SVGContainer from "../utils/SVGContainer";
import { Price, PriceStateProps } from "./priceTypes";
import { Button } from "reactstrap";

const PriceList = (
  props: PriceStateProps & { onUpdate?: () => void; showAmount?: boolean },
) => {
  const { prices, setPrices, onUpdate, showAmount } = props;

  const [selectedRows, setSelectedRows] = useState<Price[]>([]);
  const [tableInstance, setTableInstance] = useState<Table<any>>();
  const { takeAction }: IUseApi = useApi();

  const deleteMultiple = () => {
    const selectedIds = selectedRows.map((d) => d.id);

    deleteSwal(`${selectedRows.length} prices`)
      .then(() => {
        return takeAction("store", "deleted-pricing", {
          prices: selectedIds,
        });
      })
      .then(() => {
        setPrices(prices.filter((p) => !selectedIds.includes(p.id)));
        toast.success("Prices deleted successfully");
        tableInstance?.toggleAllRowsSelected(false);
        setSelectedRows([]);
      })
      .catch(errorSwal);
  };

  const deletePrice = (price: Price) =>
    takeAction("destroy", `pricing/${price.uuid}`);

  const columnDefs: ColumnDef<Price>[] = [
    {
      id: "item_explainer",
      header: "Item",
      accessorFn: (price) => `${price.item} (${price.units})`,
    },
    {
      accessorKey: "price",
      header: "Price",
      cell: (info) => <SpreadPrice price={info.row.original.price} />,
    },
    {
      accessorKey: "user",
      header: "Added By",
    },
    {
      accessorKey: "delete",
      header: "Delete",
      cell: (info) => (
        <DeletePrice
          prices={prices}
          setPrices={setPrices}
          onUpdate={onUpdate}
          deletePrice={deletePrice}
          price={info.row.original}
        />
      ),
    },
  ];

  if (showAmount) {
    columnDefs.splice(
      3,
      0,
      {
        accessorKey: "amount",
        header: "Amount",
        cell: (info) => info.row.original.amount ?? "-",
      },
      {
        accessorKey: "total",
        header: "Total",
        cell: (info) => {
          return (
            <SpreadPrice
              price={(info.row.original.amount ?? 0) * info.row.original.price}
            />
          );
        },
        footer: `Total: ${money.format(
          _.sumBy(prices, (p) => (p.amount ?? 0) * p.price),
        )}`,
      },
    );
  }

  const columns = useMemo<ColumnDef<Price, any>[]>(
    () => columnDefs,
    [prices, setPrices],
  );

  if (prices.length === 0) {
    return (
      <SVGContainer SVG={PiggyBank}>
        <p className="mg-y-20">
          No prices added, start by adding a price above!
        </p>
      </SVGContainer>
    );
  }

  return (
    <>
      {selectedRows.length > 0 && (
        <div className="d-flex mb-3">
          <Button
            onClick={deleteMultiple}
            size="sm"
            className="ms-auto"
            outline
            color="danger"
          >
            Delete {selectedRows.length} prices
          </Button>
        </div>
      )}
      <ReactTable
        columns={columns}
        data={prices}
        enableMultiSelect
        setSelectedRows={setSelectedRows}
        getTableInstance={setTableInstance}
      />
    </>
  );
};

const DeletePrice = ({
  price,
  onUpdate,
  deletePrice,
  prices,
  setPrices,
}: {
  price: Price;
  onUpdate?: Function;
  deletePrice: (price: Price) => void;
} & PriceStateProps) => {
  const deleteThis = () => {
    deleteSwal(price.item)
      .then(() => deletePrice(price))
      .then(() => {
        toast.success(`${price.item} deleted successfully`);
        setPrices(prices.filter((p) => p.uuid !== price.uuid));

        return onUpdate?.();
      })
      .catch(errorSwal);
  };

  return <DeleteButton deleteFunction={deleteThis} />;
};

export default PriceList;
