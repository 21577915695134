import { BiCloudUpload } from "react-icons/bi";
import { Button } from "reactstrap";
import useApi from "../api/useApi";

import HeaderPage from "../header/HeaderPage";
import Spinner from "../utils/Spinner";
import AddUserInvite from "./AddUserInvite";
import InviteList from "./InviteList";
import UploadUserInvite from "./UploadUserInvite";

const Invite = () => {
  const {
    data: invites,
    setData: setInvites,
    loading,
  } = useApi("invite/user", []);

  if (loading) return <Spinner loading />;

  return (
    <div>
      <HeaderPage
        titlePage="Invite Staff"
        crumbs={[
          { link: "/organisations/settings/links", name: "Organisation" },
          { link: "users/invite", name: "Invite Staff", active: true },
        ]}
      />
      <div className="row mb-3">
        <div className="col-lg-8">
          <AddUserInvite
            setInvites={setInvites}
            invites={invites}
            form="InviteUser"
          />
        </div>
        <div className="col-lg-4">
          <UploadUserInvite setInvites={setInvites} invites={invites} />
        </div>
      </div>
      <InviteList setInvites={setInvites} invites={invites} />
    </div>
  );
};

export default Invite;
