import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";

const InvoiceTable = ({ data }) => {
  if (!data) return null;
  if (data?.length === 0) return null;

  const columns = [
    {
      accessorKey: "name",
      header: "Invoice Number",
      cell: (info) => {
        const invoice = info.row.original;
        return (
          <>
            <Link to={invoice.link}>{info.getValue()}</Link>
            <br />
            <Badge color={invoice.badge.color}>{invoice.badge.text}</Badge>
          </>
        );
      },
    },
    {
      accessorKey: "total_due",
      header: "Invoice Value",
      cell: (info) => <SpreadPrice price={info.row.original.total_due} />,
    },
    {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => {
        const invoice = info.row.original;
        return (
          <Link to={`/${invoice.project.link}`}>{invoice.project.name}</Link>
        );
      },
    },
    {
      accessorKey: "client.name",
      header: "Client",
      cell: (info) => {
        const invoice = info.row.original;
        return <Link to={invoice.client.link}>{invoice.client.name}</Link>;
      },
    },
  ];

  return <ReactTable disableSearch columns={columns} data={data} />;
};

export default InvoiceTable;
