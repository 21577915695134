import React, { useState, useEffect } from "react";
import { FieldArray, reduxForm } from "redux-form";
import Fields from "./Fields";
import useApi from "../api/useApi";

const Form = (props) => {
  const [densityGauges, setDensityGauges] = useState([]);
  const { takeAction } = useApi();

  useEffect(() => {
    takeAction("index", "equipment-type-equipment?filter[type]=nuclear").then(
      ({ data }) => {
        const gauges = data.data.map((d) => ({
          value: d.id,
          label: d.name,
          disabled: d.disabled,
        }));

        setDensityGauges(gauges);
      },
    );
  }, []);

  return (
    <form>
      <FieldArray
        {...props}
        component={Fields}
        densityGauges={densityGauges}
        name="nucs"
      />
    </form>
  );
};
const form = reduxForm({ form: "nuclearDensometer" });
export default form(Form);
